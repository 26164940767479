import { axiosInstance } from './base';

export const CompanyUsersApi = {
	/**
	 *
	 * @returns {Promise<inndox.CompanyUser[]>}
	 */
	fetchCompanyUsers: () => axiosInstance.get('/companies/current/users'),
	/**
	 *
	 * @param {number} userId
	 * @returns {Promise<inndox.CompanyUser>}
	 */
	fetchCompanyUser: (userId) => axiosInstance.get(`/companies/current/users/${userId}`),
	/**
	 *
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.UserAccount>}
	 */
	inviteNewUserToCompany: (data) => axiosInstance.post('/companies/current/users', data),
	/**
	 *
	 * @param {*} userId
	 * @param {Record<string,any>} data
	 * @returns {Promise<void>}
	 */
	updateCompanyUser: (userId, data) => axiosInstance.put(`/companies/current/users/${userId}`, data),
	/**
	 *
	 * @param {number} userId
	 * @returns {Promise<void>}
	 */
	removeCompanyUser: (userId) => axiosInstance.delete(`/companies/current/users/${userId}`),
};
