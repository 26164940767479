import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { useSelector, useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { PaymentsApi } from "../api/payments";
import {
  appendPastPayments,
  selectPaymentDetails,
} from "../store/features/subscription.slice";
import { handleError } from "./helpers";
import { styled } from "styled-components";

const Label = styled.label`
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const PastPaymentItemValue = styled.p`
  font-size: 15px;
`;

const PastPaymentItem = styled(Col)`
  display: flex;
  align-items: center;
`;

const PastPaymentTableHeaderRow = styled(Row)`
  height: 56px;
  background-color: rgba(189, 210, 63, 0.1);
  border-radius: 4px;
  margin-top: 8px;

  ${PastPaymentItem} {
    justify-content: center;
  }

  ${Label} {
    color: #2d3540;
  }
`;

const PastPaymentTableBodyRow = styled(Row)`
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dde0e3;

  ${PastPaymentItem} {
    justify-content: center;
  }
`;

export default function PastPayments() {
  const dispatch = useDispatch();
  const paymentDetails = useSelector(selectPaymentDetails);
  const pastPaymentDetails = paymentDetails.payments;
  const {
    currentPage,
    data: pastPayments,
    pageCount,
    totalCount,
    pageSize,
  } = pastPaymentDetails;
  const [currentPageNumber, setCurrentPageNumber] = useState(currentPage);
  const [isFetchingMorePayments, setIsFetchingMorePayments] =
    useState(currentPage);

  useEffect(() => {
    if (currentPageNumber > 1) {
      async function fetchPastPayments() {
        try {
          setIsFetchingMorePayments(true);
          const updatedPastPaymentDetails = await PaymentsApi.fetchPastPayments(
            pageSize,
            currentPageNumber
          );
          dispatch(appendPastPayments(updatedPastPaymentDetails));
        } catch (e) {
          handleError(e);
        } finally {
          setIsFetchingMorePayments(false);
        }
      }
      fetchPastPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, pageSize]);

  if (!pastPayments?.length) {
    return <noscript />;
  }

  return (
    <>
      <Row className="margin-top-2">
        <Col xs={12}>
          <Label>Payment History</Label>
        </Col>
      </Row>

      {/* Payments */}
      <PastPaymentTableHeaderRow>
        <PastPaymentItem
          xs={12}
          sm={6}
          style={{ justifyContent: "flex-start" }}
        >
          <Label>Description</Label>
        </PastPaymentItem>
        <Hidden xs>
          <PastPaymentItem xs={3} sm={2}>
            <Label>Amount</Label>
          </PastPaymentItem>
        </Hidden>
        <Hidden xs>
          <PastPaymentItem xs={3} sm={2}>
            <Label>Date</Label>
          </PastPaymentItem>
        </Hidden>
        <Hidden xs>
          <PastPaymentItem xs={3} sm={2}>
            <Label>Receipt</Label>
          </PastPaymentItem>
        </Hidden>
      </PastPaymentTableHeaderRow>

      {pastPayments.map((pastPayment, index) => (
        <PastPaymentTableBodyRow key={index}>
          <PastPaymentItem
            xs={12}
            sm={6}
            style={{ justifyContent: "flex-start" }}
          >
            <Hidden xs>
              <PastPaymentItemValue>
                {pastPayment.description}
              </PastPaymentItemValue>
            </Hidden>

            <Visible xs>
              <PastPaymentItemValue>
                {pastPayment.description} on{" "}
                {pastPayment.date.endsWith("Z")
                  ? moment(pastPayment.date).format("DD/MM/YYYY")
                  : moment(pastPayment.date + "Z").format("DD/MM/YYYY")}
              </PastPaymentItemValue>
            </Visible>
          </PastPaymentItem>
          <Hidden xs>
            <PastPaymentItem xs={3} sm={2}>
              <PastPaymentItemValue>${pastPayment.amount}</PastPaymentItemValue>
            </PastPaymentItem>
          </Hidden>
          <Hidden xs>
            <PastPaymentItem xs={3} sm={2}>
              <PastPaymentItemValue>
                {pastPayment.date.endsWith("Z")
                  ? moment(pastPayment.date).format("DD/MM/YYYY")
                  : moment(pastPayment.date + "Z").format("DD/MM/YYYY")}
              </PastPaymentItemValue>
            </PastPaymentItem>
          </Hidden>
          <PastPaymentItem xs={12} sm={2}>
            {pastPayment.receiptUrl?.length ? (
              <>
                <Hidden xs>
                  <a
                    className="button button-link"
                    href={
                      pastPayment.receiptUrl +
                      `?apikey=${process.env.REACT_APP_API_KEY}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </Hidden>

                <Visible xs>
                  <a
                    className="button button-secondary full-width margin-top-2 margin-bottom-2"
                    href={
                      pastPayment.receiptUrl +
                      `?apikey=${process.env.REACT_APP_API_KEY}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Receipt
                  </a>
                </Visible>
              </>
            ) : (
              <noscript />
            )}
          </PastPaymentItem>
        </PastPaymentTableBodyRow>
      ))}

      <Row>
        <Col xs={12}>
          <label>
            Showing {pastPayments.length} of {totalCount} past payments
          </label>
        </Col>
      </Row>

      {/* Paging */}
      {currentPage < pageCount ? (
        <Row className="margin-top-2">
          <Col xs={12} className="text-center">
            <button
              className="button button-secondary"
              onClick={
                isFetchingMorePayments
                  ? undefined
                  : () => setCurrentPageNumber((_) => _ + 1)
              }
            >
              {isFetchingMorePayments ? (
                <ClipLoader loading size={16} color="#bdd23f" />
              ) : (
                "More"
              )}
            </button>
          </Col>
        </Row>
      ) : null}
    </>
  );
}
