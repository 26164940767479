import { Field } from "react-final-form";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import Error from "./error";
import Label, { Description } from "./label";
import Link from "./link";

const MoreInfoContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const animatedComponents = makeAnimated();

export function FormlessSelect({
  input,
  className,
  items,
  placeholder,
  defaultValue,
  hasError,
  canSearch = false,
  mini = false,
  showAbove = false,
}) {
  return (
    <ReactSelect
      {...input}
      menuPlacement={showAbove ? "top" : "auto"}
      options={items}
      isSearchable={canSearch}
      components={animatedComponents}
      placeholder={placeholder}
      classNamePrefix="react-select"
      defaultValue={defaultValue}
      className={className}
      captureMenuScroll
      styles={{
        container: (styles, state) => {
          return {
            ...styles,
            marginRight: "0 !important",
            background: "#FFF",
            boxShadow: "none",
            border: mini
              ? undefined
              : hasError
                ? "1px solid #FF3E3E"
                : state.isFocused
                  ? "1px solid #68c9d6"
                  : "1px solid #DDE0E3",
          };
        },
        control: (styles) => {
          if (styles["boxShadow"]) {
            // if box shadow is defined, it means the select
            // field is focused
            return {
              ...styles,
              marginRight: "0 !important",
              minHeight: 52,
              boxShadow: "none",
              backgroundColor: "#fff",
              border: "none",
              cursor: "pointer",
              "&:hover": {
                border: "none",
              },
            };
          }

          return {
            ...styles,
            minHeight: 52,
            marginRight: "0 !important",
            backgroundColor: "#fff",
            border: "none",
            cursor: "pointer",
            "&:hover": {
              border: "none",
            },
          };
        },
        valueContainer: (styles) => ({
          ...styles,
          textAlign: "left",
          fontSize: mini ? "14px" : "16px",
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: "#ffffff",
          boxShadow: "none",
          borderRadius: 4,
          border: "1px solid #DDE0E3",
        }),
        option: (styles, props) => {
          const updatedStyles = {
            ...styles,
            position: "relative",
            minHeight: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: 32,
            paddingRight: 16,
            cursor: "pointer",
            color: "#143a5a",
            textAlign: "left",
            fontSize: mini ? "14px" : "16px",
            backgroundColor: "#ffffff",
            borderTop:
              props.data.label === "Remove" ? "1px solid #DDE0E3" : "unset",
            marginTop: props.data.label === "Remove" ? 8 : "unset",
            ":hover": {
              backgroundColor: "#efefef",
            },
            ":active": { backgroundColor: "#efefef" },
          };

          if (props.isSelected) {
            updatedStyles["&:before"] = {
              content: '""',
              position: "absolute",
              top: 18,
              left: 5.5,
              width: 3,
              height: 7,
              backgroundColor: "#BFD62F",
              transform: "rotate(-45deg)",
              borderRadius: 2,
            };
            updatedStyles["&:after"] = {
              content: '""',
              position: "absolute",
              top: 12,
              left: 12,
              width: 3,
              height: 15,
              backgroundColor: "#BFD62F",
              transform: "rotate(45deg)",
              borderRadius: 2,
            };
          }

          return updatedStyles;
        },
        indicatorsContainer: (styles) => ({
          ...styles,
          marginRight: "0 !important",
        }),
        indicatorSeparator: (styles) => ({
          ...styles,
          display: "none",
        }),
        dropdownIndicator: (styles) => ({
          ...styles,
          padding: 0,
          color: "#2D3540",
        }),
      }}
    />
  );
}

export default function Select({
  name,
  label,
  items,
  validators,
  description,
  placeholder,
  required,
  className,
  tooltip,
  moreInfo,
}) {
  return (
    <Field
      name={name}
      validate={validators}
      parse={(inputValue) => inputValue?.value}
      format={(storedValue) =>
        items.find((option) => option.value === storedValue)
      }
    >
      {({ input, meta }) => {
        const shouldDisplayError = meta.touched && meta.error;
        return (
          <div className={`form-element ${className}`}>
            <Label label={label} required={required} tooltip={tooltip} />
            {description && <Description>{description}</Description>}
            <FormlessSelect
              input={input}
              items={items}
              placeholder={placeholder}
              hasError={shouldDisplayError}
            />
            {shouldDisplayError && <Error error={meta.error} invalid />}
            {moreInfo ? (
              <MoreInfoContainer>
                <Link
                  title={moreInfo.title}
                  link={moreInfo.link}
                  tabIndex={-1}
                />
              </MoreInfoContainer>
            ) : (
              <noscript />
            )}
          </div>
        );
      }}
    </Field>
  );
}
