import { useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompanyApi } from '../api/company';
import {
	selectCompany,
	updateCurrentCompany,
} from '../store/features/company.slice';
import { handleError } from './helpers';
import xeroLogo from '../assets/images/xero.svg';
import { ClipLoader } from 'react-spinners';
import { styled } from 'styled-components';

const Content = styled.div`
	padding: 32px 32px 24px;
	border-radius: 8px;
	border: 1px solid #dde0e3;
`;

const IconContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
`;

const Title = styled.p`
	margin-top: 8px;
	font-size: 16px;
	font-weight: 700;
`;

export default function XeroIntegration() {
	const company = useSelector(selectCompany);
	const { hash } = useLocation();
	const [isRemovingXero, setIsRemovingXero] = useState(false);
	const dispatch = useDispatch();

	const connectWithXero = () => CompanyApi.updateXeroIntegration();

	const disconnectFromXero = async () => {
		setIsRemovingXero(true);

		try {
			await CompanyApi.removeXeroIntegration();
			const integrations = company.integrations.filter(
				(integration) => !integration.toLowerCase().includes('xero')
			);
			dispatch(
				updateCurrentCompany({
					id: company.id,
					integrations,
				})
			);
		} catch (e) {
			handleError(e);
		} finally {
			setIsRemovingXero(false);
		}
	};

	useEffect(() => {
		if (hash?.length > 0 && hash.includes('#integrations?error=')) {
			try {
				const error = hash?.replace('#integrations?error=', '');
				toast.error(error);
			} catch (e) {
				console.log(e);
			}
		}
	}, [hash]);

	const hasIntegratedWithXero = company?.integrations?.indexOf('Xero') > -1;
	return (
		<Col xs={12} sm={6} className="margin-top-2 margin-bottom-1">
			<Content style={{ textAlign: 'center' }}>
				<IconContainer>
					<img src={xeroLogo} alt="xero logo" />
				</IconContainer>
				<Title>Xero</Title>
				<p>
					{hasIntegratedWithXero
						? 'Your inndox is connected to Xero.'
						: 'Payroll management and automation platform'}
				</p>

				{hasIntegratedWithXero ? (
					isRemovingXero ? (
						<ClipLoader size={16} color="#a0b42a" loading={true} />
					) : (
						<button
							className="button button-remove margin-top-2"
							onClick={() => disconnectFromXero()}>
							Remove integration
						</button>
					)
				) : (
					<button
						className="button button-secondary margin-top-2"
						onClick={connectWithXero}>
						Connect with Xero
					</button>
				)}
			</Content>
		</Col>
	);
}
