import { Col, Hidden, Row } from "react-grid-system";
import moment from "moment";
import AddToCalendar from "./add-to-calendar";
import { useNavigate } from "react-router-dom";
import { CompanyRoutes } from "../routes";
import SetupBlock from "./setup-block";
import { useState } from "react";
import AddOrEditWarrantyModal from "../pages/common/add-or-edit-warranty-modal";

/**
 * @typedef {Object} WarrantyReminderRowItemProps
 * @property {inndox.LogbookDocument} document
 * @property {number} index
 * @property {boolean} canEdit
 */

/**
 *
 * @param {WarrantyReminderRowItemProps} props
 * @returns
 */
function WarrantyReminderSummaryRowItem({ document, canEdit }) {
  return (
    <Row key={`upcoming-warranty-${document.id}`}>
      <Col xs={9} sm={7} className="col-body thick">
        <p className="truncate">{document.name}</p>
      </Col>
      <Hidden xs>
        <Col sm={3} md={4} className="col-body thick">
          <p className="truncate">
            {moment(document.warrantyExpiresOn).fromNow()}
          </p>
        </Col>
      </Hidden>
      <Col xs={3} sm={2} md={1} className="col-body thick">
        <AddToCalendar
          title={document.name}
          date={document.warrantyExpiresOn}
          location="Some location"
        />
      </Col>
    </Row>
  );
}

/**
 * @typedef {Object} WarrantyRemindersListProps
 * @property {inndox.LogbookDocument[]} documents
 * @property {boolean} canEdit
 * @property {boolean} showActions
 */

/**
 *
 * @param {WarrantyRemindersListProps} props
 * @returns
 */
export default function WarrantyRemindersList({
  documents = [],
  canEdit,
  showActions,
}) {
  const navigate = useNavigate();
  const [wantsToAddOrEditWarranty, setWantsToAddOrEditWarranty] =
    useState(false);

  if (!documents?.length) {
    return (
      <>
        <SetupBlock description="There are no documents with a warranty yet in this logbook" />

        <AddOrEditWarrantyModal
          isOpen={wantsToAddOrEditWarranty}
          onClose={() => setWantsToAddOrEditWarranty(false)}
        />
      </>
    );
  }

  return (
    <div className="document-list">
      <Row>
        <Col xs={10} sm={7} className="col-head">
          Title
        </Col>

        <Hidden xs>
          <Col sm={3} md={4} className="col-head">
            Expires
          </Col>
        </Hidden>

        <Col xs={2} sm={2} md={1} className="col-head" />
      </Row>

      {/* Documents */}
      {documents.map((document, index) => (
        <WarrantyReminderSummaryRowItem
          document={document}
          key={document.id}
          index={index}
          canEdit={canEdit}
        />
      ))}

      {/* Actions */}
      {showActions ? (
        <Row
          style={{
            borderTop: "1px solid #dde0e3",
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          <div className="flex end" style={{ width: "100%" }}>
            {canEdit ? (
              <button
                className="button button-secondary"
                onClick={() => setWantsToAddOrEditWarranty(true)}
              >
                Add new
              </button>
            ) : (
              <noscript />
            )}

            <button
              className="button button-alt margin-left-1"
              onClick={() => navigate(CompanyRoutes.warranties)}
            >
              View all
            </button>
          </div>
        </Row>
      ) : (
        <noscript />
      )}

      <AddOrEditWarrantyModal
        isOpen={wantsToAddOrEditWarranty}
        onClose={() => setWantsToAddOrEditWarranty(false)}
      />
    </div>
  );
}
