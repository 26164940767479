import { Col, Row } from "react-grid-system";
import ContactsList, { sortContacts } from "./contact-list";
import { useSelector } from "react-redux";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { useNavigate } from "react-router-dom";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../routes";
import SetupBlockWithImage from "./setup-block-with-image";
import addressBookImage from "../assets/images/address-book.svg";
import { useAuth } from "./authentication";

/**
 * @typedef {Object} ContactsWidgetProps
 * @property {inndox.Contact[]} contacts
 * @property {boolean} isLogbookTransferred
 * @property {boolean} isPartOfATemplate
 * @property {number} ownerId
 */

/**
 *
 * @param {ContactsWidgetProps} props
 * @returns
 */
export default function ContactsWidget({
  contacts,
  isLogbookTransferred,
  isPartOfATemplate,
  ownerId,
}) {
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const navigate = useNavigate();
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook = session.userId !== ownerId;

  if (!contacts?.length) {
    if (!isCompanyUser && isCurrentUserAReadOnlyMemberForLogbook) {
      return <noscript />;
    }

    return (
      <>
        <Row>
          <Col xs={12} className="margin-top-2">
            <label>Contacts</label>
          </Col>
        </Row>
        <SetupBlockWithImage
          description="Add useful contacts"
          image={addressBookImage}
        >
          <div
            className="margin-top-2"
            onClick={() => {
              if (isPartOfATemplate) {
                navigate(TemplateRoutes.addTemplateContact);
              } else if (isCompanyUser) {
                navigate(CompanyRoutes.addLogbookContact);
              } else {
                navigate(OwnerRoutes.addLogbookContact);
              }
            }}
          >
            <button className="button button-secondary">Add contact</button>
          </div>
        </SetupBlockWithImage>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} className="margin-top-2">
          <label>Contacts</label>
        </Col>
      </Row>

      <ContactsList
        contacts={sortContacts(contacts).slice(0, 5)}
        canEdit={
          isCompanyUser
            ? !isLogbookTransferred
            : isCurrentUserAReadOnlyMemberForLogbook
            ? false
            : true
        }
        showCompany
        showFooter
      />
    </>
  );
}
