import { axiosInstance } from './base';

export const LinksApi = {
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	createNewLink: (propertyId, data) => axiosInstance.post(`/properties/${propertyId}/links`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} linkId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDocument>}
	 */
	updateLink: (propertyId, linkId, data) => axiosInstance.put(`/properties/${propertyId}/links/${linkId}`, data),
};
