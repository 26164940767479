import { useEffect, useMemo, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { UserSessionsApi } from "../../api/user-sessions";
import { UsersApi } from "../../api/users";
import companyIcon from "../../assets/images/account-type-company.svg";
import ownerIcon from "../../assets/images/account-type-owner.svg";
import chevronIcon from "../../assets/images/chevron-right.svg";
import registerImage from "../../assets/images/register-side.png";
import { FiftyFifty } from "../../components/fifty-fifty";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import { GoogleSignIn } from "../../components/google-signin";
import { handleError } from "../../components/helpers";
import { AccountRoutes } from "../../routes";
import { setSession, updateUser } from "../../store/features/user.slice";

const ACCOUNT_TYPES = {
  HOME_OWNER: "homeowner",
  COMPANY: "company",
};

const AccountTypeOptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 82px;
  width: 100%;
  max-width: 450px;
  padding: 32px;
  background: #ffffff;
  border: 1px solid #d3d7e3;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
    padding: 24px;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;

const AccountTypeOptionIcon = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    width: 40px;
    height: 40px;
  }

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

const AccountTypeOptionTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2d3540;
  margin-left: 32px;
  text-align: left;

  @media only screen and (max-width: 576px) {
    margin-left: 20px;
  }
`;

const Chevron = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: 0;
`;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Register(props) {
  const query = useQuery();
  const dispatch = useDispatch();

  // For Signing in with Social
  const token = query?.get("token");
  const emailParam = query?.get("email");

  // Invitation for REISA
  const masterCompanyCode = query?.get("mastercompanycode");
  const accountPlan = query?.get("plan");

  // Signup as owner for a company via link
  const companyCode = query?.get("companycode");

  // Account Type
  const [accountType, setAccountType] = useState(
    masterCompanyCode && accountPlan
      ? "company"
      : companyCode
        ? "owner"
        : undefined,
  );

  // Social Sign Up
  const signUpWithSocialProvider = async (data) => {
    try {
      const response = await UserSessionsApi.loginAnonymously(token, {
        email: emailParam,
        token,
      });
      dispatch(setSession(response));
    } catch (e) {
      handleError(e);
    }
  };

  // Social sign up effect
  useEffect(() => {
    if (token && emailParam) {
      signUpWithSocialProvider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accountType) {
    // ask user to choose which account they would like
    // to sign up for.
    return (
      <DocumentTitle title="Register | inndox">
        <FiftyFifty backgroundImage={registerImage}>
          <h1>How can we help?</h1>

          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            Please select what account type you would like to set up for.
          </p>

          <AccountTypeOptionContainer onClick={() => setAccountType("owner")}>
            <AccountTypeOptionIcon>
              <img src={ownerIcon} alt="owner" />
            </AccountTypeOptionIcon>

            <AccountTypeOptionTitle>I am an asset owner</AccountTypeOptionTitle>

            <Chevron>
              <img src={chevronIcon} alt="chevron" />
            </Chevron>
          </AccountTypeOptionContainer>

          <AccountTypeOptionContainer onClick={() => setAccountType("company")}>
            <AccountTypeOptionIcon>
              <img src={companyIcon} alt="company" />
            </AccountTypeOptionIcon>

            <AccountTypeOptionTitle>
              I want to set up for a company
            </AccountTypeOptionTitle>

            <Chevron>
              <img src={chevronIcon} alt="chevron" />
            </Chevron>
          </AccountTypeOptionContainer>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  const { email, firstName, lastName } = props;
  return (
    <DocumentTitle title="Register | inndox">
      <FiftyFifty backgroundImage={registerImage}>
        <h1>Finish setting up your account</h1>

        <p
          className="margin-top-1"
          style={{
            maxWidth: 326,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
          }}
        >
          Use your email address and create a password to access your logbook
          files at any time.
        </p>

        <div className="margin-top-2">
          <Form
            initialValues={{
              email,
              firstName,
              lastName,
              mastercompanycode: masterCompanyCode,
              companycode: companyCode,
              plan: accountPlan,
              acceptTermsConditions: true,
            }}
            onSubmit={async (values) => {
              try {
                const account = await UsersApi.register(values);
                dispatch(updateUser(account));

                const session = await UserSessionsApi.login({
                  email: values.email,
                  password: values.password,
                });
                dispatch(setSession(session));
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => {
              return (
                <>
                  <Row style={{ marginTop: 0 }}>
                    <Col xs={6}>
                      <TextField
                        name="firstName"
                        label="First name"
                        placeholder="John"
                        className="margin-top-4"
                        type="text"
                        required
                        validate={requiredValidator}
                        autoComplete="given-name"
                      />
                    </Col>

                    <Col xs={6}>
                      <TextField
                        name="lastName"
                        label="Last name"
                        placeholder="Doe"
                        className="margin-top-4"
                        type="text"
                        required
                        validate={requiredValidator}
                        autoComplete="family-name"
                      />
                    </Col>
                  </Row>

                  {accountType === ACCOUNT_TYPES.COMPANY && (
                    <TextField
                      name="companyName"
                      label="Company Name"
                      placeholder="Building Pro"
                      className="margin-top-2"
                      required
                      validate={requiredValidator}
                    />
                  )}

                  <TextField
                    name="email"
                    label="Email address"
                    placeholder="john@example.com"
                    className="margin-top-2"
                    required
                    validate={emailValidator}
                    autoComplete="email"
                  />

                  <TextField
                    name="password"
                    label="Password"
                    className="margin-top-2"
                    placeholder="Enter a password of your choice here"
                    type="password"
                    required
                    validate={requiredValidator}
                    autoComplete="new-password"
                  />

                  <button
                    className="button button-primary button-big full-width margin-top-4 button-large flex center"
                    onClick={props.submitting ? undefined : props.handleSubmit}
                  >
                    {props.submitting && (
                      <ClipLoader size={16} color="#fff" loading={true} />
                    )}
                    {props.submitting ? "Registering" : "Get started!"}
                  </button>

                  {accountType === ACCOUNT_TYPES.HOME_OWNER && (
                    <GoogleSignIn
                      redirectUrl={`${
                        process.env.REACT_APP_DOMAIN + "/account/register"
                      }`}
                    />
                  )}

                  <p
                    className="helper-text margin-top-3"
                    style={{ textAlign: "center" }}
                  >
                    Already have an account?{" "}
                    <NavLink to={"/" + AccountRoutes.login} className="link">
                      Log in
                    </NavLink>
                    <br />
                    <a
                      href="https://www.inndox.com/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-style-elem"
                    >
                      Terms and Conditions
                    </a>
                  </p>
                </>
              );
            }}
          />
        </div>
      </FiftyFifty>
    </DocumentTitle>
  );
}
