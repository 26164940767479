import styled from "styled-components";
import Modal from "./modal";
import { PaymentsApi } from "../api/payments";
import { useDispatch } from "react-redux";
import { setPaymentDetails } from "../store/features/subscription.slice";
import { toast } from "react-toastify";
import Toast from "./toast";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { formatNumberInCurrency } from "../helpers/currency";

const UpdatedBillingDetailsContainer = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: #ffcc0040;
  border-radius: 8px;
  margin-top: 16px;
`;

const StyledList = styled.ul`
  margin-left: 20px;
`;

/**
 * @typedef {Object} SwitchBillingCycleProps
 * @property {inndox.PaymentDetails} paymentDetails
 * @property {boolean} isOpen
 * @property {Function} onClose
 */

/**
 *
 * @param {SwitchBillingCycleProps} props
 * @returns
 */
export default function SwitchBillingCycle({
  paymentDetails,
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();
  const [isUpdatingBillingCycle, setIsUpdatingBillingCycle] = useState(false);
  const planMonthlyPriceAfterDiscount =
    Math.round(
      paymentDetails.planMonthlyPrice *
        (1 - paymentDetails.discount / 100) *
        100
    ) / 100;
  const planAnnualPriceAfterDiscount =
    Math.round(
      paymentDetails.planAnnualPrice * (1 - paymentDetails.discount / 100) * 100
    ) / 100;

  const switchBillingCycle = async (billingInterval) => {
    setIsUpdatingBillingCycle(true);
    try {
      const updatedPaymentDetails = await PaymentsApi.updateSubscription({
        subscribeToPlan: true,
        interval: billingInterval,
      });
      dispatch(setPaymentDetails(updatedPaymentDetails));
      toast.success(
        <Toast
          title="Success!"
          details={`Your billing cycle has been updated to ${billingInterval}`}
        />
      );
      onClose();
    } catch (e) {
      toast.error(
        <Toast
          title="Oops!"
          details="There was an error updating your billing cycle. Please try again later or contact us."
        />
      );
    } finally {
      setIsUpdatingBillingCycle(false);
    }
  };

  return (
    <Modal title="Change Billing Cycle" isOpen={isOpen} onClose={onClose}>
      {paymentDetails.planInterval === "Annual" ? (
        <>
          <p>
            This will change your billing cycle to <b>Monthly</b>.
          </p>
          <br />
          This change is immediate. You will automatically get a discount for
          the unused portion of your current billing cycle towards future
          subscription charges.
          <UpdatedBillingDetailsContainer>
            <p>
              <strong>Your updated billing details will be:</strong>
            </p>

            <StyledList>
              <li>
                Billing cycle: <strong>Monthly</strong>
              </li>
              <li>
                Billing amount:{" "}
                <strong>
                  {paymentDetails.planCurrency}{" "}
                  {formatNumberInCurrency(
                    planMonthlyPriceAfterDiscount,
                    paymentDetails.planCurrency
                  )}
                </strong>
              </li>
            </StyledList>
          </UpdatedBillingDetailsContainer>
          <br />
          <p>Are you sure you want to continue?</p>
          <button
            className="button button-primary button-large button-big full-width margin-top-5"
            onClick={
              isUpdatingBillingCycle
                ? null
                : () => switchBillingCycle("Monthly")
            }
          >
            {isUpdatingBillingCycle ? (
              <ClipLoader size={16} color="#fff" />
            ) : (
              "Switch to Monthly cycle"
            )}
          </button>
        </>
      ) : (
        <>
          <p>
            This will change your billing cycle to <b>Annual</b>.
          </p>
          <br />
          This change is immediate and your saved card will be charged the full
          amount. You will automatically get a discount for the unused portion
          of your current billing cycle.
          <UpdatedBillingDetailsContainer>
            <p>
              <strong>Your updated billing details will be:</strong>
            </p>

            <StyledList>
              <li>
                Billing cycle: <strong>Annual</strong>
              </li>
              <li>
                Billing amount:{" "}
                <strong>
                  {paymentDetails.planCurrency}{" "}
                  {formatNumberInCurrency(
                    planAnnualPriceAfterDiscount,
                    paymentDetails.planCurrency
                  )}
                </strong>
              </li>
            </StyledList>
          </UpdatedBillingDetailsContainer>
          <br />
          <p>Are you sure you want to continue?</p>
          <button
            className="button button-primary button-large button-big full-width margin-top-5"
            onClick={
              isUpdatingBillingCycle ? null : () => switchBillingCycle("Annual")
            }
          >
            {isUpdatingBillingCycle ? (
              <ClipLoader size={16} color="#fff" />
            ) : (
              "Switch to Annual cycle"
            )}
          </button>
        </>
      )}
    </Modal>
  );
}
