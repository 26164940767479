import moment from "moment";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import { MaintenanceItemFrequencyTypes } from "../../components/helpers";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import { selectCurrentTemplate } from "../../store/features/templates.slice";

const Content = styled.div`
  margin-left: 0;
  margin-right: auto;
  max-width: 450px;
  width: 100%;
  text-align: left;
`;

export default function ViewMaintenanceItem() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPartOfATemplate = pathname.includes("templates");
  const currentTemplate = useSelector(selectCurrentTemplate);
  const currentLogbook = useSelector(selectCurrentLogbook);
  const logbook = isPartOfATemplate ? currentTemplate : currentLogbook;

  const { maintenanceId: maintenanceIdPathParam } = useParams();
  const maintenanceId = parseInt(maintenanceIdPathParam);
  const maintenanceItem = logbook?.maintenanceItems.find(
    (item) => item.id === maintenanceId,
  );

  let frequency =
    maintenanceItem.frequencyInMonths === 1 && maintenanceItem.repeatsFor === 1
      ? "One Off"
      : MaintenanceItemFrequencyTypes.find(
          (type) => type.value === maintenanceItem.frequencyInMonths,
        )?.label;

  if (!frequency) {
    frequency = `Every ${maintenanceItem.frequencyInMonths} months`;
  }

  const contact = maintenanceItem.scheduledFor
    ? logbook.contacts.find(
        (contact) => contact.id === maintenanceItem.scheduledFor,
      )
    : undefined;
  return (
    <DocumentTitle title="Contact Details | inndox">
      <FiftyFifty backgroundImage={logbookSideImage} stretch expanded>
        <Content>
          <BackButton />
          <h1>Maintenance Item Details</h1>

          <div className="margin-top-4">
            <label className="margin-top-2">Title</label>
            <p>{maintenanceItem.title}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">
              {maintenanceItem.frequencyInMonths ? "Next due date" : "Due date"}
            </label>
            <p>
              {maintenanceItem.repeatsFor === 1 &&
              maintenanceItem.nextScheduledEvent === null
                ? "One Off"
                : maintenanceItem.nextScheduledEvent
                ? moment(maintenanceItem.nextScheduledEvent).format(
                    "MMMM DD, YYYY",
                  )
                : `Handover + ${maintenanceItem.frequencyInMonths} Month${
                    maintenanceItem.frequencyInMonths > 1 ? "s" : ""
                  }`}
            </p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Frequency</label>
            <p>{frequency}</p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Contact responsible</label>
            <p>
              {contact
                ? contact.firstName + " " + contact.lastName
                : maintenanceItem.scheduledFor
                ? "Contact Removed"
                : "-"}
            </p>
          </div>

          <div className="margin-top-2">
            <label className="margin-top-2">Notes</label>
            <p>{maintenanceItem.notes || "-"}</p>
          </div>

          <button
            className="button button-primary button-large button-big full-width margin-top-4"
            onClick={() => navigate("edit")}
          >
            Edit maintenance
          </button>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
