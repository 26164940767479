import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import closeIcon from "../assets/images/close.svg";
import warningRedIcon from "../assets/images/warning-red.svg";
import warningYellowIcon from "../assets/images/warning-yellow.svg";
import { CompanyRoutes } from "../routes";
import { selectCurrentLogbookDefects } from "../store/features/logbooks.slice";

const RecentNotificationContainer = styled.div`
  padding-bottom: 32px;
`;

const RecentNotification = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
  }
`;

const NotificationTypeIconContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

const NotificationContent = styled.p`
  @media only screen and (max-width: 576px) {
    padding-left: 48px;
  }
`;

const CloseButton = styled.img`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 12px;
  right: 12px;
`;

const ViewDefectButton = styled.button`
  @media only screen and (max-width: 576px) {
    margin-left: 48px;
    margin-top: 16px;
    max-width: calc(100% - 48px);
  }
`;

export default function RecentDefectNotification() {
  const defects = useSelector(selectCurrentLogbookDefects);
  const [defect, setDefect] = useState();
  const navigate = useNavigate();

  const getDefectToDisplay = useCallback(() => {
    if (!defects || defects.length === 0) {
      return;
    }

    const sortedAndFilteredDefects = defects
      .filter((defect) => defect.status === "Open")
      .filter(
        (defect) =>
          localStorage.getItem(defect.property.id + "/" + defect.id) !== "yes",
      )
      .sort((a, b) => {
        const dateA = new Date(a.createdOn);
        const dateB = new Date(b.createdOn);

        return dateB - dateA;
      });

    if (!sortedAndFilteredDefects.length) {
      return;
    }

    return sortedAndFilteredDefects[0];
  }, [defects]);

  const closeDefect = () => {
    // update local storage
    localStorage.setItem(defect.property.id + "/" + defect.id, "yes");

    // update local defects
    setDefect(getDefectToDisplay());
  };

  useEffect(() => {
    setDefect(getDefectToDisplay());
  }, [getDefectToDisplay]);

  if (!defect) {
    return <noscript />;
  }

  return (
    <Container>
      <Row>
        <Col>
          <RecentNotificationContainer>
            <RecentNotification>
              <div className="flex center grow">
                <NotificationTypeIconContainer className="flex center">
                  <img
                    src={defect.isUrgent ? warningRedIcon : warningYellowIcon}
                    alt="warning"
                    width={24}
                    height={24}
                  />
                </NotificationTypeIconContainer>

                <div className="flex col" style={{ flexGrow: 1 }}>
                  <p>
                    <strong>
                      {defect.isUrgent
                        ? "Urgent New Claim Added"
                        : "New Claim Added"}
                    </strong>
                  </p>

                  {/* Description for all except mobile devices */}
                  <Hidden xs>
                    <NotificationContent>
                      {defect.subject} has been added on{" "}
                      {moment(defect.createdOn).format("MMM D, YYYY")}
                    </NotificationContent>
                  </Hidden>
                </div>
              </div>

              <Visible xs>
                <NotificationContent>
                  {defect.subject} has been added on{" "}
                  {moment(defect.createdOn).format("MMM D, YYYY")}
                </NotificationContent>
              </Visible>

              <ViewDefectButton
                className={`margin-right-2 button button-warning${
                  defect.isUrgent ? "-urgent" : ""
                }`}
                onClick={() =>
                  navigate(
                    // This is only for company and from the logbook itself.
                    // So no special condition to check. Check the full logic
                    // in DefectRowItem
                    "/" +
                      CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      CompanyRoutes.logbook.replace(
                        ":logbookId",
                        defect.property.id,
                      ) +
                      "/" +
                      CompanyRoutes.logbookDefect.replace(
                        ":defectId",
                        defect.id,
                      ),
                  )
                }
              >
                View more info
              </ViewDefectButton>

              <CloseButton src={closeIcon} alt="close" onClick={closeDefect} />
            </RecentNotification>
          </RecentNotificationContainer>
        </Col>
      </Row>
    </Container>
  );
}
