import { useEffect, useRef } from 'react';

export default function usePrevious(value) {
	// create a ref
	const ref = useRef();

	// store value
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes

	return ref.current;
}
