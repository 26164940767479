import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ContactsList, { sortContacts } from "../../components/contact-list";
import { CompanyRoutes, TemplateRoutes } from "../../routes";
import { selectCurrentTemplate } from "../../store/features/templates.slice";

const StyledHeading = styled.h2`
  @media only screen and (max-width: 576px) {
    margin-bottom: 16px;
  }
`;

export default function TemplateContacts() {
  const logbook = useSelector(selectCurrentTemplate);
  const navigate = useNavigate();
  const contacts = logbook.contacts;

  return (
    <DocumentTitle title="Logbook Contacts | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex">
            <StyledHeading className="name">Template Contacts</StyledHeading>
          </Col>

          <Col xs={12} md={6} className="flex end">
            <button
              className="button button-secondary margin-right-2"
              onClick={() =>
                navigate(
                  "/" +
                    CompanyRoutes.basePath.replace("/*", "") +
                    "/" +
                    TemplateRoutes.template.replace(":templateId", logbook.id) +
                    "/" +
                    TemplateRoutes.addTemplateContact,
                )
              }
            >
              Add a new contact
            </button>
          </Col>
        </Row>

        <ContactsList
          contacts={sortContacts(contacts)}
          canEdit
          isInsideATemplate
          showCompany
        />
      </Container>
    </DocumentTitle>
  );
}
