import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFileTypeIcon, UPLOAD_STATUS } from '../../../helpers';
import { DocumentsApi } from '../../../../api/documents';
import {
	addDocumentToLogbook,
	incrementLogbookFolderStatistics,
	updateDocumentToSharedFolder,
} from '../../../../store/features/logbooks.slice';
import { ClipLoader } from 'react-spinners';
import pendingIcon from '../../../../assets/images/hourglass-start-solid.svg';
import successIcon from '../../../../assets/images/circle-check-solid.svg';
import failedIcon from '../../../../assets/images/circle-xmark-solid.svg';
import styled from 'styled-components';
import {
	addDocumentToCurrentTemplate,
	incrementTemplateFolderStatistics,
} from '../../../../store/features/templates.slice';

export const fileIsAZipFile = (file) => {
	return (
		file.type === 'application/zip' ||
		file.type === 'application/x-zip-compressed' ||
		file.type === 'multipart/x-zip'
	);
};

const FilePreviewContainer = styled.div`
	width: 100%;
	max-width: 100%;
	height: 64px;
`;
const FilePreviewImage = styled.img`
	width: 40px;
	height: 40px;
	margin-left: 8px;
	flex-shrink: 0;
`;

const FilePreviewName = styled.p`
	margin-left: 16px;
	max-width: calc(100% - 88px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex-grow: 1;
`;

const StatusContent = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
`;

/**
 * @typedef { Object } PreviewAndUploadFileProps
 * @property { boolean } [showPreview]
 * @property { boolean } [isUploadingToATemplate]
 * @property { File } file
 * @property { () => void } removeFile
 * @property { () => void } onFileUploaded
 * @property { Record<string,any> } initialValues
 * @property { boolean } [shared]
 */

/**
 *
 * @param {PreviewAndUploadFileProps} param0
 */
export function PreviewAndUploadFile({
	showPreview = false,
	isUploadingToATemplate = false,
	file,
	removeFile,
	initialValues,
	shared,
	shouldUpload = false,
	onFileUploaded,
}) {
	const [fileUploadStatus, setFileUploadStatus] = useState(
		UPLOAD_STATUS.PENDING
	);
	const currentFileIsAZipFile = fileIsAZipFile(file);
	const [unpackZipFile, setUnpackZipFile] = useState(!currentFileIsAZipFile);
	const [fileUploadError, setFileUploadError] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (unpackZipFile !== undefined && shouldUpload) {
			async function uploadFile() {
				// update file upload status
				setFileUploadStatus(UPLOAD_STATUS.UPLOADING);

				// create form data
				const formData = new FormData();
				Object.keys(initialValues)
					.filter((key) => initialValues[key] !== undefined)
					.forEach((key) => {
						formData.append(key, initialValues[key]);
					});
				if (currentFileIsAZipFile) {
					formData.append('unpackZipFile', unpackZipFile);
				}
				formData.append('documentFile', file);

				try {
					const newDocument = await DocumentsApi.uploadDocumentsToProperty(
						initialValues.propertyId,
						formData
					);

					if (shared) {
						dispatch(updateDocumentToSharedFolder(newDocument));
					} else {
						if (isUploadingToATemplate) {
							dispatch(addDocumentToCurrentTemplate(newDocument));
							if (initialValues.folderId) {
								dispatch(
									incrementTemplateFolderStatistics({
										id: initialValues.folderId,
									})
								);
							}
						} else {
							dispatch(addDocumentToLogbook(newDocument));
							if (initialValues.folderId) {
								dispatch(
									incrementLogbookFolderStatistics({
										id: initialValues.folderId,
									})
								);
							}
						}
					}

					// update file upload status
					setFileUploadStatus(UPLOAD_STATUS.SUCCESS);

					onFileUploaded();
				} catch (e) {
					setFileUploadError('There was an error uploading this file.');
					setFileUploadStatus(UPLOAD_STATUS.FAILED);
				}
			}
			uploadFile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldUpload, unpackZipFile]);

	return (
		<>
			<FilePreviewContainer className="flex align-center">
				<FilePreviewImage src={getFileTypeIcon(file.type)} />

				<FilePreviewName>{file.name}</FilePreviewName>

				<StatusContent>
					{currentFileIsAZipFile ? (
						<>
							<button
								className="button button-link"
								onClick={() => setUnpackZipFile(false)}>
								Upload ZIP
							</button>
							<button
								className="button button-link margin-left-1"
								onClick={() => setUnpackZipFile(true)}>
								Unpack ZIP
							</button>
						</>
					) : fileUploadStatus === UPLOAD_STATUS.PENDING ? (
						<img src={pendingIcon} alt="close" width={16} />
					) : fileUploadStatus === UPLOAD_STATUS.UPLOADING ? (
						<ClipLoader loading size={16} color="#a0b42a" />
					) : fileUploadStatus === UPLOAD_STATUS.SUCCESS ? (
						<img src={successIcon} alt="close" width={16} />
					) : fileUploadStatus === UPLOAD_STATUS.FAILED ? (
						<img src={failedIcon} alt="close" width={16} />
					) : null}
				</StatusContent>
			</FilePreviewContainer>
			{fileUploadError ? <p>{fileUploadError}</p> : <noscript />}
		</>
	);
}
