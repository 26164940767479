import { useSelector } from "react-redux";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import LogbookFolders from "../common/logbook-folders";

export default function OwnerLogbookFolders() {
  const logbook = useSelector(selectCurrentLogbook);

  return (
    <LogbookFolders
      logbookId={logbook.id}
      folders={logbook.folders}
      documents={logbook.documents}
    />
  );
}
