import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./components/authentication";
import { ToastClose } from "./components/toast-close";
import SiteMap from "./routes/site-map";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <AuthProvider>
      <SiteMap />

      <ToastContainer
        position="top-center"
        closeButton={ToastClose}
        limit={1}
      />
    </AuthProvider>
  );
}
