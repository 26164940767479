import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DefectsApi } from '../api/defects';
import { LogbooksApi } from '../api/properties';
import {
	setCurrentLogbook,
	setCurrentLogbookDefects,
} from '../store/features/logbooks.slice';

/**
 *
 * @param {number} logbookId
 */
export default function useLogbookData(logbookId) {
	const dispatch = useDispatch();
	const [isFetchingData, setIsFetchingData] = useState(true);

	useEffect(() => {
		async function fetchLogbook() {
			try {
				// logbook
				const logbook = await LogbooksApi.fetchLogbook(logbookId);
				dispatch(setCurrentLogbook(logbook));

				// defects
				const defects = await DefectsApi.fetchDefectsForProperty(logbookId);
				dispatch(setCurrentLogbookDefects(defects));
			} catch (e) {
				console.error('logbook data > error fetching data');
			} finally {
				setIsFetchingData(false);
			}
		}
		fetchLogbook();
	}, [logbookId, dispatch]);

	return isFetchingData;
}
