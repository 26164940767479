import { Fragment, useState } from "react";
import { Form } from "react-final-form";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { CompanyApi } from "../api/company";
import { DocumentsApi } from "../api/documents";
import { LogbooksApi } from "../api/properties";
import addLogbookIcon from "../assets/images/add-logbook-icon.svg";
import cameraIcon from "../assets/images/camera.svg";
import cameraWhiteIcon from "../assets/images/camera_white.svg";
import downloadIcon from "../assets/images/download.svg";
import editIcon from "../assets/images/edit-icon.svg";
import logbookTypeCaravan from "../assets/images/logbook-type-caravan.svg";
import logbookTypeMarine from "../assets/images/logbook-type-marine.svg";
import logbookTypeOther from "../assets/images/logbook-type-other.svg";
import logbookTypeRealEstate from "../assets/images/logbook-type-real-estate.svg";
import logbookTypeVehicle from "../assets/images/logbook-type-vehicle.svg";
import uploadIcon from "../assets/images/upload.svg";
import { useLogbookTransferStatus } from "../hooks/logbook-transfer-status";
import {
  AccountRoutes,
  CompanyRoutes,
  OwnerRoutes,
  TemplateRoutes,
} from "../routes";
import {
  selectCompany,
  updateCurrentCompany,
} from "../store/features/company.slice";
import {
  selectCurrentLogbook,
  updateCurrentLogbook,
  updateLogbookInList,
} from "../store/features/logbooks.slice";
import { selectPaymentDetails } from "../store/features/subscription.slice";
import {
  selectCurrentTemplate,
  setCurrentTemplate,
} from "../store/features/templates.slice";
import { useAuth } from "./authentication";
import CancelHandover from "./cancel-handover";
import Dropzone from "./form/dropzone";
import { handleError } from "./helpers";
import Modal from "./modal";
import Toast from "./toast";

const LOGBOOK_TYPE_TO_ICON_MAPPING = {
  Property: logbookTypeRealEstate,
  Vehicle: logbookTypeVehicle,
  Caravan: logbookTypeCaravan,
  Marine: logbookTypeMarine,
  Other: logbookTypeOther,
};

const LOGBOOK_TYPE_TO_TITLE_MAPPING = {
  Property: "Real estate",
  Vehicle: "Vehicle",
  Caravan: "Caravan",
  Marine: "Marine",
};

export const DESKTOP_HEIGHT = 8 * 46;

const LogbookHeaderContainer = styled.div``;

export const CoverImage = styled.div`
  height: ${DESKTOP_HEIGHT}px;
  background-color: #eeeff3;
  border-radius: 16px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  @media only screen and (max-width: 575px) {
    width: 100%;
    height: 200px;
  }
`;

const InfoPanelContainer = styled.div`
  width: 100%;

  @media only screen and (max-width: 576px) {
    height: auto;
  }
`;

const ActionContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 32px;
`;
const InfoPanel = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: 8px;

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
  }
`;

const InfoPanelContent = styled.div`
  padding: 24px;
  width: 50%;

  @media only screen and (max-width: 576px) {
    padding: 16px 24px;
    width: 100%;
  }
`;

const InfoPanelLeftContent = styled(InfoPanelContent)``;

const InfoPanelRightContent = styled(InfoPanelContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const Title = styled.p`
  color: #203d5e;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.38;
  margin-bottom: 8px;

  @media only screen and (max-width: 575px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #adadb0;
  margin-top: -8px;
  margin-bottom: 4px;
`;

const Label = styled.label`
  color: #68c9d6;
`;

const PrimaryAction = styled.button`
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  &.add {
    background-color: #bfd62f;

    &:hover {
      background-color: #a0b42a;
    }
  }

  &.delete {
    background-color: #ff3e3e;

    &:hover {
      background-color: #ef4232;
    }
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const SecondaryAction = styled.button`
  padding: 12px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #203d5e;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e6ea;
  margin-top: 8px;

  &:hover {
    background-color: #d0d5de;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const StyledContainer = styled(Container)`
  @media only screen and (max-width: 576px) {
    margin-top: -16px;
  }
`;

const CoverImageContainer = styled.div`
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 368px;

  @media only screen and (max-width: 576px) {
    height: 220px;
  }
`;

const OwnerActions = styled.div`
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const DownloadButton = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 5px;
  background-color: #e4e6ea;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: #d0d5de;
  }
`;

const LogbookTypeIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  img {
    max-width: 28px;
    max-height: 28px;
  }
`;

const TransferredLogbookContent = styled.div`
  display: flex;
  max-width: 100%;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

export default function LogbookHeader({
  // this is only for company dashboard
  isCompanyView = false,
  companyId,
  templateId,
  isOwner = false,
  hasTransferred = false,
  coverImage,
  tertiaryAction,
  ownerId,
}) {
  const dispatch = useDispatch();
  const [isHovering, setIsHovering] = useState(false);
  const [wantsToUpdateCoverPhoto, setWantsToUpdateCoverPhoto] = useState(false);
  const currentCompany = useSelector(selectCompany);
  const currentLogbook = useSelector(selectCurrentLogbook);
  const currentTemplate = useSelector(selectCurrentTemplate);
  const property = templateId ? currentTemplate : currentLogbook;
  const { session } = useAuth();
  const currentAccountEmail = session.email;
  const isCurrentAccountLogbookOwner = isOwner
    ? property?.transfer?.to.email === currentAccountEmail ||
      !property?.primaryCompanyId
    : property?.transfer?.status !== "Transferred";
  const isOwnerTransferringOrHasTransferredToAnotherOwner =
    isOwner && property?.transfer?.to.email !== currentAccountEmail;
  const isCurrentUserAReadOnlyMemberForLogbook = session.userId !== ownerId;

  const logbookTag = useLogbookTransferStatus(
    isCompanyView || !!templateId
      ? undefined
      : isOwnerTransferringOrHasTransferredToAnotherOwner
        ? property.transfer?.status
        : isOwner
          ? undefined
          : property.transfer?.status,
    isCompanyView ? undefined : property.transfer?.createdOn,
    isOwner,
  );
  const paymentDetails = useSelector(selectPaymentDetails);
  const [showClonedLogbookWarning, setShowClonedLogbookWarning] =
    useState(false);
  const navigate = useNavigate();
  const [wantsToCancelHandover, setWantsToCancelHandover] = useState(false);

  const hasCancelledSubscription =
    paymentDetails.creditCardIsValid && paymentDetails.planStatus !== "Active";

  // methods
  const onUploadCoverPhoto = async (formValues) => {
    if (!formValues.photoFile) {
      toast.error("Please select a file.");
      return;
    }

    const values = { ...formValues };
    values.photoFile = Array.isArray(values.photoFile)
      ? values.photoFile[0]
      : values.photoFile;

    const formData = new FormData();

    if (isCompanyView) {
      // upload image to company
      const payload = {};
      payload.backgroundFile = values.photoFile;

      Object.keys(payload)
        .filter((key) => !!payload[key])
        .forEach((key) => formData.append(key, payload[key]));

      try {
        const response = await CompanyApi.updateCompanyImages(
          values.id,
          formData,
        );
        dispatch(updateCurrentCompany(response));
      } catch (e) {
        handleError(e);
      }
    } else {
      formData.append("id", values.id);
      formData.append("photoFile", values.photoFile);

      try {
        const response = await LogbooksApi.updateLogbookCoverImage(
          values.id,
          formData,
        );

        if (templateId) {
          dispatch(setCurrentTemplate(response));
        } else {
          dispatch(updateCurrentLogbook(response));
          dispatch(updateLogbookInList(response));
        }
      } catch (e) {
        handleError(e);
      }
    }

    setWantsToUpdateCoverPhoto(false);
  };

  const editLogbookOrTemplate = () => {
    if (!isOwner) {
      if (property.isTemplate) {
        // navigate to edit template
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.editTemplate.replace(":templateId", templateId),
        );
      } else {
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.logbook.replace(":logbookId", property.id) +
            "/" +
            CompanyRoutes.editLogbook,
        );
      }
    } else {
      navigate(
        "/" +
          OwnerRoutes.basePath.replace("/*", "") +
          "/" +
          OwnerRoutes.logbook.replace(":logbookId", property.id) +
          "/" +
          OwnerRoutes.editLogbook,
      );
    }
  };

  const handoverLogbook = () => {
    // If its a cloned logbook
    if (property.description.includes("CLONE")) {
      // show dialog
      setShowClonedLogbookWarning(true);
    } else if (hasCancelledSubscription) {
      // show inactive subscription notice
      navigate(
        "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.subscriptionInactive +
          `?logbookId=${property.id}`,
      );
    } else if (paymentDetails.planStatus === "None") {
      // show add payment details
      navigate(
        "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.subscribe +
          `?logbookId=${property.id}`,
      );
    } else if (property.documents.length === 0) {
      // no documents
      toast.error(
        <Toast title="You can not transfer a logbook without any documents." />,
      );
    } else {
      navigate(
        !isOwner ? CompanyRoutes.logbookTransfer : OwnerRoutes.logbookTransfer,
        {
          state: {
            // We will use this on new owner
            // contact details page to determine
            // whether or not to show Step 2 of 2
            // label
            hasArrivedOnTransferDirectly: true,
          },
        },
      );
    }
  };

  const downloadLogbookContents = () => {
    DocumentsApi.downloadDocumentsForProperty(property.id);
  };

  const coverImageContents = [
    isCurrentAccountLogbookOwner ? (
      <Fragment key="2">
        <Hidden xs>
          <button
            className="button button-secondary"
            style={{
              position: "absolute",
              top: 16,
              left: 32,
              zIndex: 10,
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => setWantsToUpdateCoverPhoto(true)}
          >
            <img
              src={isHovering ? cameraWhiteIcon : cameraIcon}
              alt="camera"
              style={{
                width: 20,
              }}
            />

            <span style={{ marginLeft: 8, color: "inherit" }}>
              Edit cover photo
            </span>
          </button>
        </Hidden>

        <Visible xs>
          <button
            key="2"
            className="button button-secondary"
            style={{
              position: "absolute",
              top: 16,
              left: 32,
              zIndex: 10,
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={() => setWantsToUpdateCoverPhoto(true)}
          >
            <img
              src={isHovering ? cameraWhiteIcon : cameraIcon}
              alt="camera"
              style={{
                width: 20,
              }}
            />
          </button>
        </Visible>
      </Fragment>
    ) : (
      <noscript key="2" />
    ),
    <ActionContainer key="1" className="flex end">
      {tertiaryAction}
    </ActionContainer>,
  ];

  return (
    <LogbookHeaderContainer>
      <StyledContainer>
        <Row>
          <Hidden xs>
            <Col xs={12}>
              <CoverImageContainer
                style={{
                  backgroundImage: `url(${coverImage}?w=928&h=368)`,
                }}
              />
              {coverImageContents}
            </Col>
          </Hidden>

          <Visible xs>
            <Col xs={12}>
              <CoverImageContainer
                style={{
                  backgroundImage: `url(${coverImage}?h=368)`,
                }}
              />
              {coverImageContents}
            </Col>
          </Visible>

          <Col xs={12}>
            <InfoPanelContainer className="flex col top end">
              <InfoPanel>
                {isCompanyView ? (
                  <InfoPanelLeftContent>
                    <Label>{currentCompany.addressFormatted}</Label>

                    {/* Title */}
                    <Title>{currentCompany.name}</Title>
                  </InfoPanelLeftContent>
                ) : (
                  <InfoPanelLeftContent>
                    {/* Icon & Type */}
                    <Label className="flex">
                      <LogbookTypeIcon>
                        <img
                          src={
                            LOGBOOK_TYPE_TO_ICON_MAPPING[property.logbookType]
                          }
                          alt={property.logbookType}
                        />
                      </LogbookTypeIcon>

                      {templateId
                        ? LOGBOOK_TYPE_TO_TITLE_MAPPING[property.logbookType]
                        : property.logbookType === "Property"
                          ? LOGBOOK_TYPE_TO_TITLE_MAPPING[
                              property.logbookType
                            ] +
                            " | " +
                            property.propertyType
                          : LOGBOOK_TYPE_TO_TITLE_MAPPING[property.logbookType]}
                    </Label>

                    {/* Title */}
                    <Title>{property.description || property.address}</Title>

                    {/* Description */}
                    <Description>
                      {property.isTemplate ||
                      property.logbookType === "Property" ||
                      property.logbookType === "Other"
                        ? undefined
                        : (property.logbookType === "Vehicle"
                            ? "VIN"
                            : property.logbookType === "Marine"
                              ? "HIN"
                              : property.logbookType === "Caravan"
                                ? "Chassis"
                                : "") +
                          " " +
                          property.serialNumber}
                    </Description>

                    {!!templateId || isCompanyView ? <noscript /> : logbookTag}
                  </InfoPanelLeftContent>
                )}

                {isCompanyView ? (
                  <InfoPanelRightContent>
                    <BannerPrimaryAction
                      icon={addLogbookIcon}
                      text="Setup a logbook"
                      onClick={() =>
                        navigate(
                          "/" +
                            CompanyRoutes.basePath.replace("/*", "") +
                            "/" +
                            CompanyRoutes.newLogbookType,
                        )
                      }
                    />
                    {/* Removed as Andrew asked. Ticket: 2663 */}
                    {/* <BannerSecondaryAction
                      icon={editIcon}
                      text="Edit company"
                      onClick={() =>
                        navigate(
                          "/" +
                            AccountRoutes.basePath.replace("/*", "") +
                            "/" +
                            AccountRoutes.settingsBasePath.replace("/*", "") +
                            "/" +
                            AccountRoutes.companyInfo
                        )
                      }
                    /> */}
                  </InfoPanelRightContent>
                ) : (
                  <InfoPanelRightContent>
                    {isOwner ? (
                      <>
                        <OwnerActions className="flex">
                          {!isCurrentUserAReadOnlyMemberForLogbook ? (
                            <BannerPrimaryAction
                              text="Upload logbook file(s)"
                              onClick={() => {
                                // add files to logbook
                                if (!isOwner) {
                                  navigate(
                                    "/" +
                                      CompanyRoutes.basePath.replace("/*", "") +
                                      "/" +
                                      CompanyRoutes.logbook.replace(
                                        ":logbookId",
                                        property.id,
                                      ) +
                                      "/" +
                                      CompanyRoutes.addLogbookDocuments,
                                  );
                                } else {
                                  navigate(
                                    "/" +
                                      OwnerRoutes.basePath.replace("/*", "") +
                                      "/" +
                                      OwnerRoutes.logbook.replace(
                                        ":logbookId",
                                        property.id,
                                      ) +
                                      "/" +
                                      OwnerRoutes.addLogbookDocuments,
                                  );
                                }
                              }}
                            />
                          ) : (
                            <noscript />
                          )}
                          <DownloadButton
                            id="download-all-files"
                            style={{ marginLeft: 8 }}
                            onClick={downloadLogbookContents}
                          >
                            <img src={downloadIcon} alt="download" />
                          </DownloadButton>
                          <Tooltip
                            anchorId="download-all-files"
                            style={{
                              borderRadius: 4,
                              textTransform: "none",
                              padding: 8,
                              fontFamily: "ProjectFont",
                              fontSize: "12px",
                              lineHeight: "18px",
                              letterSpacing: "normal",
                              maxWidth: 250,
                            }}
                            place="top"
                          >
                            Download all files
                          </Tooltip>
                        </OwnerActions>

                        {/* <BannerSecondaryAction
													icon={editIcon}
													text="Edit logbook"
													onClick={() =>
														navigate(
															'/' +
																OwnerRoutes.basePath.replace('/*', '') +
																'/' +
																OwnerRoutes.logbook.replace(':logbookId', property.id) +
																'/' +
																OwnerRoutes.editLogbook
														)
													}
												/> */}
                      </>
                    ) : property.isTemplate ? (
                      <>
                        <BannerPrimaryAction
                          text="Upload file(s)"
                          onClick={() =>
                            navigate(
                              "/" +
                                CompanyRoutes.basePath.replace("/*", "") +
                                "/" +
                                TemplateRoutes.template.replace(
                                  ":templateId",
                                  currentTemplate.id,
                                ) +
                                "/" +
                                TemplateRoutes.addDocumentsToTemplate,
                            )
                          }
                        />
                        <BannerSecondaryAction
                          icon={editIcon}
                          text="Edit template"
                          onClick={editLogbookOrTemplate}
                        />
                      </>
                    ) : property.transfer?.status === "Transferred" ? (
                      <TransferredLogbookContent>
                        {/* Owner Details */}
                        <div className="flex col" style={{ marginRight: 32 }}>
                          <label>Owner details</label>
                          <p>
                            {property.transfer?.to.companyName ||
                              property.transfer?.to.firstName +
                                " " +
                                property.transfer?.to.lastName}
                          </p>

                          <p>
                            <a
                              href={`mailto:${property.transfer?.to.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#68c9d6" }}
                            >
                              {property.transfer?.to.email}
                            </a>
                          </p>
                        </div>

                        {/* This is only for company users viewing claims for  */}
                        <BannerSecondaryAction
                          text="View issue log"
                          onClick={() =>
                            navigate(
                              "/" +
                                CompanyRoutes.basePath.replace("/*", "") +
                                "/" +
                                CompanyRoutes.logbook.replace(
                                  ":logbookId",
                                  property.id,
                                ) +
                                "/" +
                                CompanyRoutes.logbookDefects,
                            )
                          }
                        />
                      </TransferredLogbookContent>
                    ) : property.transfer?.status === "Transferring" ? (
                      <>
                        <BannerPrimaryAction
                          text="Cancel transfer"
                          onClick={() => setWantsToCancelHandover(true)}
                        />
                        <BannerSecondaryAction
                          icon={editIcon}
                          text="Edit logbook"
                          onClick={editLogbookOrTemplate}
                        />
                      </>
                    ) : (
                      <>
                        <BannerPrimaryAction
                          text="Handover this logbook"
                          onClick={handoverLogbook}
                        />
                        <BannerSecondaryAction
                          icon={editIcon}
                          text="Edit logbook"
                          onClick={editLogbookOrTemplate}
                        />
                      </>
                    )}
                  </InfoPanelRightContent>
                )}
              </InfoPanel>
            </InfoPanelContainer>
          </Col>
        </Row>
      </StyledContainer>

      {/* Modals */}

      {/* Only show the modal when required to overcome the issue of precedance for dropzones */}
      <Modal
        isOpen={wantsToUpdateCoverPhoto}
        title={
          <>
            <img
              src={cameraIcon}
              alt="camera"
              width={24}
              style={{ marginRight: 8 }}
            />
            Cover Image
          </>
        }
        onClose={() => setWantsToUpdateCoverPhoto(false)}
      >
        <Form
          initialValues={
            isCompanyView ? { id: companyId } : { id: property.id }
          }
          onSubmit={onUploadCoverPhoto}
          render={(props) => (
            <>
              <Dropzone
                name="photoFile"
                placeholder="Select a file from your computer or click to browse"
                className="margin-top-4"
                accept=".png,.jpg,.jpeg"
                removeCamera
                required
              />

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  <>
                    <img
                      src={uploadIcon}
                      alt="upload"
                      style={{ marginRight: 8 }}
                    />
                    Upload cover photo
                  </>
                )}
              </button>
            </>
          )}
        />
      </Modal>

      {/* Cloned Property Can't Transfer */}
      <Modal
        title="Logbook Update Required"
        isOpen={showClonedLogbookWarning}
        onClose={() => setShowClonedLogbookWarning(false)}
      >
        <p>
          This a cloned logbook. Please update the details of this cloned
          logbook before handover.
        </p>

        <div className="flex end margin-top-3">
          <button
            className="button button-alt margin-right-2"
            onClick={() => setShowClonedLogbookWarning(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      {/* Cancel Handover */}
      {isCompanyView || property.isTemplate ? (
        <noscript />
      ) : (
        <CancelHandover
          propertyId={property.id}
          isOpen={wantsToCancelHandover}
          onClose={() => setWantsToCancelHandover(false)}
          receiverEmail={property.transfer?.to.email}
        />
      )}
    </LogbookHeaderContainer>
  );
}

function BannerPrimaryAction({ icon, text, onClick }) {
  return (
    <PrimaryAction
      className={text === "Cancel transfer" ? "delete" : "add"}
      onClick={onClick}
    >
      {icon && <img src={icon} alt="icon" style={{ marginRight: 8 }} />}
      {text}
    </PrimaryAction>
  );
}

function BannerSecondaryAction({ icon, text, onClick }) {
  return (
    <SecondaryAction onClick={onClick}>
      {icon && <img src={icon} alt="icon" style={{ marginRight: 8 }} />}
      {text}
    </SecondaryAction>
  );
}

// if paymentDetails.subscriptionDaysRemaining is defined
// it means user has cancelled subscription but its active
// for some more days
