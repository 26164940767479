const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  session: null,
  account: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    updateUser: (state, action) => {
      state.account = {
        ...state.account,
        ...action.payload,
      };
    },
    clearSession: () => {
      return initialState;
    },
  },
});

// Actions
export const { setSession, updateUser, clearSession } = userSlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.UserSession}
 */
export const selectSession = (state) => state.user.session;

/**
 *
 * @param {*} state
 * @returns {inndox.UserAccount}
 */
export const selectAccount = (state) => state.user.account;

/**
 *
 * @param {*} state
 * @returns {boolean}
 */
export const isCurrentUserACompany = (state) =>
  state.user.session?.roles?.includes("CompanyAdmin") ||
  state.user.session?.roles?.includes("CompanyUser");

/**
 *
 * @param {*} state
 * @returns {boolean}
 */
export const isLoggedIn = (state) => !!state.user.session?.email;

export default userSlice.reducer;

export { initialState as userSliceInitialState };
