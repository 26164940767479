import { axiosInstance } from './base';

export const MaintenanceItemsApi = {
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<inndox.LogbookMaintenanceItem[]>}
	 */
	fetchItems: (propertyId) =>
		axiosInstance.get(`/properties/${propertyId}/maintenance`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookMaintenanceItem>}
	 */
	createNewItem: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/maintenance`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} itemId
	 * @returns {Promise<inndox.LogbookMaintenanceItem>}
	 */
	fetchItem: (propertyId, itemId) =>
		axiosInstance.get(`/properties/${propertyId}/maintenance/${itemId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} itemId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookMaintenanceItem>}
	 */
	updateItem: (propertyId, itemId, data) =>
		axiosInstance.put(`/properties/${propertyId}/maintenance/${itemId}`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} itemId
	 * @returns {Promise<void>}
	 */
	removeItem: (propertyId, itemId) =>
		axiosInstance.delete(`/properties/${propertyId}/maintenance/${itemId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} itemId
	 * @param {Record<string,any>} [data]
	 * @returns {Promise<void>}
	 */
	snoozeItem: (propertyId, itemId, data) =>
		axiosInstance.put(
			`/properties/${propertyId}/maintenance/${itemId}/snooze`,
			data
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} itemId
	 * @returns {Promise<void>}
	 */
	unsnoozeItem: (propertyId, itemId) =>
		axiosInstance.delete(
			`/properties/${propertyId}/maintenance/${itemId}/snooze`
		),
};
