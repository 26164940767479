import { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "CLEAR_OUT",
    });
  }, [dispatch]);

  return (
    <DocumentTitle title="Logging out... | inndox">
      <Container>
        <Row>
          <Col xs={12} className="flex center">
            <ClipLoader loading size={16} color="#bdd23f" />
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
