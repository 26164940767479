import { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { CompanyRoutes } from ".";
import useLogbookData from "../hooks/logbook-data";
import ViewContact from "../pages/common/view-contact";
import AddClaimsRecipient from "../pages/company/add-claims-recipient";
import AddOrEditCompanyLogbookContact from "../pages/company/add-or-edit-logbook-contact";
import AddOrEditCompanyLogbookDefect from "../pages/company/add-or-edit-logbook-defect";
import AddOrEditCompanyLogbookMaintenanceItem from "../pages/company/add-or-edit-logbook-maintenance-item";
import AddOrEditCompanyLogbookWarranty from "../pages/company/add-or-edit-logbook-warranty";
import ApplyTemplate from "../pages/company/apply-template";
import AssignClaim from "../pages/company/assign-claim";
import EditCompanyLogbook from "../pages/company/edit-company-logbook";
import CompanyLogbook from "../pages/company/logbook";
import CompanyLogbookContacts from "../pages/company/logbook-contacts";
import CompanyLogbookDefects from "../pages/company/logbook-defects";
import CompanyLogbookFolder from "../pages/company/logbook-folder";
import CompanyLogbookFolders from "../pages/company/logbook-folders";
import CompanyLogbookMaintenanceItems from "../pages/company/logbook-maintenance-items";
import CompanyLogbookTransfer from "../pages/company/logbook-transfer";
import CompanyLogbookWarranties from "../pages/company/logbook-warranties";
import ViewCompanyLogbookDefect from "../pages/company/view-logbook-defect";
import ViewCompanyLogbookMaintenanceItem from "../pages/company/view-logbook-maintenance-item";
import { setCurrentLogbook } from "../store/features/logbooks.slice";

export default function CompanyLogbookSiteMap() {
  const { logbookId: logbookIdPathParam } = useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const isFetchingData = useLogbookData(logbookId);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentLogbook());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <Routes>
      <Route path="" element={<CompanyLogbook />} />
      <Route path={CompanyRoutes.applyTemplate} element={<ApplyTemplate />} />
      <Route
        path={CompanyRoutes.editLogbook}
        element={<EditCompanyLogbook />}
      />

      {/* Contacts */}
      <Route
        path={CompanyRoutes.logbookContacts}
        element={<CompanyLogbookContacts />}
      />
      <Route
        path={CompanyRoutes.addLogbookContact}
        element={<AddOrEditCompanyLogbookContact />}
      />
      <Route
        path={CompanyRoutes.viewLogbookContact}
        element={<ViewContact />}
      />
      <Route
        path={CompanyRoutes.editLogbookContact}
        element={<AddOrEditCompanyLogbookContact />}
      />

      {/* Defects */}
      <Route
        path={CompanyRoutes.logbookDefects}
        element={<CompanyLogbookDefects />}
      />
      <Route
        path={CompanyRoutes.addRecipientForLogbookDefects}
        element={<AddClaimsRecipient />}
      />
      <Route
        path={CompanyRoutes.addLogbookDefect}
        element={<AddOrEditCompanyLogbookDefect />}
      />
      <Route
        path={CompanyRoutes.editLogbookDefect}
        element={<AddOrEditCompanyLogbookDefect />}
      />
      <Route
        path={CompanyRoutes.assignLogbookDefect}
        element={<AssignClaim />}
      />
      <Route
        path={CompanyRoutes.logbookDefect}
        element={<ViewCompanyLogbookDefect />}
      />

      {/* Folders */}
      <Route
        path={CompanyRoutes.logbookFolders}
        element={<CompanyLogbookFolders />}
      />
      <Route
        path={CompanyRoutes.logbookFolder}
        element={<CompanyLogbookFolder />}
      />
      <Route
        path={CompanyRoutes.logbookSubFolder}
        element={<CompanyLogbookFolder />}
      />

      {/* Maintenance Items */}
      <Route
        path={CompanyRoutes.logbookMaintenanceItems}
        element={<CompanyLogbookMaintenanceItems />}
      />
      <Route
        path={CompanyRoutes.addLogbookMaintenanceItem}
        element={<AddOrEditCompanyLogbookMaintenanceItem />}
      />
      <Route
        path={CompanyRoutes.viewLogbookMaintenanceItem}
        element={<ViewCompanyLogbookMaintenanceItem />}
      />
      <Route
        path={CompanyRoutes.editLogbookMaintenanceItem}
        element={<AddOrEditCompanyLogbookMaintenanceItem />}
      />

      {/* Warranty */}
      <Route
        path={CompanyRoutes.warranties}
        element={<CompanyLogbookWarranties />}
      />
      {/* <Route
				path={CompanyRoutes.newWarranty}
				element={<AddOrEditCompanyLogbookWarranty />}
			/> */}
      <Route
        path={CompanyRoutes.editWarranty}
        element={<AddOrEditCompanyLogbookWarranty />}
      />

      {/* Logbook Transfer */}
      <Route
        path={CompanyRoutes.logbookTransfer}
        element={<CompanyLogbookTransfer />}
      />

      {/* Redirect from everything else */}
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
