import Animate from '../animate';

export default function Error(props) {
	const { error, invalid = false } = props;

	return (
		<Animate show={invalid}>
			<div className="form-error">
				<p className="helper-text margin-top-1">{error}</p>
			</div>
		</Animate>
	);
}
