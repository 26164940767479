import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { DocumentsApi } from "../api/documents";
import { SharedRoutes } from "../routes";
import {
  addDocumentToLogbook,
  addDocumentToSharedLogbook,
  incrementLogbookFolderStatistics,
} from "../store/features/logbooks.slice";
import {
  addDocumentToCurrentTemplate,
  incrementTemplateFolderStatistics,
} from "../store/features/templates.slice";
import Dropzone from "./form/dropzone";
import { handleError } from "./helpers";
import Modal from "./modal";
import Toast from "./toast";

const allowedFileTypes = {
  "image/png": [".png"],
  "image/gif": [".gif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/tiff": [".tif", ".tiff"],
  "application/pdf": [".pdf"],
  "application/x-pdf": [".pdf"], // DEPRECATED
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlxs",
  ],
  "application/zip-compressed": [".zip"],
  "application/zip": [".zip"], // DEPRECATED
  "application/x-zip-compressed": [".zip"], // DEPRECATED
  "text/plain": [".txt", ".text"],
  "video/mp4": [".mp4", ".mp4v", ".mpg4"],
};

/**
 * @typedef {Object} FolderAndFileUploadModalProps
 * @property {*} isVisible
 * @property {Function} onClose
 * @property {number} logbookId
 * @property {number} [folderId]
 * @property {number} [subFolderId]
 * @property {boolean} [isAddingToATemplate]
 */

/**
 *
 * @param {FolderAndFileUploadModalProps} props
 */
export default function FolderAndFileUploadModal({
  isVisible,
  onClose,
  logbookId,
  folderId,
  subFolderId,
  isAddingToATemplate,
}) {
  const { pathname } = useLocation();
  const isViewingASharedFolder = folderId
    ? pathname.includes("/" + SharedRoutes.basePath.replace("/*", ""))
    : false;
  const dispatch = useDispatch();

  return (
    <Modal isOpen={isVisible} onClose={onClose} title="Upload new file(s)">
      <Form
        onSubmit={async (values) => {
          if (!values.documentFiles?.length) {
            toast.error(<Toast title="Please add at least one file" />);

            return;
          }

          const documentFiles = values.documentFiles;
          const documentUploadRequests = documentFiles.map((file) => {
            const formData = new FormData();
            formData.append("propertyId", logbookId);
            if (folderId) {
              formData.append("folderId", folderId);
            }
            if (subFolderId) {
              formData.append("subfolderId", subFolderId);
            }
            formData.append("documentFile", file);
            if (file.shouldUnpack) {
              formData.append("unpackZipFile", file.shouldUnpack);
            }

            return DocumentsApi.uploadDocumentsToProperty(logbookId, formData);
          });

          try {
            const uploadedDocuments = await Promise.all(documentUploadRequests);

            uploadedDocuments.forEach((document) => {
              if (isViewingASharedFolder) {
                dispatch(addDocumentToSharedLogbook(document));
              } else {
                if (isAddingToATemplate) {
                  dispatch(addDocumentToCurrentTemplate(document));
                  if (folderId) {
                    dispatch(
                      incrementTemplateFolderStatistics({
                        id: folderId,
                      }),
                    );
                  }
                } else {
                  dispatch(addDocumentToLogbook(document));
                  if (folderId) {
                    dispatch(
                      incrementLogbookFolderStatistics({
                        id: folderId,
                      }),
                    );
                  }
                }
              }
            });

            toast.success(
              <Toast title="The selected documents have been uploaded successfully." />,
            );

            onClose();
          } catch (e) {
            handleError(e);
          }
        }}
        render={(props) => (
          <>
            <Dropzone
              name="documentFiles"
              placeholder="Drag file(s) here or click to browse"
              multiple
              accept={allowedFileTypes}
              isUploading={props.submitting}
              allowUnzip
            />

            <div className="flex margin-top-3">
              <button
                className="button button-primary button-large button-big"
                style={{ minWidth: 123 }}
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Upload"
                )}
              </button>

              <button
                className="button button-secondary-link button-big margin-left-1"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      />
    </Modal>
  );
}
