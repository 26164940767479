/**
 *
 * @param {inndox.LogbookDocument[]} documents
 * @param {number} folderId
 * @param {number} [subFolderId]
 */
export function getDocuments(documents, folderId, subFolderId) {
  const folderDocuments = documents.filter((d) => d.folderId === folderId);

  if (subFolderId) {
    return folderDocuments.filter((d) => d.subfolderId === subFolderId);
  }

  return folderDocuments.filter((d) => !d.subfolderId);
}
