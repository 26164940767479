import styled from "styled-components";
import Modal from "./modal";

const InfoItem = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
`;

const InfoItemLabel = styled.div`
  width: 120px;
  flex-shrink: 0;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #adadb0;
`;

const InfoItemValue = styled.div`
  flex-grow: 1;
`;

/**
 * @typedef {Object} FolderInformationModalProps
 * @property {inndox.Folder} folder
 * @property {inndox.SubFolder} [subFolder]
 * @property {boolean} isVisible
 * @property {Function} onEdit
 * @property {Function} onClose
 */

/**
 *
 * @param {FolderInformationModalProps} props
 *
 */
export default function FolderInformationModal({
  isVisible,
  onClose,
  folder,
  subFolder,
  onEdit,
}) {
  const renderedFolder = subFolder ?? folder;
  const isShared = folder.sharePermissions.length > 1;

  const sharedWithList = isShared
    ? folder.sharePermissions.length > 2
      ? `${folder.sharePermissions[0].sharedWith.name}, ${folder.sharePermissions[1].sharedWith.name} and ${folder.sharePermissions.length - 2} others.`
      : folder.sharePermissions.length > 1
        ? `${folder.sharePermissions[0].sharedWith.name} and ${folder.sharePermissions[1].sharedWith.name}`
        : folder.sharePermissions[0].sharedWith.name
    : "";

  const isViewingSubFolder = !!subFolder;
  const privacyDescription = isViewingSubFolder
    ? isShared
      ? `Shared with ${sharedWithList} as part of shared parent folder.`
      : "Private to you"
    : isShared
      ? "Shared with " + sharedWithList
      : "Private to you";

  return (
    <Modal title="Folder Information" isOpen={isVisible} onClose={onClose}>
      <InfoItem>
        <InfoItemLabel>
          <Label>Name</Label>
        </InfoItemLabel>
        <InfoItemValue>
          <p className="margin-bottom-2">{renderedFolder.name}</p>
        </InfoItemValue>
      </InfoItem>

      <InfoItem>
        <InfoItemLabel>
          <Label>Description</Label>
        </InfoItemLabel>
        <InfoItemValue>
          <p className="margin-bottom-2">{renderedFolder.description ?? "-"}</p>
        </InfoItemValue>
      </InfoItem>

      {subFolder && (
        <InfoItem>
          <InfoItemLabel>
            <Label>Parent Folder</Label>
          </InfoItemLabel>
          <InfoItemValue>
            <p className="margin-bottom-2">{folder.name}</p>
          </InfoItemValue>
        </InfoItem>
      )}

      <InfoItem>
        <InfoItemLabel>
          <Label>Privacy</Label>
        </InfoItemLabel>
        <InfoItemValue>
          <p className="margin-bottom-2">{privacyDescription}</p>
        </InfoItemValue>
      </InfoItem>

      <button
        className="button button-primary button-big button-large margin-top-4"
        style={{ width: 140 }}
        onClick={onEdit}
      >
        Edit
      </button>
    </Modal>
  );
}
