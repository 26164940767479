import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { TemplatesApi } from "../../api/templates";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import Select from "../../components/form/select";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { useTemplatesOfType } from "../../hooks/templates";
import { CompanyRoutes } from "../../routes";
import {
  selectCurrentLogbook,
  updateCurrentLogbook,
} from "../../store/features/logbooks.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function ApplyTemplate() {
  const { logbookId } = useParams();
  const logbook = useSelector(selectCurrentLogbook);
  const templates = useTemplatesOfType(logbook.logbookType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const backPath = pathname.replace("/" + CompanyRoutes.applyTemplate, "");

  return (
    <DocumentTitle title="Apply Template to Logbook | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content className="margin-top-1">
          <BackButton backPath={backPath} />
          <h1>Apply Template</h1>
          <p className="margin-bottom-4 margin-top-1">
            Apply a template to an existing logbook.
          </p>

          <Form
            initialValues={{
              logbookId,
            }}
            onSubmit={async (values) => {
              try {
                const updatedLogbook = await TemplatesApi.applyTemplate(
                  values.template,
                  values.logbookId,
                );
                dispatch(updateCurrentLogbook(updatedLogbook));

                // go back to logbook home
                navigate(backPath);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <Select
                  name="template"
                  label="Template"
                  items={templates.map((template) => ({
                    label: template.name,
                    value: template.id,
                  }))}
                  required
                  validators={requiredValidator}
                />

                <button
                  className="button button-primary button-large button-big margin-top-4 full-width"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Apply"
                  )}
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
