import styled from 'styled-components';
import { FILE_UPLOAD_STATUS, getFileUploadStatusIcon } from './helpers';
import { getFileTypeIcon } from '../helpers';
import { useEffect, useState } from 'react';
import { DocumentsApi } from '../../api/documents';
import { useDispatch } from 'react-redux';
import {
	addDocumentToLogbook,
	incrementLogbookFolderStatistics,
} from '../../store/features/logbooks.slice';

const FileUploadNotificationContainer = styled.div``;

const FileUploadNotificationTitle = styled.p`
	font-weight: bold;
	line-height: 24px;
`;

/**
 *
 * @typedef {Object} FileUploadNotificationProps
 * @property {File[]} files
 * @property {Record<string,any>} additionalProps
 */

/**
 * @param {FileUploadNotificationProps} props
 */
export default function FileUploadNotification({ files, additionalProps }) {
	return (
		<FileUploadNotificationContainer>
			<FileUploadNotificationTitle>Uploading File(s)</FileUploadNotificationTitle>

			{files.map((file, index) => (
				<FileUploadDisplay
					key={index}
					file={file}
					additionalProps={additionalProps}
					uploadStatus={FILE_UPLOAD_STATUS.PENDING}
				/>
			))}
		</FileUploadNotificationContainer>
	);
}

const FileUploadDisplayContainer = styled.div`
	height: 32px;
	width: 100%;
	margin-top: 4px;
	padding: 0 4px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const FileTypeIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 4px;
`;

const FileName = styled.p`
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-right: auto;
	width: calc(100% - 50px);
	overflow: hidden;
`;

/**
 *
 * @typedef {Object} FileUploadDisplayProps
 * @property {File} file
 * @property {FILE_UPLOAD_STATUS} uploadStatus
 */

/**
 * @param {FileUploadDisplayProps} props
 */
function FileUploadDisplay({ file, additionalProps, onUploadComplete }) {
	const [uploadStatus, setUploadStatus] = useState(FILE_UPLOAD_STATUS.PENDING);
	const [canUpload, setCanUpload] = useState(false);
	const dispatch = useDispatch();

	const uploadFile = async () => {
		setUploadStatus(FILE_UPLOAD_STATUS.UPLOADING);

		const formData = new FormData();
		formData.append('documentFile', file);
		formData.append('propertyId', additionalProps.logbookId);
		formData.append('folderId', additionalProps.folderId);

		try {
			const uploadedDocument = await DocumentsApi.uploadDocumentsToProperty(
				additionalProps.logbookId,
				formData
			);

			setUploadStatus(FILE_UPLOAD_STATUS.UPLOADED);

			dispatch(addDocumentToLogbook(uploadedDocument));
			dispatch(incrementLogbookFolderStatistics({ id: additionalProps.folderId }));
		} catch (e) {
			setUploadStatus(FILE_UPLOAD_STATUS.FAILED);
		}
	};

	useEffect(() => {
		if (canUpload) {
			uploadFile();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canUpload]);

	useEffect(() => {
		if (file.type !== 'application/zip') {
			setCanUpload(true);
		}
	}, [file]);

	return (
		<FileUploadDisplayContainer>
			<FileTypeIcon src={getFileTypeIcon(file.type)} />

			<FileName>{file.name}</FileName>

			{getFileUploadStatusIcon(uploadStatus)}
		</FileUploadDisplayContainer>
	);
}
