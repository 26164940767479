import styled from 'styled-components';

const SetupBlock = styled.div`
	border-radius: 10px;
	background-color: #fff;
	padding: 24px;
	text-align: center;

	.text {
		text-align: center;
		align-self: center;
	}
`;

const SetupImage = styled.img`
	height: 35px;
	width: 35px;
`;

export default function SetupBlockWithImage({
	description,
	children,
	image,
	title,
}) {
	return (
		<SetupBlock>
			{image ? (
				<div>
					<SetupImage src={image} alt={title} />
				</div>
			) : null}
			<div className="text helper-text margin-top-2">{description}</div>

			{children}
		</SetupBlock>
	);
}
