import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: null,
};

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticles: (state, action) => {
      state.list = action.payload;
    },
  },
});

// Actions
export const { setArticles } = articlesSlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.Article[]}
 */
export const selectArticles = (state) => state.articles.list;

export default articlesSlice.reducer;

export { initialState as articlesSliceInitialState };
