import { axiosInstance } from './base';

export const PropertyContactsApi = {
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<inndox.Contact[]>}
	 */
	fetchLogbookContacts: (propertyId) =>
		axiosInstance.get(`/properties/${propertyId}/contacts`),
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<inndox.Contact>}
	 */
	fetchLogbookContact: (propertyId, contactId) =>
		axiosInstance.get(`/properties/${propertyId}/contacts/${contactId}`),
	/**
	 *
	 * @param {number} logbookId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Contact>}
	 */
	createLogbookContact: (logbookId, data) =>
		axiosInstance.post(`/properties/${logbookId}/contacts`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} contactId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Contact>}
	 */
	updateLogbookContact: (propertyId, contactId, data) =>
		axiosInstance.put(`/properties/${propertyId}/contacts/${contactId}`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} contactId
	 * @returns {Promise<void>}
	 */
	removeLogbookContact: (propertyId, contactId) =>
		axiosInstance.delete(`/properties/${propertyId}/contacts/${contactId}`),
};
