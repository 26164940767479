import { useEffect, useRef, useState } from "react";
import { Field } from "react-final-form";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import getAddressComponents from "../../hooks/address-breakdown";
import { Countries } from "../helpers";
import Error from "./error";
import Label from "./label";
import Select from "./select";
import TextField from "./text-field";
import { requiredValidator } from "./validators";

const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #dde0e3;
  font-size: 16px;
  color: #2d3540;
  padding: 15px 8px;
  line-height: 20px;
  caret-color: #bdd23f;
  border-radius: 4px;

  &:focus {
    border-color: #68c9d6;
  }

  &:disabled {
    background-color: #f7f9fc;
    cursor: not-allowed;
  }

  &.error {
    border-color: #ff3e3e;
  }
`;

const StyledRow = styled(Row)`
  height: 0;
  overflow: hidden;

  &.visible {
    height: unset;
    overflow: initial;
  }
`;

const EntryFormatSwitch = styled.div`
  text-align: left;
  cursor: pointer;
  margin-top: 4px;
`;

const AddressLine = styled(Col)`
  display: flex;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Unit = styled.div`
  flex-basis: 20%;
  min-width: 100px;
  margin-right: 16px;

  @media only screen and (max-width: 576px) {
    flex-basis: 100%;
    width: 100%;
  }
`;

const StreetAddress = styled.div`
  flex-grow: 1;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

function InternalAddress({ input, placeholder, setFieldValue, className }) {
  const [inputRef, setInputRef] = useState(null);
  const autocomplete = useRef(null);

  const fillInAddress = () => {
    const place = autocomplete.current.getPlace();
    const addressComponents = getAddressComponents(place);

    Object.keys(addressComponents).forEach((addressComponentKey) => {
      setFieldValue(
        addressComponentKey,
        addressComponents[addressComponentKey]
      );
    });
  };

  useEffect(() => {
    if (inputRef && window.google) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef,
        {
          fields: ["address_components", "formatted_address"],
          types: ["address"],
        }
      );

      autocomplete.current?.addListener("place_changed", fillInAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  return (
    <Input
      {...input}
      ref={setInputRef}
      className={className}
      placeholder={placeholder || input.placeholder}
    />
  );
}

export default function Address({
  placeholder = "Type property address",
  setFieldValue,
}) {
  const [wantsToEnterAddressManually, setWantsToEnterAddressManually] =
    useState(false);

  useEffect(() => {
    setFieldValue("isManualAddress", wantsToEnterAddressManually);
  }, [setFieldValue, wantsToEnterAddressManually]);

  return (
    <>
      <Row>
        {/* Address */}
        <AddressLine xs={12}>
          {/* Unit */}
          <Unit>
            <TextField
              className="margin-top-2 margin-right-2"
              name="unit"
              label="Unit"
            />
          </Unit>

          <StreetAddress>
            {wantsToEnterAddressManually ? (
              <TextField
                className="margin-top-2"
                name="streetAddress"
                label="Street Address"
                required
                validate={requiredValidator}
                type={wantsToEnterAddressManually ? "text" : "hidden"}
              />
            ) : (
              <Field name="address" validate={requiredValidator}>
                {({ input, meta }) => {
                  const shouldDisplayError = meta.touched && meta.error;

                  return (
                    <div className="form-element margin-top-2">
                      <Label label="Street Address" required />
                      <InternalAddress
                        input={input}
                        className={shouldDisplayError ? "error" : undefined}
                        placeholder={placeholder}
                        setFieldValue={setFieldValue}
                      />
                      {shouldDisplayError && (
                        <Error error={meta.error} invalid />
                      )}
                    </div>
                  );
                }}
              </Field>
            )}
          </StreetAddress>
        </AddressLine>
        <Col xs={12}>
          <EntryFormatSwitch
            className="link-style-elem"
            onClick={() => setWantsToEnterAddressManually((_) => !_)}
          >
            {wantsToEnterAddressManually ? "Or lookup" : "Or enter manually"}
          </EntryFormatSwitch>
        </Col>
      </Row>

      {/* Suburb */}
      {wantsToEnterAddressManually ? (
        <StyledRow className={wantsToEnterAddressManually ? "visible" : ""}>
          <Col xs={12} sm={6}>
            <TextField
              name="suburb"
              label="Suburb"
              required
              validate={requiredValidator}
              className="margin-top-2"
            />
          </Col>

          {/* Suburb */}
          <Col xs={12} sm={6}>
            <TextField
              name="state"
              label="State"
              required
              validate={requiredValidator}
              className="margin-top-2"
            />
          </Col>

          {/* Postcode */}
          <Col xs={12} sm={4}>
            <TextField
              name="postcode"
              label="Postcode"
              required
              validate={requiredValidator}
              className="margin-top-2"
            />
          </Col>

          {/* Country */}
          <Col xs={12} sm={8}>
            <Select
              name="country"
              label="Country"
              type="select"
              items={Countries}
              textField="label"
              valueField="value"
              placeholder="Select"
              required
              validators={requiredValidator}
              className="margin-top-2"
            />
          </Col>
        </StyledRow>
      ) : (
        <noscript />
      )}
    </>
  );
}
