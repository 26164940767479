import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CompanyApi } from '../api/company';
import { CompanyUsersApi } from '../api/company-users';
import { ContactsApi } from '../api/contacts';
import { DefectsApi } from '../api/defects';
import { FoldersApi } from '../api/folders';
import { PaymentsApi } from '../api/payments';
import { LogbooksApi } from '../api/properties';
import { TemplatesApi } from '../api/templates';
import {
	setCompany,
	setCompanyContacts,
	setCompanyDefects,
	setCompanyUsers,
	setDefectStatistics,
	setSubCompanies,
} from '../store/features/company.slice';
import {
	setLogbooks,
	setSharedLogbooks,
} from '../store/features/logbooks.slice';
import { setPaymentDetails } from '../store/features/subscription.slice';
import { setTemplates } from '../store/features/templates.slice';

export default function useCompanyData() {
	const dispatch = useDispatch();
	const [isFetchingData, setIsFetchingData] = useState(true);

	useEffect(() => {
		async function fetchCompanyData() {
			try {
				// Company
				const company = await CompanyApi.fetchCompanies();
				dispatch(setCompany(company));

				if (company.isMasterCompany) {
					const subCompanies = await CompanyApi.fetchChildCompanies();
					dispatch(setSubCompanies(subCompanies));
				}

				// Company Users
				const companyUsers = await CompanyUsersApi.fetchCompanyUsers();
				dispatch(setCompanyUsers(companyUsers));

				// Logbooks
				const logbooks = await LogbooksApi.fetchLogbooks();
				dispatch(setLogbooks(logbooks));

				// Defects
				const defects = await DefectsApi.fetchDefectsForCurrentCompany();
				dispatch(setCompanyDefects(defects));

				// Defect Statistics
				const defectStats = await DefectsApi.fetchDefectStatistics();
				dispatch(setDefectStatistics(defectStats));

				// Contacts
				const contacts = await ContactsApi.fetchContacts();
				dispatch(setCompanyContacts(contacts));

				// Templates
				const templates = await TemplatesApi.fetchTemplates();
				dispatch(setTemplates(templates));

				// Shared folders/properties
				const sharedProperties =
					await FoldersApi.fetchSharedFoldersForCurrentUser();
				dispatch(setSharedLogbooks(sharedProperties));

				// Payments
				const paymentDetails = await PaymentsApi.fetchConfig();
				dispatch(setPaymentDetails(paymentDetails));
			} catch (e) {
				console.error('company data > error fetching data');
			} finally {
				setIsFetchingData(false);
			}
		}
		fetchCompanyData();
	}, [dispatch]);

	return isFetchingData;
}
