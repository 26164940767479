import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { ALL_ALLOWED_FILE_TYPES } from './helpers';

const FileDroppableAreaContainer = styled.div``;

/**
 * @typedef {Object} FileDroppableAreaProps
 * @property {boolean} [multiple]
 * @property {(File[]) => void} onFileDrop
 * @property {(isDraggingOver: boolean) => any} children
 */

/**
 *
 * @param {FileDroppableAreaProps} props
 * @returns
 */
export default function FileDroppableArea({
	multiple = false,
	onFileDrop,
	children,
}) {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onFileDrop,
		accept: ALL_ALLOWED_FILE_TYPES,
		multiple,
	});

	return (
		<FileDroppableAreaContainer
			{...getRootProps({
				onClick: (e) => {
					e.stopPropagation();
				},
			})}>
			{/* Hidden input */}
			<input {...getInputProps()} />

			{children(isDragActive)}
		</FileDroppableAreaContainer>
	);
}
