import { useSelector } from 'react-redux';
import { selectTemplates } from '../store/features/templates.slice';

/**
 *
 * @param {inndox.LogbookType} logbookType
 */
export function useTemplatesOfType(logbookType) {
	const templates = useSelector(selectTemplates);

	return templates
		? templates.filter((template) => template.logbookType === logbookType)
		: [];
}
