import { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  AccountRoutes,
  CompanyRoutes,
  OwnerRoutes,
  SharedRoutes,
} from "../routes";
import {
  isCurrentUserACompany,
  selectAccount,
  selectSession,
} from "../store/features/user.slice";
import QueryString from "query-string";

/**
 * @typedef {Object} IAuthContext
 * @property {inndox.UserAccount} [user]
 * @property {inndox.UserSession} [session]
 * @property {boolean} isCompanyUser
 */

const AuthContext = createContext(null);

/**
 *
 * @returns {IAuthContext}
 */
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const session = useSelector(selectSession);
  const user = useSelector(selectAccount);
  const isCompanyUser = useSelector(isCurrentUserACompany);

  const value = { user, session, isCompanyUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const auth = useAuth();
  const { pathname } = useLocation();

  const NEXT_ROUTE =
    pathname ===
    "/" + AccountRoutes.basePath.replace("/*", "") + "/" + AccountRoutes.logout
      ? undefined
      : pathname;

  if (!auth.session) {
    return (
      <Navigate
        to={
          "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.login +
          (NEXT_ROUTE ? "?next=" + NEXT_ROUTE : "")
        }
      />
    );
  }

  return children;
}

export function DoNotRequireAuth({ children }) {
  const auth = useAuth();
  const { search } = useLocation();
  const { next } = QueryString.parse(search);
  const isCompanyUser = useSelector(isCurrentUserACompany);

  if (auth?.session) {
    if (next) {
      if (isCompanyUser) {
        // only allow company or shared routes
        if (
          next.startsWith("/" + CompanyRoutes.basePath.replace("/*", "")) ||
          next.startsWith("/" + SharedRoutes.basePath.replace("/*", ""))
        ) {
          return <Navigate to={next} />;
        } else {
          return <Navigate to="/decider" />;
        }
      } else {
        // only allow owner or shared routes
        if (
          next.startsWith("/" + OwnerRoutes.basePath.replace("/*", "")) ||
          next.startsWith("/" + SharedRoutes.basePath.replace("/*", ""))
        ) {
          return <Navigate to={next} />;
        } else {
          return <Navigate to="/decider" />;
        }
      }
    } else {
      return <Navigate to="/decider" />;
    }
  }

  return children;
}
