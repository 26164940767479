import QueryString from "query-string";
import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { styled } from "styled-components";
import { UsersApi } from "../../api/users";
import accountSideImage from "../../assets/images/account-side.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { AccountRoutes } from "../../routes";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function ResetPassword() {
  const { search } = useLocation();
  const { Email: email, Token: token } = QueryString.parse(search);
  const navigate = useNavigate();
  const [hasResetPassword, setHasResetPassword] = useState(false);

  if (hasResetPassword) {
    return (
      <DocumentTitle title="Reset Password | inndox">
        <FiftyFifty backgroundImage={accountSideImage}>
          <Content>
            <h1>Login to continue</h1>
            <p className="margin-top-1">
              Login with your new credentials to continue using the app.
            </p>

            <button
              className="button button-primary button-big full-width margin-top-4 button-large flex center"
              onClick={() =>
                navigate(
                  "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.login,
                )
              }
            >
              Login
            </button>
          </Content>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Reset Password | inndox">
      <FiftyFifty backgroundImage={accountSideImage}>
        <Content>
          <h1>Reset your password</h1>
          <p className="margin-top-1">Enter your new password.</p>

          <Form
            initialValues={{
              email,
              token,
            }}
            onSubmit={async (values) => {
              const { confirmPassword, ...remainingValues } = values;
              try {
                await UsersApi.resetPassword(
                  remainingValues.token,
                  remainingValues,
                );
                setHasResetPassword(true);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <TextField
                  name="password"
                  type="password"
                  label="New password"
                  required
                  validate={requiredValidator}
                  className="margin-top-4"
                />

                <TextField
                  name="confirmPassword"
                  type="password"
                  label="Confirm password"
                  required
                  validate={(confirmPassword) => {
                    const passesRequiredValidator =
                      requiredValidator(confirmPassword);

                    if (passesRequiredValidator === undefined) {
                      return props.values.password === confirmPassword
                        ? undefined
                        : "Passwords must match";
                    }

                    return passesRequiredValidator;
                  }}
                  className="margin-top-2"
                />

                <button
                  className="button button-primary button-big full-width margin-top-4 button-large flex center"
                  onClick={props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Update password"
                  )}
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
