import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Route, Routes } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { OwnerRoutes } from ".";
import useOwnerData from "../hooks/owner-data";
import NewLogbookDetails from "../pages/common/new-logbook/logbook-details";
import NewLogbookFirstFile from "../pages/common/new-logbook/logbook-first-file";
import NewLogbookType from "../pages/common/new-logbook/logbook-type";
import OwnerHome from "../pages/owner/home";
import OwnerLanding from "../pages/owner/landing";
import OwnerLogbookSiteMap from "./owner-logbook-site-map";

export default function OwnerSiteMap() {
  const isFetchingData = useOwnerData();

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <Routes>
      <Route path={OwnerRoutes.landing} element={<OwnerLanding />} />

      <Route path={OwnerRoutes.home} element={<OwnerHome />} />

      <Route path={OwnerRoutes.newLogbookType} element={<NewLogbookType />} />
      <Route
        path={OwnerRoutes.newLogbookDetails}
        element={<NewLogbookDetails />}
      />
      <Route
        path={OwnerRoutes.newLogbookFirstFile}
        element={<NewLogbookFirstFile />}
      />

      <Route
        path={OwnerRoutes.logbook + "/*"}
        element={<OwnerLogbookSiteMap />}
      />

      {/* Redirect from everything else */}
      <Route path="*" element={<Navigate to={OwnerRoutes.home} />} />
    </Routes>
  );
}
