import { trackWithPixel } from "./meta-pixel";

/**
 *
 * @param {inndox.UserSession} session
 */
export const trackUser = (session) => {
  // We skip this if the user is being impersonated
  if (window.Intercom && !session.impersonator) {
    let role = "";
    // Determine role
    if (session.roles.some((x) => x === "CompanyAdmin")) {
      role = "Company Admin";
    }

    window.Intercom("update", {
      name: session.fullName,
      email: session.email,
      user_id: session.userId,
      role: role,
    });
  }

  trackWithPixel("Sign_In");
};
