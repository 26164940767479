import React, { useState } from 'react';
import { Hidden, Visible } from 'react-grid-system';
import styled from 'styled-components';
import { FormlessSelect } from './form/select';

const TabsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 24px;
`;

const TabsHeader = styled.div`
	display: flex;

	> div {
		width: 100%;
	}

	@media only screen and (max-width: 575px) {
		flex-wrap: wrap;
		margin-bottom: 8px;
	}
`;

const Tab = styled.div`
	flex-grow: 1;
	padding: 15px 16px;
	max-width: 240px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 1.2px;
	line-height: 16px;
	text-transform: uppercase;
	color: #c2c2c2;
	cursor: pointer;
	transition: all 150ms linear;
	text-align: center;

	@media only screen and (max-width: 575px) {
		width: 120px;
	}

	&.active {
		background-color: #bfd62f;
		color: #fff;
		cursor: default;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
`;

const TabContent = styled.div`
	flex-shrink: 0;
`;

/**
 * @typedef {Object} TabsProps
 * @property { {label:string,content: React.ReactNode}[] } tabs
 * @property {number} [startingIndex]
 */

/**
 *
 * @param {TabsProps} props
 * @returns
 */
export default function Tabs(props) {
	const startingIndex = props.startingIndex ?? 0;
	const [currentTabIndex, setCurrentTabIndex] = useState(startingIndex);
	const startingDefaultValue = props.tabs[startingIndex];

	return (
		<TabsContainer>
			<TabsHeader className="tabs-header">
				<Hidden xs>
					{props.tabs.map((tab, index) => (
						<Tab
							className={index === currentTabIndex ? 'active' : ''}
							key={index}
							onClick={() => setCurrentTabIndex(index)}>
							{tab.label}
						</Tab>
					))}
				</Hidden>

				<Visible xs>
					<FormlessSelect
						items={props.tabs.map((tab, index) => ({
							label: tab.label,
							value: index,
						}))}
						defaultValue={startingDefaultValue}
						input={{
							onChange: (selectedTab) => setCurrentTabIndex(selectedTab.value),
						}}
					/>
				</Visible>
			</TabsHeader>

			<TabContent className="tabs-content">
				{props.tabs[currentTabIndex].content}
			</TabContent>
		</TabsContainer>
	);
}
