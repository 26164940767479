import moment from 'moment';
import { Col } from 'react-grid-system';
import styled from 'styled-components';
import calendarIcon from '../assets/images/calendar.svg';

const CalendarIcon = styled.img`
	width: 20px;
	margin-right: 8px;
`;

/**
 *
 * @typedef {Object} MaintenanceItemActivationDisplayProps
 * @property {boolean} isOwner
 * @property {boolean} isTemplate
 * @property {string} scheduledDate
 * @property {number} frequencyInMonths
 * @property {number} frequencyPeriod
 */

/**
 *
 * @param {MaintenanceItemActivationDisplayProps} props
 * @returns
 */
export default function MaintenanceItemActivationDisplay({
	isOwner,
	isTemplate,
	scheduledDate,
	frequencyInMonths,
	frequencyPeriod,
}) {
	/** Methods */
	const displayOneOffActivationDate = () => {
		if (!scheduledDate) {
			return <noscript />;
		}

		return (
			<Col className="margin-top-2">
				<p>
					{/* Calendar icon */}
					<CalendarIcon src={calendarIcon} alt="calendar icon" />{' '}
					<span style={{ verticalAlign: 'super' }}>
						Your due date will be scheduled for{' '}
						<strong>{moment(scheduledDate).format('MMMM Do YYYY.')}</strong>
					</span>
				</p>
			</Col>
		);
	};

	const displayRecurringActivationDate = () => {
		if (frequencyInMonths === -1 && !frequencyPeriod) {
			return <noscript />;
		}

		if (isOwner) {
			return (
				<Col className="margin-top-2">
					<p>
						{/* Calendar icon */}
						<CalendarIcon src={calendarIcon} alt="calendar icon" />{' '}
						<span style={{ verticalAlign: 'super' }}>
							Your next due date will be scheduled for{' '}
							<strong>{moment(scheduledDate).format('MMMM Do YYYY')}</strong> and will
							repeat every{' '}
							<strong>
								{frequencyPeriod} {frequencyPeriod > 1 ? ' months' : ' month'}
							</strong>{' '}
							thereafter.
						</span>
					</p>
				</Col>
			);
		}

		if (frequencyPeriod) {
			return (
				<Col className="margin-top-2">
					<p>
						{/* Calendar icon */}
						<CalendarIcon src={calendarIcon} alt="calendar icon" />{' '}
						<span style={{ verticalAlign: 'super' }}>
							Your next due date will be scheduled for{' '}
							<strong>
								Date of logbook handover + {frequencyPeriod}{' '}
								{frequencyPeriod > 1 ? ' months' : ' month'}
							</strong>{' '}
							and will repeat every{' '}
							<strong>
								{frequencyPeriod} {frequencyPeriod > 1 ? ' months' : ' month'}
							</strong>{' '}
							thereafter.
						</span>
					</p>
				</Col>
			);
		}

		return <noscript />;
	};

	/** Render */

	if (isTemplate) {
		// We don't show the activation date
		return <noscript />;
	}

	if (isOwner) {
		// We will show the actual start date

		if (!frequencyInMonths) {
			// We wait for user to select a frequency before displaying
			return <noscript />;
		}

		if (frequencyInMonths === 0) {
			return displayOneOffActivationDate();
		}

		return displayRecurringActivationDate();
	}

	// Lastly, it would be a company
	// We will show the start date relative to the handover date
	if (!frequencyInMonths) {
		return <noscript />;
	}

	if (frequencyInMonths === 0) {
		return displayOneOffActivationDate();
	}

	return displayRecurringActivationDate();
}
