import { Field } from "react-final-form";
import styled from "styled-components";
import Error from "./error";
import { Description } from "./label";
import { useState } from "react";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  label {
    font-size: 16px;
    line-height: 22px;
    color: #2d3540;
    text-transform: none;
    letter-spacing: normal;
    font-weight: normal;
    cursor: pointer;
  }
`;

const VisibleCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #bfbfbf;
  cursor: pointer;
  position: relative;
  transition: all 150ms linear;
  flex-shrink: 0;
  margin-right: 8px;

  &:hover {
    border-color: #bdd23f;
  }

  &.checked {
    background-color: #bdd23f;
    border-color: #bdd23f;

    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(45deg);
      left: 3px;
      top: 8px;
      transition: all 150ms linear;
    }
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(-45deg);
      left: 6px;
      top: 7px;
      transition: all 150ms linear;
    }
  }
`;

const InnerCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  appearance: none;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export default function Checkbox({
  label,
  name,
  disabled,
  className,
  description,
}) {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => (
        <>
          <CheckboxContainer className={className}>
            <VisibleCheckbox className={input.checked ? "checked" : ""} />
            <label>{label}</label>

            <InnerCheckbox {...input} disabled={disabled} />
          </CheckboxContainer>
          {description && (
            <Description style={{ marginTop: 4 }}>{description}</Description>
          )}
          {meta.touched && meta.error && <Error error={meta.error} invalid />}
        </>
      )}
    </Field>
  );
}

export function FormlessCheckbox({ className, label, description, onChange }) {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    onChange(!isChecked);
    setIsChecked((_) => !_);
  };

  return (
    <>
      <CheckboxContainer className={className} onClick={toggleCheckbox}>
        <VisibleCheckbox className={isChecked ? "checked" : ""} />
        <label>{label}</label>

        <InnerCheckbox />
      </CheckboxContainer>
      {description && (
        <Description style={{ marginTop: 4 }}>{description}</Description>
      )}
    </>
  );
}
