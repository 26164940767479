import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PropertyContactsApi } from "../../api/property-contacts";
import { handleError } from "../../components/helpers";
import {
  addLogbookContact,
  selectCurrentLogbook,
  updateLogbookContact,
} from "../../store/features/logbooks.slice";
import AddOrEditContact from "../common/add-or-edit-contact";

export default function AddOrEditOwnerLogbookContact() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logbookId: logbookIdPathParam, contactId: contactIdPathParam } =
    useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const contactId = parseInt(contactIdPathParam);

  const isUpdatingContact = !!contactId;
  const logbook = useSelector(selectCurrentLogbook);
  const contacts = logbook.contacts;
  const contact = contacts?.find((_) => _.id === contactId);

  const addOrUpdateContact = async (values) => {
    if (isUpdatingContact) {
      try {
        const updatedContact = await PropertyContactsApi.updateLogbookContact(
          logbookId,
          contactId,
          values,
        );
        dispatch(updateLogbookContact(updatedContact));

        navigate(-1);
      } catch (e) {
        handleError(e);
      }
    } else {
      try {
        const newContact = await PropertyContactsApi.createLogbookContact(
          logbookId,
          values,
        );
        dispatch(addLogbookContact(newContact));

        navigate(-1);
      } catch (e) {
        handleError(e);
      }
    }
  };

  return (
    <AddOrEditContact
      initialValues={contact || { propertyId: logbookId }}
      onSubmit={addOrUpdateContact}
    />
  );
}
