import { Field } from "react-final-form";
import styled from "styled-components";
import Error from "./error";
import Label, { Description } from "./label";
import { Variables } from "../../index.style";

const Textarea = styled.textarea`
  width: 100%;
  background-color: transparent;
  border: 1px solid #dde0e3;
  font-size: 16px;
  color: #2d3540;
  padding: 8px;
  caret-color: #bdd23f;
  border-radius: 4px;

  &:focus {
    border-color: ${Variables.secondaryColor};
  }
`;

export default function TextArea({
  label,
  required,
  description,
  name,
  validate,
  placeholder,
  disabled,
  rows = 5,
  className,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <div className={`form-element ${className}`}>
          <Label label={label} required={required} />
          {description && <Description>{description}</Description>}
          <Textarea
            {...input}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
          />
          {meta.touched && meta.error && <Error error={meta.error} invalid />}
        </div>
      )}
    </Field>
  );
}
