import archiveImage from "../assets/images/icon-archive.svg";
import styled from "styled-components";

const ZipUploadProcessingNoticeContainer = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: 24px;
  text-align: center;

  .text {
    text-align: center;
    align-self: center;
  }
`;

const Image = styled.img`
  height: 35px;
  width: 35px;
`;

/**
 *
 * @typedef {Object} ZipUploadProcessingNoticeProps
 * @property {inndox.Logbook} logbook
 * @property {string} [className]
 */
/**
 *
 * @param {ZipUploadProcessingNoticeProps} props
 */

export default function ZipUploadProcessingNotice({ className, logbook }) {
  const zipUploadCount = logbook.documents.filter(
    (document) => document.type === "Zip" && document.name === "unpacking.zip"
  ).length;

  if (zipUploadCount > 0) {
    return (
      <ZipUploadProcessingNoticeContainer className={className}>
        <div>
          <Image src={archiveImage} alt="archive processing" />
        </div>

        <div className="text helper-text margin-top-2">
          Your zip file is currently unpacking. This will take a few minutes to
          complete.
        </div>

        <button
          className="button button-alt margin-top-3"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </button>
      </ZipUploadProcessingNoticeContainer>
    );
  }

  return <noscript />;
}
