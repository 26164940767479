import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FoldersApi } from '../api/folders';
import { PaymentsApi } from '../api/payments';
import { LogbooksApi } from '../api/properties';
import {
	setLogbooks,
	setSharedLogbooks,
} from '../store/features/logbooks.slice';
import { setPaymentDetails } from '../store/features/subscription.slice';

export default function useOwnerData() {
	const dispatch = useDispatch();
	const [isFetchingData, setIsFetchingData] = useState(true);

	useEffect(() => {
		async function fetchData() {
			try {
				// Logbooks
				const logbooks = await LogbooksApi.fetchLogbooks();
				dispatch(setLogbooks(logbooks));

				// Shared Folders
				const sharedFolders = await FoldersApi.fetchSharedFoldersForCurrentUser();
				dispatch(setSharedLogbooks(sharedFolders));

				// Payment Details
				const paymentDetails = await PaymentsApi.fetchConfig();
				dispatch(setPaymentDetails(paymentDetails));
			} catch (e) {
				console.error('owner data > error fetching data');
			} finally {
				setIsFetchingData(false);
			}
		}
		fetchData();
	}, [dispatch]);

	return isFetchingData;
}
