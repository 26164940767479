import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import LogbookDetails from "../common/logbook-details";

export default function OwnerLogbook() {
  const logbook = useSelector(selectCurrentLogbook);

  if (!logbook) {
    return (
      <Container>
        <Row>
          <Col xs={12} className="flex center">
            <ClipLoader loading size={16} color="#bdd23f" />
          </Col>
        </Row>
      </Container>
    );
  }

  return <LogbookDetails logbook={logbook} isCurrentUserACompany={false} />;
}
