import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CompanyApi } from "../api/company";
import { LogbooksApi } from "../api/properties";
import { PaymentsApi } from "../api/payments";
import { UsersApi } from "../api/users";
import { setCompany, setCompanyUsers } from "../store/features/company.slice";
import { setPaymentDetails } from "../store/features/subscription.slice";
import { updateUser } from "../store/features/user.slice";
import { CompanyUsersApi } from "../api/company-users";
import { setLogbooks } from "../store/features/logbooks.slice";

export default function useAccountData() {
  const dispatch = useDispatch();
  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    async function fetchAccountData() {
      try {
        // Account
        const account = await UsersApi.fetchCurrentUser();
        dispatch(updateUser(account));

        // Company
        if (
          account.roles.includes("CompanyAdmin") ||
          account.roles.includes("CompanyUser")
        ) {
          const company = await CompanyApi.fetchCompanies();
          dispatch(setCompany(company));

          // Company Users
          const companyUsers = await CompanyUsersApi.fetchCompanyUsers();
          dispatch(setCompanyUsers(companyUsers));
        }

        // Payments
        const paymentDetails = await PaymentsApi.fetchConfig();
        dispatch(setPaymentDetails(paymentDetails));

        if (account.roles.includes("User")) {
          // Owner account

          // Logbooks
          const logbooks = await LogbooksApi.fetchLogbooks();
          dispatch(setLogbooks(logbooks));
        }
      } catch (e) {
        console.log("account data > error fetching data");
      } finally {
        setIsFetchingData(false);
      }
    }
    fetchAccountData();
  }, [dispatch]);

  return isFetchingData;
}
