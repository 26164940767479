import { isEqual } from "lodash";
import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ContactsApi } from "../../api/contacts";
import { MaintenanceItemsApi } from "../../api/maintenance-items";
import { PropertyContactsApi } from "../../api/property-contacts";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import Checkbox from "../../components/form/checkbox";
import DatePicker from "../../components/form/date-picker";
import Label, { Description } from "../../components/form/label";
import NumberField from "../../components/form/number-field";
import Select from "../../components/form/select";
import TextArea from "../../components/form/text-area";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import {
  handleError,
  MaintenanceItemFrequencyTypes,
} from "../../components/helpers";
import MaintenanceItemActivationDisplay from "../../components/maintenance-item-activatation-display";
import Toast from "../../components/toast";
import { CompanyRoutes, OwnerRoutes } from "../../routes";
import { addCompanyContact } from "../../store/features/company.slice";
import {
  addLogbookContact,
  addLogbookMaintenanceItem,
  setLogbookContacts,
  updateLogbookMaintenanceItem,
} from "../../store/features/logbooks.slice";
import {
  addTemplateContact,
  addTemplateMaintenanceItem,
  updateTemplateMaintenanceItem,
} from "../../store/features/templates.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

const NestedForm = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #efefef5e;
  border-radius: 8px;
`;

/**
 * @typedef {Object} AddOrEditMaintenanceItemFormProps
 * @property {import('react-final-form').FormRenderProps<Record<string, any>, inndox.LogbookMaintenanceItem>} [props]
 * @property {inndox.Contact[]} contacts
 * @property {boolean} isCompanyUser
 * @property {boolean} isPartOfATemplate
 * @property {boolean} isEditingMaintenanceItem
 */

/**
 *
 * @param {AddOrEditMaintenanceItemFormProps} props
 * @returns
 */
function AddOrEditMaintenanceItemForm({
  props,
  isCompanyUser,
  isPartOfATemplate,
  isEditingMaintenanceItem,
  contacts,
}) {
  const [wantsToAddANewContact, setWantsToAddANewContact] = useState(false);

  const getFrequencyPeriod = (frequencyInMonths) => {
    const type = MaintenanceItemFrequencyTypes.find(
      (type) => type.value === frequencyInMonths,
    );
    return type?.value;
  };

  const datePicker = (
    <DatePicker
      name="nextScheduledEvent"
      label="Date"
      placeholder="Date of handover"
      description="When the reminder will be activated."
      className="margin-top-2"
      required
      validators={requiredValidator}
    />
  );

  return (
    <>
      <TextField
        name="title"
        label="Title"
        description="A brief explanation of the maintenance to be performed."
        className="margin-top-2"
        required
        validate={requiredValidator}
      />
      <Select
        name="frequencyInMonths"
        label="Frequency"
        description="How often would you like the item to repeat."
        placeholder="Choose a frequency"
        items={MaintenanceItemFrequencyTypes}
        className="margin-top-2"
      />
      {props.values.frequencyInMonths === -1 ? (
        <NumberField
          name="customFrequencyInMonths"
          label="Custom frequency"
          description="The frequency period in number of  months"
          className="margin-top-2"
        />
      ) : (
        <noscript />
      )}
      <MaintenanceItemActivationDisplay
        frequencyInMonths={props.values.frequencyInMonths}
        frequencyPeriod={
          props.values.frequencyInMonths === -1
            ? props.values.customFrequencyInMonths
            : getFrequencyPeriod(props.values.frequencyInMonths)
        }
        isOwner={!isCompanyUser}
        isTemplate={isPartOfATemplate}
      />
      {!isPartOfATemplate ? (
        props.values.frequencyInMonths === 0 ? (
          datePicker
        ) : !isCompanyUser ? (
          datePicker
        ) : (
          <noscript />
        )
      ) : (
        <noscript />
      )}
      {wantsToAddANewContact ? (
        <>
          <div className="form-element margin-top-2">
            <Label label="Contact responsible" />
            <Description>
              This person's contact details will be included in the reminder.
            </Description>
          </div>

          <NestedForm>
            <Row>
              <Col xs={12} sm={6}>
                <TextField
                  name="newContact.firstName"
                  label="First name"
                  required
                  validate={requiredValidator}
                />
              </Col>
              <Col xs={12} sm={6}>
                <TextField
                  name="newContact.lastName"
                  label="Last name"
                  required
                  validate={requiredValidator}
                />
              </Col>
              <Col xs={12}>
                <Checkbox
                  name="newContact.isPropertyOwner"
                  label="Add as logbook owner"
                  className="margin-top-1"
                />
              </Col>

              <Col xs={12}>
                <TextField
                  name="newContact.email"
                  label="Email address"
                  required
                  validate={emailValidator}
                  className="margin-top-2"
                />
              </Col>

              <Col xs={12}>
                <TextField
                  name="newContact.phone"
                  label="Phone"
                  className="margin-top-2"
                />
              </Col>

              {props.values.newContact?.isPropertyOwner ? (
                <noscript />
              ) : (
                <Col xs={12}>
                  <TextField
                    name="newContact.typeOther"
                    label="Type"
                    className="margin-top-2"
                  />
                </Col>
              )}

              <Col xs={12}>
                <TextField
                  name="newContact.address"
                  label="Address"
                  className="margin-top-2"
                />
              </Col>

              {props.values.newContact?.isPropertyOwner ? (
                <noscript />
              ) : (
                <Col xs={12}>
                  <TextField
                    name="newContact.company"
                    label="Company"
                    className="margin-top-2"
                  />
                </Col>
              )}

              {isCompanyUser ? (
                <Col
                  xs={12}
                  className="margin-top-2"
                  style={{
                    opacity: props.values.newContact?.isPropertyOwner ? 0 : 1,
                  }}
                >
                  <Checkbox
                    label="Save contact to my company account"
                    name="newContact.isSaveToCompanyAccount"
                    className="margin-top-1"
                  />
                </Col>
              ) : null}
            </Row>
          </NestedForm>

          <button
            className="button button-tertiary"
            style={{ marginRight: 0, marginLeft: "auto", display: "block" }}
            onClick={() => setWantsToAddANewContact(false)}
          >
            Or choose an existing contact
          </button>
        </>
      ) : (
        <>
          <Select
            name="scheduledFor"
            label="Contact responsible"
            description="This person's contact details will be included in the reminder."
            className="margin-top-2"
            placeholder="Select a contact"
            items={contacts.map((contact) => ({
              label:
                contact.company || contact.firstName + " " + contact.lastName,
              value: contact.id,
            }))}
          />
          <button
            className="button button-tertiary"
            style={{ marginRight: 0, marginLeft: "auto", display: "block" }}
            onClick={() => setWantsToAddANewContact(true)}
          >
            Or add new contact
          </button>
        </>
      )}
      <TextArea
        name="notes"
        label="Notes"
        description="Any additional info that should be included in the reminder."
        className="margin-top-2"
      />
      <button
        className="button button-primary button-large button-big full-width margin-top-4"
        onClick={props.submitting ? undefined : props.handleSubmit}
      >
        {props.submitting ? (
          <ClipLoader loading size={16} color="#fff" />
        ) : isEditingMaintenanceItem ? (
          "Update"
        ) : (
          "Add"
        )}
      </button>

      <style
        dangerouslySetInnerHTML={{
          __html: `.rw-widget.rw-calendar { width: 300px; }`,
        }}
      />
    </>
  );
}

/**
 * @typedef {Object} AddOrEditMaintenanceItemProps
 * @property {inndox.LogbookMaintenanceItem} [maintenanceItem]
 * @property {number} logbookId
 * @property {inndox.Contact[]} [contacts]
 */

/**
 *
 * @param {AddOrEditMaintenanceItemProps} props
 * @returns
 */
export default function AddOrEditMaintenanceItem({
  maintenanceItem,
  contacts,
  logbookId,
}) {
  const { pathname, key } = useLocation();
  const isEditingMaintenanceItem = !!maintenanceItem;
  const isPartOfATemplate = pathname.includes("templates");
  const isCompanyUser = pathname.includes("company");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backPath =
    key !== "default"
      ? -1
      : isCompanyUser
      ? "/" +
        CompanyRoutes.basePath.replace("/*", "") +
        "/" +
        CompanyRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        CompanyRoutes.logbookMaintenanceItems
      : "/" +
        OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.logbookMaintenanceItems;

  const addNewContact = async (values) => {
    const updatedValues = { ...values };
    const { newContact } = updatedValues;
    const newContactData = { ...newContact };

    if (newContactData.isPropertyOwner === true) {
      newContactData.contactType = "Owner";
      delete newContactData.isPropertyOwner;
    } else {
      newContactData.contactType = "Other";
    }

    if (newContactData.isSaveToCompanyAccount) {
      delete newContactData.isPropertyOwner;
      delete newContactData.isSaveToCompanyAccount;

      // add new contact to company
      const newContact = await ContactsApi.createNewContact(newContactData);
      dispatch(addCompanyContact(newContact));

      // add new contact to logbook
      const updatedLogbookContacts =
        await ContactsApi.bulkAddContactsToProperty(logbookId, [newContact.id]);
      dispatch(setLogbookContacts(updatedLogbookContacts));

      const { id, propertyId, ...remainingNewContactAttributes } = newContact;
      const newLogbookContact = updatedLogbookContacts.find((contact) => {
        const { id, propertyId, ...remainingNewLogbookContactAttributes } =
          contact;
        return isEqual(
          remainingNewContactAttributes,
          remainingNewLogbookContactAttributes,
        );
      });

      // return the new contact
      return newLogbookContact;
    } else {
      delete newContactData.isPropertyOwner;
      delete newContactData.isSaveToCompanyAccount;

      const newLogbookContact = await PropertyContactsApi.createLogbookContact(
        logbookId,
        {
          ...newContactData,
          propertyId: logbookId,
        },
      );

      if (isPartOfATemplate) {
        dispatch(addTemplateContact(newLogbookContact));
      } else {
        dispatch(addLogbookContact(newLogbookContact));
      }

      // return the new contact
      return newLogbookContact;
    }
  };

  const formatOccuranceFrequency = (values) => {
    switch (values.frequencyInMonths) {
      case -1: {
        // Custom frequency
        values.frequencyInMonths = values.customFrequencyInMonths;
        values.repeatsFor = null;
        delete values.customFrequencyInMonths;
        break;
      }

      case 0: {
        // one off
        values.frequencyInMonths = 1;
        values.repeatsFor = 1;
        break;
      }

      default: {
        // use the value selected
        values.repeatsFor = null;
        break;
      }
    }
  };

  const getFrequencyPeriod = (frequencyInMonths) => {
    const type = MaintenanceItemFrequencyTypes.find(
      (type) => type.value === frequencyInMonths,
    );
    return type?.value;
  };

  const frequencyType = isEditingMaintenanceItem
    ? getFrequencyPeriod(maintenanceItem.frequencyInMonths)
    : undefined;

  const formInitialValues = isEditingMaintenanceItem
    ? {
        ...maintenanceItem,
        // update frequence in months to align with internal
        frequencyInMonths:
          maintenanceItem.frequencyInMonths === 1 &&
          maintenanceItem.repeatsFor === 1
            ? 0
            : frequencyType || -1,

        customFrequencyInMonths: maintenanceItem.frequencyInMonths,
      }
    : undefined;
  return (
    <>
      <DocumentTitle
        title={
          isEditingMaintenanceItem
            ? "Edit Maintenance | inndox"
            : "Add Maintenance | inndox"
        }
      >
        <FiftyFifty backgroundImage={logbookSideImage}>
          <Content>
            <BackButton backPath={backPath} />
            <h1>
              {isEditingMaintenanceItem
                ? "Edit Maintenance Item"
                : "Add Maintenance Item"}
            </h1>
            <p className="margin-top-1">
              <span>
                {isCompanyUser
                  ? "The logbook owner will be notified two weeks before a maintenance item is due."
                  : "Get notified two weeks before a maintenance item is due."}
              </span>
              &nbsp;
              <a
                href="https://www.inndox.com/maintenance-reminders-feature"
                rel="noopener noreferrer"
                target="_blank"
                className="link-style-elem"
              >
                Find out more
              </a>
            </p>

            <Form
              initialValues={formInitialValues}
              onSubmit={async (values) => {
                if (isEditingMaintenanceItem) {
                  try {
                    const { newContact, ...remainingValues } = values;

                    if (newContact) {
                      // add new contact
                      const createdContact = await addNewContact(values);

                      // set new contact as contactId
                      remainingValues.scheduledFor = createdContact.id;
                    }

                    // format values
                    const updatedValues = { ...remainingValues };
                    formatOccuranceFrequency(updatedValues);

                    // update maintenance item
                    const updatedMaintenaceItem =
                      await MaintenanceItemsApi.updateItem(
                        maintenanceItem.propertyId,
                        maintenanceItem.id,
                        updatedValues,
                      );

                    if (isPartOfATemplate) {
                      dispatch(
                        updateTemplateMaintenanceItem(updatedMaintenaceItem),
                      );
                    } else {
                      dispatch(
                        updateLogbookMaintenanceItem(updatedMaintenaceItem),
                      );
                    }

                    toast.success(
                      <Toast title="The maintenance item has been updated." />,
                    );

                    navigate(backPath);
                  } catch (e) {
                    handleError(e);
                  }
                } else {
                  try {
                    const { newContact, ...remainingValues } = values;

                    if (newContact) {
                      // add new contact
                      const createdContact = await addNewContact(values);

                      // set new contact as contactId
                      remainingValues.scheduledFor = createdContact.id;
                    }

                    // format values
                    const updatedValues = { ...remainingValues };
                    formatOccuranceFrequency(updatedValues);

                    const newMaintenanceItem =
                      await MaintenanceItemsApi.createNewItem(
                        logbookId,
                        updatedValues,
                      );

                    if (isPartOfATemplate) {
                      dispatch(addTemplateMaintenanceItem(newMaintenanceItem));
                    } else {
                      dispatch(addLogbookMaintenanceItem(newMaintenanceItem));
                    }

                    toast.success(
                      <Toast title="The maintenance item has been added." />,
                    );

                    navigate(backPath);
                  } catch (e) {
                    handleError(e);
                  }
                }
              }}
              render={(props) => (
                <AddOrEditMaintenanceItemForm
                  props={props}
                  contacts={contacts}
                  isCompanyUser={isCompanyUser}
                  isPartOfATemplate={isPartOfATemplate}
                  isEditingMaintenanceItem={isEditingMaintenanceItem}
                />
              )}
            />
          </Content>
        </FiftyFifty>
      </DocumentTitle>

      {isPartOfATemplate ? (
        <style
          dangerouslySetInnerHTML={{
            __html: `#persistent-template-footer { display: none; }`,
          }}
        />
      ) : (
        <noscript />
      )}
    </>
  );
}
