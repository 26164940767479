import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";
import AddOrEditMaintenanceItem from "../common/add-or-edit-maintenance-item";

export default function AddOrEditOwnerLogbookMaintenanceItem() {
  const { maintenanceId: maintenanceIdPathParam } = useParams();
  const maintenanceId = parseInt(maintenanceIdPathParam);
  const logbook = useSelector(selectCurrentLogbook);
  const items = logbook?.maintenanceItems;
  const item = maintenanceId
    ? items?.find((item) => item.id === maintenanceId)
    : undefined;

  return (
    <AddOrEditMaintenanceItem
      logbookId={logbook.id}
      maintenanceItem={item}
      contacts={logbook.contacts}
    />
  );
}
