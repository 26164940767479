import { orderBy } from "lodash";
import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ContactsApi } from "../api/contacts";
import { PropertyContactsApi } from "../api/property-contacts";
import deleteIcon from "../assets/images/cancel-subscription.svg";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../routes";
import { removeCompanyContact } from "../store/features/company.slice";
import { removeLogbookContact } from "../store/features/logbooks.slice";
import { removeTemplateContact } from "../store/features/templates.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { getPersonSymbol, handleError } from "./helpers";
import Modal from "./modal";
import MoreMenu, {
  DELETE_CONTACT_MENU_ITEM,
  EDIT_CONTACT_MENU_ITEM,
  VIEW_CONTACT_MENU_ITEM,
} from "./more-menu";
import SetupBlock from "./setup-block";
import Toast from "./toast";

const ContactEmail = styled.a`
  color: #9b9b9b;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

/**
 *
 * @param {inndox.Contact[]} contacts
 * @returns {inndox.Contact[]}
 */
export const sortContacts = (contacts) => {
  return orderBy(
    contacts,
    [
      (contact) => contact?.firstName?.toLowerCase(),
      (contact) => contact?.lastName?.toLowerCase(),
    ],
    "asc",
  );
};

/**
 * @typedef {Object} ContactRowItemProps
 * @property {inndox.Contact} contact
 * @property {number} index
 * @property {boolean} canEdit
 * @property {boolean} showCompany
 */

/**
 *
 * @param {ContactRowItemProps} props
 * @returns
 */
function ContactRowItem({ contact, index, canEdit, showCompany = false }) {
  const [wantsToDeleteContact, setWantsToDeleteContact] = useState(false);
  const { firstName, lastName, phone, email, contactType, typeOther, company } =
    contact;
  const initials =
    (firstName ? firstName.charAt(0) : "") +
    (lastName ? lastName.charAt(0) : "");
  const { pathname } = useLocation();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const navigate = useNavigate();
  const isViewingAtLogbookLevel = pathname.indexOf("logbooks") > 0;
  const isViewingInsideATemplate = pathname.indexOf("templates") > 0;
  const isViewingAtCompanyLevel =
    !isViewingInsideATemplate && !isViewingAtLogbookLevel;

  const dispatch = useDispatch();

  const actionSelectMenuOption = (value) => {
    switch (value) {
      case VIEW_CONTACT_MENU_ITEM.value: {
        // view contact
        if (isViewingInsideATemplate) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              TemplateRoutes.template.replace(
                ":templateId",
                contact.propertyId,
              ) +
              "/" +
              TemplateRoutes.viewTemplateContact.replace(
                ":contactId",
                contact.id,
              ),
          );
        } else if (isViewingAtLogbookLevel) {
          // inside a logbook
          navigate(
            isCompanyUser
              ? "/" +
                  CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  CompanyRoutes.logbook.replace(
                    ":logbookId",
                    contact.propertyId,
                  ) +
                  "/" +
                  CompanyRoutes.viewLogbookContact.replace(
                    ":contactId",
                    contact.id,
                  )
              : "/" +
                  OwnerRoutes.basePath.replace("/*", "") +
                  "/" +
                  OwnerRoutes.logbook.replace(
                    ":logbookId",
                    contact.propertyId,
                  ) +
                  "/" +
                  OwnerRoutes.viewLogbookContact.replace(
                    ":contactId",
                    contact.id,
                  ),
          );
        } else {
          // company level
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.viewCompanyContact.replace(
                ":contactId",
                contact.id,
              ),
          );
        }
        break;
      }

      case EDIT_CONTACT_MENU_ITEM.value: {
        // edit contact
        if (isViewingAtLogbookLevel) {
          // inside a logbook
          navigate(
            isCompanyUser
              ? "/" +
                  CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  CompanyRoutes.logbook.replace(
                    ":logbookId",
                    contact.propertyId,
                  ) +
                  "/" +
                  CompanyRoutes.editLogbookContact.replace(
                    ":contactId",
                    contact.id,
                  )
              : "/" +
                  OwnerRoutes.basePath.replace("/*", "") +
                  "/" +
                  OwnerRoutes.logbook.replace(
                    ":logbookId",
                    contact.propertyId,
                  ) +
                  "/" +
                  OwnerRoutes.editLogbookContact.replace(
                    ":contactId",
                    contact.id,
                  ),
          );
        } else if (isViewingInsideATemplate) {
          // company level
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              TemplateRoutes.template.replace(
                ":templateId",
                contact.propertyId,
              ) +
              "/" +
              TemplateRoutes.editTemplateContact.replace(
                ":contactId",
                contact.id,
              ),
          );
        } else {
          // company level
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.editCompanyContact.replace(
                ":contactId",
                contact.id,
              ),
          );
        }
        break;
      }

      case DELETE_CONTACT_MENU_ITEM.value: {
        // delete contact
        setWantsToDeleteContact(true);
        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [VIEW_CONTACT_MENU_ITEM];
  if (canEdit) {
    menuOptions.push(EDIT_CONTACT_MENU_ITEM);
    menuOptions.push(DELETE_CONTACT_MENU_ITEM);
  }

  return (
    <Row>
      <Col xs={9} sm={3} className="col-body">
        <Hidden xs>{initials ? getPersonSymbol(initials, index) : null}</Hidden>

        <Hidden xs>
          <div style={{ maxWidth: "calc(100% - 48px)" }}>
            <p className="truncate">
              {firstName} {lastName && ` ${lastName}`}
            </p>

            {showCompany ? (
              <p className="truncate helper-text">{company}</p>
            ) : email ? (
              <p className="truncate helper-text">
                <ContactEmail
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </ContactEmail>
              </p>
            ) : null}
          </div>
        </Hidden>

        <Visible xs>
          <div style={{ maxWidth: "100%" }}>
            <p className="truncate">
              {firstName} {lastName && ` ${lastName}`}
            </p>

            {showCompany ? (
              <p className="truncate helper-text">{company}</p>
            ) : email ? (
              <p className="truncate helper-text">
                <ContactEmail
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </ContactEmail>
              </p>
            ) : null}
          </div>
        </Visible>
      </Col>

      <Hidden xs>
        <Col xs={3} sm={2} className="col-body">
          <p className="truncate">
            {contactType === "Other" ? typeOther || "NA" : contactType}
          </p>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={3} className="col-body">
          <p className="truncate">{company || "NA"}</p>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={3} className="col-body">
          {phone ? (
            <a href={`tel:${phone}`} className="link-style-elem">
              {phone || "NA"}
            </a>
          ) : (
            <p className="truncate">NA</p>
          )}
        </Col>
      </Hidden>

      <Col xs={3} sm={1} className="col-body">
        {menuOptions.length ? (
          <MoreMenu options={menuOptions} onSelect={actionSelectMenuOption} />
        ) : (
          <noscript />
        )}
      </Col>

      {/* Delete Contact Modal */}
      <Modal
        isOpen={wantsToDeleteContact}
        title={
          <>
            <img
              src={deleteIcon}
              alt="delete contact"
              style={{ marginRight: 8 }}
            />
            Delete this contact
          </>
        }
        onClose={() => setWantsToDeleteContact(false)}
      >
        {isViewingAtCompanyLevel === true ? (
          <>
            <p>
              Deleting this contact will delete it from your account and any
              logbook it has been added to forever.
            </p>
            <br />
            <p>Are you sure you want to proceed?</p>
          </>
        ) : (
          <p>
            If you delete this contact, it will be gone forever. Are you sure
            you want to proceed?
          </p>
        )}

        <Row className="margin-top-2">
          <Col xs={12}>
            <Form
              onSubmit={async () => {
                try {
                  if (isViewingAtCompanyLevel === true) {
                    // company level
                    await ContactsApi.removeContact(contact.id);
                    dispatch(removeCompanyContact(contact.id));
                  } else {
                    // inside a logboor or template
                    await PropertyContactsApi.removeLogbookContact(
                      contact.propertyId,
                      contact.id,
                    );

                    if (pathname.indexOf("templates") > -1) {
                      dispatch(removeTemplateContact(contact.id));
                    } else {
                      dispatch(removeLogbookContact(contact.id));
                    }
                  }

                  toast.success(<Toast title="Contact has been deleted" />);

                  setWantsToDeleteContact(false);
                } catch (e) {
                  handleError(e);
                }
              }}
              render={(props) => (
                <button
                  className="button button-danger margin-right-1"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Yes, delete this contact"
                  )}
                </button>
              )}
            />

            <button
              className="button button-alt"
              onClick={() => setWantsToDeleteContact(false)}
            >
              Cancel
            </button>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}

/**
 * @typedef {Object} ContactsListProps
 * @property {inndox.Contact[]} contacts
 * @property {boolean} canEdit
 * @property {boolean} showFooter
 * @property {boolean} isInsideATemplate
 * @property {boolean} showCompany
 */

/**
 *
 * @param {ContactsListProps} props
 * @returns
 */
export default function ContactsList({
  contacts,
  canEdit,
  showFooter = false,
  showCompany = false,
}) {
  const navigate = useNavigate();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  if (!contacts?.length) {
    return <SetupBlock description="There are no contacts in this logbook" />;
  }

  return (
    <div className="document-list">
      <Row>
        {/* Header Row */}
        <Col xs={9} sm={3} className="col-head">
          Name
        </Col>

        <Hidden xs>
          <Col xs={5} sm={2} className="col-head">
            Type
          </Col>
        </Hidden>

        <Hidden xs>
          <Col sm={3} className="col-head">
            Company
          </Col>
        </Hidden>

        <Hidden xs>
          <Col sm={3} className="col-head">
            Phone
          </Col>
        </Hidden>

        <Col xs={3} sm={1} className="col-head" />
      </Row>

      {/* Contacts */}
      {contacts.map((contact, index) => (
        <ContactRowItem
          contact={contact}
          key={`contact-${index}`}
          index={index}
          canEdit={canEdit}
          showCompany={showCompany}
        />
      ))}

      {showFooter ? (
        <Row
          style={{
            borderTop: "1px solid #dde0e3",
            paddingTop: 16,
            paddingBottom: 8,
          }}
        >
          <div className="flex end" style={{ width: "100%" }}>
            {canEdit ? (
              <button
                className={`button button-secondary`}
                onClick={() => {
                  if (isCompanyUser) {
                    navigate(CompanyRoutes.addLogbookContact);
                  } else {
                    navigate(OwnerRoutes.addLogbookContact);
                  }
                }}
              >
                Add new
              </button>
            ) : (
              <noscript />
            )}

            <button
              className={`button button-alt margin-left-1`}
              onClick={() => {
                if (isCompanyUser) {
                  navigate(CompanyRoutes.logbookContacts);
                } else {
                  navigate(OwnerRoutes.logbookContacts);
                }
              }}
            >
              View all
            </button>
          </div>
        </Row>
      ) : (
        <noscript />
      )}
    </div>
  );
}
