import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { LogbooksApi } from "../api/properties";
import rightArrowIcon from "../assets/images/arrow-right.svg";
import closeIcon from "../assets/images/close.svg";
import searchIcon from "../assets/images/search.svg";
import DocumentList from "../pages/common/document-list";
import FolderList from "../pages/common/folder-list";
import { CompanyRoutes, OwnerRoutes } from "../routes";
import {
  selectLogbookContentSearchResults,
  setSearchResults,
} from "../store/features/logbooks.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import AddOrEditFolderModal from "./add-or-edit-folder.modal";
import AddOrEditLinkModal from "./add-or-edit-link.modal";
import TextField from "./form/text-field";
import MoreMenu, {
  ADD_LINK_TO_LOGBOOK_MENU_ITEM,
  ADD_LOGBOOK_FOLDER_MENU_ITEM,
  DIVIDER_MENU_ITEM,
  SHARE_FOLDERS_MENU_ITEM,
  UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM,
  VIEW_ALL_FOLDERS_MENU_ITEM,
} from "./more-menu";
import SetupBlock from "./setup-block";
import ShareLogbookOrContent from "./share-logbook-or-content";
import FolderAndFileUploadModal from "./upload-files-and-folders.modal";

const ClearButton = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  width: 48px;
  height: 48px;
  background-color: #d0d5de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms linear;
  overflow: hidden;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 18px;
`;

const SearchFieldContainer = styled.div`
  width: 100%;
  max-width: 375px;
  position: relative;
  margin-right: 8px;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

const SearchButton = styled.div`
  position: absolute;
  top: 27px;
  right: 27px;
  width: 0;
  height: 0;
  background-color: #bdd23f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms linear;
  overflow: hidden;
  cursor: pointer;

  &.visible {
    width: 48px;
    height: 48px;
    top: 3px;
    right: 3px;
  }

  img {
    max-width: 50%;
  }
`;

export default function SearchLogbookForm({
  logbookId,
  children,
  isInsideATemplate,
}) {
  const dispatch = useDispatch();
  const [isEnteringQuery, setIsEnteringQuery] = useState(false);
  const [isSearchingForFilesAndFolders, setIsSearchingForFilesAndFolders] =
    useState(false);
  const searchResults = useSelector(selectLogbookContentSearchResults);
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const navigate = useNavigate();
  const [wantsToUploadFiles, setWantsToUploadFiles] = useState(false);
  const [wantsToUploadLink, setWantsToUploadLink] = useState(false);
  const [wantsToAddFolder, setWantsToAddFolder] = useState(false);
  const [wantsToShareCurrentLogbook, setWantsToShareCurrentLogbook] =
    useState(false);

  const hasResultsAfterSearch =
    searchResults?.folders?.length || searchResults?.documents?.length;

  const actionSelectedMenuItem = (value) => {
    switch (value) {
      case VIEW_ALL_FOLDERS_MENU_ITEM.value: {
        // user is on the dashboard
        // so we can just redirect them to folders
        navigate(
          isCompanyUser
            ? CompanyRoutes.logbookFolders
            : OwnerRoutes.logbookFolders,
        );
        break;
      }
      case UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM.value: {
        setWantsToUploadFiles(true);
        break;
      }
      case ADD_LINK_TO_LOGBOOK_MENU_ITEM.value: {
        setWantsToUploadLink(true);
        break;
      }
      case ADD_LOGBOOK_FOLDER_MENU_ITEM.value: {
        setWantsToAddFolder(true);
        break;
      }
      case SHARE_FOLDERS_MENU_ITEM.value: {
        setWantsToShareCurrentLogbook(true);
        break;
      }
      default:
        break;
    }
  };

  return (
    <Form
      onSubmit={async (values) => {
        try {
          const newSearchResults = await LogbooksApi.searchLogbookContents(
            logbookId,
            values.query,
          );
          dispatch(setSearchResults(newSearchResults));
          setIsEnteringQuery(false);
        } catch (e) {}
      }}
      mutators={{
        setFieldValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={(props) => (
        <>
          <Row className="margin-top-3">
            <Col xs={12} className="flex end" style={{ paddingRight: 16 }}>
              <SearchFieldContainer>
                <TextField
                  name="query"
                  placeholder="Search for file name"
                  className="search"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsSearchingForFilesAndFolders(true);
                      props.handleSubmit();
                    }
                  }}
                  onFocus={() => setIsEnteringQuery(true)}
                />

                <SearchIcon>
                  <img src={searchIcon} alt="search" />
                </SearchIcon>

                {isEnteringQuery || props.submitting ? (
                  <SearchButton
                    className={
                      props.values?.query?.length > 0 ? "visible" : undefined
                    }
                    onClick={
                      props.submitting
                        ? undefined
                        : () => {
                            setIsSearchingForFilesAndFolders(true);
                            props.handleSubmit();
                          }
                    }
                  >
                    {props.submitting ? (
                      <ClipLoader loading size={16} color="#fff" />
                    ) : (
                      <img src={rightArrowIcon} alt="search" />
                    )}
                  </SearchButton>
                ) : props.values?.query?.length > 0 ? (
                  <ClearButton
                    onClick={() => {
                      props.form.mutators.setFieldValue("query", "");
                      setIsSearchingForFilesAndFolders(false);
                    }}
                  >
                    <img src={closeIcon} alt="clear" />
                  </ClearButton>
                ) : (
                  <noscript />
                )}
              </SearchFieldContainer>

              <MoreMenu
                options={[
                  VIEW_ALL_FOLDERS_MENU_ITEM,
                  DIVIDER_MENU_ITEM,
                  UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM,
                  ADD_LINK_TO_LOGBOOK_MENU_ITEM,
                  ADD_LOGBOOK_FOLDER_MENU_ITEM,
                  DIVIDER_MENU_ITEM,
                  SHARE_FOLDERS_MENU_ITEM,
                ]}
                invertColors
                onSelect={actionSelectedMenuItem}
              />
            </Col>
          </Row>

          {isSearchingForFilesAndFolders ? (
            props.submitting ? (
              <Row>
                <Col xs={12} className="flex">
                  <ClipLoader loading size={16} color="#bdd23f" />
                </Col>
              </Row>
            ) : !hasResultsAfterSearch ? (
              <>
                <br />
                <SetupBlock description="No matching files or folders found" />
              </>
            ) : (
              <>
                <FolderList
                  folders={searchResults?.folders}
                  logbookId={logbookId}
                  isCurrentUserACompany={isCompanyUser}
                  showLabel={searchResults?.folders?.length}
                  canEdit
                />

                <DocumentList
                  documents={searchResults?.documents}
                  showTitle={searchResults?.documents?.length}
                  isInsideATemplate={isInsideATemplate}
                />
              </>
            )
          ) : (
            <>
              {children}

              {/* Upload doc & folder */}
              <FolderAndFileUploadModal
                isVisible={wantsToUploadFiles}
                onClose={() => setWantsToUploadFiles(false)}
                logbookId={logbookId}
                isAddingToATemplate={isInsideATemplate}
              />

              {/* Add link */}
              <AddOrEditLinkModal
                isVisible={wantsToUploadLink}
                onClose={() => setWantsToUploadLink(false)}
                logbookId={logbookId}
                isPartOfTemplate={isInsideATemplate}
              />

              {/* Add Folder */}
              <AddOrEditFolderModal
                isVisible={wantsToAddFolder}
                onClose={() => setWantsToAddFolder(false)}
                logbookId={logbookId}
                isPartOfTemplate={isInsideATemplate}
              />

              {/* Share Folder */}
              <ShareLogbookOrContent
                isOpen={wantsToShareCurrentLogbook}
                onClose={() => setWantsToShareCurrentLogbook(false)}
              />
            </>
          )}
        </>
      )}
    />
  );
}
