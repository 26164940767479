import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DefectsApi } from "../../api/defects";
import logbookSideImage from "../../assets/images/bg2.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import Checkbox from "../../components/form/checkbox";
import Dropzone from "../../components/form/dropzone";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import Toast from "../../components/toast";
import { CompanyRoutes, OwnerRoutes } from "../../routes";
import {
  addDefectToCurrentLogbook,
  updateCurrentLogbookDefect,
} from "../../store/features/logbooks.slice";
import { isCurrentUserACompany } from "../../store/features/user.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

/**
 * @typedef {Object} EditClaimProps
 * @property {inndox.LogbookDefect} [defect]
 * @property {number} logbookId
 */

/**
 *
 * @param {EditClaimProps} props
 */
export default function AddOrEditClaim({ defect, logbookId }) {
  const { defectId: defectIdPathParam } = useParams();
  const { key } = useLocation();
  const defectId = parseInt(defectIdPathParam);
  const isEditingDefect = !!defectId;
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const backPath =
    key !== "default"
      ? -1
      : isCompanyUser
      ? "/" +
        CompanyRoutes.basePath.replace("/*", "") +
        "/" +
        CompanyRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        CompanyRoutes.defects
      : "/" +
        OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.logbookDefects;

  return (
    <DocumentTitle
      title={`${isEditingDefect ? "Edit" : "New"} Logbook Issue | inndox`}
    >
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <h1>{isEditingDefect ? "Edit Logbook Issue" : "File a New Issue"}</h1>

          {isCompanyUser ? (
            <noscript />
          ) : (
            <p className="margin-top-1">
              Your sponsored company will be notified of your issue.
            </p>
          )}

          <Form
            initialValues={isEditingDefect ? defect : { propertyId: logbookId }}
            onSubmit={async (values) => {
              const { files, ...remainingAttributes } = values;
              const formData = new FormData();
              Object.keys(remainingAttributes).forEach((key) => {
                formData.append(key, remainingAttributes[key]);
              });

              /**
               * The code below does the following two things:
               * 1. Splits files into existing and new files
               * 2. Finds existing files that are removed
               */
              if (files) {
                const filesNotRemoved = files
                  .filter((file) => !!file.id)
                  .map((file) => file.id);
                const newFiles = files.filter((file) => !file.id);

                // check for files to remove
                if (defect?.files) {
                  const filesRemoved = defect?.files.filter(
                    (file) => !filesNotRemoved.includes(file.id),
                  );
                  formData.append(
                    "fileIdsToRemove",
                    filesRemoved.map((file) => file.id),
                  );
                }

                // add new files
                newFiles?.forEach((file) => formData.append("files", file));
              }

              if (isEditingDefect) {
                try {
                  const updatedDefect = await DefectsApi.updateDefectContent(
                    logbookId,
                    defectId,
                    formData,
                  );
                  dispatch(updateCurrentLogbookDefect(updatedDefect));

                  navigate(backPath);
                } catch (e) {
                  handleError(e);
                }
              } else {
                try {
                  const newDefect = await DefectsApi.createNewDefect(
                    logbookId,
                    formData,
                  );
                  dispatch(addDefectToCurrentLogbook(newDefect));

                  if (isCompanyUser) {
                    toast.success(
                      <Toast title="Success! Your issue has been added to the logbook." />,
                    );
                  } else {
                    toast.success(
                      <Toast title="Success! Your issue has been submitted to the company for actioning." />,
                    );
                  }

                  navigate(backPath);
                } catch (e) {
                  handleError(e);
                }
              }
            }}
            render={(props) => (
              <>
                <TextField
                  name="subject"
                  label="Issue subject"
                  required
                  validate={requiredValidator}
                  className="margin-top-4"
                />

                <TextField
                  name="description"
                  label="Description"
                  className="margin-top-2"
                  required
                  validate={requiredValidator}
                />

                <Checkbox
                  className="margin-top-4"
                  name="isUrgent"
                  label="Is this issue urgent?"
                  description="E.g. Significant safety hazard or a plumbing leak."
                />

                <Dropzone
                  name="files"
                  className="margin-top-4"
                  label="Upload Files"
                  placeholder="Drag file here or click to browse"
                  activePlaceholder="Drop the file here ..."
                  accept={{
                    "image/png": [".png", ".jpg", ".jpeg", ".tiff", ".tif"],
                    "text/plain": [".txt"],
                    "application/pdf": [".pdf"],
                  }}
                  multiple
                />

                <button
                  className="button button-primary button-large button-big full-width margin-top-4"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Submit issue"
                  )}
                </button>
                <button
                  className="button button-link button-large button-big full-width"
                  onClick={() => navigate(backPath)}
                >
                  Cancel
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
