import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import warningYellowIcon from '../assets/images/warning-yellow.svg';

const RecentNotification = styled.div`
	background-color: #ffffff;
	border-radius: 12px;
	padding: 24px;
	position: relative;
`;

export default function LogbookTransferInProgressNotification() {
	return (
		<Container>
			<Row>
				<Col>
					<RecentNotification className="flex center margin-top-2 margin-bottom-4">
						<img src={warningYellowIcon} alt="warning" width={32} height={32} />

						<div className="flex col margin-left-2" style={{ flexGrow: 1 }}>
							<p>
								<strong>Logbook transfer in progress</strong>
							</p>

							<p>
								Remember to contact your client by Phone or SMS to let them know that
								they have been sent an email and that they should accept the logbook as
								soon as possible.
							</p>
						</div>
					</RecentNotification>
				</Col>
			</Row>
		</Container>
	);
}
