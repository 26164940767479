import styled from 'styled-components';
import closeIcon from '../assets/images/close.svg';

const ToastCloseContainer = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #f5fafd;
	}
`;

export function ToastClose({ closeToast }) {
	return (
		<ToastCloseContainer onClick={closeToast}>
			<img src={closeIcon} alt="close" width={15} />
		</ToastCloseContainer>
	);
}
