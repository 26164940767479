import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { LogbooksApi } from "../../api/properties";
import { useAuth } from "../../components/authentication";
import CancelHandover from "../../components/cancel-handover";
import CloneTemplateDialog from "../../components/clone-template";
import ContactsWidget from "../../components/contacts-widget";
import { handleError } from "../../components/helpers";
import LogbookHeader from "../../components/logbook-header";
import LogbookTransferInProgressNotification from "../../components/logbook-transfer-in-progress";
import MaintenanceItemWidget from "../../components/maintenance-item-widget";
import Modal from "../../components/modal";
import MoreMenu, {
  ADD_LINK_TO_LOGBOOK_MENU_ITEM,
  ADD_LINK_TO_TEMPLATE_MENU_ITEM,
  ADD_TEMPLATE_FOLDER_MENU_ITEM,
  APPLY_TEMPLATE_TO_LOGBOOK_MENU_ITEM,
  CANCEL_LOGBOOK_HANDOVER_MENU_ITEM,
  CLONE_LOGBOOK_MENU_ITEM,
  CLONE_TEMPLATE_MENU_ITEM,
  DELETE_LOGBOOK_MENU_ITEM,
  EDIT_LOGBOOK_MENU_ITEM,
  SHARE_FOLDERS_MENU_ITEM,
  SHARE_LOGBOOK_MENU_ITEM,
  TRANSFER_LOGBOOK_MENU_ITEM,
  UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM,
  VIEW_ARCHIVED_CONTENTS_MENU_ITEM,
  VIEW_LOGBOOK_CLAIMS_MENU_ITEM,
  VIEW_LOGBOOK_CONTACTS_MENU_ITEM,
} from "../../components/more-menu";
import RecentDefectNotification from "../../components/recent-defect-notification";
import SearchLogbookForm from "../../components/search-logbook";
import ShareLogbookOrContent from "../../components/share-logbook-or-content";
import Toast from "../../components/toast";
import WarrantyRemindersWidget from "../../components/warranty-reminders-widget";
import ZipUploadProcessingNotice from "../../components/zip-upload-processing-notice";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../../routes";
import { addLogbook, removeLogbook } from "../../store/features/logbooks.slice";
import DocumentList from "./document-list";
import FolderList from "./folder-list";

/**
 *
 * @typedef {Object} LogbookDetailsProps
 * @property {inndox.Logbook} logbook
 * @property {boolean} isCurrentUserACompany
 * @property {number} [templateId]
 * @returns
 */

/**
 *
 * @param {LogbookDetailsProps} props
 * @returns
 */
export default function LogbookDetails({
  logbook,
  isCurrentUserACompany,
  templateId,
}) {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, key } = useLocation();
  const currentUserId = session.userId;
  const isCurrentUserAReadOnlyMemberForLogbook =
    logbook.ownerId !== currentUserId;
  const [wantsToCloneCurrentLogbook, setWantsToCloneCurrentLogbook] =
    useState(false);
  const [wantsToShareCurrentLogbook, setWantsToShareCurrentLogbook] =
    useState(false);
  const [wantsToCloneTemplate, setWantsToCloneTemplate] = useState(false);
  const [wantsToCancelHandover, setWantsToCancelHandover] = useState(false);

  const backPath =
    key !== "default"
      ? -1
      : isCurrentUserACompany
        ? "/" + CompanyRoutes.home
        : "/" + OwnerRoutes.home;

  const documentsOutsideFolders = logbook?.documents
    .filter((document) => !document.folderId && !document.dateArchived)
    .sort((a, b) => a.filename - b.filename);

  const editLogbookOrTemplate = () => {
    if (isCurrentUserACompany) {
      if (logbook.isTemplate) {
        // navigate to edit template
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.editTemplate.replace(":templateId", templateId),
        );
      } else {
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
            "/" +
            CompanyRoutes.editLogbook,
        );
      }
    } else {
      navigate(
        "/" +
          OwnerRoutes.basePath.replace("/*", "") +
          "/" +
          OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
          "/" +
          OwnerRoutes.editLogbook,
      );
    }
  };

  const deleteLogbook = async () => {
    try {
      await LogbooksApi.removeLogbook(logbook.id);

      toast.success(
        <Toast
          title={`Logbook - ${logbook.description} - has been removed.`}
        />,
      );

      dispatch(removeLogbook(logbook.id));
      navigate(backPath);
    } catch (e) {
      handleError(e);
    }
  };

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case EDIT_LOGBOOK_MENU_ITEM.value: {
        // edit logbook details
        editLogbookOrTemplate();

        break;
      }

      case VIEW_LOGBOOK_CLAIMS_MENU_ITEM.value: {
        // view claims
        if (isCurrentUserACompany) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              CompanyRoutes.logbookDefects,
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              OwnerRoutes.logbookDefects,
          );
        }
        break;
      }

      case ADD_LINK_TO_LOGBOOK_MENU_ITEM.value: {
        // add link
        if (isCurrentUserACompany) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              CompanyRoutes.addLogbookLink,
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              OwnerRoutes.addLogbookLink,
          );
        }
        break;
      }

      case APPLY_TEMPLATE_TO_LOGBOOK_MENU_ITEM.value: {
        // apply template to logbook
        // only for companies
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
            "/" +
            CompanyRoutes.applyTemplate,
        );
        break;
      }

      case CLONE_LOGBOOK_MENU_ITEM.value: {
        // clone logbook
        setWantsToCloneCurrentLogbook(true);
        break;
      }

      case VIEW_LOGBOOK_CONTACTS_MENU_ITEM.value: {
        // view contacts
        if (isCurrentUserACompany) {
          navigate(CompanyRoutes.logbookContacts);
        } else {
          navigate(OwnerRoutes.logbookContacts);
        }

        break;
      }

      case VIEW_ARCHIVED_CONTENTS_MENU_ITEM.value: {
        // view archive
        navigate(OwnerRoutes.logbookArchive);
        break;
      }

      // transfer
      case TRANSFER_LOGBOOK_MENU_ITEM.value: {
        navigate(OwnerRoutes.logbookTransfer);
        break;
      }

      case SHARE_LOGBOOK_MENU_ITEM.value:
      case SHARE_FOLDERS_MENU_ITEM.value: {
        // if company user && if there are no folders, show toast
        if (isCurrentUserACompany && !logbook.folders?.length) {
          toast.error(
            <Toast title="Your logbook must have at least one folder before you can share it with someone." />,
          );
        } else {
          setWantsToShareCurrentLogbook(true);
        }

        break;
      }

      // cancel transfer
      case CANCEL_LOGBOOK_HANDOVER_MENU_ITEM.value: {
        //
        setWantsToCancelHandover(true);
        break;
      }

      // upload files
      case UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM.value: {
        //
        if (logbook.isTemplate) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              TemplateRoutes.template.replace(":templateId", templateId) +
              "/" +
              TemplateRoutes.addDocumentsToTemplate,
          );
        } else if (isCurrentUserACompany) {
          navigate(
            "/" +
              CompanyRoutes.basePath.replace("/*", "") +
              "/" +
              CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              CompanyRoutes.addLogbookDocuments,
          );
        } else {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
              "/" +
              OwnerRoutes.addLogbookDocuments,
          );
        }
        break;
      }

      case CLONE_TEMPLATE_MENU_ITEM.value: {
        //
        setWantsToCloneTemplate(true);
        break;
      }

      case ADD_TEMPLATE_FOLDER_MENU_ITEM.value: {
        //
        navigate(TemplateRoutes.newTemplateFolder);
        break;
      }

      case ADD_LINK_TO_TEMPLATE_MENU_ITEM.value: {
        //
        navigate(TemplateRoutes.addTemplateLink);
        break;
      }

      // delete logbook
      case DELETE_LOGBOOK_MENU_ITEM.value: {
        deleteLogbook();
        break;
      }

      default:
        break;
    }
  };

  const menuOptions = [];

  if (isCurrentUserACompany) {
    if (logbook.isTemplate) {
      menuOptions.push(...[CLONE_TEMPLATE_MENU_ITEM]);
    } else if (logbook.transfer?.status !== "Transferred") {
      menuOptions.push(
        ...[
          VIEW_LOGBOOK_CLAIMS_MENU_ITEM,
          APPLY_TEMPLATE_TO_LOGBOOK_MENU_ITEM,
          CLONE_LOGBOOK_MENU_ITEM,
        ],
      );
    }
  } else {
    menuOptions.push(
      ...[
        logbook.companies?.length > 0 &&
          logbook.hasDefectManagement &&
          VIEW_LOGBOOK_CLAIMS_MENU_ITEM,
        !isCurrentUserAReadOnlyMemberForLogbook && SHARE_LOGBOOK_MENU_ITEM,
        VIEW_ARCHIVED_CONTENTS_MENU_ITEM,
      ],
    );
  }

  return (
    <DocumentTitle title={logbook.description}>
      <div className="property">
        {logbook.transfer?.status === "Transferring" ? (
          <LogbookTransferInProgressNotification />
        ) : logbook.transfer?.status === "Transferred" &&
          isCurrentUserACompany ? (
          <RecentDefectNotification />
        ) : (
          <noscript />
        )}

        <LogbookHeader
          companyId={logbook.companyId}
          hasTransferred={logbook.transfer?.status === "Transferred"}
          templateId={templateId}
          isOwner={!isCurrentUserACompany}
          coverImage={logbook.photoPath}
          ownerId={logbook.ownerId}
          tertiaryAction={
            menuOptions.length > 0 ? (
              <div
                className="flex center"
                style={{
                  width: 50,
                  height: 50,
                  backgroundColor: "#fff",
                  borderRadius: 10,
                }}
              >
                <MoreMenu
                  options={menuOptions}
                  transparentBackground
                  onSelect={actionSelectedMenuOption}
                />
              </div>
            ) : (
              <noscript />
            )
          }
        />

        <Container>
          {/* Search */}
          <SearchLogbookForm
            logbookId={logbook.id}
            isInsideATemplate={!!templateId}
          >
            {/* Folders */}
            <FolderList
              folders={logbook.folders.slice(0, 5)}
              canEdit={
                isCurrentUserACompany ? !logbook.transfer?.acceptedOn : true
              }
              isCurrentUserACompany={isCurrentUserACompany}
              showLabel
              ownerId={logbook.ownerId}
            />

            {/* Zip Uploads */}
            <Row id="documents-container">
              <Col xs={12}>
                <ZipUploadProcessingNotice
                  className="margin-top-3"
                  logbook={logbook}
                />
              </Col>
            </Row>

            {/* Documents */}
            <DocumentList
              documents={documentsOutsideFolders}
              showTitle
              isInsideATemplate={logbook.isTemplate}
            />

            {/* Contacts */}
            <ContactsWidget
              contacts={logbook.contacts}
              isLogbookTransferred={!!logbook.transfer?.acceptedOn}
              isPartOfATemplate={logbook.isTemplate}
              ownerId={logbook.ownerId}
            />

            {/* Warranty Reminders */}
            {logbook.isTemplate ? (
              <noscript />
            ) : (
              <WarrantyRemindersWidget
                documents={logbook.documents}
                isCompanyUser={isCurrentUserACompany}
                ownerId={logbook.ownerId}
              />
            )}

            {/* Maintenance Items */}
            <MaintenanceItemWidget logbook={logbook} />
          </SearchLogbookForm>
        </Container>

        {/* Clone Logbook Modal */}
        <Modal
          isOpen={wantsToCloneCurrentLogbook}
          title="Clone Logbook"
          onClose={() => setWantsToCloneCurrentLogbook(false)}
        >
          <p>Copy this logbook including documents and folders?</p>

          <div className="flex end margin-top-3">
            <button
              className="button margin-right-2"
              onClick={() => setWantsToCloneCurrentLogbook(false)}
            >
              Cancel
            </button>

            <Form
              onSubmit={async () => {
                try {
                  // clone logbook
                  const clonedLogbook = await LogbooksApi.cloneLogbook(
                    logbook.id,
                  );
                  dispatch(addLogbook(clonedLogbook));

                  // navigate to new logbook
                  navigate(pathname.replace(logbook.id, clonedLogbook.id));
                } catch (e) {
                  handleError(e);
                }
              }}
              render={(props) => (
                <button
                  className="button button-archive flex"
                  onClick={props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#FF3E3E" />
                  ) : (
                    "Clone Logbook"
                  )}
                </button>
              )}
            />
          </div>
        </Modal>

        {/* Share Logbook or Content */}
        <ShareLogbookOrContent
          isOpen={wantsToShareCurrentLogbook}
          onClose={() => setWantsToShareCurrentLogbook(false)}
        />

        {/* Clone Template Modal */}
        {logbook.isTemplate ? (
          <CloneTemplateDialog
            isOpen={wantsToCloneTemplate}
            onClose={() => setWantsToCloneTemplate(false)}
            templateId={templateId}
          />
        ) : (
          <noscript />
        )}

        {/* Cancel transfer */}
        <CancelHandover
          propertyId={logbook.id}
          isOpen={wantsToCancelHandover}
          onClose={() => setWantsToCancelHandover(false)}
          receiverEmail={logbook.transfer?.to.email}
        />
      </div>
    </DocumentTitle>
  );
}
