import DocumentTitle from "react-document-title";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { styled } from "styled-components";
import { AccountRoutes } from ".";
import accessSettingsActiveIcon from "../assets/images/access_active.svg";
import accessSettingsInactiveIcon from "../assets/images/access_inactive.svg";
import billingActiveIcon from "../assets/images/billing_active.svg";
import billingInactiveIcon from "../assets/images/billing_inactive.svg";
import companyInfoActiveIcon from "../assets/images/company_info_active.svg";
import companyInfoInactiveIcon from "../assets/images/company_info_inactive.svg";
import integrationsActiveIcon from "../assets/images/integrations_active.svg";
import integrationsInactiveIcon from "../assets/images/integrations_inactive.svg";
import logbooksActiveIcon from "../assets/images/logbooks_active.svg";
import logbooksInactiveIcon from "../assets/images/logbooks_inactive.svg";
import personalInfoActiveIcon from "../assets/images/personal_active.svg";
import personalInfoInactiveIcon from "../assets/images/personal_inactive.svg";
import statisticsActiveIcon from "../assets/images/statistics_active.svg";
import statisticsInactiveIcon from "../assets/images/statistics_inactive.svg";
import { RequireAuth } from "../components/authentication";
import AccessSettings from "../pages/account/settings/access";
import BillingSettings from "../pages/account/settings/billing";
import CompanyInfoSettings from "../pages/account/settings/company-info";
import IntegrationSettings from "../pages/account/settings/integrations";
import LogbooksSettings from "../pages/account/settings/logbooks";
import PersonalInfoSettings from "../pages/account/settings/personal-info";
import CompanyStatistics from "../pages/account/settings/statistics";
import { selectCompany } from "../store/features/company.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";

const Content = styled.div`
  @media only screen and (max-width: 786px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const StyledHeading = styled.h2`
  @media only screen and (max-width: 576px) {
    margin-bottom: 16px;
  }
`;

const SettingsRow = styled(Row)`
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
`;

const SettingsLeftColumn = styled(Col)`
  padding: 24px !important;
  border-right: 1px solid #dde0e3;

  @media only screen and (max-width: 576px) {
    border-right: none;
    padding: 0 24px !important;
  }
`;

const SettingsRightColumn = styled(Col)`
  padding: 24px !important;
`;

const Label = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #adadb0;
`;

export default function AccountSettings() {
  return (
    <DocumentTitle title="Settings | inndox">
      <Content className="folder">
        <Container>
          <Row>
            <Col xs={12} md={6} className="flex">
              <StyledHeading className="name">Settings</StyledHeading>
            </Col>
          </Row>

          <SettingsRow>
            <SettingsSidebar />

            <Hidden xs sm>
              <SettingsRightColumn md={9}>
                <Routes>
                  {/* Personal Info */}
                  <Route
                    path={AccountRoutes.personalInfo}
                    element={
                      <RequireAuth>
                        <PersonalInfoSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Company Info */}
                  <Route
                    path={AccountRoutes.companyInfo}
                    element={
                      <RequireAuth>
                        <CompanyInfoSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Access */}
                  <Route
                    path={AccountRoutes.accessSettings}
                    element={
                      <RequireAuth>
                        <AccessSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Integrations */}
                  <Route
                    path={AccountRoutes.integrations}
                    element={
                      <RequireAuth>
                        <IntegrationSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Statistics */}
                  <Route
                    path={AccountRoutes.statistics}
                    element={
                      <RequireAuth>
                        <CompanyStatistics />
                      </RequireAuth>
                    }
                  />

                  {/* Billing */}
                  <Route
                    path={AccountRoutes.billing}
                    element={
                      <RequireAuth>
                        <BillingSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Logbooks */}
                  <Route
                    path={AccountRoutes.logbooks}
                    element={
                      <RequireAuth>
                        <LogbooksSettings />
                      </RequireAuth>
                    }
                  />

                  {/* Redirect from everything else */}
                  <Route
                    path="*"
                    element={<Navigate to={AccountRoutes.personalInfo} />}
                  />
                </Routes>
              </SettingsRightColumn>
            </Hidden>
          </SettingsRow>
        </Container>
      </Content>
    </DocumentTitle>
  );
}

const MobileSettingsLeftColumnItemContainer = styled.div`
  border-bottom: 1px solid #dde0e3;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -24px;
  margin-right: -24px;

  &:last-of-type {
    border-bottom: none;
  }
`;

const PERSONAL_INFO_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.personalInfo;

const COMPANY_INFO_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.companyInfo;

const ACCESS_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.accessSettings;

const INTEGRATION_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.integrations;

const STATS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.statistics;

const BILLING_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.billing;

const LOGBOOKS_SETTINGS_ROUTE =
  "/" +
  AccountRoutes.basePath.replace("/*", "") +
  "/" +
  AccountRoutes.settingsBasePath.replace("/*", "") +
  "/" +
  AccountRoutes.logbooks;

function SettingsSidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const companyInfo = useSelector(selectCompany);

  const wantsToViewPersonalInfoSettings =
    pathname === PERSONAL_INFO_SETTINGS_ROUTE;
  const wantsToViewCompanyInfoSettings =
    pathname === COMPANY_INFO_SETTINGS_ROUTE;
  const wantsToViewAccessSettings = pathname === ACCESS_SETTINGS_ROUTE;
  const wantsToViewIntegrationSettings =
    pathname === INTEGRATION_SETTINGS_ROUTE;
  const wantsToViewStats = pathname === STATS_ROUTE;
  const wantsToViewBillingSettings = pathname === BILLING_SETTINGS_ROUTE;
  const wantsToViewLogbooksSettings = pathname === LOGBOOKS_SETTINGS_ROUTE;

  const togglePersonalInfoSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.personalInfo,
    );
  };
  const toggleCompanyInfoSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.companyInfo,
    );
  };
  const toggleAccessSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.accessSettings,
    );
  };
  const toggleIntegrationSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.integrations,
    );
  };
  const toggleStats = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.statistics,
    );
  };
  const toggleBillingSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.billing,
    );
  };
  const toggleLogbooksSettings = () => {
    navigate(
      "/" +
        AccountRoutes.basePath.replace("/*", "") +
        "/" +
        AccountRoutes.settingsBasePath.replace("/*", "") +
        "/" +
        AccountRoutes.logbooks,
    );
  };

  return (
    <>
      <Hidden xs sm>
        {isCompanyUser ? (
          <SettingsLeftColumn md={3}>
            <Label style={{ marginBottom: 12 }}>Profile</Label>
            <SettingsLeftColumnItem
              inactiveIcon={personalInfoInactiveIcon}
              activeIcon={personalInfoActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.personalInfo
              }
              text="Personal info"
            />

            <Label style={{ marginTop: 32, marginBottom: 12 }}>
              Company Settings
            </Label>
            <SettingsLeftColumnItem
              inactiveIcon={companyInfoInactiveIcon}
              activeIcon={companyInfoActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.companyInfo
              }
              text="Company info"
            />
            <SettingsLeftColumnItem
              inactiveIcon={accessSettingsInactiveIcon}
              activeIcon={accessSettingsActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.accessSettings
              }
              text="Access settings"
            />
            <SettingsLeftColumnItem
              inactiveIcon={integrationsInactiveIcon}
              activeIcon={integrationsActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.integrations
              }
              text="Integrations"
            />
            {companyInfo?.isMasterCompany ? (
              <SettingsLeftColumnItem
                inactiveIcon={statisticsInactiveIcon}
                activeIcon={statisticsActiveIcon}
                path={
                  "/" +
                  AccountRoutes.basePath.replace("/*", "") +
                  "/" +
                  AccountRoutes.settingsBasePath.replace("/*", "") +
                  "/" +
                  AccountRoutes.statistics
                }
                text="Statistics"
              />
            ) : (
              <noscript />
            )}

            <Label style={{ marginTop: 32, marginBottom: 12 }}>
              Organisation
            </Label>
            <SettingsLeftColumnItem
              inactiveIcon={billingInactiveIcon}
              activeIcon={billingActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.billing
              }
              text="Billing"
            />
          </SettingsLeftColumn>
        ) : (
          <SettingsLeftColumn md={3}>
            <Label style={{ marginBottom: 12 }}>Profile</Label>
            <SettingsLeftColumnItem
              inactiveIcon={personalInfoInactiveIcon}
              activeIcon={personalInfoActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.personalInfo
              }
              text="Personal info"
            />

            <SettingsLeftColumnItem
              inactiveIcon={billingInactiveIcon}
              activeIcon={billingActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.billing
              }
              text="Billing"
            />

            <SettingsLeftColumnItem
              inactiveIcon={logbooksInactiveIcon}
              activeIcon={logbooksActiveIcon}
              path={
                "/" +
                AccountRoutes.basePath.replace("/*", "") +
                "/" +
                AccountRoutes.settingsBasePath.replace("/*", "") +
                "/" +
                AccountRoutes.logbooks
              }
              text="Logbooks"
            />
          </SettingsLeftColumn>
        )}
      </Hidden>

      <Visible xs sm>
        <SettingsLeftColumn xs={12}>
          {isCompanyUser ? (
            <>
              {/* User Profile */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={personalInfoInactiveIcon}
                  activeIcon={personalInfoActiveIcon}
                  text="Personal info"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.personalInfo
                  }
                  onClick={togglePersonalInfoSettings}
                />

                {wantsToViewPersonalInfoSettings ? (
                  <PersonalInfoSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>

              {/* Company Profile */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={companyInfoInactiveIcon}
                  activeIcon={companyInfoActiveIcon}
                  text="Company info"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.companyInfo
                  }
                  onClick={toggleCompanyInfoSettings}
                />
                {wantsToViewCompanyInfoSettings ? (
                  <CompanyInfoSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>

              {/* Access Settings */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={accessSettingsInactiveIcon}
                  activeIcon={accessSettingsActiveIcon}
                  text="Access settings"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.accessSettings
                  }
                  onClick={toggleAccessSettings}
                />
                {wantsToViewAccessSettings ? <AccessSettings /> : <noscript />}
              </MobileSettingsLeftColumnItemContainer>

              {/* Integrations */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={integrationsInactiveIcon}
                  activeIcon={integrationsActiveIcon}
                  text="Integrations"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.integrations
                  }
                  onClick={toggleIntegrationSettings}
                />
                {wantsToViewIntegrationSettings ? (
                  <IntegrationSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>

              {/* Stats */}
              {companyInfo?.isMasterCompany ? (
                <MobileSettingsLeftColumnItemContainer>
                  <SettingsLeftColumnItem
                    inactiveIcon={statisticsInactiveIcon}
                    activeIcon={statisticsActiveIcon}
                    text="Statistics"
                    path={
                      "/" +
                      AccountRoutes.basePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.settingsBasePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.statistics
                    }
                    onClick={toggleStats}
                  />
                  {wantsToViewStats ? <CompanyStatistics /> : <noscript />}
                </MobileSettingsLeftColumnItemContainer>
              ) : (
                <noscript />
              )}

              {/* Billing */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={billingInactiveIcon}
                  activeIcon={billingActiveIcon}
                  text="Billing"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.billing
                  }
                  onClick={toggleBillingSettings}
                />
                {wantsToViewBillingSettings ? (
                  <BillingSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>
            </>
          ) : (
            <>
              {/* User Profile */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={personalInfoInactiveIcon}
                  activeIcon={personalInfoActiveIcon}
                  text="Personal info"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.personalInfo
                  }
                  onClick={togglePersonalInfoSettings}
                />

                {wantsToViewPersonalInfoSettings ? (
                  <PersonalInfoSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>

              {/* Billing */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={billingInactiveIcon}
                  activeIcon={billingActiveIcon}
                  text="Billing"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.billing
                  }
                  onClick={toggleBillingSettings}
                />
                {wantsToViewBillingSettings ? (
                  <BillingSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>

              {/* Logbooks */}
              <MobileSettingsLeftColumnItemContainer>
                <SettingsLeftColumnItem
                  inactiveIcon={logbooksInactiveIcon}
                  activeIcon={logbooksActiveIcon}
                  text="Logbooks"
                  path={
                    "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.settingsBasePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.logbooks
                  }
                  onClick={toggleLogbooksSettings}
                />
                {wantsToViewLogbooksSettings ? (
                  <LogbooksSettings />
                ) : (
                  <noscript />
                )}
              </MobileSettingsLeftColumnItemContainer>
            </>
          )}
        </SettingsLeftColumn>

        <Routes>
          {/* Personal Info */}
          <Route path={AccountRoutes.personalInfo} element={<noscript />} />

          {/* Company Info */}
          <Route path={AccountRoutes.companyInfo} element={<noscript />} />

          {/* Access */}
          <Route path={AccountRoutes.accessSettings} element={<noscript />} />

          {/* Integrations */}
          <Route path={AccountRoutes.integrations} element={<noscript />} />

          {/* Statistics */}
          <Route path={AccountRoutes.statistics} element={<noscript />} />

          {/* Billing */}
          <Route path={AccountRoutes.billing} element={<noscript />} />

          {/* Billing */}
          <Route path={AccountRoutes.logbooks} element={<noscript />} />

          {/* Redirect from everything else */}
          <Route
            path="*"
            element={<Navigate to={AccountRoutes.personalInfo} />}
          />
        </Routes>
      </Visible>
    </>
  );
}

const SettingsLeftColumnItemContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  &.active {
    color: #203d5e;
  }

  &.inactive {
    color: #adadb0;
    cursor: pointer;
  }
`;

const SettingsLeftColumnItemIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;

function SettingsLeftColumnItem({
  text,
  inactiveIcon,
  activeIcon,
  path,
  onClick,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isItemActive = pathname === path;

  const clickHandler = onClick ?? (() => navigate(path));

  return (
    <SettingsLeftColumnItemContainer
      className={isItemActive ? "active" : "inactive"}
      onClick={isItemActive ? null : clickHandler}
    >
      <SettingsLeftColumnItemIconContainer>
        <img src={isItemActive ? activeIcon : inactiveIcon} alt="icon" />
      </SettingsLeftColumnItemIconContainer>

      {text}
    </SettingsLeftColumnItemContainer>
  );
}
