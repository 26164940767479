import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import Checkbox from "../../../components/form/checkbox";
import TextField from "../../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../../components/form/validators";
import { CompanyRoutes } from "../../../routes";
import { isCurrentUserACompany } from "../../../store/features/user.slice";

const StepCount = styled.label`
  font-size: 13px;
  font-weight: 900;
  line-height: 1.69;
  color: #bfd62f;
  margin-top: 24px;
  display: block;
`;

const InfoContainer = styled.div`
  text-align: left;
  padding: 8px 16px;
  background-color: rgba(191, 214, 47, 0.14);
  border-top: 1px solid #bfd62f;
  border-bottom: 1px solid #bfd62f;
`;

export default function NewOwnerDetails({ onContinue, onBack, submitting }) {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  return (
    <>
      {state?.["hasArrivedOnTransferDirectly"] === true ? (
        <noscript />
      ) : (
        <StepCount>Step 2 of 2</StepCount>
      )}
      <h1>Handover to Owner</h1>

      {isCompanyUser ? (
        <p className="margin-top-1">
          <b>Tip:</b>&nbsp;You'll still be able to view files after
          transferring.
        </p>
      ) : (
        <noscript />
      )}

      <p className="margin-top-2">
        <strong>Things to keep in mind</strong>
      </p>
      <InfoContainer className="margin-top-1">
        The logbook transfer will be <strong> pending</strong> until the new
        owner has accepted the transfer. If you need to cancel, you can do so
        from your dashboard.
      </InfoContainer>

      <TextField
        name="email"
        label="Owner's Email"
        required
        validate={emailValidator}
        className="margin-top-2"
      />

      <TextField
        name="firstName"
        label="First name"
        required
        validate={requiredValidator}
        className="margin-top-2"
      />

      <TextField
        name="lastName"
        label="Last name"
        required
        validate={requiredValidator}
        className="margin-top-2"
      />

      <TextField
        name="phone"
        label="Phone Number"
        className="margin-top-2"
        required
        validate={requiredValidator}
      />

      {isCompanyUser ? (
        <Checkbox
          name="hasDefectManagement"
          label="Report issues"
          className="margin-top-2"
          description="Allow the owner to to use inndox to report issues. You will receive an email notification for every issue."
        />
      ) : (
        <noscript />
      )}

      <button
        className="button button-primary button-large button-big full-width margin-top-4"
        onClick={submitting ? undefined : onContinue}
      >
        {submitting ? (
          <ClipLoader loading size={16} color="#fff" />
        ) : (
          "Continue"
        )}
      </button>

      <p className="text-center margin-top-3">
        Any folders shared on this logbook will be transferred to the new owner.
        Click cancel to change any settings before handover.
      </p>

      <button
        className="button button-tertiary button-large button-big full-width margin-top-2"
        onClick={() => {
          if (isCompanyUser) {
            navigate(pathname.replace("/" + CompanyRoutes.logbookTransfer, ""));
          } else {
            onBack();
          }
        }}
      >
        Cancel
      </button>
    </>
  );
}
