import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCompanyDefects } from "../../store/features/company.slice";
import ViewClaim from "../common/view-claim";

export default function ViewCompanyDefect() {
  const { defectId: defectIdPathParam } = useParams();
  const defectId = parseInt(defectIdPathParam);
  const defects = useSelector(selectCompanyDefects);
  const defect = defects?.find((defect) => defect.id === defectId);

  return <ViewClaim defect={defect} />;
}
