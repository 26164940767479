/**
 *
 * @param {google.maps.places.PlaceResult} place
 * @returns {inndox.AddressComponents}
 */
export default function getAddressComponents(place) {
	const { address_components, formatted_address } = place;

	const getAddressComponent = (property, useLongName = false) => {
		if (!address_components || address_components.length === 0) {
			return undefined;
		}

		const matchedComponent = address_components.find(
			(addressComponent) =>
				!!addressComponent.types &&
				addressComponent.types.some((_) => _ === property)
		);
		if (!matchedComponent) {
			return undefined;
		}

		return matchedComponent[useLongName ? 'long_name' : 'short_name'];
	};

	return {
		address: formatted_address,
		streetAddress:
			getAddressComponent('street_number', true) +
			' ' +
			getAddressComponent('route', true),
		suburb: getAddressComponent('locality', true) || 'NA',
		state: getAddressComponent('administrative_area_level_1') || 'NA',
		country: getAddressComponent('country'),
		postcode: getAddressComponent('postal_code'),
	};
}
