import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { ContactsApi } from "../../api/contacts";
import { DefectsApi } from "../../api/defects";
import { PropertyContactsApi } from "../../api/property-contacts";
import defectSideImage from "../../assets/images/account-side.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import Label from "../../components/form/label";
import Select from "../../components/form/select";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import {
  selectCompanyDefects,
  updateCompanyDefect,
} from "../../store/features/company.slice";
import {
  selectCurrentLogbookDefects,
  updateCurrentLogbookDefect,
} from "../../store/features/logbooks.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

const DefectSummaryContainer = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #a0b42a20;
  border-radius: 8px;
  border: 1px solid #a0b42a;
  margin-bottom: 16px;
  text-align: left;
`;

const DefectSummaryContainerTitle = styled.p`
  font-weight: 600;
`;

const DefectSummaryContainerDescription = styled.p``;

export default function AssignClaim() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // defect
  const { defectId: defectIdPathParam } = useParams();
  const defectId = parseInt(defectIdPathParam);
  const { pathname, key } = useLocation();
  const isViewingDefectInsideALogbook = pathname.includes("logbooks");

  const backPath =
    key !== "default" ? -1 : pathname.replace("/" + defectId + "/assign", "");

  // local state
  const [contacts, setContacts] = useState();
  const [wantsToAddNewContact, setWantsToAddNewContact] = useState(
    contacts?.length ? false : true,
  );

  // defect
  const companyDefects = useSelector(selectCompanyDefects);
  const logbookDefects = useSelector(selectCurrentLogbookDefects);
  const defect = (
    isViewingDefectInsideALogbook ? logbookDefects : companyDefects
  ).find((defect) => defect.id === defectId);

  useEffect(() => {
    async function fetchContacts() {
      const contactsResponse = await PropertyContactsApi.fetchLogbookContacts(
        defect.property.id,
      );
      setContacts(contactsResponse);
    }
    fetchContacts();
  }, [defect.property.id]);

  if (!contacts) {
    return (
      <DocumentTitle title="Assign Issue | inndox">
        <FiftyFifty backgroundImage={defectSideImage}>
          <Content
            className="margin-top-1"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <ClipLoader loading size={16} color="#bdd23f" />
          </Content>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Assign Issue | inndox">
      <FiftyFifty backgroundImage={defectSideImage}>
        <Content className="margin-top-1">
          <BackButton backPath={backPath} />
          <h1>Assign issue</h1>
          <p className="margin-bottom-4 margin-top-1">
            Who’ll be responsible for this issue?
          </p>

          {/* Defect Summary */}
          <Label label="Issue Summary" required />
          <DefectSummaryContainer>
            <DefectSummaryContainerTitle>
              {defect.subject}
            </DefectSummaryContainerTitle>
            <DefectSummaryContainerDescription>
              {defect.description}
            </DefectSummaryContainerDescription>
          </DefectSummaryContainer>

          <Form
            initialValues={{ propertyId: defect.property.id }}
            onSubmit={async (values) => {
              if (wantsToAddNewContact) {
                try {
                  // update values with contact type
                  values.contactType = 0;

                  // create new contact for company
                  const newContact = await ContactsApi.createNewContact(values);

                  // add contact to property
                  await ContactsApi.bulkAddContactsToProperty(
                    values.propertyId,
                    [newContact.id],
                  );

                  // fetch contacts for logbook
                  const updatedContacts =
                    await PropertyContactsApi.fetchLogbookContacts(
                      defect.property.id,
                    );
                  const contact = updatedContacts.find(
                    (contact) =>
                      contact.email === values.email &&
                      contact.firstName === values.firstName &&
                      contact.lastName === values.lastName,
                  );

                  // assign defect to contact
                  await DefectsApi.assignDefect(
                    defect.property.id,
                    defectId,
                    contact.id,
                  );

                  const updatedDefect = await DefectsApi.fetchDefect(
                    defect.property.id,
                    defect.id,
                  );

                  if (isViewingDefectInsideALogbook) {
                    dispatch(updateCurrentLogbookDefect(updatedDefect));
                  } else {
                    dispatch(updateCompanyDefect(updatedDefect));
                  }

                  navigate(backPath);
                } catch (e) {
                  handleError(e);
                }
              } else {
                // assign defect to contact
                await DefectsApi.assignDefect(
                  defect.property.id,
                  defectId,
                  values.contactId,
                );
                const updatedDefect = await DefectsApi.fetchDefect(
                  defect.property.id,
                  defect.id,
                );

                if (isViewingDefectInsideALogbook) {
                  dispatch(updateCurrentLogbookDefect(updatedDefect));
                } else {
                  dispatch(updateCompanyDefect(updatedDefect));
                }

                navigate(backPath);
              }
            }}
            render={(props) => (
              <>
                {wantsToAddNewContact ? (
                  <>
                    <TextField
                      name="email"
                      label="Email"
                      className="margin-top-2"
                      required
                      validate={emailValidator}
                    />
                    <TextField
                      name="firstName"
                      label="First name"
                      className="margin-top-2"
                      required
                      validate={requiredValidator}
                    />
                    <TextField
                      name="lastName"
                      label="Last name"
                      className="margin-top-2"
                      required
                      validate={requiredValidator}
                    />
                  </>
                ) : (
                  <Select
                    name="contactId"
                    label="Choose a contact to assign this issue to"
                    items={contacts.map((contact) => ({
                      label:
                        contact.company ||
                        contact.firstName + " " + contact.lastName,
                      value: contact.id,
                    }))}
                    required
                    validators={requiredValidator}
                  />
                )}

                {wantsToAddNewContact ? (
                  contacts?.length ? (
                    <p
                      className="link-style-elem margin-top-2"
                      onClick={() => setWantsToAddNewContact(false)}
                    >
                      Choose an existing contact
                    </p>
                  ) : (
                    <noscript />
                  )
                ) : (
                  <p
                    className="link-style-elem margin-top-2"
                    onClick={() => setWantsToAddNewContact(true)}
                  >
                    Add a new contact
                  </p>
                )}

                {/* Assign button */}
                <button
                  className="button button-primary button-large button-big margin-top-4"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Assign issue"
                  )}
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
