import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import QueryString from "query-string";
import { useState } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { PaymentsApi } from "../api/payments";
import sideImage from "../assets/images/bg1.jpg";
import AmericanExpressImg from "../assets/images/card-american-express.png";
import MastercardImg from "../assets/images/card-mastercard.png";
import VisaImg from "../assets/images/card-visa.png";
import PoweredByStripeImg from "../assets/images/stripe-powered.png";
import Label from "../components/form/label";
import {
  selectPaymentDetails,
  updatePaymentDetails,
} from "../store/features/subscription.slice";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { FiftyFifty } from "./fifty-fifty";
import { handleError } from "./helpers";
import Toast from "./toast";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
  padding-top: 56px;
`;

const DiscountDisplayContainer = styled.div`
  height: 58px;
  width: 100%;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #bfbfbf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 32px;
`;

const DiscountLabel = styled.label`
  line-height: 23px;
  color: #fff;
  background-color: #ff605f;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bolder;
`;

const PaymentMethodIcon = styled.img`
  height: 26px;
  margin-left: 4px;
  margin-right: 4px;
`;

const BillingCycleChoiceContainer = styled.div`
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f6f7fb;
  width: 192px;
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const BillingCycleChoice = styled.div`
  padding: 8px 16px;
  display: inline-block;
  width: 96px;
  cursor: pointer;

  &.selected {
    background-color: #bdd23f;
    color: #fff;
    cursor: default;
  }
`;

export default function AddPaymentDetails({ onFinish }) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { source } = QueryString.parse(search);
  const paymentDetails = useSelector(selectPaymentDetails);
  const {
    discount = 0,
    planMonthlyPrice,
    planLimit,
    plan,
    planCurrency,
    planAnnualPrice,
  } = paymentDetails;
  const planMonthlyPriceAfterDiscount = Math.round(
    planMonthlyPrice * (1 - discount / 100),
  );
  const planAnnualPriceAfterDiscount = Math.round(
    planAnnualPrice * (1 - discount / 100),
  );
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const [billingCycle, setBillingCycle] = useState("Monthly");

  return (
    <DocumentTitle title="Add Payment Details | inndox">
      <FiftyFifty backgroundImage={sideImage}>
        <Content>
          <h1>
            {source === "owner"
              ? "Oh no! It looks like this is a paid feature."
              : "Subscribe to inndox"}
          </h1>

          {/* Discount Copy */}
          {discount > 0 ? (
            billingCycle === "Monthly" ? (
              <>
                <DiscountDisplayContainer>
                  <strong style={{ width: 78 }}>${planMonthlyPrice}</strong>
                  <strong style={{ width: 78, color: "#FF605F" }}>
                    ${planMonthlyPriceAfterDiscount}
                  </strong>
                  <DiscountLabel>You save {discount}%</DiscountLabel>
                </DiscountDisplayContainer>

                <p className="helper-text" style={{ marginTop: 8 }}>
                  Discount applies while the coupon is valid
                </p>
              </>
            ) : (
              <>
                <DiscountDisplayContainer>
                  <strong style={{ width: 78 }}>${planAnnualPrice}</strong>
                  <strong style={{ width: 78, color: "#FF605F" }}>
                    ${planAnnualPriceAfterDiscount}
                  </strong>
                  <DiscountLabel>You save {discount}%</DiscountLabel>
                </DiscountDisplayContainer>

                <p className="helper-text" style={{ marginTop: 8 }}>
                  Discount applies while the coupon is valid
                </p>
              </>
            )
          ) : (
            <noscript />
          )}

          <BillingCycleChoiceContainer>
            <BillingCycleChoice
              className={billingCycle === "Monthly" ? "selected" : null}
              onClick={
                billingCycle === "Monthly"
                  ? null
                  : () => setBillingCycle("Monthly")
              }
            >
              Monthly
            </BillingCycleChoice>
            <BillingCycleChoice
              className={billingCycle === "Annual" ? "selected" : null}
              onClick={
                billingCycle === "Annual"
                  ? null
                  : () => setBillingCycle("Annual")
              }
            >
              Annual
            </BillingCycleChoice>
          </BillingCycleChoiceContainer>

          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            {!isCompanyUser ? (
              billingCycle === "Monthly" ? (
                <>
                  Subscribe now for{" "}
                  <b>
                    {discount === 0 ? (
                      <span>
                        {planCurrency} {planMonthlyPrice}/month
                      </span>
                    ) : (
                      <>
                        <strike>
                          {planCurrency} {planMonthlyPrice}/month
                        </strike>
                        <span style={{ marginLeft: 4 }}>
                          {planCurrency} {planMonthlyPriceAfterDiscount}/month
                        </span>
                      </>
                    )}{" "}
                    with a 30 day free trial
                  </b>{" "}
                  {planLimit > 1 ? (
                    <>
                      for up to <b>{planLimit} logbooks</b>.{" "}
                    </>
                  ) : (
                    "."
                  )}
                </>
              ) : (
                <>
                  Subscribe now for{" "}
                  <b>
                    {discount === 0 ? (
                      <span>
                        {planCurrency} {planAnnualPrice}/year
                      </span>
                    ) : (
                      <>
                        <strike>
                          {planCurrency} {planAnnualPrice}/month
                        </strike>
                        <span style={{ marginLeft: 4 }}>
                          {planCurrency} {planAnnualPriceAfterDiscount}/month
                        </span>
                      </>
                    )}{" "}
                    with a 30 day free trial
                  </b>{" "}
                  {planLimit > 1 ? (
                    <>
                      for up to <b>{planLimit} logbooks</b>.{" "}
                    </>
                  ) : (
                    "."
                  )}
                </>
              )
            ) : billingCycle === "Monthly" ? (
              planLimit ? (
                <>
                  Start on the {plan} plan for {planCurrency}{" "}
                  {planMonthlyPriceAfterDiscount}
                  /month for up to {planLimit} logbooks.
                </>
              ) : (
                <>
                  Start on the {plan} plan for {planCurrency}{" "}
                  {planMonthlyPriceAfterDiscount}
                  /month.
                </>
              )
            ) : (
              <>
                Your plan is {planCurrency} {planAnnualPriceAfterDiscount}
                /year
              </>
            )}

            {/* Have a look at our pricing on the{` `}
            <a
              href="https://www.inndox.com/pricing-companies"
              rel="noopener noreferrer"
              target="_blank"
              className="link-style-elem"
            >
              pricing page
            </a> */}
          </p>

          <AddPaymentDetailsForm
            billingCycle={billingCycle}
            onFinish={onFinish || (() => navigate(-1))}
          />

          <button
            className="button button-secondary-link button-big button-large margin-top-1"
            onClick={() => navigate(-1)}
          >
            Back
          </button>

          <div className="flex center margin-top-3">
            <PaymentMethodIcon
              src={PoweredByStripeImg}
              alt="Powered by Stripe"
            />
            <PaymentMethodIcon
              src={AmericanExpressImg}
              alt="American Express"
            />
            <PaymentMethodIcon src={VisaImg} alt="Visa" />
            <PaymentMethodIcon src={MastercardImg} alt="Mastercard" />
          </div>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function AddPaymentDetailsForm({ billingCycle, onFinish }) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentDetailsForm billingCycle={billingCycle} onComplete={onFinish} />
    </Elements>
  );
}

function PaymentDetailsForm(props) {
  const [name, setName] = useState("");
  const [promoCode, setPromoCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const isOwner = !useSelector(isCurrentUserACompany);
  const paymentDetails = useSelector(selectPaymentDetails);
  const {
    discount = 0,
    planMonthlyPrice,
    planAnnualPrice,
    plan,
    planStatus,
    planCurrency,
  } = paymentDetails;
  const planMonthlyPriceAfterDiscount = Math.round(
    planMonthlyPrice * (1 - discount / 100),
  );
  const planAnnualPriceAfterDiscount = Math.round(
    planAnnualPrice * (1 - discount / 100),
  );

  // methods
  const submitToken = async (token) => {
    const data = {
      token,
      subscribeToPlan: planStatus === "None",
      interval: props.billingCycle,
    };

    if (promoCode && promoCode.length > 0) {
      data.couponId = promoCode;
    }

    return await PaymentsApi.updateSubscription(data);
  };

  const handleSubmit = async (event) => {
    // stop default form behaviour
    event.preventDefault();
    setIsLoading(true);

    try {
      // create token
      const createTokenResponse = await stripe.createToken(
        elements.getElement(CardElement),
        {
          name,
        },
      );

      if (createTokenResponse.error) {
        throw createTokenResponse.error;
      }

      if (!createTokenResponse.token || !createTokenResponse.token.id) {
        // TODO: Remove this log
        console.error(createTokenResponse.token);
        throw new Error(
          "An unexpected error occurred processing your payment. Please try again later.",
        );
      }

      const submitTokenResponse = await submitToken(
        createTokenResponse.token.id,
      );

      toast.success(
        <Toast
          title="Success!"
          details="Your payment details have been updated."
        />,
      );

      // save in redux
      dispatch(updatePaymentDetails(submitTokenResponse));

      // callback to parent
      props.onComplete();
    } catch (e) {
      handleError(e);
    }

    setIsLoading(false);
  };

  // render
  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginBottom: 0 }}
      autoComplete="off"
    >
      {/* Cardholder's Name */}
      <div className="form-element margin-top-2" style={{ textAlign: "left" }}>
        {/* Label */}
        <Label label="Cardholder's name" required />

        {/* Input */}
        <div className="form-input">
          <input
            type="text"
            name="cardholderName"
            placeholder="Cardholder's name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      {/* Card Details */}
      <div className="form-element margin-top-2">
        {/* Label */}
        <Label label="Card details" required />

        {/* Input */}
        <div
          className="form-input"
          style={{
            border: "1px solid #dde0e3",
            fontSize: "16px",
            color: "#2d3540",
            padding: "15px 8px",
            lineHeight: "20px",
            caretColor: "#bdd23f",
            borderRadius: 4,
          }}
        >
          <CardElement
            className="stripe-input"
            onReady={() => setIsLoading(false)}
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "inherit",
                  fontWeight: "normal",
                  color: "inherit",
                },
              },
            }}
          />
        </div>
      </div>

      {/* Promo */}
      {discount === 0 ? (
        <div className="form-element margin-top-2">
          {/* Label */}
          <Label label="Promotion" />

          {/* Input */}
          <div className="form-input">
            <input
              type="text"
              placeholder="Promo Code"
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <noscript />
      )}

      <p className="margin-top-4 text-center" style={{ fontSize: "13px" }}>
        Your card will be billed {planCurrency}{" "}
        {props.billingCycle === "Monthly"
          ? `${planMonthlyPriceAfterDiscount}/month`
          : `${planAnnualPriceAfterDiscount}/year`}
        . Find out more in our{" "}
        <a
          href={
            plan !== "Master"
              ? "https://www.inndox.com/terms-conditions"
              : "https://www.inndox.com/enterprise-terms-conditions"
          }
          rel="noopener noreferrer"
          target="_blank"
          className="link-style-elem"
        >
          Terms and condition.
        </a>
      </p>

      <button
        className="button button-primary button-large button-big full-width margin-top-3"
        disabled={!stripe}
      >
        {isLoading ? (
          <ClipLoader size={16} color="#fff" loading />
        ) : paymentDetails.planStatus === "None" ? (
          isOwner ? (
            "Start my free trial"
          ) : (
            "Process payment"
          )
        ) : (
          "Update credit card"
        )}
      </button>
    </form>
  );
}
