import styled from 'styled-components';
import moment from 'moment';
import previewIcon from '../assets/images/preview_icon.svg';
import transferringIcon from '../assets/images/transferring_icon.svg';
import transferredIcon from '../assets/images/transferred_icon.svg';
import { Hidden, Visible } from 'react-grid-system';

// styled
const TransferStatus = styled.span`
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 8px;
	padding-right: 8px;
	border-radius: 8px;
	min-height: 20px;
	background-color: #009599;
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	text-transform: none;
	letter-spacing: 0;
	margin-bottom: 8px;

	&.in-progress {
		background-color: #e29d46;
	}

	&.complete {
		background-color: #bfd62f;
	}

	img {
		margin-right: 8px;
	}

	@media only screen and (max-width: 500px) {
		margin-left: 0;
	}
`;

/**
 *
 * @param {inndox.LogbookTransferHandoverStatus} [transferStatus]
 * @param {string} [transferDate]
 */
export function useLogbookTransferStatus(
	transferStatus,
	transferDate,
	isOwnerView
) {
	if (!transferStatus || transferStatus === 'None') {
		if (isOwnerView) {
			return <noscript />;
		}

		// for company logbook
		// pending transfer
		return (
			<TransferStatus>
				<img src={previewIcon} alt="logbook preview icon" />
				Preview Mode - This logbook is pending handover
			</TransferStatus>
		);
	}

	if (transferStatus === 'Transferring') {
		// for owner - pending transfer
		if (isOwnerView) {
			return (
				<TransferStatus className="in-progress" style={{ paddingLeft: '5px' }}>
					<span
						className="flex"
						style={{ color: '#fff', padding: '2px', borderRadius: '20px' }}>
						<img
							src={transferringIcon}
							alt="transferring logbook icon"
							style={{ paddingLeft: '4px' }}
						/>
						Pending transfer {` `}
					</span>
				</TransferStatus>
			);
		}

		// in progress
		return (
			<TransferStatus className="in-progress" style={{ paddingLeft: '5px' }}>
				<span
					className="flex"
					style={{ color: 'white', padding: '2px', borderRadius: '20px' }}>
					<img
						src={transferringIcon}
						alt="transferring logbook icon"
						style={{ paddingLeft: '4px' }}
					/>
					<Hidden xs>Logbook transferring in progress {` `}</Hidden>
					<Visible xs>
						<span style={{ padding: '0 5px', color: 'white' }}>Transferring</span>
					</Visible>
				</span>
			</TransferStatus>
		);
	}

	return (
		<TransferStatus className="complete">
			<img
				src={transferredIcon}
				alt="logbook transferred icon"
				style={{ paddingLeft: '5px' }}
			/>

			<span style={{ color: 'white', paddingLeft: '5px' }}>
				Logbook transferred {moment(transferDate).format('DD/MM/YYYY HH:mm')}
			</span>
		</TransferStatus>
	);
}
