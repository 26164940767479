import { Hidden, Visible } from 'react-grid-system';
import { FormlessSelect } from './form/select';

export default function OwnerHomeToggle({ currentOption, onOptionClick }) {
	const selectItems = [
		{ label: 'My logbooks', value: 1 },
		{ label: 'Logbooks shard with me', value: 2 },
	];
	const currentItem = selectItems.find((item) => item.value === currentOption);

	return (
		<>
			<Hidden xs>
				<div className="radios dashboard">
					<div className="form-element new-radio" onClick={() => onOptionClick(1)}>
						<div className="form-input">
							<input
								name="myProperties"
								readOnly
								type="radio"
								checked={currentOption === 1}
							/>
							<label htmlFor="myProperties">My logbooks</label>
						</div>
					</div>
					<div className="form-element new-radio" onClick={() => onOptionClick(2)}>
						<div className="form-input">
							<input
								name="myProperties"
								readOnly
								type="radio"
								checked={currentOption === 2}
							/>
							<label htmlFor="myProperties">Logbooks shard with me</label>
						</div>
					</div>
				</div>
			</Hidden>

			<Visible xs>
				<div className="form-element">
					<FormlessSelect
						defaultValue={currentItem}
						items={selectItems}
						input={{ onChange: (selection) => onOptionClick(selection.value) }}
					/>
				</div>
			</Visible>
		</>
	);
}
