import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { CompanyApi } from "../../../api/company";
import cameraIcon from "../../../assets/images/camera.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import Address from "../../../components/form/address-picker";
import Dropzone from "../../../components/form/dropzone";
import TextField from "../../../components/form/text-field";
import {
  requiredValidator,
  urlValidator,
} from "../../../components/form/validators";
import { handleError } from "../../../components/helpers";
import Modal from "../../../components/modal";
import Toast from "../../../components/toast";
import {
  selectCompany,
  updateCurrentCompany,
} from "../../../store/features/company.slice";

const InfoRow = styled(Row)`
  min-height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dde0e3;
`;

const InfoItem = styled(Col)`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

export default function CompanyInfoSettings() {
  const companyInfo = useSelector(selectCompany);
  const [wantsToUpdateName, setWantsToUpdateName] = useState(false);
  const [wantsToUpdateWebsite, setWantsToUpdateWebsite] = useState(false);
  const [wantsToUpdateAddress, setWantsToUpdateAddress] = useState(false);
  const dispatch = useDispatch();

  if (!companyInfo) {
    return <noscript />;
  }

  const {
    id,
    name,
    website,
    isManualAddress,
    address,
    streetAddress,
    postcode,
    country,
    state,
    suburb,
    unit,
  } = companyInfo;

  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <CompanyLogo
            logoPath={companyInfo.logoPath}
            companyId={companyInfo.id}
          />
        </Col>

        <Col xs={12} md={9}>
          <Container>
            {/* Company Name */}
            <InfoRow>
              <InfoItem xs={5} md={3}>
                <Label>Company name</Label>
              </InfoItem>
              <InfoItem xs={7} md={7}>
                <p>{companyInfo.name}</p>
              </InfoItem>
              <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
                <button
                  className="button button-secondary-link"
                  style={{ padding: 0 }}
                  onClick={() => setWantsToUpdateName(true)}
                >
                  Update
                </button>
              </InfoItem>
            </InfoRow>

            <Modal
              title="Update company name"
              isOpen={wantsToUpdateName}
              onClose={() => setWantsToUpdateName(false)}
            >
              <Form
                initialValues={{ name }}
                onSubmit={async (values) => {
                  try {
                    const updatedValues = {
                      id,
                      name: values.name,
                    };

                    const updatedCompany =
                      await CompanyApi.updateCompanyDetails(id, updatedValues);
                    dispatch(updateCurrentCompany(updatedCompany));
                    toast.success(
                      <Toast title="Your company name has been updated" />,
                    );

                    setWantsToUpdateName(false);
                  } catch (e) {
                    handleError(e);
                  }
                }}
                render={(props) => (
                  <>
                    <TextField
                      name="name"
                      label="Company name"
                      className="margin-top-2"
                      validate={requiredValidator}
                      required
                    />

                    <button
                      className="button button-primary margin-top-4"
                      onClick={
                        props.submitting ? undefined : props.handleSubmit
                      }
                    >
                      {props.submitting ? (
                        <ClipLoader loading size={16} color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </>
                )}
              />
            </Modal>

            {/* Company Website */}
            <InfoRow>
              <InfoItem xs={5} md={3}>
                <Label>Company website</Label>
              </InfoItem>
              <InfoItem xs={7} md={7}>
                <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {companyInfo.website}
                </p>
              </InfoItem>
              <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
                <button
                  className="button button-secondary-link"
                  style={{ padding: 0 }}
                  onClick={() => setWantsToUpdateWebsite(true)}
                >
                  Update
                </button>
              </InfoItem>
            </InfoRow>

            <Modal
              title="Update company website"
              isOpen={wantsToUpdateWebsite}
              onClose={() => setWantsToUpdateWebsite(false)}
            >
              <Form
                initialValues={{ website }}
                onSubmit={async (values) => {
                  try {
                    const updatedValues = {
                      id,
                      website: values.website,
                    };

                    if (
                      !updatedValues.website.startsWith("http://") &&
                      !updatedValues.website.startsWith("https://")
                    ) {
                      updatedValues.website =
                        "https://" + updatedValues.website;
                    }

                    const updatedCompany =
                      await CompanyApi.updateCompanyDetails(id, updatedValues);
                    dispatch(updateCurrentCompany(updatedCompany));
                    toast.success(
                      <Toast title="Your company website has been updated" />,
                    );

                    setWantsToUpdateWebsite(false);
                  } catch (e) {
                    handleError(e);
                  }
                }}
                render={(props) => (
                  <>
                    <TextField
                      name="website"
                      label="Company website"
                      className="margin-top-2"
                      validate={urlValidator}
                      required
                    />

                    <button
                      className="button button-primary margin-top-4"
                      onClick={
                        props.submitting ? undefined : props.handleSubmit
                      }
                    >
                      {props.submitting ? (
                        <ClipLoader loading size={16} color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </>
                )}
              />
            </Modal>

            {/* Company address */}
            <InfoRow>
              <InfoItem xs={5} md={3}>
                <Label>Address</Label>
              </InfoItem>
              <InfoItem xs={7} md={7}>
                <p>{companyInfo.addressFormatted}</p>
              </InfoItem>
              <InfoItem xs={7} md={2} offset={{ xs: 5, md: 0 }}>
                <button
                  className="button button-secondary-link"
                  style={{ padding: 0 }}
                  onClick={() => setWantsToUpdateAddress(true)}
                >
                  Update
                </button>
              </InfoItem>
            </InfoRow>

            <Modal
              title="Update company address"
              isOpen={wantsToUpdateAddress}
              onClose={() => setWantsToUpdateAddress(false)}
            >
              <Form
                initialValues={{
                  isManualAddress,
                  address,
                  streetAddress,
                  postcode,
                  country,
                  state,
                  suburb,
                  unit,
                }}
                mutators={{
                  setFieldValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                  },
                }}
                onSubmit={async (values) => {
                  try {
                    const updatedValues = {
                      id,
                      address: {
                        isManualAddress: values.isManualAddress,
                        address: values.address,
                        streetAddress: values.streetAddress,
                        postcode: values.postcode,
                        country: values.country,
                        state: values.state,
                        suburb: values.suburb,
                        unit: values.unit,
                      },
                    };

                    const updatedCompany =
                      await CompanyApi.updateCompanyDetails(id, updatedValues);
                    dispatch(updateCurrentCompany(updatedCompany));
                    toast.success(
                      <Toast title="Your company address has been updated" />,
                    );

                    setWantsToUpdateAddress(false);
                  } catch (e) {
                    handleError(e);
                  }
                }}
                render={(props) => (
                  <>
                    <Address
                      setFieldValue={props.form.mutators.setFieldValue}
                    />

                    <button
                      className="button button-primary margin-top-4"
                      onClick={
                        props.submitting ? undefined : props.handleSubmit
                      }
                    >
                      {props.submitting ? (
                        <ClipLoader loading size={16} color="#fff" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </>
                )}
              />
            </Modal>
          </Container>
        </Col>
      </Row>
    </>
  );
}

const CompanyLogoContainer = styled.div`
  position: relative;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  background-color: #ccd5de;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
`;

const Logo = styled.img`
  width: 105px;
  height: 105px;
  position: absolute;
  top: 0;
  left: 0;
`;

const EditButton = styled.button`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const LogoDropzoneContainer = styled.div`
  width: 210px;
  margin-left: auto;
  margin-right: auto;
`;

function CompanyLogo({ logoPath, companyId }) {
  const [wantsToUpdateLogo, setWantsToUpdateLogo] = useState(false);
  const dispatch = useDispatch();

  const onUploadLogo = async (formValues) => {
    if (!formValues.logoFile) {
      toast.error("Please select a file.");
      return;
    }

    const values = { ...formValues };
    values.logoFile = Array.isArray(values.photoFile)
      ? values.logoFile[0]
      : values.logoFile;

    const formData = new FormData();

    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    try {
      const response = await CompanyApi.updateCompanyImages(
        values.id,
        formData,
      );
      dispatch(updateCurrentCompany(response));
    } catch (e) {
      handleError(e);
    }

    setWantsToUpdateLogo(false);
  };

  return (
    <>
      <CompanyLogoContainer>
        {logoPath ? (
          <Logo src={logoPath + "?w=105&h=105&mode=pad"} />
        ) : (
          <noscript />
        )}
      </CompanyLogoContainer>

      <EditButton
        className="button button-link"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onClick={() => setWantsToUpdateLogo(true)}
      >
        Edit
      </EditButton>

      <Modal
        isOpen={wantsToUpdateLogo}
        title={
          <>
            <img
              src={cameraIcon}
              alt="camera"
              width={24}
              style={{ marginRight: 8 }}
            />
            Company logo
          </>
        }
        onClose={() => setWantsToUpdateLogo(false)}
      >
        <Form
          initialValues={{ id: companyId }}
          onSubmit={onUploadLogo}
          render={(props) => (
            <>
              <LogoDropzoneContainer>
                <Dropzone
                  name="logoFile"
                  className="margin-top-4"
                  placeholder="Drag file here or click to browse"
                  activePlaceholder="Drop the file here ..."
                  accept=".png, .jpg, .jpeg"
                />
              </LogoDropzoneContainer>

              <button
                className="button button-primary margin-top-4"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  width: 200,
                }}
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  <>
                    <img
                      src={uploadIcon}
                      alt="upload"
                      style={{ marginRight: 8 }}
                    />
                    Upload logo
                  </>
                )}
              </button>
            </>
          )}
        />
      </Modal>
    </>
  );
}
