export default function Toast({ title, details }) {
  return (
    <div>
      <p style={{ color: "#143A5A" }}>
        <strong>{title}</strong>
      </p>
      <p style={{ color: "#798995" }}>{details}</p>
    </div>
  );
}
