import QueryString from "query-string";
import { useCallback, useEffect } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { styled } from "styled-components";
import { FoldersApi } from "../../api/folders";
import { UserSessionsApi } from "../../api/user-sessions";
import accountSideImage from "../../assets/images/account-side.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import TextField from "../../components/form/text-field";
import {
  emailValidator,
  requiredValidator,
} from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { trackUser } from "../../helpers/user-identification";
import { AccountRoutes, SharedRoutes } from "../../routes";
import { setSession } from "../../store/features/user.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

function LoginForm(props) {
  const { handleSubmit } = props;

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => document.removeEventListener("keypress", handleKeyPress);
  }, [handleKeyPress]);

  return (
    <>
      <TextField
        name="email"
        label="Email address"
        type="email"
        placeholder="john@example.com"
        className="margin-top-4"
        suppressReturn={false}
        validate={emailValidator}
        required
      />

      <TextField
        name="password"
        label="Password"
        className="margin-top-2"
        placeholder="*********"
        type="password"
        validate={requiredValidator}
        required
        moreInfo={{
          title: "Forgot password?",
          link:
            "/" +
            AccountRoutes.basePath.replace("/*", "") +
            "/" +
            AccountRoutes.forgotPassword,
        }}
      />

      <TextField name="code" type="hidden" />
      <TextField name="accessToken" type="hidden" />

      <button
        className="button button-primary button-big full-width margin-top-4 button-large flex center"
        onClick={props.submitting ? undefined : props.handleSubmit}
      >
        {props.submitting ? (
          <ClipLoader size={16} color="#fff" loading={true} />
        ) : (
          "Sign in"
        )}
      </button>

      <div className="margin-top-4 button-large flex center are-you-a-builder">
        Don't have an account?
        <a
          href={
            "/" +
            AccountRoutes.basePath.replace("/*", "") +
            "/" +
            AccountRoutes.register
          }
          className="link-style-elem"
        >
          &nbsp;Register here
        </a>
        .
      </div>
    </>
  );
}

export default function Login() {
  const { search } = useLocation();
  const {
    Email,
    email: prefillEmail,
    PropertyCode: code,
    accessToken,
  } = QueryString.parse(search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const email = Email || prefillEmail;

  return (
    <DocumentTitle title="Login | inndox">
      <FiftyFifty backgroundImage={accountSideImage}>
        <Content>
          <h1>
            Sign{" "}
            <a
              href="/EmailAccount/login"
              style={{
                color: "inherit",
                cursor: "inherit",
                textDecoration: "none",
              }}
            >
              i
            </a>
            n to start
            <br />
            your session
          </h1>

          <Form
            initialValues={{ email, code, accessToken }}
            onSubmit={async (values) => {
              const { email, password, accessToken } = values;
              try {
                const session = await UserSessionsApi.login({
                  email,
                  password,
                });
                trackUser(session);
                dispatch(setSession(session));

                if (accessToken) {
                  // Get access to shared folder via link
                  await FoldersApi.acceptInvitationToSharedFolder(accessToken);
                  const sharedFolderDetails =
                    await FoldersApi.fetchSharedFolderViaAccessCode(
                      accessToken,
                    );
                  navigate(
                    "/" +
                      SharedRoutes.basePath.replace("*", "") +
                      SharedRoutes.folder
                        .replace(":logbookId", sharedFolderDetails.property.id)
                        .replace(":folderId", sharedFolderDetails.id),
                  );
                }
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => <LoginForm {...props} />}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
