import Link from './form/link';
import backIcon from '../assets/images/chevron-left-blue.svg';
import styled from 'styled-components';

const StyledLink = styled(Link)`
	margin-bottom: 8px;
	display: flex;
	align-items: center;
`;

const BackIcon = styled.img`
	height: 16px;
	margin-right: 4px;
`;

const BackCopy = styled.span`
	font-weight: 500;
	color: #68c9d6;
`;

/**
 * @typedef {Object} BackButtonProps
 * @property {string} [backPath]
 */

/**
 *
 * @param {BackButtonProps} props
 */
export default function BackButton({ backPath }) {
	return (
		<StyledLink
			title={
				<>
					<BackIcon src={backIcon} alt="back" />

					<BackCopy>Back</BackCopy>
				</>
			}
			link={backPath || -1}
		/>
	);
}
