import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CompanyApi } from "../../api/company";
import SetupBlock from "../../components/setup-block";
import { AccountRoutes } from "../../routes";
import { selectSubCompanies } from "../../store/features/company.slice";

const SearchInput = styled.input`
  background-color: #fff;
  padding: 10px 16px 10px 40px;
  border-radius: 100px;
  border: 1px solid #bfbfbf;
  font-size: 16px;
  width: 300px;
  max-width: 100%;
  height: 42px;
`;

export default function SubCompanies() {
  const associatedCompanies = useSelector(selectSubCompanies);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [selectedAssociatedCompanies, setSelectedAssociatedCompanies] =
    useState(associatedCompanies ?? null);

  const searchCurrentQuery = (text) => {
    const searchText = text.toLowerCase();
    const companies = associatedCompanies?.filter(
      (a) =>
        a.name?.toLowerCase().includes(searchText) ||
        a.adminName?.toLowerCase().includes(searchText),
    );
    setSelectedAssociatedCompanies(companies);
  };

  const impersonate = async (companyId) => {
    try {
      await CompanyApi.impersonateCompany(companyId);
      navigate(
        "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.impersonate,
      );
      return false;
    } catch (e) {}
  };

  const hasAssociatedCompanies = selectedAssociatedCompanies?.length > 0;

  return (
    <DocumentTitle title="Associated Companies | inndox">
      <Container className="folder margin-top-2">
        <Row className="margin-bottom-3">
          <Col xs={12} className="flex col">
            <h2 className="name">Associated companies</h2>
          </Col>
        </Row>

        {hasAssociatedCompanies ? (
          <>
            <Row className="margin-bottom-1">
              <Col xs={8} sm={6} md={5} lg={4}>
                <SearchInput
                  type="text"
                  name="query"
                  placeholder="Search"
                  onChange={(e) => {
                    searchCurrentQuery(e.target.value);
                    setSearchText(e.target.value);
                  }}
                />
              </Col>
              <Col xs={4} sm={3} md={2}>
                <button
                  className="button button-primary"
                  onClick={() => searchCurrentQuery(searchText)}
                >
                  Search
                </button>
              </Col>
            </Row>

            <div className="document-list" id="tbl-maintenance-items">
              <Row>
                <Col className="col-head" xs={5}>
                  Name
                </Col>
                <Col className="col-head" xs={5}>
                  Admin
                </Col>
                <Col className="col-head" xs={2}>
                  Action
                </Col>
              </Row>

              {selectedAssociatedCompanies.map((associatedCompany) => {
                return (
                  <Row key={`template-item-${associatedCompany.id}`}>
                    <Col xs={5} className="col-body thick">
                      <p className="link-style-elem truncate">
                        {associatedCompany.name}
                      </p>
                    </Col>
                    <Col xs={5} className="col-body thick">
                      <p className="truncate">{associatedCompany.adminName}</p>
                    </Col>
                    <Col xs={2} className="col-body thick">
                      <button
                        className="button button-link"
                        onClick={() => impersonate(associatedCompany.id)}
                      >
                        Impersonate
                      </button>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </>
        ) : (
          <SetupBlock description="Your company has no associated companies yet." />
        )}
      </Container>
    </DocumentTitle>
  );
}
