import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_EXTENSION,
  withCredentials: true,
  headers: {
    apiKey: process.env.REACT_APP_API_KEY,
  },
});

// Ensures all our APIs get the data rather than
// the full Axios response
axiosInstance.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error.response?.status === 401) {
      store.dispatch({
        type: "CLEAR_OUT",
      });
    }

    return Promise.reject(error);
  }
);

export { axiosInstance };
