const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  list: null,
  current: null,
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.list = action.payload;
    },
    updateTemplate: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }

        return item;
      });
    },
    addTemplate: (state, action) => {
      state.list.push(action.payload);
    },
    removeTemplate: (state, action) => {
      state.list = state.list.filter(
        (template) => template.id !== action.payload,
      );
    },
    setCurrentTemplate: (state, action) => {
      state.current = action.payload;
    },
    addDocumentToCurrentTemplate: (state, action) => {
      state.current.documents.push(action.payload);
    },
    incrementTemplateFolderStatistics: (state, action) => {
      const folderId = parseInt(action.payload.id);
      state.current = {
        ...state.current,
        folders: state.current.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              documentStatistics: {
                count: (folder.documentStatistics.count || 0) + 1,
                newCount: (folder.documentStatistics.newCount || 0) + 1,
              },
            };
          }

          return folder;
        }),
      };
    },
    decrementTemplateFolderStatistics: (state, action) => {
      const folderId = parseInt(action.payload.id);
      state.current = {
        ...state.current,
        folders: state.current.folders.map((folder) => {
          if (folder.id === folderId) {
            return {
              ...folder,
              documentStatistics: {
                ...folder.documentStatistics,
                count: folder.documentStatistics.count
                  ? folder.documentStatistics.count - 1
                  : 0,
              },
            };
          }

          return folder;
        }),
      };
    },
    updateTemplateDocument: (state, action) => {
      state.current = {
        ...state.current,
        documents: state.current.documents.map((document) => {
          if (document.id === action.payload.id) {
            return {
              ...document,
              ...action.payload,
            };
          }

          return document;
        }),
      };
    },
    setTemplateDocument: (state, action) => {
      state.current = {
        ...state.current,
        documents: state.current.documents.map((document) => {
          if (document.id === action.payload.id) {
            return action.payload;
          }

          return document;
        }),
      };
    },
    removeTemplateDocument: (state, action) => {
      state.current = {
        ...state.current,
        documents: state.current.documents.filter(
          (document) => document.id !== action.payload,
        ),
      };
    },
    addTemplateMaintenanceItem: (state, action) => {
      state.current.maintenanceItems.push(action.payload);
    },
    updateTemplateMaintenanceItem: (state, action) => {
      state.current = {
        ...state.current,
        maintenanceItems: state.current.maintenanceItems.map(
          (maintenanceItem) => {
            if (maintenanceItem.id === action.payload.id) {
              return {
                ...maintenanceItem,
                ...action.payload,
              };
            }

            return maintenanceItem;
          },
        ),
      };
    },
    removeTemplateMaintenanceItem: (state, action) => {
      state.current = {
        ...state.current,
        maintenanceItems: state.current.maintenanceItems.filter(
          (maintenanceItem) => maintenanceItem.id !== action.payload,
        ),
      };
    },
    addTemplateContact: (state, action) => {
      state.current.contacts.push(action.payload);
    },
    updateTemplateContact: (state, action) => {
      state.current.contacts = state.current.contacts.map((contact) => {
        if (contact.id === action.payload.id) {
          return {
            ...contact,
            ...action.payload,
          };
        }

        return contact;
      });
    },
    removeTemplateContact: (state, action) => {
      state.current.contacts = state.current.contacts.filter(
        (contact) => contact.id !== action.payload,
      );
    },
    addFolderToTemplate: (state, action) => {
      state.current.folders.push(action.payload);
    },
    addSubFolderToTemplate: (state, action) => {
      state.current.folders = state.current.folders.map((folder) => {
        if (folder.id !== action.payload.folderId) {
          return folder;
        }
        [].push();
        return {
          ...folder,
          subfolders: [...folder.subfolders, action.payload],
        };
      });
    },
    updateTemplateFolder: (state, action) => {
      state.current = {
        ...state.current,
        folders: state.current.folders.map((folder) => {
          if (folder.id === action.payload.id) {
            return {
              ...folder,
              ...action.payload,
            };
          }

          return folder;
        }),
      };
    },
    removeTemplateFolder: (state, action) => {
      state.current.folders = state.current.folders.filter(
        (folder) => folder.id !== action.payload,
      );
    },
    removeTemplateSubFolder: (state, action) => {
      state.current.folders = state.current.folders.map((folder) => {
        if (folder.id !== action.payload.folderId) {
          return folder;
        }

        return {
          ...folder,
          subfolders: folder.subfolders.filter(
            (sf) => sf.id !== action.payload.id,
          ),
        };
      });
    },
  },
});

// Actions
export const {
  setTemplates,
  addTemplate,
  updateTemplate,
  setCurrentTemplate,
  removeTemplate,
  addDocumentToCurrentTemplate,
  addFolderToTemplate,
  addSubFolderToTemplate,
  addTemplateContact,
  removeTemplateDocument,
  removeTemplateMaintenanceItem,
  addTemplateMaintenanceItem,
  updateTemplateMaintenanceItem,
  updateTemplateContact,
  removeTemplateContact,
  updateTemplateDocument,
  setTemplateDocument,
  updateTemplateFolder,
  incrementTemplateFolderStatistics,
  removeTemplateFolder,
  removeTemplateSubFolder,
  decrementTemplateFolderStatistics,
} = templatesSlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.Template[]}
 */
export const selectTemplates = (state) => state.templates.list;

/**
 *
 * @param {*} state
 * @returns {inndox.Logbook}
 */
export const selectCurrentTemplate = (state) => state.templates.current;

export default templatesSlice.reducer;

export { initialState as templatesSliceInitialState };
