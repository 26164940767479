import { axiosInstance } from './base';

export const DefectsApi = {
	/**
	 *
	 * @returns {Promise<inndox.LogbookDefect[]>}
	 */
	fetchDefectsForCurrentCompany: () => axiosInstance.get('/defects'),
	/**
	 *
	 * @returns {Promise<inndox.LogbookDefectStatistic[]>}
	 */
	fetchDefectStatistics: () => axiosInstance.get('/defects/statistics'),
	/**
	 *
	 * @param {string} token
	 * @returns {Promise<inndox.LogbookDefectIdentifiers>}
	 */
	fetchDefectIdentifersWithToken: (token) =>
		axiosInstance.get(`/defects/${token}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {boolean} allowPropertyToOwnerFileDefects
	 * @returns {Promise<inndox.Logbook>}
	 */
	updateDefectManagementStatus: (propertyId, allowPropertyToOwnerFileDefects) =>
		axiosInstance.post(`/properties/${propertyId}/defect`, {
			propertyId,
			hasDefectManagement: allowPropertyToOwnerFileDefects,
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<inndox.LogbookDefect[]>}
	 */
	fetchDefectsForProperty: (propertyId) =>
		axiosInstance.get(`/properties/${propertyId}/defects`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDefect>}
	 */
	createNewDefect: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/defects`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @returns {Promise<inndox.LogbookDefect>}
	 */
	fetchDefect: (propertyId, defectId) =>
		axiosInstance.get(`/properties/${propertyId}/defects/${defectId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.LogbookDefect>}
	 */
	updateDefectContent: (propertyId, defectId, data) =>
		axiosInstance.put(`/properties/${propertyId}/defects/${defectId}`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @returns {Promise<void>}
	 */
	removeDefect: (propertyId, defectId) =>
		axiosInstance.delete(`/properties/${propertyId}/defects/${defectId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @param {Record<string,any>} data
	 * @returns {Promise<void>}
	 */
	updateDefectStatus: (propertyId, defectId, data) =>
		axiosInstance.put(
			`/properties/${propertyId}/defects/${defectId}/status`,
			data
		),

	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @returns
	 */
	downloadDefectFiles: (propertyId, defectId) =>
		window.open(
			process.env.REACT_APP_BASE_URL +
				process.env.REACT_APP_API_EXTENSION +
				`/properties/${propertyId}/defects/${defectId}/zip?download=true&apikey=${process.env.REACT_APP_API_KEY}`,
			'_blank'
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @param {number} contactId
	 * @returns {Promise<void>}
	 */
	assignDefect: (propertyId, defectId, contactId) =>
		axiosInstance.put(
			`/properties/${propertyId}/defects/${defectId}/assign/${contactId}`,
			{
				propertyId,
				defectId,
				contactId,
			}
		),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @param {number} contactId
	 * @returns {Promise<inndox.LogbookDefect>}
	 */
	unassignDefect: (propertyId, defectId, contactId) =>
		axiosInstance.delete(`/properties/${propertyId}/defects/${defectId}/assign`, {
			data: {
				propertyId,
				defectId,
				contactId,
			},
		}),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} defectId
	 * @param {string} comment
	 * @returns {Promise<inndox.LogbookDefect>}
	 */
	postCommentForDefect: (propertyId, defectId, comment) =>
		axiosInstance.post(`/properties/${propertyId}/defects/${defectId}/comments`, {
			defectId,
			text: comment,
		}),

	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Logbook>}
	 */
	addDefectRecipient: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/defect`, data),
};
