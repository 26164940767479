import { Form } from 'react-final-form';
import { Col, Row } from 'react-grid-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { LogbooksApi } from '../api/properties';
import AddressPicker from './form/address-picker';
import Select from './form/select';
import TextField from './form/text-field';
import { requiredValidator } from './form/validators';
import { handleError, PropertyTypes } from './helpers';
import { useTemplatesOfType } from '../hooks/templates';
import { CompanyRoutes, OwnerRoutes } from '../routes';
import {
	addLogbook,
	setCurrentLogbook,
} from '../store/features/logbooks.slice';
import { useDispatch } from 'react-redux';
import logbookCoverRealEstate from '../assets/images/logbook-cover-real-estate.png';
import logbookCoverVehicle from '../assets/images/logbook-cover-vehicle.png';
import logbookCoverCaravan from '../assets/images/logbook-cover-caravan.png';
import logbookCoverMarine from '../assets/images/logbook-cover-marine.png';
import logbookCoverOther from '../assets/images/logbook-cover-other.png';
import NumberField from './form/number-field';
import DatePicker from './form/date-picker';

const createAndSaveNewLogbook = async (
	values,
	dispatch,
	navigate,
	isCompany
) => {
	try {
		const createdLogbook = await LogbooksApi.createNewLogbook(values);
		dispatch(addLogbook(createdLogbook));
		dispatch(setCurrentLogbook(createdLogbook));

		navigate(
			isCompany
				? '/' +
						CompanyRoutes.basePath.replace('/*', '') +
						'/' +
						CompanyRoutes.logbook.replace(':logbookId', createdLogbook.id)
				: '/' +
						OwnerRoutes.basePath.replace('/*', '') +
						'/' +
						OwnerRoutes.newLogbookFirstFile
		);
	} catch (e) {
		handleError(e);
	}
};

function RealEstateLogbookForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const templates = useTemplatesOfType('Property');
	const { pathname, key } = useLocation();
	const backPath = pathname.startsWith('/company')
		? '/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
		: '/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home;

	return (
		<Form
			mutators={{
				setFieldValue: ([field, value], state, { changeValue }) => {
					changeValue(state, field, () => value);
				},
			}}
			onSubmit={async (values) => {
				const updatedValues = { ...values };
				updatedValues.logbookType = 'Property';

				await createAndSaveNewLogbook(
					updatedValues,
					dispatch,
					navigate,
					pathname.startsWith('/company')
				);
			}}
			render={(props) => (
				<>
					{/* Address */}
					<AddressPicker setFieldValue={props.form.mutators.setFieldValue} />

					<Row>
						{/* Property Type */}
						<Col xs={12} sm={templates?.length ? 6 : 12} className="margin-top-2">
							<Select
								name="propertyType"
								label="Property Type"
								type="select"
								items={PropertyTypes}
								placeholder="Select"
								required
								validators={requiredValidator}
							/>
						</Col>

						{/* Template */}
						{templates?.length > 0 ? (
							<Col xs={12} md={6} className="margin-top-2">
								<Select
									name="templateId"
									label="Template"
									type="select"
									items={templates.map((template) => ({
										label: template.name,
										value: template.id,
									}))}
									textField="text"
									valueField="value"
									placeholder="Select"
									tooltip="A suggested list of folders and maintenance items to help get you started. You can edit these."
								/>
							</Col>
						) : (
							<noscript />
						)}

						{/* Actions */}
						<Col xs={12} className="margin-top-4">
							<button
								className="button button-primary button-large button-big full-width"
								onClick={props.submitting ? null : props.handleSubmit}>
								{props.submitting ? (
									<ClipLoader loading={props.submitting} size={16} color="#fff" />
								) : (
									'Continue'
								)}
							</button>

							<button
								type="submit"
								className="button button-secondary-link button-big button-large full-width"
								onClick={() => {
									if (key !== 'default') {
										navigate(-1);
									} else {
										navigate(backPath);
									}
								}}>
								Back
							</button>
						</Col>
					</Row>
				</>
			)}
		/>
	);
}

function VehicleLogbookForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const templates = useTemplatesOfType('Vehicle');
	const { pathname, key } = useLocation();
	const backPath = pathname.startsWith('/company')
		? '/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
		: '/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home;

	return (
		<Form
			onSubmit={async (values) => {
				const updatedValues = { ...values };
				if (values.logbookDate) {
					const date = new Date();
					date.setDate(1);
					date.setMonth(0);
					date.setFullYear(parseInt(values.logbookDate));
					updatedValues.logbookDate = date.toISOString();
				}
				updatedValues.logbookType = 'Vehicle';

				await createAndSaveNewLogbook(
					updatedValues,
					dispatch,
					navigate,
					pathname.startsWith('/company')
				);
			}}
			render={(props) => (
				<Row>
					{/* Make */}
					<Col xs={12} sm={6}>
						<TextField
							name="make"
							label="Make"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					{/* Model */}
					<Col xs={12} sm={6}>
						<TextField
							name="model"
							label="Model"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					{/* Year of Manufacture */}
					<Col xs={12} sm={6}>
						<NumberField
							name="logbookDate"
							label="Year of Manufacture"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					{/* VIN */}
					<Col xs={12} sm={6}>
						<TextField
							name="serialNumber"
							label="VIN"
							required
							validate={requiredValidator}
							className="margin-top-2"
							tooltip="Vehicle Identification Number. A unique 17 character code found on your vehicle"
						/>
					</Col>

					<Col xs={12} sm={templates?.length ? 6 : 12}>
						<TextField
							name="state"
							label="State Registered"
							className="margin-top-2"
						/>
					</Col>

					{/* Template */}
					{templates?.length ? (
						<Col xs={12} md={6} className="margin-top-2">
							<Select
								name="templateId"
								label="Template"
								type="select"
								items={templates.map((template) => ({
									label: template.name,
									value: template.id,
								}))}
								textField="text"
								valueField="value"
								placeholder="Select"
								tooltip="A suggested list of folders and maintenance items to help get you started. You can edit these."
							/>
						</Col>
					) : (
						<noscript />
					)}

					<Col xs={12} className="margin-top-4">
						<button
							className="button button-primary button-large button-big full-width"
							onClick={props.submitting ? null : props.handleSubmit}>
							{props.submitting ? (
								<ClipLoader loading={props.submitting} size={16} color="#fff" />
							) : (
								'Continue'
							)}
						</button>

						<button
							type="submit"
							className="button button-secondary-link button-big button-large full-width"
							onClick={() => {
								if (key !== 'default') {
									navigate(-1);
								} else {
									navigate(backPath);
								}
							}}>
							Back
						</button>
					</Col>
				</Row>
			)}
		/>
	);
}

function MarineLogbookForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const templates = useTemplatesOfType('Marine');
	const { pathname, key } = useLocation();
	const backPath = pathname.startsWith('/company')
		? '/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
		: '/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home;

	return (
		<Form
			onSubmit={async (values) => {
				const updatedValues = { ...values };
				if (values.logbookDate) {
					const date = new Date();
					date.setDate(1);
					date.setMonth(1);
					date.setFullYear(parseInt(values.logbookDate, 10));
					updatedValues.logbookDate = date.toISOString();
				}
				updatedValues.logbookType = 'Marine';

				await createAndSaveNewLogbook(
					updatedValues,
					dispatch,
					navigate,
					pathname.startsWith('/company')
				);
			}}
			render={(props) => (
				<Row>
					<Col xs={12} sm={6}>
						<TextField
							name="make"
							label="Make"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<TextField
							name="model"
							label="Model"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<NumberField
							name="logbookDate"
							label="Year of Manufacture"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<TextField
							name="serialNumber"
							label="HIN"
							required
							validate={requiredValidator}
							className="margin-top-2"
							tooltip="Hull Identification Number. A series of letters and numbers printed on the hull"
						/>
					</Col>

					<Col xs={12} sm={templates?.length ? 6 : 12}>
						<TextField
							name="state"
							label="State Registered"
							className="margin-top-2"
						/>
					</Col>

					{/* Template */}
					{templates?.length ? (
						<Col xs={12} md={6} className="margin-top-2">
							<Select
								name="templateId"
								label="Template"
								type="select"
								items={templates.map((template) => ({
									label: template.name,
									value: template.id,
								}))}
								textField="text"
								valueField="value"
								placeholder="Select"
								tooltip="A suggested list of folders and maintenance items to help get you started. You can edit these."
								C
							/>
						</Col>
					) : (
						<noscript />
					)}

					<Col xs={12} className="margin-top-2">
						<button
							className="button button-primary button-large button-big full-width"
							onClick={props.submitting ? null : props.handleSubmit}>
							{props.submitting ? (
								<ClipLoader loading={props.submitting} size={16} color="#fff" />
							) : (
								'Continue'
							)}
						</button>

						<button
							type="submit"
							className="button button-secondary-link button-big  button-large full-width"
							onClick={() => {
								if (key !== 'default') {
									navigate(-1);
								} else {
									navigate(backPath);
								}
							}}>
							Back
						</button>
					</Col>
				</Row>
			)}
		/>
	);
}

function CaravanLogbookForm() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const templates = useTemplatesOfType('Caravan');
	const { pathname, key } = useLocation();
	const backPath = pathname.startsWith('/company')
		? '/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
		: '/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home;

	return (
		<Form
			onSubmit={async (values) => {
				const updatedValues = { ...values };
				if (values.logbookDate) {
					const date = new Date();
					date.setDate(1);
					date.setMonth(0);
					date.setFullYear(parseInt(values.logbookDate));
					updatedValues.logbookDate = date.toISOString();
				}
				updatedValues.logbookType = 'Caravan';

				await createAndSaveNewLogbook(
					updatedValues,
					dispatch,
					navigate,
					pathname.startsWith('/company')
				);
			}}
			render={(props) => (
				<Row>
					<Col xs={12} sm={6}>
						<TextField
							name="make"
							label="Make"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<TextField
							name="model"
							label="Model"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<NumberField
							name="logbookDate"
							label="Year of Manufacture"
							required
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12} sm={6}>
						<TextField
							name="serialNumber"
							label="Chassis"
							required
							validate={requiredValidator}
							className="margin-top-2"
							tooltip="A unique 17  Identification Number located on the A Frame of the Caravan"
						/>
					</Col>

					<Col xs={12} sm={templates?.length ? 6 : 12}>
						<TextField
							name="state"
							label="State Registered"
							className="margin-top-2"
						/>
					</Col>

					{/* Template */}
					{templates?.length ? (
						<Col xs={12} md={6} className="margin-top-2">
							<Select
								name="templateId"
								label="Template"
								type="select"
								items={templates.map((template) => ({
									label: template.name,
									value: template.id,
								}))}
								textField="text"
								valueField="value"
								placeholder="Select"
								tooltip="A suggested list of folders and maintenance items to help get you started. You can edit these."
							/>
						</Col>
					) : (
						<noscript />
					)}

					<Col xs={12} className="margin-top-4">
						<button
							className="button button-primary button-large button-big full-width"
							onClick={props.submitting ? null : props.handleSubmit}>
							{props.submitting ? (
								<ClipLoader loading={props.submitting} size={16} color="#fff" />
							) : (
								'Continue'
							)}
						</button>

						<button
							type="submit"
							className="button button-secondary-link button-big button-large full-width"
							onClick={() => {
								if (key !== 'default') {
									navigate(-1);
								} else {
									navigate(backPath);
								}
							}}>
							Back
						</button>
					</Col>
				</Row>
			)}
		/>
	);
}

function OtherLogbookForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const templates = useTemplatesOfType('Other');
	const { pathname, key } = useLocation();
	const backPath = pathname.startsWith('/company')
		? '/' + CompanyRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
		: '/' + OwnerRoutes.basePath.replace('/*', '') + '/' + OwnerRoutes.home;

	return (
		<Form
			onSubmit={async (values) => {
				const updatedValues = { ...values };
				updatedValues.logbookType = 'Other';

				await createAndSaveNewLogbook(
					updatedValues,
					dispatch,
					navigate,
					pathname.startsWith('/company')
				);
			}}
			render={(props) => (
				<Row>
					{/* Type */}
					<Col xs={12}>
						<TextField
							name="make"
							label="Type"
							required
							placeholder="e.g. Tractor"
							validate={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					{/* Date Acquired */}
					<Col xs={12}>
						<DatePicker
							name="logbookDate"
							label="Date Acquired"
							required
							validators={requiredValidator}
							className="margin-top-2"
						/>
					</Col>

					<Col xs={12}>
						<TextField
							name="state"
							label="State Registered"
							className="margin-top-2"
						/>
					</Col>

					{/* Template */}
					{templates?.length ? (
						<Col xs={12} className="margin-top-2">
							<Select
								name="templateId"
								label="Template"
								type="select"
								items={templates.map((template) => ({
									label: template.name,
									value: template.id,
								}))}
								textField="text"
								valueField="value"
								placeholder="Select"
								tooltip="A suggested list of folders and maintenance items to help get you started. You can edit these."
							/>
						</Col>
					) : (
						<noscript />
					)}

					<Col xs={12} className="margin-top-4">
						<button
							className="button button-primary button-large button-big full-width"
							onClick={props.submitting ? null : props.handleSubmit}>
							{props.submitting ? (
								<ClipLoader loading={props.submitting} size={16} color="#fff" />
							) : (
								'Continue'
							)}
						</button>

						<button
							className="button button-secondary-link button-big button-large full-width"
							onClick={() => {
								if (key !== 'default') {
									navigate(-1);
								} else {
									navigate(backPath);
								}
							}}>
							Back
						</button>
					</Col>
				</Row>
			)}
		/>
	);
}

export const LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING = {
	Property: logbookCoverRealEstate,
	Vehicle: logbookCoverVehicle,
	Caravan: logbookCoverCaravan,
	Marine: logbookCoverMarine,
	Other: logbookCoverOther,
};

export const LOGBOOK_TYPE_TO_CLASS_MAPPING = {
	Property: RealEstateLogbookForm,
	Vehicle: VehicleLogbookForm,
	Caravan: CaravanLogbookForm,
	Marine: MarineLogbookForm,
	Other: OtherLogbookForm,
};
