import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { CompanyApi } from "../api/company";
import { AccountRoutes, CompanyRoutes } from "../routes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubCompanies,
  setSubCompanies,
} from "../store/features/company.slice";
import { ClipLoader } from "react-spinners";

export default function SubCompaniesWidget({ isTab = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFetchingCompany, setIsFetchingCompany] = useState(false);
  const associatedCompanies = useSelector(selectSubCompanies);
  const [selectedAssociatedCompanies, setSelectedAssociatedCompanies] =
    useState(associatedCompanies);
  const [searchText, setSearchText] = useState("");

  // methods
  const searchCurrentQuery = (text) => {
    let searchText = text.toLowerCase();
    const companies = associatedCompanies?.filter(
      (a) =>
        a.name?.toLowerCase().includes(searchText) ||
        a.adminName?.toLowerCase().includes(searchText)
    );
    setSelectedAssociatedCompanies(companies);
  };

  const fetchAssociatedCompanies = async () => {
    setIsFetchingCompany(true);
    try {
      const subCompanies = await CompanyApi.fetchChildCompanies();
      dispatch(setSubCompanies(subCompanies));
      setSelectedAssociatedCompanies(subCompanies);
    } catch (e) {
      console.error("Error fetching company", e);
    }

    setIsFetchingCompany(false);
  };

  const impersonate = async (companyId) => {
    try {
      await CompanyApi.impersonateCompany(companyId);
      navigate(
        "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.impersonate
      );
      return false;
    } catch (e) {}
  };

  const associatedCompanyHeader = () => {
    return (
      <Row>
        <Col className="col-head" xs={5}>
          Name
        </Col>
        <Col className="col-head" xs={5}>
          Admin
        </Col>
        <Col className="col-head" xs={2}>
          Action
        </Col>
      </Row>
    );
  };

  const associatedCompanyBody = (associatedCompany) => {
    return (
      <Row key={`template-item-${associatedCompany.id}`}>
        <Col xs={5} className="col-body thick">
          <p className="link-style-elem truncate">{associatedCompany.name}</p>
        </Col>
        <Col xs={5} className="col-body thick">
          <p className="truncate">{associatedCompany.adminName}</p>
        </Col>
        <Col xs={2} className="col-body thick">
          <button
            className="button button-link"
            style={{ paddingLeft: 0 }}
            onClick={() => impersonate(associatedCompany.id)}
          >
            Impersonate
          </button>
        </Col>
      </Row>
    );
  };

  const getAssociatedCompaniesFragment = () => {
    return (
      <div className="document-list bottom-only">
        {associatedCompanyHeader()}

        {associatedCompanies
          .slice(0, 3)
          .map((associatedCompany, i) =>
            associatedCompanyBody(associatedCompany, impersonate)
          )}

        <Row
          justify="end"
          style={{ borderTop: "1px solid #dde0e3", paddingTop: "16px" }}
        >
          <button
            className="button button-alt"
            onClick={() =>
              navigate(
                "/" +
                  CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  CompanyRoutes.subCompanies
              )
            }
            style={{
              marginLeft: 8,
            }}
          >
            View all
          </button>
        </Row>
      </div>
    );
  };

  const loadAssociatedCompanyPage = () => {
    return (
      <DocumentTitle title={`Associated Companies`}>
        <Container className="folder margin-top-2">
          <Row className="margin-bottom-1">
            <Col xs={8} sm={6} md={5} lg={4}>
              <div
                className="form-element search"
                style={{ display: "inline-block" }}
              >
                <div className="form-input">
                  <input
                    type="text"
                    name="query"
                    placeholder="Search "
                    className="form-input search"
                    onChange={(e) => {
                      searchCurrentQuery(e.target.value);
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={4} sm={3} md={2}>
              <button
                className="button button-primary"
                onClick={() => searchCurrentQuery(searchText)}
              >
                {isFetchingCompany ? (
                  <ClipLoader
                    loading={isFetchingCompany}
                    size={16}
                    color="#FFF"
                  />
                ) : (
                  "Search"
                )}
              </button>
            </Col>
          </Row>

          <div className="document-list" id="tbl-maintenance-items">
            {associatedCompanyHeader()}

            {selectedAssociatedCompanies != null
              ? selectedAssociatedCompanies
                  .sort((a, b) => b.name - a.name)
                  .map((companyProperty) => {
                    return associatedCompanyBody(companyProperty);
                  })
              : null}

            {isFetchingCompany && (
              <Row>
                <Col xs={12} style={{ textAlign: "center" }}>
                  {isFetchingCompany && (
                    <ClipLoader
                      loading={isFetchingCompany}
                      size={16}
                      color="#bdd23f"
                    />
                  )}
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </DocumentTitle>
    );
  };

  useEffect(() => {
    fetchAssociatedCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isTab ? getAssociatedCompaniesFragment() : loadAssociatedCompanyPage();
}
