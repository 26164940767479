import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  details: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    /**
     *
     * @param {*} state
     * @param {inndox.PaymentDetails} action
     */
    setPaymentDetails: (state, action) => {
      state.details = action.payload;
    },
    updatePaymentDetails: (state, action) => {
      state.details = {
        ...state.details,
        ...action.payload,
      };
    },
    appendPastPayments: (state, action) => {
      const { data, ...remainingAttributes } = action.payload;

      state.details.payments = {
        ...remainingAttributes,
        data: state.details.payments.data.concat(data),
      };
    },
  },
});

// Actions
export const { setPaymentDetails, updatePaymentDetails, appendPastPayments } =
  subscriptionSlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.PaymentDetails}
 */
export const selectPaymentDetails = (state) => state.subscription.details;

export default subscriptionSlice.reducer;

export { initialState as subscriptionSliceInitialState };
