import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CompanyUsersApi } from "../../api/company-users";
import { DefectsApi } from "../../api/defects";
import defectSideImage from "../../assets/images/account-side.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import Select from "../../components/form/select";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import Toast from "../../components/toast";
import { updateCurrentLogbook } from "../../store/features/logbooks.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AddClaimsRecipient() {
  const { logbookId: logbookIdPathParam } = useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const { key } = useLocation();
  const [users, setUsers] = useState([]);
  const [isFetchingCompanyUsers, setIsFetchingCompanyUsers] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backPath =
    key !== "default" ? -1 : `/company/logbooks/${logbookId}/defects`;

  useEffect(() => {
    async function fetchCompanyUsers() {
      try {
        const companyUsers = await CompanyUsersApi.fetchCompanyUsers();
        setUsers(companyUsers);
        setIsFetchingCompanyUsers(false);
      } catch (e) {
        toast.error(
          <Toast title="There was an unexpected error. Please refresh the page and try again." />,
        );
      }
    }
    fetchCompanyUsers();
  }, []);

  if (isFetchingCompanyUsers) {
    return (
      <DocumentTitle title="New Issue Log Recipient | inndox">
        <FiftyFifty backgroundImage={defectSideImage}>
          <Content className="margin-top-1">
            <ClipLoader loading size={16} color="#bdd23f" />
          </Content>
        </FiftyFifty>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="New Issue Log Recipient | inndox">
      <FiftyFifty backgroundImage={defectSideImage}>
        <Content className="margin-top-1">
          <BackButton backPath={backPath} />
          <h1>Manage issue recipient</h1>
          <p className="margin-bottom-4 margin-top-1">
            Set a recipient to also receive any incoming issues for the owner.
          </p>

          <Form
            initialValues={{
              propertyId: logbookId,
            }}
            onSubmit={async (values) => {
              try {
                const updatedLogbook = await DefectsApi.addDefectRecipient(
                  logbookId,
                  values,
                );
                dispatch(updateCurrentLogbook(updatedLogbook));

                toast.success(
                  <Toast title="The recipient was added successfully." />,
                );

                navigate(backPath);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <Select
                  name="defectAdminId"
                  label="Set a recipient"
                  items={users.map((contact) => ({
                    label:
                      contact.company ||
                      contact.firstName + " " + contact.lastName,
                    value: contact.id,
                  }))}
                  required
                  validators={requiredValidator}
                />

                {/* Assign button */}
                <button
                  className="button button-primary button-large button-big margin-top-4 full-width"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Include recipient to issue log"
                  )}
                </button>

                <p className="margin-top-1 text-center">
                  The recipient will be included on the email for any issues
                  submitted by the owner.
                </p>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
