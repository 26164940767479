import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { TemplateRoutes } from ".";
import { useTemplateData } from "../hooks/template-data";
import ViewContact from "../pages/common/view-contact";
import AddOrEditTemplateContact from "../pages/company/add-or-edit-template-contact";
import AddOrEditTemplateMaintenanceItem from "../pages/company/add-or-edit-template-maintenance-item";
import TemplateDetails from "../pages/company/template";
import TemplateContacts from "../pages/company/template-contacts";
import TemplateFolder from "../pages/company/template-folder";
import TemplateFolders from "../pages/company/template-folders";
import TemplateMaintenanceItems from "../pages/company/template-maintenance-items";
import ViewTemplateMaintenanceItem from "../pages/company/view-template-maintenance-item";
import { selectCurrentTemplate } from "../store/features/templates.slice";

const PersistentFooter = styled.div`
  background-color: #000;
  position: sticky;
  bottom: 0;
`;

export default function TemplatesSiteMap() {
  const { templateId: templateLogbookIdPathParam } = useParams();
  const templateLogbookId = parseInt(templateLogbookIdPathParam);
  const isFetchingData = useTemplateData(templateLogbookId);
  const templateLogbook = useSelector(selectCurrentTemplate);

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <>
      <Routes>
        {/* Template details */}
        <Route path="" element={<TemplateDetails />} />

        {/* folder */}
        <Route
          path={TemplateRoutes.templateFolders}
          element={<TemplateFolders />}
        />
        <Route
          path={TemplateRoutes.templateFolder}
          element={<TemplateFolder />}
        />
        <Route
          path={TemplateRoutes.templateSubFolder}
          element={<TemplateFolder />}
        />

        {/* Contacts */}
        <Route
          path={TemplateRoutes.templateContacts}
          element={<TemplateContacts />}
        />
        <Route
          path={TemplateRoutes.addTemplateContact}
          element={<AddOrEditTemplateContact />}
        />
        <Route
          path={TemplateRoutes.viewTemplateContact}
          element={<ViewContact />}
        />
        <Route
          path={TemplateRoutes.editTemplateContact}
          element={<AddOrEditTemplateContact />}
        />
        {/* Maintenance */}
        <Route
          path={TemplateRoutes.templateMaintenanceItems}
          element={<TemplateMaintenanceItems />}
        />
        <Route
          path={TemplateRoutes.addTemplateMaintenanceItem}
          element={<AddOrEditTemplateMaintenanceItem />}
        />
        <Route
          path={TemplateRoutes.viewLogbookMaintenanceItem}
          element={<ViewTemplateMaintenanceItem />}
        />
        <Route
          path={TemplateRoutes.editTemplateMaintenanceItem}
          element={<AddOrEditTemplateMaintenanceItem />}
        />
        {/* Redirect from everything else */}
        <Route path="*" element={<Navigate to="" />} />
      </Routes>

      <PersistentFooter id="persistent-template-footer">
        <Container className="margin-top-3">
          <Row>
            <Col xs={12} className="flex center" style={{ height: 56 }}>
              <p style={{ color: "#fff", fontSize: 14, fontWeight: 600 }}>
                You are viewing {templateLogbook.address} template
              </p>
            </Col>
          </Row>
        </Container>
      </PersistentFooter>
    </>
  );
}
