import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Field } from "react-final-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import cameraIcon from "../../assets/images/camera_white.svg";
import {
  MultipleFilePreview,
  SingleFilePreview,
} from "../dropzone-file-preview";
import {
  getFilesAndFoldersFromDroppedDocuments,
  NUMBER_OF_BYTES_IN_ONE_MB,
  UPLOAD_STATUS,
} from "../helpers";
import Error from "./error";
import Label, { Description } from "./label";
import { PreviewAndUploadFile } from "./PageUploader/components/UploadFilePreview";

const DropzoneContainer = styled.div`
  height: 200px;
  padding: 32px;
  border: 2px dashed #e4f0f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-top: 24px;

  &.active {
    background-color: #e4f0f7;
  }
`;

const CameraIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #bfd62f;
  position: absolute;
  top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img {
    max-width: 24px;
    max-height: 24px;
  }
`;

/**
 *
 * @param {FieldInputProps<any, HTMLElement>} param0
 * @returns
 */
function InternalDropzone({
  input,
  placeholder = "Drop your PDF file here or select from your computer",
  activePlaceholder = "Drop the files here ...",
  accept,
  multiple = false,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (multiple) {
        input.onChange({
          target: {
            value: [...input.value, ...acceptedFiles],
          },
        });
      } else {
        input.onChange({
          target: {
            value: acceptedFiles[0],
          },
        });
      }
    },
    [input, multiple],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  return (
    <DropzoneContainer
      className={isDragActive ? "active" : ""}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <CameraIcon>
        <img src={cameraIcon} alt="camera" />
      </CameraIcon>

      <p>{isDragActive ? activePlaceholder : placeholder}</p>
    </DropzoneContainer>
  );
}

export default function Dropzone({
  name,
  label,
  description,
  placeholder,
  activePlaceholder,
  accept,
  multiple = false,
  validate,
  className,
  isUploading,
  allowUnzip = false,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const value = multiple ? input.value : input.value || meta.initial;
        const shouldShowDropzone = multiple ? true : !value;

        return (
          <div className={`form-element ${className}`}>
            <Label label={label} />
            {description && <Description>{description}</Description>}

            {shouldShowDropzone ? (
              <InternalDropzone
                input={input}
                placeholder={placeholder}
                activePlaceholder={activePlaceholder}
                multiple={multiple}
                accept={accept}
              />
            ) : (
              <noscript />
            )}

            {value ? (
              multiple ? (
                <MultipleFilePreview
                  files={value}
                  updateSelectedFiles={input.onChange}
                  isUploading={isUploading}
                  allowUnzip={allowUnzip}
                />
              ) : (
                <SingleFilePreview
                  file={value}
                  removeFile={() => {
                    input.onChange();
                    input.onBlur();
                  }}
                  isUploading={isUploading}
                />
              )
            ) : (
              <noscript />
            )}

            {/* Error(optional) */}
            {meta.touched && meta.error && <Error error={meta.error} invalid />}
          </div>
        );
      }}
    </Field>
  );
}

/**
 * @typedef {Object} FormlessDropzoneProps
 * @property {number} logbookId
 * @property {number} [folderId]
 * @property {number} [companyId]
 * @property {boolean} [shared]
 * @property {string} [className]
 */

/**
 *
 * @param {FormlessDropzoneProps} props
 */
export function FormlessDropzone({
  logbookId,
  companyId,
  folderId,
  shared,
  className,
  placeholder,
  activePlaceholder,
}) {
  const [files, setFiles] = useState({});
  const [folders, setFolders] = useState([]);

  const onDrop = (acceptedFiles) => {
    try {
      const { files: newFiles, folders: newFolders } =
        getFilesAndFoldersFromDroppedDocuments({
          fileList: acceptedFiles,
          folderId: null,
        });

      const updatedFiles = { ...files };
      for (const file of newFiles) {
        if (file.size / NUMBER_OF_BYTES_IN_ONE_MB > 50) {
          toast.error(
            "File size exceed 50MB maximum allowed. Try file compression(zip) before uploading again.",
          );
        } else {
          file.id = uuid();
          file.status = UPLOAD_STATUS.PENDING;
          updatedFiles[file.id] = file;
        }
      }

      setFiles(updatedFiles);
      setFolders([folders, ...newFolders]);
    } catch (e) {
      console.error(e.message);
    }
  };

  // computed
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // render
  return (
    <div
      className={`form-element ${className}`}
      {...getRootProps({
        onClick: (e) => {
          e.stopPropagation();
        },
      })}
    >
      <DropzoneContainer
        className={isDragActive ? "active" : ""}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <CameraIcon>
          <img src={cameraIcon} alt="camera" />
        </CameraIcon>

        <p>{isDragActive ? activePlaceholder || placeholder : placeholder}</p>
      </DropzoneContainer>

      {/* input */}
      {/* <input {...getInputProps()} /> */}

      {/* file previews */}
      {Object.values(files).map((file, index) => (
        <PreviewAndUploadFile
          key={index}
          file={file}
          initialValues={{ propertyId: logbookId, folderId, companyId }}
          shared={shared}
        />
      ))}
    </div>
  );
}
