import { NavLink } from 'react-router-dom';

/**
 *
 * @typedef {Object} LinkProps
 * @property {string} title
 * @property {string} link
 * @property {number} [tabIndex]
 */

/**
 *
 * @param {LinkProps} props
 * @returns
 */
export default function Link(props) {
	const { title, link, tabIndex, className } = props;

	if (typeof link === 'string' && link.indexOf('http') === 0) {
		return (
			<a
				href={link}
				target="_blank"
				className={`link ${className}`}
				rel="noopener noreferrer">
				{title}
			</a>
		);
	}
	return (
		<NavLink to={link} className={`link ${className}`} tabIndex={tabIndex}>
			{title}
		</NavLink>
	);
}
