import { axiosInstance } from './base';

const TEMPLATES_API_BASE_URL = process.env.REACT_APP_BASE_URL + '/api/v2';

export const TemplatesApi = {
	/**
	 *
	 * @returns {Promise<inndox.Template[]>}
	 */
	fetchTemplates: () =>
		axiosInstance.get('/templates', {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
	/**
	 *
	 * @param {number} templateId
	 * @returns {Promise<inndox.Template>}
	 */
	fetchTemplate: (templateId) =>
		axiosInstance.get(`/templates/${templateId}`, {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
	/**
	 *
	 * @param {number} templateId
	 * @returns {Promise<inndox.Template>}
	 */
	cloneTemplate: (templateId) =>
		axiosInstance.post(`/templates/${templateId}/clone`, null, {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
	/**
	 *
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Template>}
	 */
	createNewTemplate: (data) => {
		return axiosInstance.post('/templates', data, {
			baseURL: TEMPLATES_API_BASE_URL,
		});
	},
	/**
	 *
	 * @param {number} templateId
	 * @param {Record<string,any> | FormData} data
	 * @returns {Promise<inndox.Template>}
	 */
	updateTemplate: (templateId, data) =>
		axiosInstance.put(`/templates/${templateId}`, data, {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
	/**
	 *
	 * @param {number} templateId
	 * @returns {Promise<void>}
	 */
	removeTemplate: (templateId) =>
		axiosInstance.delete(`/templates/${templateId}`, {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
	/**
	 *
	 * @param {number} templateId
	 * @param {number} propertyId
	 * @returns {Promise<inndox.Logbook>}
	 */
	applyTemplate: (templateId, propertyId) =>
		axiosInstance.put(`/templates/${templateId}/apply/${propertyId}`, null, {
			baseURL: TEMPLATES_API_BASE_URL,
		}),
};
