import { Component, useState } from "react";
import { Field } from "react-final-form";
import Error from "./error";
import Label from "./label";
import calendarIcon from "../../assets/images/calendar.svg";
import styled from "styled-components";
import moment from "moment";
import onClickOutside from "react-onclickoutside";
import { Calendar } from "react-widgets";

const defaultCurrentDate = new Date();
defaultCurrentDate.setHours(0, 0, 0, 0);

class WidgetsPicker extends Component {
  handleClickOutside = () => {
    this.props.onFinish();
  };

  render() {
    const { onFinish, minDate, ...remainingAttributes } = this.props;
    return <Calendar footer={false} {...remainingAttributes} min={minDate} />;
  }
}

const InnerPicker = onClickOutside(WidgetsPicker);

const InputContainer = styled.div`
  position: relative;
  text-align: left;

  &.error {
    border-color: #ff3e3e;
  }
`;

const CalendarIcon = styled.img`
  position: absolute;
  right: 8px;
  height: 18px;
  top: calc(52px / 2 - 10px);
`;

const Input = styled.input`
  width: 100%;
  background-color: #fff;
  border: 1px solid #dde0e3;
  font-size: 16px;
  color: #2d3540;
  padding: 15px 8px;
  line-height: 20px;
  caret-color: #bdd23f;
  border-radius: 4px;

  &:focus {
    border-color: #68c9d6;
  }

  &:disabled {
    background-color: #f7f9fc;
    cursor: not-allowed;
  }
`;

export default function DatePicker({
  name,
  label,
  validators,
  className,
  required,
  minDate,
}) {
  const [isPickingDate, setIsPickingDate] = useState(false);

  const defaultFormatter = (value) => {
    if (!value || value === null) {
      return "";
    }

    return moment(value).format("MMMM DD, YYYY");
  };

  const defaultParser = (value) => {
    if (!value) {
      return null;
    }

    return moment(value).toISOString(true);
  };

  return (
    <Field
      name={name}
      validate={validators}
      format={defaultFormatter}
      parse={defaultParser}
    >
      {({ input, meta }) => {
        const shouldDisplayError = meta.touched && meta.error;

        return (
          <div className={`form-element ${className}`}>
            <Label label={label} required={required} className={className} />
            <InputContainer className="form-input">
              <CalendarIcon src={calendarIcon} alt="calendar" />

              <Input
                readOnly
                onClick={() => setIsPickingDate((_) => !_)}
                placeholder="Choose a date"
                className={shouldDisplayError && "error"}
                value={
                  input.value && input.value !== "January 01, 0001"
                    ? input.value
                    : ""
                }
                style={{ cursor: "pointer", color: "#d8d8d8d" }}
              />

              {isPickingDate && (
                <InnerPicker
                  defaultCurrentDate={
                    input.value && input.value !== "January 01, 0001"
                      ? new Date(input.value)
                      : minDate ?? defaultCurrentDate
                  }
                  minDate={minDate}
                  onChange={(data) => {
                    // get selected date
                    const selectedDate = moment(data).toDate();

                    // create new date with user's locale and time settings
                    const newDate = new Date();
                    newDate.setDate(selectedDate.getDate());
                    newDate.setMonth(selectedDate.getMonth());
                    newDate.setFullYear(selectedDate.getFullYear());

                    // update displayed date
                    input.onChange(newDate);
                    setIsPickingDate(false);
                  }}
                  onFinish={() => setIsPickingDate(false)}
                />
              )}
            </InputContainer>

            {meta.touched && meta.error && <Error error={meta.error} invalid />}
          </div>
        );
      }}
    </Field>
  );
}
