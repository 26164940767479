import QueryString from "query-string";
import DocumentTitle from "react-document-title";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import { CompanyRoutes } from "../../routes";
import { selectPaymentDetails } from "../../store/features/subscription.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

const StepCount = styled.label`
  font-size: 13px;
  font-weight: 900;
  line-height: 1.69;
  color: #bfd62f;
  margin-top: 24px;
  display: block;
`;

export default function SubscriptionInactive() {
  const { search } = useLocation();
  const { logbookId } = QueryString.parse(search);
  const paymentDetails = useSelector(selectPaymentDetails);
  const { planCurrency, planMonthlyPrice, planLimit } = paymentDetails;
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Subscription Inactive | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <BackButton />
          <StepCount>Step 1 of 2</StepCount>
          <h1>You’re unable to do a handover at this stage</h1>

          <p className="margin-top-2">
            It seems like you would like to do a handover whilst your account
            has been cancelled. That’s ok, you can re-activate your account for{" "}
            <strong>
              {planCurrency} {planMonthlyPrice}/month
            </strong>{" "}
            for {planLimit} logbooks.
          </p>

          <button
            className="button button-primary button-big margin-top-4 full-width"
            onClick={() =>
              navigate(
                "/" +
                  CompanyRoutes.basePath.replace("/*", "") +
                  "/" +
                  CompanyRoutes.logbook.replace(":logbookId", logbookId) +
                  "/" +
                  CompanyRoutes.logbookTransfer,
              )
            }
          >
            Reactivate my subscription
          </button>

          <button
            className="button button-link button-big margin-top-3 full-width"
            style={{ color: "#68c9d6" }}
            onClick={() => navigate(-1)}
          >
            Back
          </button>

          <p className="helper-text margin-top-2">
            At the completion of this handover, your card will be billed{" "}
            {planCurrency} {planMonthlyPrice}. Cancel your payments any time.
            Find out more in our{" "}
            <a
              href="https://inndox.com/terms-conditions"
              className="link-style-elem"
            >
              Terms and Conditions
            </a>
            .
          </p>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
