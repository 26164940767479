import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import {
  Col,
  Container,
  Hidden,
  Row,
  ScreenClassRender,
  Visible,
} from "react-grid-system";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import { LogbooksApi } from "../../api/properties";
import closeIcon from "../../assets/images/close.svg";
import logbookTypeCaravan from "../../assets/images/logbook-type-caravan.svg";
import logbookTypeMarine from "../../assets/images/logbook-type-marine.svg";
import logbookTypeOther from "../../assets/images/logbook-type-other.svg";
import logbookTypeRealEstate from "../../assets/images/logbook-type-real-estate.svg";
import logbookTypeVehicle from "../../assets/images/logbook-type-vehicle.svg";
import questionIcon from "../../assets/images/question.svg";
import searchIcon from "../../assets/images/search.svg";
import CancelHandover from "../../components/cancel-handover";
import { handleError } from "../../components/helpers";
import Modal from "../../components/modal";
import MoreMenu, {
  CLONE_LOGBOOK_MENU_ITEM,
  DELETE_LOGBOOK_MENU_ITEM,
  VIEW_LOGBOOK_MENU_ITEM,
} from "../../components/more-menu";
import SetupBlock from "../../components/setup-block";
import Tabs from "../../components/tabs";
import usePrevious from "../../hooks/previous";
import { CompanyRoutes } from "../../routes";
import { addLogbook, removeLogbook } from "../../store/features/logbooks.slice";

const LOGBOOK_TYPE_TO_ICON_MAPPING = {
  Property: logbookTypeRealEstate,
  Vehicle: logbookTypeVehicle,
  Caravan: logbookTypeCaravan,
  Marine: logbookTypeMarine,
  Other: logbookTypeOther,
};

const SearchInput = styled.input`
  background-color: #fff;
  padding: 10px 16px 10px 40px;
  border-radius: 100px;
  border: 1px solid #bfbfbf;
  font-size: 16px;
  width: 300px;
  max-width: 100%;
  height: 42px;
`;

/**
 *
 * @param {inndox.Logbook} logbook
 * @returns {string}
 */
function getLogbookSubheading(logbook) {
  const { logbookType } = logbook;

  let subtitle;

  switch (logbookType) {
    case "Property": {
      subtitle = logbook.suburb + ", " + logbook.state;
      break;
    }

    case "Vehicle": {
      subtitle = "VIN: " + logbook.serialNumber;
      break;
    }

    case "Caravan": {
      subtitle = "Chassis: " + logbook.serialNumber;
      break;
    }

    case "Marine": {
      subtitle = "HIN: " + logbook.serialNumber;
      break;
    }

    default: {
      return null;
    }
  }

  if (subtitle) {
    return (
      <p
        className="helper-text"
        style={{
          display: "block",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {subtitle}
      </p>
    );
  }

  return null;
}

const LogbookDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const LogbookDisplayIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 576px) {
    margin-right: 0;
  }

  img {
    max-width: 80%;
    max-height: 80%;
  }
`;

const LogbookDisplayHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 48px;

  @media only screen and (max-width: 576px) {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const LogbookDisplayContent = styled.div`
  max-width: calc(100% - 48px - 16px);

  @media only screen and (max-width: 576px) {
    max-width: 100%;
    margin-top: 16px;
  }
`;

const ClearButton = styled.div`
  position: absolute;
  top: 2px;
  right: 6px;
  width: 38px;
  height: 38px;
  background-color: #d0d5de;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms linear;
  overflow: hidden;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 11px;
`;

const SearchFieldContainer = styled.div`
  width: 100%;
  max-width: 375px;
  position: relative;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

function LogbookDisplay({ logbook, children }) {
  return (
    <LogbookDisplayContainer>
      <LogbookDisplayHeader>
        <LogbookDisplayIcon>
          <img
            src={LOGBOOK_TYPE_TO_ICON_MAPPING[logbook.logbookType]}
            alt={`${logbook.logbookType} logbook`}
          />
        </LogbookDisplayIcon>

        <Visible xs>{children}</Visible>
      </LogbookDisplayHeader>

      <LogbookDisplayContent>
        <NavLink
          to={
            "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.logbook.replace(":logbookId", logbook.id)
          }
          className="link-style-elem"
          style={{
            display: "block",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {logbook.description}
        </NavLink>

        {getLogbookSubheading(logbook)}
      </LogbookDisplayContent>
    </LogbookDisplayContainer>
  );
}

/**
 *
 * @typedef {Object} LogbookRowItemProps
 * @property {inndox.Logbook} logbook
 */

/**
 *
 * @param {LogbookRowItemProps} props
 */
function LogbookRowItem({ logbook, onRemoveLogbook }) {
  const navigate = useNavigate();
  const [wantsToCloneCurrentLogbook, setWantsToCloneCurrentLogbook] =
    useState(false);
  const [wantsToCancelHandover, setWantsToCancelHandover] = useState(false);
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const menuOptions = [VIEW_LOGBOOK_MENU_ITEM];
  if (!logbook.transfer?.acceptedOn) {
    menuOptions.push(CLONE_LOGBOOK_MENU_ITEM);
  }
  if (!logbook.transfer?.acceptedOn && logbook.canDelete) {
    menuOptions.push(DELETE_LOGBOOK_MENU_ITEM);
  }

  const deleteLogbook = async () => {
    try {
      setIsDeleting(true);
      await LogbooksApi.removeLogbook(logbook.id);
      dispatch(removeLogbook(logbook.id));
      onRemoveLogbook(logbook.id);
      setIsDeleting(false);
    } catch (e) {
      handleError(e);
    }
  };

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case VIEW_LOGBOOK_MENU_ITEM.value: {
        // view logbook
        navigate(
          "/" +
            CompanyRoutes.basePath.replace("/*", "") +
            "/" +
            CompanyRoutes.logbook.replace(":logbookId", logbook.id),
        );
        break;
      }

      case CLONE_LOGBOOK_MENU_ITEM.value: {
        // clone logbook
        setWantsToCloneCurrentLogbook(true);
        break;
      }

      case DELETE_LOGBOOK_MENU_ITEM.value: {
        // delete logbook
        deleteLogbook();
        break;
      }

      default:
        break;
    }
  };

  return (
    <Row>
      <Col xs={12} sm={7} md={5} className="col-body">
        <LogbookDisplay logbook={logbook}>
          <MoreMenu options={menuOptions} onSelect={actionSelectedMenuOption} />
        </LogbookDisplay>
      </Col>

      <Hidden xs sm>
        <Col md={2} className="col-body">
          <span className="helper-text">
            {logbook.transfer?.acceptedOn
              ? logbook.transfer.to.companyName ||
                logbook.transfer.to.firstName +
                  " " +
                  logbook.transfer.to.lastName
              : ""}
          </span>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className="col-body">
          <span className="helper-text">
            {moment(logbook.dateCreated).format("DD/MM/YYYY")}
          </span>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className="col-body">
          {logbook.transfer?.acceptedOn ? (
            <p className="helper-text">
              {moment(logbook.transfer.acceptedOn).format("DD/MM/YYYY")}
            </p>
          ) : logbook.transfer?.status === "Transferring" ? (
            <button
              className="button button-danger-inverse"
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => setWantsToCancelHandover(true)}
            >
              Cancel transfer
            </button>
          ) : (
            <noscript />
          )}
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={1} className="col-body">
          {isDeleting ? (
            <ClipLoader
              style={{ flexShrink: 0 }}
              loading
              size={16}
              color="#FF3E3E"
            />
          ) : (
            <MoreMenu
              options={menuOptions}
              onSelect={actionSelectedMenuOption}
            />
          )}
        </Col>
      </Hidden>

      {/* Clone Logbook Modal */}
      <Modal
        isOpen={wantsToCloneCurrentLogbook}
        title="Clone Logbook"
        onClose={() => setWantsToCloneCurrentLogbook(false)}
      >
        <p>Copy this logbook including documents and folders?</p>

        <div className="flex end margin-top-3">
          <button
            className="button margin-right-2"
            onClick={() => setWantsToCloneCurrentLogbook(false)}
          >
            Cancel
          </button>

          <Form
            onSubmit={async () => {
              try {
                // clone logbook
                const clonedLogbook = await LogbooksApi.cloneLogbook(
                  logbook.id,
                );
                dispatch(addLogbook(clonedLogbook));

                setWantsToCloneCurrentLogbook(false);

                // navigate to new logbook
                navigate(`${clonedLogbook.id}`);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <button
                className="button button-archive flex"
                onClick={props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#FF3E3E" />
                ) : (
                  "Clone Logbook"
                )}
              </button>
            )}
          />
        </div>
      </Modal>

      <CancelHandover
        propertyId={logbook.id}
        isOpen={wantsToCancelHandover}
        onClose={() => setWantsToCancelHandover(false)}
        receiverEmail={logbook.transfer?.to.email}
        refreshOnComplete
      />
    </Row>
  );
}

/**
 * @typedef {Object} LogbookListSearchParams
 * @property {boolean} pendingHandoverOnly
 * @property {boolean} handoversOnly
 * @property {String} query
 */

/**
 * @typedef {Object} LogbookListProps
 * @property {String} emptyStateDescription
 * @property {LogbookListSearchParams} searchParams
 */

/**
 *
 * @param {LogbookListProps} props
 * @returns
 */
function LogbookList({ emptyStateDescription, searchParams }) {
  const [isFetchingLogbooks, setIsFetchingLogbooks] = useState(false);
  const [logbooks, setLogbooks] = useState([]);
  const prevQuery = usePrevious(searchParams.query);

  const removeLogbook = (logbookId) => {
    setLogbooks((currentLogbooks) => {
      return currentLogbooks.filter((_) => _.id !== logbookId);
    });
  };

  const searchForLogbooks = useCallback(async () => {
    try {
      setIsFetchingLogbooks(true);

      const searchedLogbooks = await LogbooksApi.searchForLogbooks({
        ...searchParams,
        query: searchParams.query?.length ? searchParams.query : undefined,
      });
      setLogbooks(searchedLogbooks);

      setIsFetchingLogbooks(false);
    } catch (e) {}
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.query !== prevQuery || !searchParams.query?.length) {
      searchForLogbooks();
    }
  }, [searchParams.query, prevQuery, searchForLogbooks]);

  if (isFetchingLogbooks) {
    return (
      <div className="document-list">
        <ClipLoader loading size={16} color="#bdd23f" />
      </div>
    );
  }

  if (!logbooks.length) {
    return <SetupBlock description={emptyStateDescription} />;
  }

  return (
    <div className="document-list">
      {/* Header */}
      <Row>
        <Col xs={8} sm={7} md={5} className="col-head">
          Logbook
        </Col>

        <Hidden xs sm>
          <Col md={2} className="col-head">
            Owner
          </Col>
        </Hidden>

        <Hidden xs>
          <Col sm={2} className="col-head">
            Created
          </Col>
        </Hidden>

        <Hidden xs>
          <Col sm={2} className="col-head">
            Handover
          </Col>
        </Hidden>

        <Col xs={4} sm={1} className="col-head" />
      </Row>

      {/* Logbooks */}
      {logbooks.map((logbook) => (
        <LogbookRowItem
          key={logbook.id}
          logbook={logbook}
          onRemoveLogbook={removeLogbook}
        />
      ))}
    </div>
  );
}

export default function CompanyLogbooks() {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchText, setSearchText] = useState("");

  const submitQuery = () => {
    setQuery(searchText);
  };

  return (
    <DocumentTitle title="Logbooks | inndox">
      <Container className="folder">
        {/* Header */}
        <Row>
          <Col xs={12} sm={7} className="flex">
            <h2 className="name">
              Logbooks
              <img
                src={questionIcon}
                width={24}
                style={{ marginLeft: 8, marginBottom: -4 }}
                id="logbooks-help"
                alt="help"
              />
            </h2>

            <ScreenClassRender
              render={(screenClass) => (
                <Tooltip
                  anchorId="logbooks-help"
                  style={{
                    borderRadius: 4,
                    textTransform: "none",
                    padding: 8,
                    fontFamily: "ProjectFont",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "normal",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 4px 2px #00000020",
                    zIndex: 1,
                    maxWidth:
                      screenClass === "xs" || screenClass === "sm" ? 250 : 450,
                    color: "initial",
                  }}
                  place="bottom"
                  clickable={true}
                >
                  If you wish to cease sponsoring an already transferred
                  logbook, please email the logbook title and description to{" "}
                  <a
                    href="mailto:info@inndox.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#bdd23f" }}
                  >
                    info@inndox.com
                  </a>{" "}
                  with your request and we will remove it.
                </Tooltip>
              )}
            />
          </Col>

          <Col xs={12} sm={5} className="flex end">
            <Hidden xs>
              <button
                id="btn-add-property"
                className="button button-secondary"
                onClick={() =>
                  navigate(
                    "/" +
                      CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      CompanyRoutes.newLogbookType,
                  )
                }
              >
                Setup a new Logbook
              </button>
            </Hidden>

            <Visible xs>
              <button
                id="btn-add-property"
                className="button button-secondary margin-top-2"
                onClick={() =>
                  navigate(
                    "/" +
                      CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      CompanyRoutes.newLogbookType,
                  )
                }
              >
                Setup a new Logbook
              </button>
            </Visible>
          </Col>
        </Row>

        {/* Search Box */}
        <Row className="margin-top-3">
          <Col xs={8} sm={6} md={5} lg={4}>
            <SearchFieldContainer>
              <SearchInput
                type="text"
                placeholder="Search logbooks"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitQuery();
                  }
                }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />

              <SearchIcon>
                <img src={searchIcon} alt="search" />
              </SearchIcon>

              {searchText?.length > 0 ? (
                <ClearButton
                  onClick={() => {
                    setSearchText("");
                    setQuery("");
                  }}
                >
                  <img src={closeIcon} alt="clear" />
                </ClearButton>
              ) : (
                <noscript />
              )}
            </SearchFieldContainer>
          </Col>
          <Col xs={4} sm={3} md={2}>
            <button className="button button-primary" onClick={submitQuery}>
              Search
            </button>
          </Col>
        </Row>

        {/* Logbook List */}
        <Row>
          <Col xs={12}>
            <Tabs
              tabs={[
                {
                  label: "Handovers",
                  content: (
                    <LogbookList
                      emptyStateDescription="No logbooks have been accepted by their owners yet."
                      searchParams={{
                        pendingHandoverOnly: false,
                        handoversOnly: true,
                        query,
                      }}
                    />
                  ),
                },
                {
                  label: "Pending",
                  content: (
                    <LogbookList
                      emptyStateDescription="No logbooks pending transfer to their owner yet."
                      searchParams={{
                        pendingHandoverOnly: true,
                        handoversOnly: false,
                        query,
                      }}
                    />
                  ),
                },
                {
                  label: "All",
                  content: (
                    <LogbookList
                      emptyStateDescription="No logbooks have been created yet."
                      searchParams={{
                        pendingHandoverOnly: false,
                        handoversOnly: false,
                        query,
                      }}
                    />
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
