import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { Navigate, Route, Routes } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { AccountRoutes } from ".";
import AddPaymentDetails from "../components/add-payment-details";
import { DoNotRequireAuth, RequireAuth } from "../components/authentication";
import useAccountData from "../hooks/account-data";
import AcceptInvitation from "../pages/account/accept-invitation";
import ConfirmEmail from "../pages/account/confirm-email";
import ForgotPassword from "../pages/account/forgot-password";
import ImpersonateAUser from "../pages/account/impersonate";
import Login from "../pages/account/login";
import Logout from "../pages/account/logout";
import Register from "../pages/account/register";
import ResetPassword from "../pages/account/reset-password";
import Subscribe from "../pages/account/subscribe";
import SubscriptionInactive from "../pages/account/subscription-inactive";
import LoginViaToken from "../pages/account/via-token";
import AccountSettings from "./account-settings-site-map";

export default function AccountSiteMap() {
  const isFetchingData = useAccountData();

  if (isFetchingData) {
    return (
      <DocumentTitle title="Loading...">
        <Container>
          <Row>
            <Col xs={12} className="flex center">
              <ClipLoader loading size={16} color="#bdd23f" />
            </Col>
          </Row>
        </Container>
      </DocumentTitle>
    );
  }

  return (
    <Routes>
      <Route
        path={AccountRoutes.acceptInvitation}
        element={
          <DoNotRequireAuth>
            <AcceptInvitation />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.forgotPassword}
        element={
          <DoNotRequireAuth>
            <ForgotPassword />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.login}
        element={
          <DoNotRequireAuth>
            <Login />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.logout}
        element={
          <RequireAuth>
            <Logout />
          </RequireAuth>
        }
      />
      <Route
        path={AccountRoutes.register}
        element={
          <DoNotRequireAuth>
            <Register />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.confirmEmail}
        element={
          <DoNotRequireAuth>
            <ConfirmEmail />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.resetPassword}
        element={
          <DoNotRequireAuth>
            <ResetPassword />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.token}
        element={
          <DoNotRequireAuth>
            <LoginViaToken />
          </DoNotRequireAuth>
        }
      />
      <Route
        path={AccountRoutes.subscribe}
        element={
          <RequireAuth>
            <Subscribe />
          </RequireAuth>
        }
      />
      <Route
        path={AccountRoutes.subscriptionInactive}
        element={
          <RequireAuth>
            <SubscriptionInactive />
          </RequireAuth>
        }
      />
      <Route
        path={AccountRoutes.addPaymentDetails}
        element={
          <RequireAuth>
            <AddPaymentDetails />
          </RequireAuth>
        }
      />
      <Route
        path={AccountRoutes.settingsBasePath}
        element={
          <RequireAuth>
            <AccountSettings />
          </RequireAuth>
        }
      />
      <Route path={AccountRoutes.impersonate} element={<ImpersonateAUser />} />

      {/* Redirect from everything else */}
      <Route path="*" element={<Navigate to="/route-decider" />} />
    </Routes>
  );
}
