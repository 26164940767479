import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { TemplatesApi } from "../../api/templates";
import logbookSideImage from "../../assets/images/bg2.jpg";
import BackButton from "../../components/back-button";
import { FiftyFifty } from "../../components/fifty-fifty";
import Select from "../../components/form/select";
import TextArea from "../../components/form/text-area";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { CompanyRoutes, TemplateRoutes } from "../../routes";
import { selectCompany } from "../../store/features/company.slice";
import {
  addTemplate,
  selectTemplates,
  updateTemplate,
} from "../../store/features/templates.slice";

const Content = styled.div`
  width: 100%;
  max-width: 408px;
  max-height: calc(100% - 64px);
`;

export default function AddOrEditTemplate() {
  const { templateId: templateIdPathParam } = useParams();
  const templateId = parseInt(templateIdPathParam);
  const templates = useSelector(selectTemplates);
  const template = templates.find((template) => template.id === templateId);
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DocumentTitle
      title={`${templateId ? "Edit Template" : "Add Template"} | inndox`}
    >
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <BackButton />
          <h1>{templateId ? "Edit Template" : "New Template"}</h1>
          <p className="margin-top-1">
            {templateId
              ? "Modify an existing logbook template."
              : "Create a new logbook template."}
          </p>

          <Form
            initialValues={{
              ...template,
              companyId: company.id,
            }}
            onSubmit={async (values) => {
              if (templateId) {
                try {
                  // update template
                  const updatedTemplate = await TemplatesApi.updateTemplate(
                    templateId,
                    values,
                  );
                  dispatch(updateTemplate(updatedTemplate));

                  // navigate to template
                  navigate(
                    "/" +
                      CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      TemplateRoutes.template.replace(
                        ":templateId",
                        updatedTemplate.propertyId,
                      ),
                  );
                } catch (e) {
                  handleError(e);
                }
              } else {
                try {
                  // create new template
                  const newTemplate = await TemplatesApi.createNewTemplate(
                    values,
                  );
                  dispatch(addTemplate(newTemplate));

                  // navigate to template
                  navigate(
                    "/" +
                      CompanyRoutes.basePath.replace("/*", "") +
                      "/" +
                      TemplateRoutes.template.replace(
                        ":templateId",
                        newTemplate.propertyId,
                      ),
                  );
                } catch (e) {
                  handleError(e);
                }
              }
            }}
            render={(props) => (
              <>
                <Select
                  name="logbookType"
                  label="Template Type"
                  className="margin-top-4"
                  required
                  validators={requiredValidator}
                  items={[
                    { label: "Real estate", value: "Property" },
                    { label: "Vehicle", value: "Vehicle" },
                    { label: "Caravan", value: "Caravan" },
                    { label: "Marine", value: "Marine" },
                    { label: "Other", value: "Other" },
                  ]}
                />

                <TextField
                  name="name"
                  label="Name"
                  required
                  validate={requiredValidator}
                  className="margin-top-2"
                />

                <TextArea
                  name="description"
                  label="Description"
                  className="margin-top-2"
                />

                <button
                  className="button button-primary button-large button-big margin-top-4 full-width"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : templateId ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
