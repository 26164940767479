import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useEffect, useState } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { UserSessionsApi } from "../api/user-sessions";
import logo from "../assets/images/logo-only.svg";
import menuCloseIcon from "../assets/images/menu-close.svg";
import menuIcon from "../assets/images/menu.svg";
import { AccountRoutes, CompanyRoutes, OwnerRoutes } from "../routes";
import { selectCompany } from "../store/features/company.slice";
import { selectCurrentLogbook } from "../store/features/logbooks.slice";
import {
  isCurrentUserACompany,
  setSession,
} from "../store/features/user.slice";
import { useAuth } from "./authentication";
import { handleError } from "./helpers";

const RootContainer = styled.header`
  background-color: #000;
  position: sticky;
  top: 0;
  z-index: 11;
`;

const ImpersonationBanner = styled.div`
  height: 32px;
  background-color: #56cfa2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
  }
`;

const LogoContainer = styled.div`
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 40px;

  &.custom {
    height: 58px;
    width: 58px;
    object-fit: contain;
  }

  @media only screen and (max-width: 576px) {
    &.custom {
      width: 60px;
      height: 60px;
    }
  }
`;

const MenuLinks = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  li {
    margin-left: 24px;
  }

  .menu-link {
    display: block;
    height: 80px;
    line-height: 80px;
    color: #fff;
    font-weight: 400;
    position: relative;

    &.active {
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 6px;
        background-color: #bdd23f;
      }
    }
  }
`;

const AccountMenuLink = styled(MenuButton)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #4d4d4d;
  border: 1px solid #666;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

const AccountMenuItem = styled(MenuItem)`
  margin-left: unset !important;
`;

const MobileMenuContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 11;
  top: 0;
  left: 100vw;
  transition: all 150ms linear;

  &.open {
    left: 0;
  }
`;

const MobileMenuLinks = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 16px;

  li {
    width: 100%;
    margin-bottom: 8px;

    .menu-link {
      display: block;
      height: 40px;
      font-size: 20px;
      line-height: 36px;
      border-radius: 4px;

      &.active {
        border-bottom: 4px solid #bdd23f;
        font-weight: bold;
      }
    }
  }
`;

const MobileMenuContainerHeader = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const HamburgerMenuContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const COMPANY_LINKS = [
  {
    title: "Company",
    link:
      "/" + CompanyRoutes.basePath.replace("/*", "") + "/" + CompanyRoutes.home,
  },
  {
    title: "Issues",
    link:
      "/" +
      CompanyRoutes.basePath.replace("/*", "") +
      "/" +
      CompanyRoutes.defects,
  },
  {
    title: "Contacts",
    link:
      "/" +
      CompanyRoutes.basePath.replace("/*", "") +
      "/" +
      CompanyRoutes.contacts,
  },
];

const OWNER_LINKS = [
  {
    title: "Dashboard",
    link: "/" + OwnerRoutes.basePath.replace("/*", "") + "/" + OwnerRoutes.home,
  },
];

const COMMON_LINKS = [
  {
    title: "Settings",
    link:
      "/" +
      AccountRoutes.basePath.replace("/*", "") +
      "/" +
      AccountRoutes.settingsBasePath.replace("/*", ""),
  },
  {
    title: "Billing",
    link:
      "/" +
      AccountRoutes.basePath.replace("/*", "") +
      "/" +
      AccountRoutes.settingsBasePath.replace("/*", "") +
      "/" +
      AccountRoutes.billing,
  },
  {
    title: "Logout",
    link:
      "/" +
      AccountRoutes.basePath.replace("/*", "") +
      "/" +
      AccountRoutes.logout,
  },
];

const UNAUTH_LINKS = [
  {
    title: "Login",
    link:
      "/" +
      AccountRoutes.basePath.replace("/*", "") +
      "/" +
      AccountRoutes.login,
  },
];

export default function Header() {
  const { session } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const company = useSelector(selectCompany);
  const [isViewingMobileMenu, setIsViewingMobileMenu] = useState(false);
  const navigate = useNavigate();
  const logbook = useSelector(selectCurrentLogbook);
  const isUserOnSponsporedLogbookPage = !!logbook?.companies?.[0];

  const links = !!session
    ? isCompanyUser
      ? COMPANY_LINKS
      : OWNER_LINKS
    : UNAUTH_LINKS;

  const onSelectAccountItem = (value) => {
    switch (value) {
      case "settings": {
        navigate(
          "/" +
            AccountRoutes.basePath.replace("/*", "") +
            "/" +
            AccountRoutes.settingsBasePath.replace("/*", ""),
        );
        break;
      }

      case "billing": {
        navigate(
          "/" +
            AccountRoutes.basePath.replace("/*", "") +
            "/" +
            AccountRoutes.settingsBasePath.replace("/*", "") +
            "/" +
            AccountRoutes.billing,
        );
        break;
      }

      case "logout": {
        navigate(
          "/" +
            AccountRoutes.basePath.replace("/*", "") +
            "/" +
            AccountRoutes.logout,
        );
        break;
      }

      default:
        break;
    }
  };

  const unimpersonateCurrentUser = async () => {
    UserSessionsApi.undoImpersonate()
      .then((newSession) => {
        if (
          session.impersonator?.roles.some((role) =>
            role.role.includes("Administrator"),
          )
        ) {
          dispatch(setSession(newSession));
          window.location.href = process.env.REACT_APP_BASE_URL + "/admin";
        } else {
          dispatch(setSession(newSession));
          window.location.reload();
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    setIsViewingMobileMenu(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isViewingMobileMenu) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.height = "unset";
    }
  }, [isViewingMobileMenu]);

  return (
    <RootContainer>
      {/* Impersonation Banner */}
      {session?.impersonator ? (
        <ImpersonationBanner>
          <p>You are impersonating {company?.name}.</p>
          <button
            className="button button-link"
            style={{
              padding: 0,
              paddingLeft: 8,
              backgroundColor: "transparent",
              color: "#fff",
              border: "none",
              textDecoration: "underline",
            }}
            onClick={unimpersonateCurrentUser}
          >
            Exit View
          </button>
        </ImpersonationBanner>
      ) : (
        <noscript />
      )}

      <Container>
        <Row>
          {/* Left */}
          <Col
            xs={8}
            md={4}
            style={{ height: 80, cursor: "pointer" }}
            className="flex start"
            id="header-branding"
          >
            <LogoContainer>
              <Logo
                className={
                  isCompanyUser && company?.logoPath
                    ? "custom"
                    : logbook && logbook.companies
                      ? "custom"
                      : ""
                }
                src={
                  isCompanyUser
                    ? company?.logoPath
                      ? company.logoPath + "?h=116"
                      : logo
                    : logbook
                      ? logbook.companies
                        ? logbook.companies[0]?.logoPath + "?h=116"
                        : logo
                      : logo
                }
                onClick={() => navigate("/route-decider")}
              />
            </LogoContainer>

            {isCompanyUser || isUserOnSponsporedLogbookPage ? (
              <h3
                style={{ color: "#fff", marginLeft: 16 }}
                onClick={() => navigate("/route-decider")}
              >
                {company?.name || logbook?.companies?.[0]?.name}
              </h3>
            ) : null}
          </Col>

          {/* Right */}
          <Col xs={4} md={8}>
            <Hidden xs sm>
              <MenuLinks>
                {links.map((link, index) => (
                  <li key={index}>
                    <NavLink to={link.link} className="menu-link">
                      {link.title}
                    </NavLink>
                  </li>
                ))}

                <li>
                  {session ? (
                    <Menu
                      menuButton={() => (
                        <AccountMenuLink onClick={(e) => e.stopPropagation()}>
                          {session.firstName.charAt(0) +
                            session.lastName.charAt(0)}
                        </AccountMenuLink>
                      )}
                      onItemClick={(e) => onSelectAccountItem(e.value)}
                      viewScroll="close"
                      align="end"
                      gap={10}
                    >
                      <AccountMenuItem value="settings">
                        Settings
                      </AccountMenuItem>
                      <AccountMenuItem value="billing">Billing</AccountMenuItem>
                      <AccountMenuItem
                        value="logout"
                        style={{
                          color: "#FF3E3E",
                        }}
                      >
                        Logout
                      </AccountMenuItem>
                    </Menu>
                  ) : (
                    <noscript />
                  )}
                </li>
              </MenuLinks>
            </Hidden>

            {/* Hamburger Menu */}
            <Visible xs sm>
              {/* Button to open menu */}
              <MenuLinks>
                <HamburgerMenuContainer
                  onClick={() => setIsViewingMobileMenu((_) => !_)}
                >
                  <img src={menuIcon} alt="menu" />
                </HamburgerMenuContainer>
              </MenuLinks>
            </Visible>
          </Col>
        </Row>
      </Container>

      {/* Mobile Menu */}
      <Visible xs sm>
        <MobileMenuContainer
          className={isViewingMobileMenu ? "open" : undefined}
        >
          <Container>
            <Row>
              <Col xs={12}>
                <MobileMenuContainerHeader>
                  <HamburgerMenuContainer
                    onClick={() => setIsViewingMobileMenu((_) => !_)}
                  >
                    <img src={menuCloseIcon} alt="close menu" />
                  </HamburgerMenuContainer>
                </MobileMenuContainerHeader>
              </Col>

              <Col xs={12}>
                <MobileMenuLinks>
                  {[...links, ...COMMON_LINKS].map((link, index) => (
                    <li key={index}>
                      <NavLink to={link.link} className="menu-link">
                        {link.title}
                      </NavLink>
                    </li>
                  ))}
                </MobileMenuLinks>
              </Col>
            </Row>
          </Container>
        </MobileMenuContainer>
      </Visible>
    </RootContainer>
  );
}
