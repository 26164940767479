import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  selectCurrentTemplate,
  selectTemplates,
} from "../../store/features/templates.slice";
import LogbookDetails from "../common/logbook-details";

export default function TemplateDetails() {
  const { templateId: templateLogbookIdPathParam } = useParams();
  const templateLogbookId = parseInt(templateLogbookIdPathParam);
  const templateLogbook = useSelector(selectCurrentTemplate);
  const templates = useSelector(selectTemplates);
  const template = templates.find(
    (template) => template.propertyId === templateLogbookId,
  );

  return (
    <LogbookDetails
      logbook={templateLogbook}
      templateId={template.id}
      isCurrentUserACompany
    />
  );
}
