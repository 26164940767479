import styled from 'styled-components';

const SetupBlockContainer = styled.div`
	border-radius: 10px;
	background-color: #fff;
	padding: 24px;
	text-align: center;

	.text {
		text-align: center;
		align-self: center;
	}
`;

export default function SetupBlock({ description, children }) {
	return (
		<SetupBlockContainer style={{ textAlign: 'left', padding: 32 }}>
			<div
				style={{
					fontSize: 20,
					lineHeight: '36px',
					fontStyle: 'italic',
					color: '#adadb0',
				}}>
				{description}
			</div>

			{children}
		</SetupBlockContainer>
	);
}
