import { axiosInstance } from "./base";
import { UserSessionsApi } from "./user-sessions";

export const UsersApi = {
  /**
   *
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  register: (data) => axiosInstance.post("/Users/byemail", data),
  /**
   *
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  initiatePasswordReset: (data) => axiosInstance.post("/Users/tokens", data),
  /**
   *
   * @param {string} token
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  resetPassword: (token, data) =>
    axiosInstance.patch(`/Users/bytoken/${token}`, data),
  /**
   *
   * @param {string} token
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  validateToken: (token, params) =>
    axiosInstance.get(`/Users/bytoken/${token}`, {
      params,
    }),
  /**
   *
   * @param {string} token
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserSession>}
   */
  acceptInvitation: (token, data) =>
    axiosInstance.patch(`/Users/bytoken/${token}`, data).then(() =>
      UserSessionsApi.login({
        email: data.email,
        password: data.password,
      })
    ),
  /**
   *
   * @returns {Promise<inndox.UserAccount>}
   */
  fetchCurrentUser: () => axiosInstance.get("/Users/current"),
  /**
   *
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  updateCurrentUser: (data) => axiosInstance.put("/Users/current", data),
  /**
   *
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.UserAccount>}
   */
  updateLoginDetails: (data) => axiosInstance.patch("/Users/current", data),
  /**
   * @param {Record<string,any>} data
   * @returns {Promise<void>}
   */
  removeCurrentUser: (data) =>
    axiosInstance.delete("/Users/current", {
      data,
    }),
};
