import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import Link from './link';
import questionIcon from '../../assets/images/question.svg';

const TooltipIcon = styled.img`
	width: 14px;
	margin-left: 4px;
	margin-right: auto;
`;

/**
 *
 * @param {*} props
 * @returns
 */
export default function Label(props) {
	const {
		label = undefined,
		required,
		moreInfo = undefined,
		className,
		tooltip,
	} = props;

	const tooltipId = `tooltip` + (1 + Math.floor(Math.random() * 1000));

	if (moreInfo) {
		if (label) {
			return (
				<div className={`form-label flex between ${className}`}>
					<label className="flex between">
						<>
							{label}

							{tooltip ? (
								<>
									<TooltipIcon id={tooltipId} src={questionIcon} alt="help" />
									<Tooltip
										anchorId={tooltipId}
										style={{
											borderRadius: 4,
											textTransform: 'none',
											padding: 8,
											fontFamily: 'ProjectFont',
											fontSize: '12px',
											lineHeight: '18px',
											letterSpacing: 'normal',
											maxWidth: 250,
										}}>
										{tooltip}
									</Tooltip>
								</>
							) : (
								<noscript />
							)}
						</>

						{required ? <noscript /> : <span>Optional</span>}
					</label>

					<Link title={moreInfo.title} link={moreInfo.link} tabIndex={-1} />
				</div>
			);
		}

		return (
			<div className={`form-label flex between ${className}`}>
				<Link title={moreInfo.title} link={moreInfo.link} />
			</div>
		);
	}

	if (label) {
		return (
			<div className={`form-label ${props.className}`}>
				<label className="flex between">
					<>
						{label}

						{tooltip ? (
							<>
								<TooltipIcon id={tooltipId} src={questionIcon} alt="help" />
								<Tooltip
									anchorId={tooltipId}
									style={{
										borderRadius: 4,
										textTransform: 'none',
										padding: 8,
										fontFamily: 'ProjectFont',
										fontSize: '12px',
										lineHeight: '18px',
										letterSpacing: 'normal',
										maxWidth: 250,
									}}>
									{tooltip}
								</Tooltip>
							</>
						) : (
							<noscript />
						)}
					</>
					{required ? <noscript /> : <span>Optional</span>}
				</label>
			</div>
		);
	}

	return <noscript />;
}

export const Description = styled.p`
	margin-top: -6px;
	margin-bottom: 6px;
	display: block;
	line-height: 20px;
	font-size: 12px;
	color: #adadb0;
	font-weight: normal;
	text-align: left;
`;
