import { Col } from 'react-grid-system';
import { useSelector } from 'react-redux';
import stripeLogo from '../assets/images/stripe.png';
import { selectPaymentDetails } from '../store/features/subscription.slice';
import { styled } from 'styled-components';

const STRIPE_STATUS = {
	PendingInformation: 'Pending',
	PendingVerification: 'Pending Verification',
	Completed: 'Connected',
};

const Content = styled.div`
	padding: 32px 32px 24px;
	border-radius: 8px;
	border: 1px solid #dde0e3;
`;

const IconContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
`;

const Title = styled.p`
	margin-top: 8px;
	font-size: 16px;
	font-weight: 700;
`;

export default function StripeIntegration() {
	const paymentDetails = useSelector(selectPaymentDetails);

	const connectWithStripe = async () => {
		window.location.href =
			process.env.REACT_APP_BASE_URL +
			process.env.REACT_APP_API_EXTENSION +
			'/payments/payoutsetup?apikey=' +
			process.env.REACT_APP_API_KEY;
	};

	const needsStripeIntegration = !!paymentDetails?.stripeAccount;

	if (!needsStripeIntegration) {
		return <noscript />;
	}

	const integrationStatus = paymentDetails.stripeAccount.status;
	return (
		<Col xs={12} sm={6} className="margin-top-2 margin-bottom-1">
			<Content style={{ textAlign: 'center' }}>
				<IconContainer>
					<img src={stripeLogo} alt="stripe logo" height={32} />
				</IconContainer>
				<Title>Stripe</Title>
				<p>Status: {STRIPE_STATUS[integrationStatus]}</p>

				{integrationStatus === 'PendingInformation' ? (
					<button
						className="button button-secondary margin-top-2"
						onClick={connectWithStripe}>
						Connect with Stripe
					</button>
				) : integrationStatus === 'PendingVerification' ? (
					<button
						className="button button-secondary margin-top-2"
						onClick={connectWithStripe}>
						Complete verification
					</button>
				) : (
					<noscript />
				)}
			</Content>
		</Col>
	);
}
