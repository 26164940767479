import { useSelector } from "react-redux";
import { selectCurrentTemplate } from "../../store/features/templates.slice";
import LogbookFolder from "../common/logbook-folder";

export default function TemplateFolder() {
  const currentTemplate = useSelector(selectCurrentTemplate);

  return (
    <LogbookFolder
      isPartOfTemplate
      isCompanyUser
      logbookId={currentTemplate.id}
      folders={currentTemplate.folders}
      documents={currentTemplate.documents}
      description={currentTemplate.description}
    />
  );
}
