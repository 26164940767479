import pendingIcon from '../../assets/images/hourglass-start-solid.svg';
import successIcon from '../../assets/images/circle-check-solid.svg';
import failedIcon from '../../assets/images/circle-xmark-solid.svg';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

export const NUMBER_OF_BYTES_IN_ONE_MB = 1048576;

export const ALL_ALLOWED_FILE_TYPES = {
	'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.tiff'],
	'application/*': ['.doc', '.docx', '.pdf', '.xlsx', '.xls', '.zip'],
	'text/*': ['.txt'],
	'video/*': ['.mp4'],
};

export const ALL_ALLOWED_FILE_MIME_TYPES = Object.keys(
	ALL_ALLOWED_FILE_TYPES
).reduce((red, key) => {
	ALL_ALLOWED_FILE_TYPES[key].forEach((ext) => {
		red.push(`${key.replace('*', '')}${ext.replace('.', '')}`);
	});

	return red;
}, []);

export const FILE_UPLOAD_STATUS = {
	PENDING: 'pending',
	UPLOADING: 'uploading',
	UPLOADED: 'uploaded',
	FAILED: 'failed',
};

const Icon = styled.img`
	width: 16px;
	height: 16px;
`;

export function getFileUploadStatusIcon(uploadStatus) {
	switch (uploadStatus) {
		case FILE_UPLOAD_STATUS.PENDING:
			return <Icon src={pendingIcon} />;

		case FILE_UPLOAD_STATUS.UPLOADING:
			return <ClipLoader loading size={16} color="#BFD62F" />;

		case FILE_UPLOAD_STATUS.UPLOADED:
			return <Icon src={successIcon} />;

		case FILE_UPLOAD_STATUS.FAILED:
			return <Icon src={failedIcon} />;

		default:
			break;
	}
}
