import { axiosInstance } from "./base";

export const CompanyApi = {
  /**
   *
   * @returns {Promise<inndox.Company>}
   */
  fetchCompanies: () => axiosInstance.get("/companies"),

  /**
   * Not images
   * @param {number} companyId
   * @param {Record<string,any>} data
   * @returns {Promise<void>}
   */
  updateCompanyDetails: (companyId, data) =>
    axiosInstance.patch(`/companies/${companyId}`, data),
  /**
   * Not images
   * @param {number} companyId
   * @param {FormData} data
   * @returns {Promise<void>}
   */
  updateCompanyImages: (companyId, data) =>
    axiosInstance.patch(`/companies/${companyId}`, data),
  /**
   *
   * @returns {Promise<inndox.Company[]>}
   */
  fetchChildCompanies: () => axiosInstance.get("/companies/children"),
  /**
   * Add or update Rex integration
   * @param {Record<string,any>} data
   * @returns {Promise<void>}
   */
  updateRexIntegration: (data) =>
    axiosInstance.put("/companies/current/rex", data),
  /**
   * Remove Rex integration
   * @returns {Promise<void>}
   */
  removeRexIntegration: () => axiosInstance.delete("/companies/current/rex"),
  /**
   * Add or update Xero integration
   * @returns {void}
   */
  updateXeroIntegration: () =>
    window.open(
      process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_API_EXTENSION +
        "/companies/current/xero/oauth/url",
      "_self"
    ),
  /**
   * Remove Xero integration
   * @returns {Promise<void>}
   */
  removeXeroIntegration: () =>
    axiosInstance.delete("/companies/current/xero/oauth"),

  /**
   *
   * @param {number} companyId
   * @returns {Promise<inndox.UserSession>}
   */
  impersonateCompany: (companyId) =>
    axiosInstance.post(`/companies/${companyId}/impersonate`),
};
