import { axiosInstance } from './base';

export const ContactsApi = {
	/**
	 *
	 * @returns {Promise<inndox.Contact[]>}
	 */
	fetchContacts: () => axiosInstance.get('/Contacts'),
	/**
	 *
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Contact>}
	 */
	createNewContact: (data) => axiosInstance.post('/Contacts', data),
	/**
	 *
	 * @param {number} contactId
	 * @returns {Promise<inndox.Contact>}
	 */
	fetchContact: (contactId) => axiosInstance.get(`/Contacts/${contactId}`),
	/**
	 *
	 * @param {number} contactId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Contact>}
	 */
	updateContact: (contactId, data) =>
		axiosInstance.put(`/Contacts/${contactId}`, data),
	/**
	 *
	 * @param {number} contactId
	 * @returns {Promise<void>}
	 */
	removeContact: (contactId) => axiosInstance.delete(`/Contacts/${contactId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number[]} contactIds
	 * @returns {Promise<inndox.Contact[]>} returns all the contacts for the logbook
	 */
	bulkAddContactsToProperty: (propertyId, contactIds) =>
		axiosInstance.post(`/Contacts/property/${propertyId}`, {
			propertyId,
			contactIds,
		}),
	/**
	 *
	 * @returns {Promise<void>}
	 */
	importContactsFromXero: () => axiosInstance.post('/Contacts/import/xero'),
	/**
	 *
	 * @param {FormData} data
	 * @returns {Promise<void>}
	 */
	importContactsFromCsv: (data) =>
		axiosInstance.post('/Contacts/import/csv', data),
};
