import { Col, Row } from "react-grid-system";
import SetupBlockWithImage from "./setup-block-with-image";
import warrantyImage from "../assets/images/warranty.svg";
import MaintenanceItemList from "./maintenance-item-list";
import { useNavigate } from "react-router-dom";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { useSelector } from "react-redux";
import { CompanyRoutes, OwnerRoutes, TemplateRoutes } from "../routes";

/**
 * @typedef {Object} MaintenanceItemWidgetProps
 * @property {inndox.Logbook} logbook
 */

/**
 *
 * @param {MaintenanceItemWidgetProps} props
 * @returns
 */
export default function MaintenanceItemWidget({ logbook }) {
  const { contacts, maintenanceItems, isTemplate, transfer } = logbook;
  const navigate = useNavigate();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  if (!maintenanceItems?.length) {
    return (
      <>
        <Row>
          <Col xs={12} className="margin-top-2">
            <label>Maintenance Reminders</label>
          </Col>
        </Row>

        <SetupBlockWithImage
          title="Setup logbook maintenance alerts"
          description="Add maintenance and safety reminders."
          image={warrantyImage}
        >
          <div
            className="margin-top-2"
            onClick={() => {
              if (isTemplate) {
                navigate(TemplateRoutes.addTemplateMaintenanceItem);
              } else if (isCompanyUser) {
                navigate(CompanyRoutes.addLogbookMaintenanceItem);
              } else {
                navigate(OwnerRoutes.addLogbookMaintenanceItem);
              }
            }}
          >
            <button className="button button-secondary">
              Add maintenance item
            </button>
          </div>
        </SetupBlockWithImage>
      </>
    );
  }

  const items = maintenanceItems.slice(0, 5);
  return (
    <>
      <Row>
        <Col xs={12} className="margin-top-2">
          <label>Maintenance Reminders</label>
        </Col>
      </Row>

      <MaintenanceItemList
        items={items}
        contacts={contacts}
        isPartOfATemplate={isTemplate}
        showActions
        logbookIsTransferred={!!transfer?.acceptedOn}
        ownerId={logbook.ownerId}
      />
    </>
  );
}
