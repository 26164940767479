import moment from "moment";
import { Col, Hidden, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import addLogbookIcon from "../../../assets/images/add-logbook-icon.svg";
import logbookTypeCaravan from "../../../assets/images/logbook-type-caravan.svg";
import logbookTypeMarine from "../../../assets/images/logbook-type-marine.svg";
import logbookTypeOther from "../../../assets/images/logbook-type-other.svg";
import logbookTypeRealEstate from "../../../assets/images/logbook-type-real-estate.svg";
import logbookTypeVehicle from "../../../assets/images/logbook-type-vehicle.svg";
import { useAuth } from "../../../components/authentication";
import { AccountRoutes, OwnerRoutes } from "../../../routes";
import { selectLogbooks } from "../../../store/features/logbooks.slice";
import { selectPaymentDetails } from "../../../store/features/subscription.slice";

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const StyledButton = styled.button`
  border-radius: 5px;

  @media only screen and (max-width: 576px) {
    margin-top: 8px;
    width: 100%;
  }
`;

const StyledCol = styled(Col)`
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde0e3;
`;

const Label = styled.label`
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const LogbookIconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: auto;
  margin-right: auto;

  img {
    max-width: 32px;
    max-height: 32px;
  }
`;

export default function LogbooksSettings() {
  const navigate = useNavigate();
  const paymentDetails = useSelector(selectPaymentDetails);
  const hasNeverSubscribed = paymentDetails?.planStatus === "None";
  const logbooks = useSelector(selectLogbooks);
  const { session } = useAuth();
  const currentAccountEmail = session?.email;

  const filteredLogbooks = logbooks?.filter((logbook) => {
    return (
      logbook.transfer?.to?.email === currentAccountEmail &&
      !!logbook.transfer?.acceptedOn
    );
  });

  const getLogbookTypeIcon = (logbookType) => {
    switch (logbookType) {
      case "Property":
        return logbookTypeRealEstate;

      case "Vehicle":
        return logbookTypeVehicle;

      case "Caravan":
        return logbookTypeCaravan;

      case "Marine":
        return logbookTypeMarine;

      default:
        return logbookTypeOther;
    }
  };

  return (
    <>
      <Row className="margin-bottom-2">
        <Col xs={12} md={4} className="flex">
          <Header>Logbooks</Header>
        </Col>
        <Col xs={12} md={8} className="flex end">
          <StyledButton
            className="button button-primary"
            style={{ borderRadius: 5 }}
            onClick={() => {
              if (hasNeverSubscribed) {
                navigate(
                  "/" +
                    AccountRoutes.basePath.replace("/*", "") +
                    "/" +
                    AccountRoutes.addPaymentDetails +
                    "?source=owner",
                );
              } else {
                navigate(
                  "/" +
                    OwnerRoutes.basePath.replace("/*", "") +
                    "/" +
                    OwnerRoutes.newLogbookType,
                );
              }
            }}
          >
            <img
              src={addLogbookIcon}
              alt="add user"
              style={{ marginRight: 8 }}
            />
            Setup a new logbook
          </StyledButton>
        </Col>
      </Row>

      <Row className="margin-top-2">
        <StyledCol xs={3} md={2} style={{ textAlign: "center" }}>
          <Label>Type</Label>
        </StyledCol>

        <StyledCol xs={9} md={6}>
          <Label>Logbook</Label>
        </StyledCol>

        <Hidden xs>
          <StyledCol md={4}>
            <Label>Transferred</Label>
          </StyledCol>
        </Hidden>
      </Row>
      {filteredLogbooks.map((logbook) => (
        <Row key={logbook.id}>
          <StyledCol xs={3} md={2}>
            <LogbookIconContainer>
              <img
                src={getLogbookTypeIcon(logbook.logbookType)}
                alt={logbook.logbookType}
              />
            </LogbookIconContainer>
          </StyledCol>

          <StyledCol xs={9} md={6}>
            {logbook.description}
          </StyledCol>

          <Hidden xs>
            <StyledCol md={4}>
              {moment(logbook.transfer.acceptedOn).format("DD/MM/YYYY") +
                " at " +
                moment(logbook.transfer.acceptedOn).format("h:mma")}
            </StyledCol>
          </Hidden>
        </Row>
      ))}
    </>
  );
}
