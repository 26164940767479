import { Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { styled } from "styled-components";
import RexIntegration from "../../../components/rex-integration";
import StripeIntegration from "../../../components/stripe-integration";
import XeroIntegration from "../../../components/xero-integration";
import { selectCompany } from "../../../store/features/company.slice";

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

export default function IntegrationSettings() {
  const company = useSelector(selectCompany);

  return (
    <>
      <Header>Integrations</Header>

      <Row>
        <RexIntegration companyId={company.id} />

        <XeroIntegration />

        <StripeIntegration />
      </Row>
    </>
  );
}
