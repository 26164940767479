import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DefectsApi } from "../../api/defects";
import logbookSideImage from "../../assets/images/bg2.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import Checkbox from "../../components/form/checkbox";
import Dropzone from "../../components/form/dropzone";
import Select from "../../components/form/select";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import Toast from "../../components/toast";
import { addDefectToCompany } from "../../store/features/company.slice";
import { selectLogbooks } from "../../store/features/logbooks.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AddCompanyDefect() {
  const logbooks = useSelector(selectLogbooks);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DocumentTitle title="New Logbook Issue | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <h1>File a New Issue</h1>
          <p className="margin-top-1">
            The owner will be notified of this defect item.
          </p>

          <Form
            onSubmit={async (values) => {
              const { files, ...remainingAttributes } = values;
              const formData = new FormData();
              for (const [key, value] of Object.entries(remainingAttributes)) {
                formData.append(key, value);
              }

              if (files?.length) {
                // add new files
                files.forEach((file) => formData.append("files", file));
              }

              try {
                const newDefect = await DefectsApi.createNewDefect(
                  values.propertyId,
                  formData,
                );
                dispatch(addDefectToCompany(newDefect));

                toast.success(
                  <Toast
                    title="Success!"
                    details="Your issue has been added to the logbook."
                  />,
                );

                navigate(-1);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <Select
                  name="propertyId"
                  label="Logbook"
                  type="select"
                  items={logbooks.map((logbook) => ({
                    label: logbook.description,
                    value: logbook.id,
                  }))}
                  placeholder="Select"
                  required
                  validators={requiredValidator}
                  className="margin-top-4"
                />

                <TextField
                  name="subject"
                  label="Issue subject"
                  required
                  validate={requiredValidator}
                  className="margin-top-4"
                />

                <TextField
                  name="description"
                  label="Description"
                  className="margin-top-2"
                  required
                  validate={requiredValidator}
                />

                <Checkbox
                  className="margin-top-4"
                  name="isUrgent"
                  label="Is this issue urgent?"
                  description="E.g. Significant safety hazard or a plumbing leak."
                />

                <Dropzone
                  name="files"
                  className="margin-top-4"
                  label="Upload Files"
                  placeholder="Drag file here or click to browse"
                  activePlaceholder="Drop the file here ..."
                  accept={{
                    "image/png": [".png", ".jpg", ".jpeg", ".tiff", ".tif"],
                    "text/plain": [".txt"],
                    "application/pdf": [".pdf"],
                  }}
                  multiple
                />

                <button
                  className="button button-primary button-large button-big full-width margin-top-4"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Submit issue"
                  )}
                </button>
                <button
                  className="button button-link button-large button-big full-width"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
