import { ClipLoader } from 'react-spinners';
import cancelIcon from '../assets/images/cancel-subscription.svg';
import transferIcon from '../assets/images/transferring_icon_color.svg';
import envelopPending from '../assets/images/envelope-pending.svg';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LogbooksApi } from '../api/properties';
import { toast } from 'react-toastify';
import {
	setLogbooks,
	updateCurrentLogbook,
} from '../store/features/logbooks.slice';
import Modal from './modal';
import Toast from './toast';

export default function CancelHandover({
	propertyId,
	isOpen,
	onClose,
	receiverEmail,
	refreshOnComplete = false,
}) {
	const [wantsToCancel, setWantsToCancel] = useState(false);
	const [isCancelling, setIsCancelling] = useState(false);
	const dispatch = useDispatch();

	// methods
	const cancelHandover = async () => {
		setIsCancelling(true);

		try {
			await LogbooksApi.cancelLogbookHandover(propertyId);
			toast.success(<Toast title="Transfer was successfully cancelled." />);

			// Update logbook list if needed
			const updatedLogbooks = await LogbooksApi.fetchLogbooks();
			dispatch(setLogbooks(updatedLogbooks));

			// Update current logbook if needed
			const updatedLogbook = await LogbooksApi.fetchLogbook(propertyId);
			dispatch(updateCurrentLogbook(updatedLogbook));

			setIsCancelling(false);
			onClose();

			if (refreshOnComplete) {
				window.location.reload();
			}
		} catch (e) {
			setIsCancelling(false);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			icon={wantsToCancel ? cancelIcon : transferIcon}
			title={
				wantsToCancel
					? 'Cancel the logbook transfer'
					: 'This logbook is pending a logbook transfer'
			}
			onClose={onClose}>
			{wantsToCancel ? (
				<>
					<p>
						Are you sure you want to cancel the logbook transfer to {receiverEmail}?
					</p>

					<br />

					<p>
						The recipient will receive an email notifying that the transfer has been
						cancelled.
					</p>

					<div className="flex margin-top-3">
						<button
							className="button button-archive"
							onClick={() => cancelHandover()}>
							<ClipLoader loading={isCancelling} size={16} color="#fff" />

							{isCancelling ? 'Cancelling...' : 'Yes, cancel the logbook transfer'}
						</button>

						<button
							className="button button-alt margin-left-1"
							onClick={() => setWantsToCancel(false)}>
							Cancel
						</button>
					</div>
				</>
			) : (
				<div className="text-center">
					<img src={envelopPending} alt="pending transfer" />

					<p>
						This logbook is awaiting {receiverEmail} to accept the logbook transfer
						ownership.
					</p>

					<button
						className="button button-archive margin-top-3"
						onClick={() => setWantsToCancel(true)}>
						I want to cancel the logbook transfer
					</button>
				</div>
			)}
		</Modal>
	);
}
