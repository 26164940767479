import { useDispatch, useSelector } from 'react-redux';
import {
	selectCurrentLogbook,
	updateCurrentLogbook,
} from '../store/features/logbooks.slice';
import { Form } from 'react-final-form';
import { DefectsApi } from '../api/defects';
import { toast } from 'react-toastify';
import Toast from './toast';
import styled from 'styled-components';

const ToggleContainer = styled.div`
	border-radius: 8px;
	border: 1px solid #c6d85b80;
	background: #fefff5;
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Content = styled.div`
	margin-right: 24px;
`;

const Title = styled.p`
	font-size: 16px;
	font-weight: bolder;
`;

const Description = styled.p`
	font-size: 14px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	label {
		font-size: 16px;
		line-height: 22px;
		color: #bfbfbf;
		text-transform: none;
		letter-spacing: normal;
		font-weight: normal;
		cursor: pointer;
	}
`;

const VisibleCheckbox = styled.div`
	width: 27px;
	height: 27px;
	border-radius: 3px;
	border: 1px solid #bfbfbf;
	background-color: #fff;
	cursor: pointer;
	position: relative;
	transition: all 150ms linear;
	flex-shrink: 0;
	margin-right: 8px;

	&:hover {
		border-color: #bdd23f;
	}

	&.checked {
		background-color: #bdd23f;
		border-color: #bdd23f;

		&:before {
			content: '';
			position: absolute;
			width: 10px;
			height: 3px;
			background-color: #ffffff;
			border-radius: 16px;
			transform: rotate(45deg);
			left: 1px;
			top: 14px;
			transition: all 150ms linear;
		}
		&:after {
			content: '';
			position: absolute;
			width: 20px;
			height: 3px;
			background-color: #ffffff;
			border-radius: 16px;
			transform: rotate(-45deg);
			left: 5px;
			top: 10px;
			transition: all 150ms linear;
		}
	}
`;

export default function ToggleOwnerAbilityToFileDefects() {
	const dispatch = useDispatch();
	const logbook = useSelector(selectCurrentLogbook);
	const hasDefectManagement = logbook?.hasDefectManagement;

	const onToggle = async () => {
		try {
			const updatedLogbook = await DefectsApi.updateDefectManagementStatus(
				logbook.id,
				!hasDefectManagement
			);
			dispatch(updateCurrentLogbook(updatedLogbook));

			toast.success(
				<Toast
					title={
						'Issue management has been ' +
						(updatedLogbook.hasDefectManagement ? 'enabled' : 'disabled') +
						' for this logbook.'
					}
				/>
			);
		} catch (e) {
			toast.error(
				<Toast
					title={
						'There was an error ' +
						(hasDefectManagement ? 'disabling' : 'enabling') +
						' issue management for this logbook. Please try again later.'
					}
				/>
			);
		}
	};

	return (
		<Form
			initialValues={{ hasDefectManagement }}
			onSubmit={onToggle}
			render={(props) => (
				<ToggleContainer>
					<Content>
						<Title>Issue Log Management</Title>
						<Description>
							Toggle if the owner is allowed to log issues against the warranty of this
							logbook
						</Description>
					</Content>

					<CheckboxContainer>
						<VisibleCheckbox
							className={props.values.hasDefectManagement ? 'checked' : ''}
							onClick={onToggle}
						/>
					</CheckboxContainer>
				</ToggleContainer>
			)}
		/>
	);
}
