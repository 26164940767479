import { axiosInstance } from './base';

export const LogbooksApi = {
	/**
	 *
	 * @returns {Promise<inndox.Logbook[]>}
	 */
	fetchLogbooks: () => axiosInstance.get('/properties'),
	/**
	 *
	 * @returns {Promise<inndox.Logbook>}
	 */
	fetchLogbook: (propertyId) => axiosInstance.get(`/properties/${propertyId}`),
	/**
	 *
	 * @returns {Promise<inndox.Logbook>}
	 */
	cloneLogbook: (propertyId) =>
		axiosInstance.post(`/properties/${propertyId}/clone`),
	/**
	 *
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Logbook>}
	 */
	createNewLogbook: (data) => axiosInstance.post('/properties', data),
	/**
	 *
	 * @param {Record<string,any>} params
	 * @returns {Promise<inndox.Logbook[]>}
	 */
	searchForLogbooks: (params) =>
		axiosInstance.get('/properties/search', { params }),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} query
	 * @returns {Promise<inndox.LogbookContentSearchResults>}
	 */
	searchLogbookContents: (propertyId, query) =>
		axiosInstance.post(`/properties/${propertyId}/search`, { propertyId, query }),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Logbook>}
	 */
	updateLogbook: (propertyId, data) =>
		axiosInstance.put(`/properties/${propertyId}`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Logbook>}
	 */
	updateLogbookCoverImage: (propertyId, data) =>
		axiosInstance.patch(`/properties/${propertyId}`, data),
	/**
	 *
	 * @returns {Promise<void>}
	 */
	removeLogbook: (propertyId) =>
		axiosInstance.delete(`/properties/${propertyId}`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.Logbook>}
	 */
	handoverLogbook: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/handover`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<void>}
	 */
	cancelLogbookHandover: (propertyId) =>
		axiosInstance.delete(`/properties/${propertyId}/handover`),
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<void>}
	 */
	acceptLogbookHandover: (propertyId) =>
		axiosInstance.post(`/properties/${propertyId}/accept`),
	/**
	 *
	 * @param {number} propertyId
	 * @returns {Promise<void>}
	 */
	cancelLogbookSponsorship: (propertyId) =>
		axiosInstance.post(`/properties/${propertyId}/sponsorship`),
	/**
	 *
	 * @param {number} propertyId
	 * @param {Record<string,any>} data
	 * @returns {Promise<void>}
	 */
	shareLogbook: (propertyId, data) =>
		axiosInstance.post(`/properties/${propertyId}/access`, data),
	/**
	 *
	 * @param {number} propertyId
	 * @param {number} permissionId
	 * @returns {Promise<void>}
	 */
	unshareLogbook: (logbookId, permissionId) =>
		axiosInstance.delete(`/properties/${logbookId}/access/${permissionId}`),
};
