import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { DocumentsApi } from "../../api/documents";
import logbookSideImage from "../../assets/images/bg2.jpg";
import { FiftyFifty } from "../../components/fifty-fifty";
import DatePicker from "../../components/form/date-picker";
import Select from "../../components/form/select";
import TextField from "../../components/form/text-field";
import { requiredValidator } from "../../components/form/validators";
import { handleError } from "../../components/helpers";
import { CompanyRoutes, OwnerRoutes } from "../../routes";
import {
  selectCurrentLogbook,
  updateLogbookDocument,
} from "../../store/features/logbooks.slice";
import { isCurrentUserACompany } from "../../store/features/user.slice";

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

export default function AddOrEditWarranty() {
  const { key } = useLocation();
  const { logbookId: logbookIdPathParam, documentId: documentIdPathParam } =
    useParams();
  const logbookId = parseInt(logbookIdPathParam);
  const documentId = parseInt(documentIdPathParam);
  const logbook = useSelector(selectCurrentLogbook);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documents = logbook.documents;
  const unarchivedDocuments = documents.filter(
    (document) => !document.dateArchived,
  );
  const document = documentId
    ? unarchivedDocuments.find((document) => document.id === documentId)
    : undefined;
  const isCompanyUser = useSelector(isCurrentUserACompany);

  const isUpdatingAnExistingWarranty = !!documentId;

  let backPath = "";
  if (key !== "default") {
    backPath = -1;
  }
  if (isUpdatingAnExistingWarranty) {
    if (isCompanyUser) {
      backPath =
        "/" +
        CompanyRoutes.basePath.replace("/*", "") +
        "/" +
        CompanyRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        CompanyRoutes.warranties;
    } else {
      backPath =
        "/" +
        OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.warranties;
    }
  } else {
    if (isCompanyUser) {
      backPath =
        "/" +
        CompanyRoutes.basePath.replace("/*", "") +
        "/" +
        CompanyRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        CompanyRoutes.warranties;
    } else {
      backPath =
        "/" +
        OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbookId) +
        "/" +
        OwnerRoutes.warranties;
    }
  }

  return (
    <DocumentTitle title="Edit Logbook | inndox">
      <FiftyFifty backgroundImage={logbookSideImage}>
        <Content>
          <h1>
            {isUpdatingAnExistingWarranty
              ? "Edit Warranty"
              : "Specify a New Warranty"}
          </h1>

          <p className="margin-top-1">
            The logbook owner will be notified when a warranty is going to
            expire. A reminder will be sent 3 months before the expire date.
          </p>

          <Form
            initialValues={{
              selectedDocument: document?.id,
              title: document?.name,
              expiryDate: document?.warrantyExpiresOn,
            }}
            onSubmit={async (values) => {
              try {
                const updatedDocument =
                  await DocumentsApi.setWarrantyOnDocument(
                    logbookId,
                    documentId || values.selectedDocument,
                    {
                      id: values.selectedDocument,
                      propertyId: logbookId,
                      name: values.title,
                      warrantyExpiresOn: values.expiryDate,
                    },
                  );

                dispatch(updateLogbookDocument(updatedDocument));

                navigate(backPath);
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <>
                <Select
                  name="selectedDocument"
                  label="Select a document"
                  items={unarchivedDocuments.map((document) => ({
                    label: document.filename,
                    value: document.id,
                  }))}
                  className="margin-top-4"
                  required
                  validate={requiredValidator}
                />

                <TextField
                  name="title"
                  label="Title"
                  className="margin-top-2"
                  required
                  validate={requiredValidator}
                />

                <DatePicker
                  label="Expiry Date"
                  name="expiryDate"
                  className="margin-top-2"
                  required
                  validators={requiredValidator}
                />

                <button
                  className="button button-primary button-large button-big full-width margin-top-4"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : isUpdatingAnExistingWarranty ? (
                    "Update warranty"
                  ) : (
                    "Set warranty"
                  )}
                </button>

                <button
                  className="button button-link button-big fullw-width button-large"
                  onClick={() => navigate(backPath)}
                >
                  Cancel
                </button>
              </>
            )}
          />
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
