import { useState } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes } from '../routes';
import { selectLogbooks } from '../store/features/logbooks.slice';
import SetupBlock from './setup-block';
import moment from 'moment';
import CancelHandover from './cancel-handover';

export default function RecentlyUpdatedLogbooks() {
	const navigate = useNavigate();
	const logbooks = useSelector(selectLogbooks);
	const [currentLogbook, setCurrentLogbook] = useState(undefined);
	const [cancelTransfer, setCancelTransfer] = useState(false);

	const cancelLogbookTransfer = (logbook) => {
		setCurrentLogbook(logbook);
		setCancelTransfer(true);
	};

	if (!logbooks?.length) {
		return (
			<SetupBlock description="You have no logbooks setup yet">
				<div className="margin-top-2">
					<button
						className="button button-secondary"
						onClick={() =>
							navigate(
								'/' +
									CompanyRoutes.basePath.replace('/*', '') +
									'/' +
									CompanyRoutes.newLogbookType
							)
						}>
						Setup a logbook
					</button>
				</div>
			</SetupBlock>
		);
	}

	const logbooksToDisplay = logbooks.slice(0, 5);
	return (
		<>
			<div className="document-list bottom-only property-handovers-widget">
				<Row>
					<Col xs={8} sm={7} className="col-head">
						Name
					</Col>
					<Col xs={4} sm={3} className="col-head">
						Handover Status
					</Col>
					<Hidden xs>
						<Col sm={2} className="col-head">
							Created
						</Col>
					</Hidden>
				</Row>
				{logbooksToDisplay.map((logbook, i) => (
					<Row key={`template-item-${i}`}>
						<Col xs={8} sm={7} className="col-body thick">
							<div style={{ maxWidth: '100%' }}>
								<p
									className="link-style-elem truncate"
									onClick={() =>
										navigate(
											'/' +
												CompanyRoutes.basePath.replace('/*', '') +
												'/' +
												CompanyRoutes.logbooks +
												'/' +
												logbook.id
										)
									}>
									{logbook.description}
								</p>

								{['Vehicle', 'Marine', 'Caravan'].includes(logbook.logbookType) ? (
									<p className="helper-text" style={{ fontSize: '12px' }}>
										{logbook.serialNumber}
									</p>
								) : (
									<noscript />
								)}
							</div>
						</Col>
						<Col xs={4} sm={3} className="col-body thick">
							<div
								className="flex col top"
								style={{ marginTop: -8, marginBottom: -8 }}>
								{!logbook.transfer
									? 'Pending'
									: logbook.transfer?.status === 'Transferring'
									? 'Transfer in progress'
									: logbook.transfer?.status}

								{logbook.transfer?.status === 'Transferring' ? (
									<button
										className="button button-danger-inverse"
										style={{ padding: 0 }}
										onClick={() => cancelLogbookTransfer(logbook)}>
										Cancel transfer
									</button>
								) : null}
							</div>
						</Col>
						<Hidden xs>
							<Col sm={2} className="col-body thick">
								<p className="truncate">
									{moment(logbook.dateCreated).format('DD/MM/YYYY')}
								</p>
							</Col>
						</Hidden>
					</Row>
				))}
				<Row
					justify="end"
					style={{ borderTop: '1px solid #dde0e3', paddingTop: '16px' }}>
					<Visible xs>
						<button
							className={`button button-secondary`}
							onClick={() =>
								navigate(
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										CompanyRoutes.logbooks
								)
							}>
							View all
						</button>
					</Visible>
					<Hidden xs>
						<button
							className={`button button-alt`}
							onClick={() =>
								navigate(
									'/' +
										CompanyRoutes.basePath.replace('/*', '') +
										'/' +
										CompanyRoutes.logbooks
								)
							}>
							View all
						</button>
					</Hidden>
				</Row>
			</div>

			{currentLogbook ? (
				<CancelHandover
					receiverEmail={currentLogbook.transfer?.to.email}
					propertyId={currentLogbook.id}
					isOpen={cancelTransfer}
					onClose={() => {
						setCancelTransfer(false);
						setCurrentLogbook(null);
					}}
				/>
			) : null}
		</>
	);
}
