import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectCurrentLogbookDefects } from "../../store/features/logbooks.slice";
import ViewClaim from "../common/view-claim";

export default function ViewCompanyLogbookDefect() {
  const { defectId: defectIdPathParam } = useParams();
  const defectId = parseInt(defectIdPathParam);
  const defects = useSelector(selectCurrentLogbookDefects);
  const defect = defects.find((defect) => defect.id === defectId);

  return <ViewClaim defect={defect} />;
}
