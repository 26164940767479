import { useState } from "react";
import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import questionIcon from "../../assets/images/question.svg";
import Modal from "../../components/modal";
import TemplateList from "../../components/template-list";
import { CompanyRoutes, TemplateRoutes } from "../../routes";
import { selectTemplates } from "../../store/features/templates.slice";

const HelpIcon = styled.img`
  height: 28px;
  margin-left: 16px;
  cursor: pointer;
`;

export default function CompanyTemplates() {
  const templates = useSelector(selectTemplates);
  const navigate = useNavigate();
  const [wantsToViewHelpVideo, setWantsToViewHelpVideo] = useState(false);

  return (
    <DocumentTitle title="Templates | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={6} className="flex">
            <h2 className="name">Templates</h2>
            <HelpIcon
              src={questionIcon}
              alt="help"
              onClick={() => setWantsToViewHelpVideo(true)}
            />
          </Col>
          <Col xs={6} className="flex end">
            <button
              className="button button-secondary"
              onClick={() =>
                navigate(
                  "/" +
                    CompanyRoutes.basePath.replace("/*", "") +
                    "/" +
                    TemplateRoutes.newTemplate,
                )
              }
            >
              Add template
            </button>
          </Col>
        </Row>

        <TemplateList templates={templates} />

        <Modal
          title="Templates"
          isOpen={wantsToViewHelpVideo}
          onClose={() => setWantsToViewHelpVideo(false)}
        >
          <div className="aspect-ratio-16-9 margin-bottom-2">
            <iframe
              title="Video about Property Templates"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/DqSuewRor88"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style={{
                position: "absolute",
              }}
            ></iframe>
          </div>
        </Modal>
      </Container>
    </DocumentTitle>
  );
}
