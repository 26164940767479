import { axiosInstance } from "./base";

export const PaymentsApi = {
  /**
   *
   * @returns {Promise<inndox.PaymentDetails>}
   */
  fetchConfig: () => axiosInstance.get("/Payments/configuration"),
  /**
   *
   * @returns {Promise<inndox.PaymentDetails>}
   */
  updateSubscription: (data) =>
    axiosInstance.patch("/Payments/configuration", data),
  /**
   *
   * @param {Record<string,any>} data
   * @returns {Promise<inndox.PaymentDetails>}
   */
  cancelSubscription: (data) =>
    axiosInstance.delete("/Payments/configuration", {
      data,
    }),
  /**
   *
   * @param {number} pageSize
   * @param {number} pageNumber
   * @returns {Promise<inndox.PagedPayments>}
   */
  fetchPastPayments: (pageSize, pageNumber) =>
    axiosInstance.get("/Payments", {
      params: { pageSize, pageNumber },
    }),
  /**
   * Set up stripe connection. Redirects to new URL
   */
  setupStripeForPayouts: () => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_API_EXTENSION +
      "/Payments/payoutsetup?apikey=" +
      process.env.REACT_APP_API_KEY;
  },
};
