import { Menu, MenuButton, MenuDivider, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import styled from "styled-components";
import menuActiveIcon from "../assets/images/new-more-icon-active.svg";
import menuIcon from "../assets/images/new-more-icon.svg";

// Defect Menu Items
export const VIEW_DEFECT_MENU_ITEM = {
  label: "View issue",
  value: "view-defect",
};
export const COMPLETE_DEFECT_MENU_ITEM = {
  label: "Mark as complete",
  value: "complete-defect",
};
export const EDIT_DEFECT_MENU_ITEM = {
  label: "Edit issue",
  value: "edit-defect",
};
export const DELETE_DEFECT_MENU_ITEM = {
  label: "Delete",
  value: "remove-defect",
};
export const ASSIGN_DEFECT_MENU_ITEM = {
  label: "Assign issue",
  value: "assign-defect",
};
export const ADD_DEFECT_MENU_ITEM = {
  label: "New issue",
  value: "add-defect",
};
export const ADD_DEFECT_CLAIMS_RECIPIENT_MENU_ITEM = {
  label: "Add issue log recipient",
  value: "add-defect-recipient",
};

// Folder Menu Items
export const OPEN_FOLDER_MENU_ITEM = { label: "Open", value: "open-folder" };
export const EDIT_FOLDER_MENU_ITEM = {
  label: "Edit folder",
  value: "edit-folder",
};
export const DELETE_FOLDER_MENU_ITEM = {
  label: "Delete",
  value: "delete-folder",
};
export const UPLOAD_FILES_TO_FOLDER_MENU_ITEM = {
  label: "Upload file(s)",
  value: "upload-to-folder",
};
export const ADD_LINK_TO_FOLDER_MENU_ITEM = {
  label: "Add link",
  value: "add-link-to-folder",
};
export const SHARE_FOLDER_MENU_ITEM = {
  label: "Share",
  value: "share-folder",
};
export const ADD_SUB_FOLDER_MENU_ITEM = {
  label: "New folder",
  value: "add-sub-folder",
};
export const VIEW_FOLDER_INFO_MENU_ITEM = {
  label: "Folder information",
  value: "view-folder-info",
};
export const VIEW_ALL_FOLDERS_MENU_ITEM = {
  label: "View all folders",
  value: "view-folders",
};

// Document Actions
export const DOWNLOAD_DOCUMENT_MENU_ITEM = {
  label: "Download",
  value: "download-document",
};
export const VIEW_DOCUMENT_MENU_ITEM = {
  label: "View",
  value: "view-document",
};
export const EDIT_WARRANTY_FOR_DOCUMENT_MENU_ITEM = {
  label: "Edit Warranty",
  value: "edit-document-warranty",
};
export const EDIT_DOCUMENT_LINK_MENU_ITEM = {
  label: "Edit",
  value: "edit-document-link",
};
export const MOVE_DOCUMENT_TO_LOGBOOK_ROOT_MENU_ITEM = {
  label: "Move to Dashboard",
  value: "move-document-to-root",
};
export const MOVE_DOCUMENT_TO_PARENT_FOLDER_MENU_ITEM = {
  label: "Move to Main Folder",
  value: "move-document-to-folder-root",
};
export const DELETE_DOCUMENT_MENU_ITEM = {
  label: "Delete",
  value: "delete-document",
};
export const ARCHIVE_DOCUMENT_MENU_ITEM = {
  label: "Archive",
  value: "archive-document",
};

// Contact Actions
export const VIEW_CONTACT_MENU_ITEM = {
  label: "View all details",
  value: "view-contact",
};

export const EDIT_CONTACT_MENU_ITEM = {
  label: "Edit contact",
  value: "edit-contact",
};

export const DELETE_CONTACT_MENU_ITEM = {
  label: "Delete",
  value: "delete-contact",
};

// Maintenance Item Actions
export const VIEW_MAINTENANCE_MENU_ITEM = {
  label: "More info",
  value: "view-maintenance-item",
};
export const EDIT_MAINTENANCE_MENU_ITEM = {
  label: "Edit",
  value: "edit-maintenance-item",
};
export const DELETE_MAINTENANCE_MENU_ITEM = {
  label: "Delete",
  value: "delete-maintenance-item",
};
export const SNOOZE_MAINTENANCE_MENU_ITEM = {
  label: "Snooze",
  value: "snooze-maintenance-item",
};
export const UNSNOOZE_MAINTENANCE_MENU_ITEM = {
  label: "Unsnooze",
  value: "unsnooze-maintenance-item",
};

// Logbook Actions
export const VIEW_LOGBOOK_MENU_ITEM = {
  label: "View",
  value: "view-logbook",
};
export const EDIT_LOGBOOK_MENU_ITEM = {
  label: "Edit logbook",
  value: "edit-logbook",
};
export const UPLOAD_FILES_TO_LOGBOOK_MENU_ITEM = {
  label: "Upload file(s)",
  value: "add-files-to-logbook",
};
export const VIEW_LOGBOOK_CLAIMS_MENU_ITEM = {
  label: "Issue log",
  value: "view-logbook-defects",
};
export const ADD_LOGBOOK_FOLDER_MENU_ITEM = {
  label: "Add folder",
  value: "add-logbook-folder",
};
export const ADD_LINK_TO_LOGBOOK_MENU_ITEM = {
  label: "Add link",
  value: "add-link-to-logbook",
};
export const VIEW_LOGBOOK_CONTACTS_MENU_ITEM = {
  label: "Contacts",
  value: "view-logbook-contacts",
};
export const APPLY_TEMPLATE_TO_LOGBOOK_MENU_ITEM = {
  label: "Apply template",
  value: "apply-logbook-to-template",
};
export const CLONE_LOGBOOK_MENU_ITEM = {
  label: "Clone",
  value: "clone-logbook",
};
export const DELETE_LOGBOOK_MENU_ITEM = {
  label: "Delete",
  value: "delete-logbook",
};
export const HANDOVER_LOGBOOK_MENU_ITEM = {
  label: "Handover Logbook",
  value: "transfer-logbook",
};
export const TRANSFER_LOGBOOK_MENU_ITEM = {
  label: "Transfer logbook",
  value: "transfer-logbook",
};
export const SHARE_LOGBOOK_MENU_ITEM = {
  label: "Share",
  value: "share-logbook",
};
export const SHARE_FOLDERS_MENU_ITEM = {
  label: "Share folder(s)",
  value: "share-folders",
};
export const CANCEL_LOGBOOK_HANDOVER_MENU_ITEM = {
  label: "Cancel transfer",
  value: "cancel-logbook-transfer",
};
export const VIEW_ARCHIVED_CONTENTS_MENU_ITEM = {
  label: "Archived",
  value: "view-archived-logbook-contents",
};

// Company Actions
export const IMPORT_XERO_CONTACTS_TO_ACCOUNT_MENU_ITEM = {
  label: "Import from Xero",
  value: "import-xero-contacts",
};
export const IMPORT_CSV_CONTACTS_TO_ACCOUNT_MENU_ITEM = {
  label: "Import via CSV",
  value: "import-csv-contacts",
};

const StyledMenuButton = styled(MenuButton)`
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  flex-shrink: 0;
`;

// Template Actions
export const EDIT_TEMPLATE_MENU_ITEM = {
  label: "Edit Template",
  value: "edit-template",
};
export const CLONE_TEMPLATE_MENU_ITEM = {
  label: "Clone",
  value: "clone-template",
};
export const DELETE_TEMPLATE_MENU_ITEM = {
  label: "Delete",
  value: "delete-template",
};
export const ADD_TEMPLATE_FOLDER_MENU_ITEM = {
  label: "Add folder",
  value: "add-template-folder",
};
export const ADD_LINK_TO_TEMPLATE_MENU_ITEM = {
  label: "Add link",
  value: "add-link-to-template",
};

// Company/Manage Account
export const VIEW_COMPANY_ACCESS_SETTINGS = {
  label: "Access Settings",
  value: "view-company-access-settings",
};

// Folder Permissions
export const GRANT_FOLDER_VIEW_PERMISSION_ACCESS_MENU_ITEM = {
  label: "View Only",
  value: "grant-view-folder-permission",
};
export const GRANT_FOLDER_UPLOAD_PERMISSION_ACCESS_MENU_ITEM = {
  label: "Upload Access",
  value: "grant-upload-folder-permission",
};
export const REMOVE_FOLDER_PERMISSION_MENU_ITEM = {
  label: "Remove",
  value: "remove-folder-permission",
};

// Divider
export const DIVIDER_MENU_ITEM = {
  label: "Divider",
};

/**
 *
 * @typedef {Object} MoreMenuProps
 * @property {{label:string, value:string}[]} options
 * @property {(value:string) => void} onSelect
 * @property {boolean} [invertColors]
 * @property {boolean} [transparentBackground]
 * @returns
 */

/**
 *
 * @param {MoreMenuProps} props
 * @returns
 */
export default function MoreMenu({
  options,
  invertColors,
  transparentBackground,
  onSelect,
}) {
  const [isHovering, setIsHovering] = useState(false);

  if (!options?.length) {
    return null;
  }

  return (
    <Menu
      menuButton={({ open }) => (
        <StyledMenuButton
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: transparentBackground
              ? "transparent"
              : isHovering || open
                ? invertColors
                  ? "#fff"
                  : "#F6F7FB"
                : invertColors
                  ? "#F6F7FB"
                  : "#fff",
          }}
        >
          <img
            src={isHovering || open ? menuActiveIcon : menuIcon}
            alt="more options"
          />
        </StyledMenuButton>
      )}
      onItemClick={(e) => onSelect(e.value)}
      viewScroll="close"
      position="auto"
      align="end"
      arrow
    >
      {options.map((option, index) => {
        if (option.label === DIVIDER_MENU_ITEM.label) {
          return <MenuDivider key={index} />;
        }

        return (
          <MenuItem
            key={index}
            value={option.value}
            style={
              option.color
                ? {
                    color: option.color,
                  }
                : undefined
            }
            onClick={(e) => e.syntheticEvent.stopPropagation()}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
