import { Col, Hidden, Row, Visible } from "react-grid-system";
import noFiles from "../assets/images/no-files.svg";
import errorIcon from "../assets/images/error.svg";
import expiredWarningIcon from "../assets/images/expired-warning.svg";
import expiringWarningIcon from "../assets/images/expiring-warning.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectPaymentDetails } from "../store/features/subscription.slice";
import { useAuth } from "./authentication";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import { useState } from "react";
import { LogbooksApi } from "../api/properties";
import {
  setCurrentLogbook,
  setLogbooks,
} from "../store/features/logbooks.slice";
import { handleError } from "./helpers";
import DefaultCoverImage from "../assets/images/house.jpg";
import { useNavigate } from "react-router-dom";
import circleCheckmarkIcon from "../assets/images/circle-checkmark.svg";
import transferringIcon from "../assets/images/transferring_icon_color.svg";
import MoreMenu, {
  CANCEL_LOGBOOK_HANDOVER_MENU_ITEM,
  EDIT_LOGBOOK_MENU_ITEM,
  SHARE_LOGBOOK_MENU_ITEM,
  TRANSFER_LOGBOOK_MENU_ITEM,
} from "./more-menu";
import logo from "../assets/images/logo-only.svg";
import logbookTypeRealEstate from "../assets/images/logbook-type-real-estate.svg";
import logbookTypeVehicle from "../assets/images/logbook-type-vehicle.svg";
import logbookTypeMarine from "../assets/images/logbook-type-marine.svg";
import logbookTypeCaravan from "../assets/images/logbook-type-caravan.svg";
import logbookTypeOther from "../assets/images/logbook-type-other.svg";
import { AccountRoutes, OwnerRoutes } from "../routes";
import CancelHandover from "./cancel-handover";
import ShareLogbookOrContent from "./share-logbook-or-content";
import downloadGreyIcon from "../assets/images/download-grey.svg";
import downloadGreenIcon from "../assets/images/download-green.svg";
import { DocumentsApi } from "../api/documents";
import { Tooltip } from "react-tooltip";

const LOGBOOK_TYPE_TO_ICON_MAPPING = {
  Property: logbookTypeRealEstate,
  Vehicle: logbookTypeVehicle,
  Caravan: logbookTypeCaravan,
  Marine: logbookTypeMarine,
  Other: logbookTypeOther,
};

const LOGBOOK_TYPE_TO_TITLE_MAPPING = {
  Property: "Real estate",
  Vehicle: "Vehicle",
  Caravan: "Caravan",
  Marine: "Marine",
  Other: "Other",
};

const AcceptPropertyContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;

    .button-accept-ownership {
      margin-left: 48px;
      margin-top: 16px;
    }
  }
`;

const StyledLogbookRow = styled(Row)`
  margin-top: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .cover-image {
    height: 240px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .info {
    margin-left: -16px;
    background-color: #fff;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @media only screen and (max-width: 575px) {
      margin-left: unset;
    }
  }

  &.transferring {
    .cover-image,
    .info {
      border-top: 4px solid #fecb48;
    }

    @media only screen and (max-width: 575px) {
      .info {
        border-top: unset;
      }
    }
  }

  &.transferred {
    .cover-image,
    .info {
      border-top: 4px solid #468f2d;
    }

    @media only screen and (max-width: 575px) {
      .info {
        border-top: unset;
      }
    }
  }

  &.disabled {
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }

  .property-name {
    font-size: 28px;
    color: #203d5e;
    letter-spacing: 0;
    line-height: 36px;
    font-weight: 700;
  }

  .property-type {
    color: #68c9d6;
    font-weight: bold;
  }

  .new-items-label {
    position: absolute;
    right: 32px;
    top: 24px;
    padding: 2px 6px;
    color: #fff;
    background-color: #bfd62f;
    font-size: 12px;
    line-height: normal;
    border-radius: 4px;
  }
  .new-items-count {
    position: absolute;
    right: -18px;
    top: -11px;
    padding: 4px 8px;
    color: #fff;
    background-color: #ff3e3e;
    font-size: 12px;
    line-height: normal;
    border-radius: 10px;
  }

  .company-logo-dashboard {
    position: absolute;
    right: 24px;
    bottom: 8px;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 4px 2px #00000020;

    @media only screen and (max-width: 575px) {
      right: 8px;
      bottom: 8px;
    }
  }

  .grayscale {
    filter: grayscale(1);
  }

  .status {
    max-width: calc(100% - 80px);

    @media only screen and (max-width: 575px) {
      max-width: 100%;
    }
  }

  .questionMarkPanel {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 20px;
    background-color: #f6a6a8;
    display: inline-block;
    margin-right: 20px;

    label {
      margin: auto;
      color: white;
      font-weight: bold;
      font-size: 25px;
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: 575.99px) {
    .radios.dashboard {
      width: 100%;

      .form-element {
        width: 50%;

        .form-input {
          width: 100%;
        }

        label {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
`;

const LogbookTypeIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  img {
    max-width: 32px;
    max-height: 32px;
  }
`;

const CoverImage = styled.div`
  height: 240px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media only screen and (max-width: 576px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const TopActions = styled.div`
  height: 40px;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* background-color: #fff; */
`;

const MoreMenuContainer = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: #fff;

  @media only screen and (max-width: 575px) {
    box-shadow: 0 1px 4px #00000025;
    border-radius: 4px;
  }
`;

const DownloadButtonBase = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 4px;
`;

const NewItemsLabel = styled.div`
  position: relative;
  margin-right: 24px;
  padding: 2px 6px;
  color: #fff;
  background-color: #bfd62f;
  font-size: 12px;
  line-height: normal;
  border-radius: 4px;
`;

/**
 *
 * @typedef {Object} LogbookRowItemProps
 * @property {inndox.Logbook} logbook
 */

/**
 *
 * @param {LogbookRowItemProps} props
 */
function LogbookRowItem({ logbook }) {
  const dispatch = useDispatch();
  const paymentDetails = useSelector(selectPaymentDetails);
  const hasValidPlan = paymentDetails?.planStatus !== "None";
  const companyForLogbook = logbook.companies
    ? logbook.companies.find(
        (company) => company.id === logbook.primaryCompanyId
      )
    : null;
  const isSponsoredLogbook = companyForLogbook?.isSponsored;
  const isSponsorshipExpiring = companyForLogbook?.sponsoredDaysRemaining > 0;
  const hasSponsorshipExpired =
    logbook?.transfer?.from.companyId && !logbook.companies && !hasValidPlan;
  const { session } = useAuth();
  const navigate = useNavigate();
  const currentAccountEmail = session.email;
  const currentAccountId = session.userId;
  const currentUserPermission = logbook.permissions?.find(
    (permission) => permission.email === session.email
  );
  const isCurrentUserAReadOnlyMemberForLogbook = currentUserPermission
    ? !currentUserPermission.permissions.includes("Create")
    : currentAccountId !== logbook.ownerId;
  const [isAcceptingProperty, setIsAcceptingProperty] = useState(false);
  const [wantsToCancelLogbookHandover, setWantsToCancelLogbookHandover] =
    useState(false);
  const [wantsToShareLogbook, setWantsToShareLogbook] = useState(false);

  const acceptPropertyOwnership = async () => {
    if (!hasValidPlan && !isSponsoredLogbook) {
      navigate(
        "/" +
          AccountRoutes.basePath.replace("/*", "") +
          "/" +
          AccountRoutes.addPaymentDetails
      );
      return;
    }

    setIsAcceptingProperty(true);

    try {
      await LogbooksApi.acceptLogbookHandover(logbook.id);
      const logbooks = await LogbooksApi.fetchLogbooks();
      dispatch(setLogbooks(logbooks));
    } catch (e) {
      handleError(e);
    } finally {
      setIsAcceptingProperty(false);
    }
  };

  const getPhoto = () => {
    return logbook.photoPath ? `${logbook.photoPath}?h=224` : DefaultCoverImage;
  };

  const actionSelectedMenuOptions = (value) => {
    switch (value) {
      case EDIT_LOGBOOK_MENU_ITEM.value: {
        navigate(
          "/" +
            OwnerRoutes.basePath.replace("/*", "") +
            "/" +
            OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
            "/" +
            OwnerRoutes.editLogbook
        );
        break;
      }

      case CANCEL_LOGBOOK_HANDOVER_MENU_ITEM.value: {
        // cancel logbook handover
        setWantsToCancelLogbookHandover(true);
        break;
      }

      case SHARE_LOGBOOK_MENU_ITEM.value: {
        // cancel logbook handover
        dispatch(setCurrentLogbook(logbook));
        setWantsToShareLogbook(true);
        break;
      }

      case TRANSFER_LOGBOOK_MENU_ITEM.value: {
        // handover logbook
        navigate(
          "/" +
            OwnerRoutes.basePath.replace("/*", "") +
            "/" +
            OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
            "/" +
            OwnerRoutes.logbookTransfer
        );
        break;
      }

      default:
        break;
    }
  };

  // accept logbook view
  if (
    logbook.transfer?.to.email === currentAccountEmail &&
    logbook.transfer?.status === "Transferring"
  ) {
    return (
      <Row className="property-list-item margin-top-1">
        <Col xs={12}>
          <AcceptPropertyContainer
            className="padding-2"
            style={{ background: "white" }}
          >
            <div className="padding-right-3 flex top" style={{ flexGrow: 1 }}>
              <img
                src={errorIcon}
                alt="error"
                style={{
                  width: 32,
                  height: 32,
                  marginTop: 4,
                  flexShrink: 0,
                  marginRight: 16,
                }}
              />

              <div className="flex col">
                <p>
                  <strong>
                    {logbook.transfer?.from.companyName ||
                      logbook.transfer?.from.firstName}{" "}
                    has transferred you a new logbook
                  </strong>
                </p>
                <p>
                  {"You have a new logbook transfer for " +
                    logbook.description +
                    ". You have " +
                    logbook.transfer.expiryInDays +
                    " day" +
                    (logbook.transfer.expiryInDays > 1 ? "s" : "") +
                    " left to accept the logbook."}
                </p>
              </div>
            </div>

            <button
              className="button button-primary"
              onClick={
                isAcceptingProperty ? undefined : acceptPropertyOwnership
              }
              style={{ flexShrink: 0 }}
            >
              {isAcceptingProperty ? (
                <ClipLoader loading size={16} color="#fff" />
              ) : (
                "Accept logbook"
              )}
            </button>
          </AcceptPropertyContainer>
        </Col>
      </Row>
    );
  }

  const hasLogbookBeenTransferredToAnotherOwner =
    logbook.transfer?.to.email !== currentAccountEmail &&
    logbook.transfer?.status === "Transferred";
  const isLogbookBeingTransferredToAnotherOwner =
    logbook.transfer?.to.email !== currentAccountEmail &&
    logbook.transfer?.status === "Transferring";
  const hasLogbookBeenTransferredToCurrentOwner =
    logbook.transfer?.to.email === currentAccountEmail &&
    logbook.transfer?.status === "Transferred";
  const acceptedOnDate = new Date(logbook.transfer?.acceptedOn);
  const shouldShowLogbookAsNew = hasLogbookBeenTransferredToCurrentOwner
    ? Math.floor(Date.now() - acceptedOnDate) / (1000 * 60 * 60 * 24) <= 2
    : false;

  const menuOptions = [EDIT_LOGBOOK_MENU_ITEM, SHARE_LOGBOOK_MENU_ITEM];
  if (logbook.transfer?.status === "Transferring") {
    menuOptions.push(CANCEL_LOGBOOK_HANDOVER_MENU_ITEM);
  } else {
    menuOptions.push(TRANSFER_LOGBOOK_MENU_ITEM);
  }

  const canVisitLogbook =
    (hasValidPlan || isSponsoredLogbook) &&
    !hasLogbookBeenTransferredToAnotherOwner;

  return (
    <StyledLogbookRow
      nogutter
      className={
        (logbook.transfer?.status === "Transferring" ? "transferring" : "") +
        (shouldShowLogbookAsNew ? "transferred" : "") +
        (!canVisitLogbook ? "disabled" : "")
      }
      onClick={() => {
        if (canVisitLogbook) {
          navigate(
            "/" +
              OwnerRoutes.basePath.replace("/*", "") +
              "/" +
              OwnerRoutes.logbook.replace(":logbookId", logbook.id)
          );
        }
      }}
    >
      <Col xs={12} sm={5}>
        <CoverImage
          style={{
            backgroundImage: `url('${getPhoto()}')`,
          }}
          className={
            canVisitLogbook
              ? "valid-property cover-image"
              : "grayscale cover-image"
          }
        />

        {/* Sponsoring Company Logo */}
        {logbook.companyId > 0 ? (
          <>
            {companyForLogbook?.logoPath ? (
              <div
                className="company-logo-dashboard"
                style={{
                  height: 80,
                  width: 80,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url('${companyForLogbook.logoPath}?w=80&h=80&mode=pad')`,
                }}
              ></div>
            ) : (
              <div
                className="company-logo-dashboard"
                style={{
                  height: 80,
                  width: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={logo} alt="logo" />
              </div>
            )}
          </>
        ) : (
          <noscript />
        )}

        {/* Sponsored label */}
        <Visible xs sm>
          <LogbookSponsorshipStatus
            className="mobile"
            isLogbookBeingTransferredToAnotherOwner={
              isLogbookBeingTransferredToAnotherOwner
            }
            hasLogbookBeenTransferredToAnotherOwner={
              hasLogbookBeenTransferredToAnotherOwner
            }
            isSponsoredLogbook={isSponsoredLogbook}
            isSponsorshipExpiring={isSponsorshipExpiring}
            hasSponsorshipExpired={hasSponsorshipExpired}
          />
        </Visible>
      </Col>

      <Col xs={12} sm={7}>
        <div className="flex col padding-2 padding-left-3 info">
          <label className="property-type flex">
            <LogbookTypeIcon>
              <img
                src={LOGBOOK_TYPE_TO_ICON_MAPPING[logbook.logbookType]}
                alt={logbook.logbookType}
              />
            </LogbookTypeIcon>
            {LOGBOOK_TYPE_TO_TITLE_MAPPING[logbook.logbookType]}

            {logbook.logbookType === "Property" ? (
              `  |  ${logbook.propertyType}`
            ) : (
              <noscript />
            )}
          </label>
          <p className="property-name margin-left-1">{logbook.description}</p>
          {logbook.serialNumber ? (
            <label
              style={{
                marginTop: 4,
                fontSize: "16px",
                lineHeight: "22px",
                marginLeft: 8,
              }}
            >
              {logbook.serialNumber}
            </label>
          ) : (
            <noscript />
          )}

          {hasValidPlan || isSponsoredLogbook ? (
            <div className="flex margin-top-2" style={{ width: "100%" }}>
              {hasLogbookBeenTransferredToAnotherOwner ? (
                <div className="flex top status">
                  <img
                    src={circleCheckmarkIcon}
                    alt="new logbook"
                    width={20}
                    className="margin-right-1"
                    style={{ marginTop: 4 }}
                  />

                  <p>
                    <strong>Logbook transferred</strong>
                    &nbsp;
                    <span>
                      on {acceptedOnDate.getDate()}/
                      {acceptedOnDate.getMonth() + 1}/
                      {acceptedOnDate.getFullYear()} to{" "}
                      {logbook.transfer?.to?.email}
                    </span>
                  </p>
                </div>
              ) : logbook.transfer?.status === "Transferring" ? (
                <div className="flex margin-left-1">
                  <img
                    src={transferringIcon}
                    alt="pending transfer"
                    width={20}
                    className="margin-right-1"
                  />

                  <strong>Pending transfer</strong>
                </div>
              ) : shouldShowLogbookAsNew ? (
                <div className="flex margin-left-1">
                  <img
                    src={circleCheckmarkIcon}
                    alt="new logbook"
                    width={20}
                    className="margin-right-1"
                  />

                  <strong>New logbook</strong>
                </div>
              ) : (
                <noscript />
              )}
            </div>
          ) : (
            <noscript />
          )}
        </div>
      </Col>

      <TopActions
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {logbook.documentStatistics.newCount > 0 ? (
          <NewItemsLabel>
            New file added
            <div className="new-items-count">
              {logbook.documentStatistics.newCount}
            </div>
          </NewItemsLabel>
        ) : (
          <noscript />
        )}

        {isCurrentUserAReadOnlyMemberForLogbook ? (
          <LogbookSponsorshipStatusContainer className="sponsored">
            Read Only
          </LogbookSponsorshipStatusContainer>
        ) : (
          <Hidden xs sm>
            <LogbookSponsorshipStatus
              isLogbookBeingTransferredToAnotherOwner={
                isLogbookBeingTransferredToAnotherOwner
              }
              hasLogbookBeenTransferredToAnotherOwner={
                hasLogbookBeenTransferredToAnotherOwner
              }
              isSponsoredLogbook={isSponsoredLogbook}
              isSponsorshipExpiring={isSponsorshipExpiring}
              hasSponsorshipExpired={hasSponsorshipExpired}
            />
          </Hidden>
        )}

        {(hasValidPlan || isSponsoredLogbook) &&
        !hasLogbookBeenTransferredToAnotherOwner ? (
          <>
            {isCurrentUserAReadOnlyMemberForLogbook ? (
              <noscript />
            ) : (
              <Hidden xs sm>
                <DownloadButton logbookId={logbook.id} />
              </Hidden>
            )}

            {isCurrentUserAReadOnlyMemberForLogbook ? (
              <noscript />
            ) : (
              <MoreMenuContainer>
                <MoreMenu
                  options={menuOptions}
                  onSelect={actionSelectedMenuOptions}
                />
              </MoreMenuContainer>
            )}
          </>
        ) : (
          <noscript />
        )}
      </TopActions>

      <CancelHandover
        isOpen={wantsToCancelLogbookHandover}
        onClose={() => setWantsToCancelLogbookHandover(false)}
        propertyId={logbook.id}
        receiverEmail={logbook.transfer?.to?.email}
      />

      <ShareLogbookOrContent
        isOpen={wantsToShareLogbook}
        onClose={() => {
          // ShareLogbookOrContent requires there to be a current logbook
          // so we set a current logbook and then clear it out after
          dispatch(setCurrentLogbook(null));

          // set dialog to close
          setWantsToShareLogbook(false);
        }}
      />
    </StyledLogbookRow>
  );
}

/**
 *
 * @typedef {Object} LogbookListProps
 * @property {inndox.Logbook[]} logbooks
 */

/**
 *
 * @param {LogbookListProps} props
 */
export default function LogbookList({ logbooks }) {
  const { session } = useAuth();
  const currentAccountEmail = session.email;
  const navigate = useNavigate();
  const paymentDetails = useSelector(selectPaymentDetails);
  const hasNeverSubscribed = paymentDetails?.planStatus === "None";

  if (!logbooks?.length) {
    return (
      <Row>
        <Col xs={12} className="document-empty-state">
          <img width="287" height="210" src={noFiles} alt="no-files-img" />
          <div className="flex col">
            <h1>No logbooks listed</h1>
            <p className="margin-top-2">
              You have no logbooks listed in your account just yet.
            </p>
            <button
              className="button button-primary margin-top-2"
              onClick={() => {
                if (hasNeverSubscribed) {
                  navigate(
                    "/" +
                      AccountRoutes.basePath.replace("/*", "") +
                      "/" +
                      AccountRoutes.addPaymentDetails +
                      "?source=owner"
                  );
                } else {
                  navigate(
                    "/" +
                      OwnerRoutes.basePath.replace("/*", "") +
                      "/" +
                      OwnerRoutes.newLogbookType
                  );
                }
              }}
            >
              Add my first logbook
            </button>
          </div>
        </Col>
      </Row>
    );
  }

  const logbooksPendingAcceptance = logbooks.filter(
    (logbook) =>
      logbook.transfer?.to.email === currentAccountEmail &&
      logbook.transfer?.status === "Transferring"
  );
  const logbooksPendingAcceptanceIds = logbooksPendingAcceptance.map(
    (logbook) => logbook.id
  );
  const otherLogbooks = logbooks.filter(
    (logbook) => !logbooksPendingAcceptanceIds.includes(logbook.id)
  );

  return [...logbooksPendingAcceptance, ...otherLogbooks].map((logbook) => (
    <LogbookRowItem logbook={logbook} key={logbook.id} />
  ));
}

function DownloadButton({ logbookId }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <DownloadButtonBase
        id={`download-${logbookId}-files`}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => DocumentsApi.downloadDocumentsForProperty(logbookId)}
      >
        <img
          src={isHovering ? downloadGreenIcon : downloadGreyIcon}
          alt="download all files"
        />
      </DownloadButtonBase>

      <Tooltip
        anchorId={`download-${logbookId}-files`}
        style={{
          borderRadius: 4,
          textTransform: "none",
          padding: 8,
          fontFamily: "ProjectFont",
          fontSize: "12px",
          lineHeight: "18px",
          letterSpacing: "normal",
          maxWidth: 250,
        }}
        place="top"
      >
        Download all files
      </Tooltip>
    </>
  );
}

const LogbookSponsorshipStatusContainer = styled.div`
  padding: 2px 4px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-weight: 700;

  img {
    margin-right: 4px;
  }

  &.sponsored {
    background-color: #f2f9ff;
    color: #007be5;
  }

  &.transferring {
    background-color: #fff7e3;
    color: #fecb48;
  }

  &.transferred {
    background-color: #e3f5dd;
    color: #468f2d;
  }

  &.expiring {
    background-color: #fff8e4;
    color: #ff640d;
  }

  &.expired {
    background-color: #d3d7e3;
    color: #203d5e;
  }

  &.mobile {
    position: absolute;
    bottom: 8px;
    left: 8px;
  }
`;

/**
 *
 * @typedef {Object} LogbookSponsorshipStatusProps
 * @property {boolean} hasLogbookBeenTransferredToAnotherOwner
 * @property {boolean} isLogbookBeingTransferredToAnotherOwner
 * @property {boolean} isSponsoredLogbook
 * @property {boolean} isSponsorshipExpiring
 * @property {boolean} hasSponsorshipExpired
 */

/**
 *
 * @param {LogbookSponsorshipStatusProps} props
 */

function LogbookSponsorshipStatus({
  isLogbookBeingTransferredToAnotherOwner,
  hasLogbookBeenTransferredToAnotherOwner,
  isSponsoredLogbook,
  isSponsorshipExpiring,
  hasSponsorshipExpired,
  className,
}) {
  const status = hasLogbookBeenTransferredToAnotherOwner
    ? "transferred"
    : isLogbookBeingTransferredToAnotherOwner
    ? "transferring"
    : hasSponsorshipExpired
    ? "expired"
    : isSponsorshipExpiring
    ? "expiring"
    : isSponsoredLogbook
    ? "sponsored"
    : "none";

  const content = hasLogbookBeenTransferredToAnotherOwner
    ? "Transferred"
    : isLogbookBeingTransferredToAnotherOwner
    ? "Transferring"
    : hasSponsorshipExpired
    ? "Sponsorship expired"
    : isSponsorshipExpiring
    ? "Sponsorship expiring"
    : isSponsoredLogbook
    ? "Sponsored"
    : "none";

  if (status === "none") {
    return <noscript />;
  }

  return (
    <LogbookSponsorshipStatusContainer className={`${status} ${className}`}>
      {status === "expired" ? (
        <img src={expiredWarningIcon} alt="expired" />
      ) : status === "expiring" ? (
        <img src={expiringWarningIcon} alt="expiring" />
      ) : (
        <noscript />
      )}

      {content}
    </LogbookSponsorshipStatusContainer>
  );
}
