import Modal from "./modal";
import shareIcon from "../assets/images/share.svg";
import { Field, Form } from "react-final-form";
import styled from "styled-components";
import circleCheckedIcon from "../assets/images/circle-checked.svg";
import circleCheckedSelectedIcon from "../assets/images/circle-checked-selected.svg";
import copyIcon from "../assets/images/copy.svg";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";
import Checkbox, { FormlessCheckbox } from "./form/checkbox";
import { handleError } from "./helpers";
import { toast } from "react-toastify";
import Toast from "./toast";
import { FoldersApi } from "../api/folders";
import { LogbooksApi } from "../api/properties";
import {
  removeLogbookPermission,
  selectCurrentLogbook,
  setCurrentLogbook,
  updateCurrentLogbook,
  updateLogbookFolder,
} from "../store/features/logbooks.slice";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import backIcon from "../assets/images/back.svg";
import { isCurrentUserACompany } from "../store/features/user.slice";
import { FormlessSelect } from "./form/select";
import axios from "axios";
import { Description } from "./form/label";
import { emailRegex } from "./form/validators";

const Separator = styled.hr`
  height: 1px;
  border: none;
  background: #d8d8d8;
  margin-top: 12px;
  /* margin-bottom: 12px; */
`;

const LogbookShareOptionContainer = styled.div`
  width: 100%;
  height: 98px;
  background: #ffffff;
  border: 1px solid #d3d7e3;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  &.selected {
    background: #f5f9e1;
    border: 1px solid #bfd62f;
    border-radius: 10px;
  }

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const LogbookShareOptionContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 16px;
`;

const LogbookShareOptionTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2d3540;
`;

const LogbookShareOptionDescription = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: #2d3540;
  margin-top: 4px;
`;

const CopyButton = styled.div`
  width: 132px;
  height: 44px;
  background-color: #e4e6ea;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bolder;
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #cdcfd3;
  }
`;

const EmailsInputField = styled.input`
  flex-grow: 1;
  border: none;
  padding: 4px 8px;
  font-family: ProjectFont;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
`;

function LogbookShareOption({ name, title, description, value }) {
  return (
    <Field name={name} type="radio" value={value}>
      {({ input }) => (
        <LogbookShareOptionContainer
          className={input.checked ? "selected" : ""}
        >
          <input {...input} />

          <LogbookShareOptionContent>
            <LogbookShareOptionTitle>{title}</LogbookShareOptionTitle>
            <LogbookShareOptionDescription>
              {description}
            </LogbookShareOptionDescription>
          </LogbookShareOptionContent>

          <img
            src={input.checked ? circleCheckedSelectedIcon : circleCheckedIcon}
            alt="checked or not"
          />
        </LogbookShareOptionContainer>
      )}
    </Field>
  );
}

/**
 *
 * @param {inndox.LogbookPermission[]} [permissions]
 */
const createPermissionList = (permissions) => {
  if (!permissions?.length) {
    return undefined;
  }

  switch (permissions.length) {
    case 1: {
      return permissions[0].name || permissions[0].email;
    }

    case 2:
    case 3: {
      return (
        permissions
          .slice(0, permissions.length - 1)
          .map((permission) => permission.name || permission.email)
          .join(", ") +
        " and " +
        (permissions[permissions.length - 1].name ||
          permissions[permissions.length - 1].email)
      );
    }

    default: {
      return (
        permissions
          .slice(0, 3)
          .map((permission) => permission.name || permission.email)
          .join(", ") + ` + ${permissions.length - 3} others`
      );
    }
  }
};

const PermissionList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }

  p,
  button {
    font-size: 15px;
  }

  button {
    padding-left: 0;
    padding-right: 0;
  }
`;

/**
 * @typedef {Object} LogbookShareSettingItemProps
 * @property {inndox.LogbookPermission} permission
 * @property {number} logbookId
 */

/**
 *
 * @param {LogbookShareSettingItemProps} props
 * @returns
 */
function LogbookShareSettingItem({ permission, logbookId }) {
  const dispatch = useDispatch();

  return (
    <Col
      xs={12}
      className="flex between margin-bottom-2"
      style={{
        borderBottom: "1px solid #DDE0E3",
      }}
    >
      <div className="flex col" style={{ height: 80 }}>
        <p>{permission.name || "Not provided"}</p>
        <p className="helper-text">{permission.email}</p>
      </div>

      <Form
        onSubmit={async () => {
          try {
            await LogbooksApi.unshareLogbook(logbookId, permission.id);
            dispatch(removeLogbookPermission(permission.id));
          } catch (e) {
            handleError(e);
          }
        }}
        render={(props) => (
          <button
            className="button button-remove"
            onClick={props.submitting ? undefined : props.handleSubmit}
          >
            {props.submitting ? (
              <ClipLoader loading size={16} color="#FF3E3E" />
            ) : (
              "Remove"
            )}
          </button>
        )}
      />
    </Col>
  );
}

/**
 * @typedef {Object} ShareLogbookOrContentProps
 * @property {inndox.Folder} [folder]
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */

/**
 *
 * @param {ShareLogbookOrContentProps} props
 */
export default function ShareLogbookOrContent({ isOpen, onClose, folder }) {
  if (!!folder) {
    // only share folder
    return <ShareFolder isOpen={isOpen} onClose={onClose} folder={folder} />;
  }

  return <ShareLogbookOrFolders isOpen={isOpen} onClose={onClose} />;
}

function ShareLogbookOrFolders({ isOpen, onClose }) {
  const [wantsToViewSharingSettings, setWantsToViewSharingSettings] =
    useState(false);
  const logbook = useSelector(selectCurrentLogbook);
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const shouldShowContentTypeSelector =
    isCompanyUser || wantsToViewSharingSettings
      ? false
      : logbook?.folders?.length;
  const [wantsToShareSpecificFolder, setWantsToShareSpecificFolder] = useState(
    isCompanyUser ? true : false
  );
  const [isFetchingLogbookDetails, setIsFetchingLogbookDetails] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen && logbook && !logbook.permissions?.length) {
      async function fetchLogbookDetails() {
        setIsFetchingLogbookDetails(true);

        const logbookDetails = await LogbooksApi.fetchLogbook(logbook.id);
        dispatch(setCurrentLogbook(logbookDetails));

        setIsFetchingLogbookDetails(false);
      }
      fetchLogbookDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      title={
        wantsToViewSharingSettings ? (
          <>
            <img
              src={backIcon}
              alt="share"
              style={{ marginRight: 8, cursor: "pointer" }}
              onClick={() => setWantsToViewSharingSettings(false)}
            />
            Logbook sharing settings
          </>
        ) : (
          <>
            <img src={shareIcon} alt="share" style={{ marginRight: 8 }} />
            {isCompanyUser ? "Share folder(s)" : "Share"}
          </>
        )
      }
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setWantsToViewSharingSettings(false);
      }}
    >
      {isFetchingLogbookDetails ? (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
          }}
        >
          <ClipLoader size={24} color="#a0b42a" />
        </div>
      ) : (
        <>
          {/* Radios */}
          {shouldShowContentTypeSelector ? (
            <ShareTypeSelector
              onSelectionChanged={setWantsToShareSpecificFolder}
            />
          ) : (
            <noscript />
          )}

          {wantsToShareSpecificFolder ? (
            <ShareFolders logbook={logbook} />
          ) : (
            <ShareLogbook
              logbook={logbook}
              viewShareSettings={wantsToViewSharingSettings}
              onViewShareSettings={() => setWantsToViewSharingSettings(true)}
            />
          )}
        </>
      )}
    </Modal>
  );
}

function ShareTypeSelector({ onSelectionChanged }) {
  const [wantsToShareSpecificFolder, setWantsToShareSpecificFolder] =
    useState(false);

  useEffect(() => {
    onSelectionChanged(wantsToShareSpecificFolder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToShareSpecificFolder]);

  return (
    <div className="radios dashboard">
      <div className="form-element new-radio">
        <div className="form-input">
          <input
            name="myProperties"
            readOnly
            type="radio"
            checked={!wantsToShareSpecificFolder}
            onChange={
              !wantsToShareSpecificFolder
                ? undefined
                : () => setWantsToShareSpecificFolder((_) => !_)
            }
          />
          <label htmlFor="myProperties">Logbook</label>
        </div>
      </div>
      <div className="form-element new-radio">
        <div className="form-input">
          <input
            name="myProperties"
            readOnly
            type="radio"
            checked={wantsToShareSpecificFolder}
            onChange={
              !wantsToShareSpecificFolder
                ? () => setWantsToShareSpecificFolder((_) => !_)
                : undefined
            }
          />
          <label htmlFor="myProperties">Folders</label>
        </div>
      </div>
    </div>
  );
}

function ShareLogbook({ logbook, onViewShareSettings, viewShareSettings }) {
  const dispatch = useDispatch();
  const permissionsList = createPermissionList(logbook.permissions);

  return (
    <Form
      initialValues={{
        shareType: "read",
      }}
      onSubmit={async (values, form) => {
        try {
          const emailsEntered = values?.emails?.split(",");
          const validEmails = [];
          const invalidEmails = [];

          emailsEntered?.forEach((emailEntered) => {
            if (emailEntered.match(emailRegex)) {
              validEmails.push(emailEntered);
            } else {
              invalidEmails.push(emailEntered);
            }
          });

          if (!validEmails.length) {
            if (invalidEmails.length) {
              toast.error(
                <Toast
                  title="Please enter at least one valid email address to continue with sharing."
                  details={`You entered these invalid emails: ${invalidEmails.join(
                    ", "
                  )}`}
                />
              );
            } else {
              toast.error(
                <Toast title="Please enter at least one email address to continue with sharing." />
              );
            }

            return;
          }

          // share entire logbook
          const emails = validEmails;

          await axios.all(
            emails.map((email) =>
              LogbooksApi.shareLogbook(logbook.id, {
                id: logbook.id,
                email,
                fullaccess: values.shareType === "write",
              })
            )
          );

          // fetch updated logbook
          const updatedLogbook = await LogbooksApi.fetchLogbook(logbook.id);
          dispatch(updateCurrentLogbook(updatedLogbook));

          // reset form
          form.reset();

          // notification
          toast.success(
            <Toast title={"Success! Your logbook has been shared."} />
          );
        } catch (e) {
          handleError(e);
        }
      }}
      render={(props) => {
        if (viewShareSettings) {
          return (
            <div>
              <Row style={{ maxHeight: 350, overflow: "auto" }}>
                {logbook.permissions.map((permission) => (
                  <LogbookShareSettingItem
                    key={`permission-${permission.id}`}
                    permission={permission}
                    logbookId={logbook.id}
                  />
                ))}
              </Row>
            </div>
          );
        }

        return (
          <>
            {/* Multi Enter Text Field */}
            <MultipleEmailField
              name="emails"
              className="margin-top-2"
              placeholder="Add people, separate by comma."
              style={{
                height: 52,
                transition: "all 100ms",
                backgroundColor: "#fff",
                borderRadius: 4,
                border: "1px solid #DDE0E3",
                width: "100%",
              }}
            />

            {permissionsList ? (
              <PermissionList className="margin-top-2">
                <p className="margin-right-2">
                  <strong>Shared with</strong> {permissionsList}
                </p>

                <button
                  className="button button-link"
                  style={{ flexShrink: 0 }}
                  onClick={onViewShareSettings}
                >
                  Sharing settings
                </button>
              </PermissionList>
            ) : (
              <noscript />
            )}

            <Separator />

            <div className="margin-top-2">
              <label>What to share</label>
            </div>
            <LogbookShareOption
              name="shareType"
              title="Invite with limited access"
              description="They’ll be invited to this logbook as a read only member."
              value="read"
            />
            <LogbookShareOption
              name="shareType"
              title="Invite to entire logbook"
              description="They’ll be invited to this logbook as a full member and access all files and folders"
              value="write"
            />

            <button
              className="button button-primary button-large button-big margin-top-3"
              style={{ width: 160 }}
              onClick={props.submitting ? undefined : props.handleSubmit}
            >
              {props.submitting ? (
                <ClipLoader loading size={16} color="#fff" />
              ) : (
                "Share"
              )}
            </button>
          </>
        );
      }}
    />
  );
}

function MultipleEmailField({
  name,
  style,
  className,
  placeholder = "Email(s)",
}) {
  return (
    <Field name={name}>
      {({ input }) => {
        return (
          <EmailsInputField
            {...input}
            type="email"
            multiple
            {...{ style, className, placeholder }}
          />
        );
      }}
    </Field>
  );
}

function ShareFolders({ logbook }) {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(undefined);

  const menuItems = [
    { label: "can view", value: "Read" },
    { label: "can upload", value: "Create" },
  ];

  return (
    <Form
      onSubmit={async (values, form) => {
        const emailsEntered = values?.emails?.split(",");
        const validEmails = [];
        const invalidEmails = [];

        emailsEntered?.forEach((emailEntered) => {
          if (emailEntered.match(emailRegex)) {
            validEmails.push(emailEntered);
          } else {
            invalidEmails.push(emailEntered);
          }
        });

        if (!validEmails.length) {
          if (invalidEmails.length) {
            toast.error(
              <Toast
                title="Please enter at least one valid email address to continue with sharing."
                details={`You entered these invalid emails: ${invalidEmails.join(
                  ", "
                )}`}
              />
            );
          } else {
            toast.error(
              <Toast title="Please enter at least one email address to continue with sharing." />
            );
          }

          return;
        }

        // share individual folders
        try {
          const folderIdFormValues = values.folderIds || {};
          if (Object.keys(folderIdFormValues).length > 0) {
            const folderIds = Object.keys(folderIdFormValues)
              .filter(
                (folderIdFormValueKey) =>
                  folderIdFormValues[folderIdFormValueKey] === true
              )
              .map((folderIdFormValueKey) =>
                parseInt(folderIdFormValueKey.replace("folder-", ""))
              );

            // share entire logbook
            const emails = validEmails;

            // Collect all the share requests
            const shareRequests = [];
            emails.forEach((email) => {
              folderIds.forEach((folderId) => {
                shareRequests.push(
                  FoldersApi.shareFolderWithPermissions(logbook.id, {
                    folderId,
                    propertyId: logbook.id,
                    emailAddress: email,
                    accountType: 2,
                    allowUpload: permission === "Create",
                  })
                );
              });
            });

            // execute all the share requests
            await axios.all(shareRequests);

            const updatedLogbook = await LogbooksApi.fetchLogbook(logbook.id);
            dispatch(updateCurrentLogbook(updatedLogbook));

            // reset form
            form.reset();

            toast.success(
              <Toast title="Success! The selected folder(s) have been shared" />
            );
          } else {
            toast.error(
              <Toast title="You must select at least one folder to share" />
            );
          }
        } catch (e) {
          handleError(e);
        }
      }}
      mutators={{
        setFieldValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={(props) => (
        <>
          <label style={{ display: "block", marginTop: 16, marginBottom: 8 }}>
            Email
          </label>
          <Description>Add emails separated by comma.</Description>
          <EmailsContainer>
            {/* Multi Enter Text Field */}
            <MultipleEmailField name="emails" />

            <PermissionSelector>
              <FormlessSelect
                clas
                input={{
                  value:
                    permission === "Create"
                      ? { label: "can upload", value: "Create" }
                      : { label: "can view", value: "Read" },
                  onChange: (selectedItem) => setPermission(selectedItem.value),
                }}
                mini
                items={menuItems}
                defaultValue={
                  permission === "Create"
                    ? { label: "can upload", value: "Create" }
                    : { label: "can view", value: "Read" }
                }
              />
            </PermissionSelector>
          </EmailsContainer>

          <div className="margin-top-2">
            <label>Folders</label>
            <div style={{ height: 204, overflow: "auto" }}>
              <FormlessCheckbox
                label="Select all folders"
                description="Only people with access can view these folders."
                className="margin-top-1"
                onChange={(isChecked) => {
                  logbook?.folders.forEach((folder) => {
                    props.form.mutators.setFieldValue(
                      `folderIds.folder-${folder.id}`,
                      isChecked
                    );
                  });
                }}
              />

              {logbook?.folders
                .filter((folder) => !folder.isPrivate)
                .map((folder) => (
                  <Checkbox
                    key={`folder-${folder.id}`}
                    name={`folderIds.folder-${folder.id}`}
                    label={folder.name}
                    className="margin-top-1"
                  />
                ))}
            </div>
          </div>

          <button
            className="button button-primary button-large button-big margin-top-3"
            style={{ width: 160 }}
            onClick={props.submitting ? undefined : props.handleSubmit}
          >
            {props.submitting ? (
              <ClipLoader loading size={16} color="#fff" />
            ) : (
              "Share"
            )}
          </button>
        </>
      )}
    />
  );
}

const ShareFolderPermissionList = styled.div`
  max-height: 300px;
  overflow: auto;
  margin-right: -16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-right: -8px;
    padding-right: 8px;
    max-height: 350px;
  }

  .react-select__value-container {
    text-align: right;

    .react-select__single-value {
      font-weight: 700;
      color: #adadb0;
    }
  }

  .react-select__menu-list .react-select__option {
    height: 28px;
    padding-top: unset;
    padding-bottom: unset;
    min-height: unset;

    &.react-select__option--is-selected {
      &:before {
        top: 14px;
        left: 12.5px;
      }

      &:after {
        top: 8px;
        left: 19px;
      }
    }
  }
`;

const EmailsContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #dde0e3;
  background: #fff;
  min-height: 52px;
  display: flex;

  .form-element > div {
    border: none;
  }
`;

const PermissionSelector = styled.div`
  width: 150px;
  flex-shrink: 0;

  > div {
    background: transparent;
  }

  .react-select__value-container {
    text-align: right;

    .react-select__single-value {
      font-weight: 700;
      color: #adadb0;
    }
  }

  .react-select__menu-list .react-select__option {
    height: 28px;
    padding-top: unset;
    padding-bottom: unset;
    min-height: unset;

    &.react-select__option--is-selected {
      &:before {
        top: 14px;
        left: 12.5px;
      }

      &:after {
        top: 8px;
        left: 19px;
      }
    }
  }

  .react-select__indicators {
    margin-right: 8px !important;
  }
`;

/**
 * @typedef {Object} ShareFolderProps
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {inndox.Folder} folder
 */

/**
 *
 * @param {ShareFolderProps} props
 * @returns
 */
export function ShareFolder({ isOpen, onClose, folder }) {
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(undefined);

  const menuItems = [
    { label: "can view", value: "Read" },
    { label: "can upload", value: "Create" },
  ];

  const copyFolderShareLink = () => {
    if (folder.publicShareLink) {
      navigator.clipboard.writeText(folder.publicShareLink);
      toast.success(<Toast title="Folder share link copied" />);
    }
  };

  return (
    <Form
      onSubmit={async (values, form) => {
        try {
          const emailsEntered = values?.emails?.split(",");
          const validEmails = [];
          const invalidEmails = [];

          emailsEntered?.forEach((emailEntered) => {
            if (emailEntered.match(emailRegex)) {
              validEmails.push(emailEntered);
            } else {
              invalidEmails.push(emailEntered);
            }
          });

          if (!validEmails.length) {
            if (invalidEmails.length) {
              toast.error(
                <Toast
                  title="Please enter at least one valid email address to continue with sharing."
                  details={`You entered these invalid emails: ${invalidEmails.join(
                    ", "
                  )}`}
                />
              );
            } else {
              toast.error(
                <Toast title="Please enter at least one email address to continue with sharing." />
              );
            }

            return;
          }

          const emails = validEmails;

          await axios.all(
            emails.map((email) =>
              FoldersApi.shareFolderWithPermissions(folder.propertyId, {
                folderId: folder.id,
                propertyId: folder.propertyId,
                emailAddress: email,
                accountType: "User",
                allowUpload: permission === "Create",
                allFolders: false,
              })
            )
          );

          const updatedFolder = await FoldersApi.fetchFolder(
            folder.propertyId,
            folder.id
          );
          dispatch(updateLogbookFolder(updatedFolder));

          toast.success(<Toast title="Success! The folder has been shared." />);

          form.reset();
        } catch (e) {
          handleError(e);
        }
      }}
      mutators={{
        setFieldValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={(props) => (
        <Modal
          title={
            <>
              {props.values.emails?.length ? (
                <img
                  src={backIcon}
                  alt="share"
                  style={{ marginRight: 8, cursor: "pointer" }}
                  onClick={() =>
                    props.form.mutators.setFieldValue("emails", "")
                  }
                />
              ) : (
                <img src={shareIcon} alt="share" style={{ marginRight: 8 }} />
              )}
              Share folder
            </>
          }
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
        >
          <label style={{ display: "block", marginTop: 16, marginBottom: 8 }}>
            Email
          </label>
          <Description>Add emails separated by comma.</Description>
          <EmailsContainer>
            {/* Multi Enter Text Field */}
            <MultipleEmailField name="emails" />

            <PermissionSelector>
              <FormlessSelect
                input={{
                  value:
                    permission === "Create"
                      ? { label: "can upload", value: "Create" }
                      : { label: "can view", value: "Read" },
                  onChange: (selectedItem) => setPermission(selectedItem.value),
                }}
                mini
                items={menuItems}
                defaultValue={
                  permission === "Create"
                    ? { label: "can upload", value: "Create" }
                    : { label: "can view", value: "Read" }
                }
              />
            </PermissionSelector>
          </EmailsContainer>

          {props.values.emails?.length ? (
            <Row>
              <Col xs={12}>
                <button
                  className="button button-primary margin-top-3"
                  style={{ width: 120 }}
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Share"
                  )}
                </button>

                <button
                  className="button button-remove margin-top-3"
                  onClick={() => {
                    props.form.mutators.setFieldValue("emails", "");
                  }}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          ) : (
            <>
              <label style={{ marginTop: 20, display: "block" }}>
                People with access
              </label>
              {folder.sharePermissions.length ? <Separator /> : <noscript />}

              <ShareFolderPermissionList>
                {folder.sharePermissions.map((permission) => (
                  <FolderSharePermissionItem
                    key={permission.id}
                    permission={permission}
                  />
                ))}
              </ShareFolderPermissionList>

              {props.values.emails?.length ? (
                <noscript />
              ) : (
                <CopyButton onClick={copyFolderShareLink}>
                  <img src={copyIcon} alt="copy" style={{ marginRight: 13 }} />
                  Copy link
                </CopyButton>
              )}
            </>
          )}
        </Modal>
      )}
    />
  );
}

/**
 *
 * @typedef {Object} FolderSharePermissionItemProps
 * @property {inndox.FolderSharePermission} permission
 */
/**
 *
 * @param {FolderSharePermissionItemProps} props
 */
function FolderSharePermissionItem({ permission }) {
  const [isModifyingFolderPermission, setWantsToModifyFolderPermission] =
    useState(false);
  const dispatch = useDispatch();

  const grantViewAccess = async (permissionId) => {
    try {
      setWantsToModifyFolderPermission(true);
      const updatedFolder = await FoldersApi.updateFolderSharePermission(
        permissionId,
        false
      );
      dispatch(updateLogbookFolder(updatedFolder));
      toast.success(<Toast title="Success! Access has been updated." />);
      setWantsToModifyFolderPermission(false);
    } catch (e) {
      handleError(e);
    }
  };

  const grantUploadAcess = async (permissionId) => {
    try {
      setWantsToModifyFolderPermission(true);
      const updatedFolder = await FoldersApi.updateFolderSharePermission(
        permissionId,
        true
      );
      dispatch(updateLogbookFolder(updatedFolder));
      toast.success(<Toast title="Success! Access has been updated." />);
      setWantsToModifyFolderPermission(false);
    } catch (e) {
      handleError(e);
    }
  };

  const removeAccess = async (permissionId) => {
    try {
      setWantsToModifyFolderPermission(true);
      const updatedFolder = await FoldersApi.removeFolderSharePermission(
        permissionId
      );
      dispatch(updateLogbookFolder(updatedFolder));
      toast.success(<Toast title="Success! Access has been removed." />);
      setWantsToModifyFolderPermission(false);
    } catch (e) {
      handleError(e);
    }
  };

  const onSelectOption = (permissionId, option) => {
    switch (option) {
      case "Create": {
        grantUploadAcess(permissionId);
        break;
      }

      case "Read": {
        grantViewAccess(permissionId);
        break;
      }

      case "Remove": {
        removeAccess(permissionId);
        break;
      }

      default:
        break;
    }
  };

  const menuItems = [
    { label: "can view", value: "Read" },
    { label: "can upload", value: "Create" },
    { label: "Remove", value: "Remove" },
  ];

  return (
    <div
      key={permission.id}
      className="flex between"
      style={{
        borderBottom: "solid 1px #dde0e3",
        minHeight: 56,
      }}
    >
      <div className="user-details">
        {permission.sharedWith.name ? (
          <p className="name">{permission.sharedWith.name}</p>
        ) : (
          <a
            href={`mailto:${permission.sharedWith.email}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#2D3540", display: "block" }}
          >
            {permission.sharedWith.email}
          </a>
        )}

        {permission.sharedWith.name ? (
          <a
            href={`mailto:${permission.sharedWith.email}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#adadb0", fontSize: "14px", display: "block" }}
          >
            {permission.sharedWith.email}
          </a>
        ) : (
          <noscript />
        )}
      </div>

      {isModifyingFolderPermission ? (
        <ClipLoader loading size={16} color="#bdd23f" />
      ) : (
        <div style={{ width: 150 }}>
          <FormlessSelect
            input={{
              value: permission.permissions.includes("Create")
                ? { label: "can upload", value: "Create" }
                : { label: "can view", value: "Read" },
              onChange: (selectedItem) =>
                onSelectOption(permission.id, selectedItem.value),
            }}
            mini
            items={menuItems}
            defaultValue={
              permission.permissions.includes("Create")
                ? { label: "can upload", value: "Create" }
                : { label: "can view", value: "Read" }
            }
          />
        </div>
      )}
    </div>
  );
}
