import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
  defects: null,
  defectStatistics: null,
  contacts: null,
  subCompanies: null,
  users: null,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.current = action.payload;
    },
    updateCurrentCompany: (state, action) => {
      state.current = {
        ...state.current,
        ...action.payload,
      };
    },
    setCompanyDefects: (state, action) => {
      state.defects = action.payload;
    },
    addDefectToCompany: (state, action) => {
      state.defects.push(action.payload);
    },
    updateCompanyDefect: (state, action) => {
      state.defects = state.defects.map((defect) => {
        if (defect.id === action.payload.id) {
          return {
            ...defect,
            ...action.payload,
          };
        }

        return defect;
      });
    },
    removeCompanyDefect: (state, action) => {
      state.defects = state.defects.filter(
        (defect) => defect.id !== action.payload,
      );
    },
    setDefectStatistics: (state, action) => {
      state.defectStatistics = action.payload;
    },
    setCompanyContacts: (state, action) => {
      state.contacts = action.payload;
    },
    addCompanyContact: (state, action) => {
      state.contacts.push(action.payload);
    },
    updateCompanyContact: (state, action) => {
      state.contacts = state.contacts.map((contact) => {
        if (contact.id === action.payload.id) {
          return {
            ...contact,
            ...action.payload,
          };
        }

        return contact;
      });
    },
    removeCompanyContact: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.payload,
      );
    },
    setSubCompanies: (state, action) => {
      state.subCompanies = action.payload;
    },
    setCompanyUsers: (state, action) => {
      state.users = action.payload;
    },
    removeCompanyUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
});

// Actions
export const {
  setCompany,
  updateCurrentCompany,
  setCompanyContacts,
  setCompanyDefects,
  setSubCompanies,
  setDefectStatistics,
  updateCompanyDefect,
  removeCompanyDefect,
  addCompanyContact,
  updateCompanyContact,
  removeCompanyContact,
  addDefectToCompany,
  setCompanyUsers,
  removeCompanyUser,
} = companySlice.actions;

/**
 *
 * @param {*} state
 * @returns {inndox.Company}
 */
export const selectCompany = (state) => state.company.current;

/**
 *
 * @param {*} state
 * @returns {inndox.LogbookDefect[]}
 */
export const selectCompanyDefects = (state) => state.company.defects;

/**
 *
 * @param {*} state
 * @returns {inndox.LogbookDefectStatistic[]}
 */
export const selectCompanyDefectsStatistics = (state) =>
  state.company.defectStatistics;

/**
 *
 * @param {*} state
 * @returns {inndox.Contact[]}
 */
export const selectCompanyContacts = (state) => state.company.contacts;

/**
 *
 * @param {*} state
 * @returns {inndox.Company[]}
 */
export const selectSubCompanies = (state) => state?.company?.subCompanies;

/**
 *
 * @param {*} state
 * @returns {inndox.CompanyUser[]}
 */
export const selectCompanyUsers = (state) => state.company.users;

export default companySlice.reducer;

export { initialState as companySliceInitialState };
