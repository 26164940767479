import moment from "moment";
import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Hidden, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { DocumentsApi } from "../../api/documents";
import checkIcon from "../../assets/images/small-check.svg";
import warningIcon from "../../assets/images/small-warning.svg";
import AddOrEditLinkModal from "../../components/add-or-edit-link.modal";
import { getFileIcon, handleError } from "../../components/helpers";
import InlineEditor from "../../components/inline-editor";
import Modal from "../../components/modal";
import MoreMenu, {
  ARCHIVE_DOCUMENT_MENU_ITEM,
  DELETE_DOCUMENT_MENU_ITEM,
  DIVIDER_MENU_ITEM,
  DOWNLOAD_DOCUMENT_MENU_ITEM,
  EDIT_DOCUMENT_LINK_MENU_ITEM,
  EDIT_WARRANTY_FOR_DOCUMENT_MENU_ITEM,
  MOVE_DOCUMENT_TO_LOGBOOK_ROOT_MENU_ITEM,
  MOVE_DOCUMENT_TO_PARENT_FOLDER_MENU_ITEM,
  VIEW_DOCUMENT_MENU_ITEM,
} from "../../components/more-menu";
import SetupBlock from "../../components/setup-block";
import {
  decrementLogbookFolderStatistics,
  decrementSharedLogbookFolderStatistics,
  removeDocumentFromSharedLogbook,
  removeLogbookDocument,
  setLogbookDocument,
  updateDocumentToSharedFolder,
  updateLogbookDocument,
  updateLogbookFolder,
} from "../../store/features/logbooks.slice";
import {
  decrementTemplateFolderStatistics,
  removeTemplateDocument,
  setTemplateDocument,
  updateTemplateDocument,
  updateTemplateFolder,
} from "../../store/features/templates.slice";
import { isCurrentUserACompany } from "../../store/features/user.slice";
import AddOrEditWarrantyModal from "./add-or-edit-warranty-modal";

/**
 *
 * @param {inndox.LogbookDocument} document
 */
function getDocumentWarrantyLabel(document) {
  if (!document?.warrantyExpiresOn) {
    return <noscript />;
  }

  const now = moment();
  const date = document.warrantyExpiresOn.endsWith("Z")
    ? moment(document.warrantyExpiresOn)
    : moment(document.warrantyExpiresOn + "Z");

  if (now.isBefore(date, "d")) {
    if (date.diff(now, "d") >= 90) {
      return (
        <span className="warranty-label valid">
          <img src={checkIcon} alt="check-icon" />
          In Warranty
        </span>
      );
    } else {
      return (
        <span className="warranty-label warning">
          <img src={warningIcon} alt="warning-icon" />
          Expiring Soon
        </span>
      );
    }
  } else {
    return (
      <span className="warranty-label expired">
        <img src={warningIcon} alt="warning-icon" />
        Warranty Expired
      </span>
    );
  }
}

/**
 *
 * @typedef {Object} DocumentRowItemProps
 * @property {inndox.LogbookDocument} document
 * @property {boolean} isInsideATemplate
 * @property {boolean} isInsideASharedFolder
 * @property {boolean} isPublic
 */

/**
 *
 * @param {DocumentRowItemProps} props
 */
function DocumentRowItem({
  document,
  isInsideATemplate,
  isInsideASharedFolder,
  isPublic,
}) {
  const dispatch = useDispatch();
  const isCompanyUser = useSelector(isCurrentUserACompany);
  const isDocumentArchived = !!document.dateArchived;
  const [wantsToAddOrEditWarranty, setWantsToAddOrEditWarranty] =
    useState(false);
  const [wantsEditLink, setWantsEditLink] = useState(false);
  const [wantsToArchive, setWantsToArchive] = useState(false);

  const updateDocumentName = async (updatedDocumentName) => {
    try {
      const updatedDocument = await DocumentsApi.updateDocumentName(
        document.propertyId,
        document.id,
        updatedDocumentName,
      );

      if (isInsideATemplate) {
        dispatch(updateTemplateDocument(updatedDocument));
      } else if (isInsideASharedFolder) {
        dispatch(updateDocumentToSharedFolder(updatedDocument));
      } else {
        dispatch(updateLogbookDocument(updatedDocument));
      }
    } catch (e) {
      handleError(e);
    }
  };

  const deleteDocument = async () => {
    try {
      await DocumentsApi.removeDocument(document.propertyId, document.id);

      if (isInsideATemplate) {
        dispatch(removeTemplateDocument(document.id));
        if (document.folderId) {
          dispatch(
            decrementTemplateFolderStatistics({ id: document.folderId }),
          );
        }
      } else if (isInsideASharedFolder) {
        dispatch(
          removeDocumentFromSharedLogbook({
            logbookId: document.propertyId,
            folderId: document.folderId,
            documentId: document.id,
          }),
        );
        if (document.folderId) {
          dispatch(
            decrementSharedLogbookFolderStatistics({
              propertyId: document.propertyId,
              folderId: document.folderId,
            }),
          );
        }
      } else {
        dispatch(removeLogbookDocument(document.id));
        if (document.folderId) {
          dispatch(decrementLogbookFolderStatistics({ id: document.folderId }));
        }
      }
    } catch (e) {
      handleError(e);
    }
  };

  const moveFileToDashboard = async () => {
    try {
      const updatedDocument = await DocumentsApi.moveDocumentToDashboard(
        document.propertyId,
        document.id,
      );

      if (isInsideATemplate) {
        dispatch(updateTemplateDocument(updatedDocument));
      } else {
        dispatch(updateLogbookDocument(updatedDocument));
      }
    } catch (e) {
      handleError(e);
    }
  };

  const moveFileToParentFolder = async () => {
    try {
      const updatedFolder = await DocumentsApi.moveDocumentToFolder(
        document.propertyId,
        document.id,
        document.folderId,
      );

      if (isInsideATemplate) {
        dispatch(updateTemplateFolder(updatedFolder));
        dispatch(
          setTemplateDocument({
            ...document,
            subfolderId: undefined,
          }),
        );
      } else {
        dispatch(updateLogbookFolder(updatedFolder));
        dispatch(
          setLogbookDocument({
            ...document,
            subfolderId: undefined,
          }),
        );
      }
    } catch (e) {
      handleError(e);
    }
  };

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case DOWNLOAD_DOCUMENT_MENU_ITEM.value: {
        // download document
        DocumentsApi.downloadDocument(document.propertyId, document.id);
        break;
      }

      case VIEW_DOCUMENT_MENU_ITEM.value: {
        // view link
        window.open(document.link, "_blank");
        break;
      }

      case EDIT_WARRANTY_FOR_DOCUMENT_MENU_ITEM.value: {
        // edit document warranty
        setWantsToAddOrEditWarranty(true);

        // if (isCompanyUser) {
        // 	navigate(
        // 		'/' +
        // 			CompanyRoutes.basePath.replace('/*', '') +
        // 			'/' +
        // 			CompanyRoutes.logbook.replace(':logbookId', document.propertyId) +
        // 			'/' +
        // 			CompanyRoutes.editWarranty.replace(':documentId', document.id)
        // 	);
        // } else {
        // 	navigate(
        // 		'/' +
        // 			OwnerRoutes.basePath.replace('/*', '') +
        // 			'/' +
        // 			OwnerRoutes.logbook.replace(':logbookId', document.propertyId) +
        // 			'/' +
        // 			OwnerRoutes.editWarranty.replace(':documentId', document.id)
        // 	);
        // }

        break;
      }

      case EDIT_DOCUMENT_LINK_MENU_ITEM.value: {
        // edit link
        setWantsEditLink(true);
        break;
      }

      case MOVE_DOCUMENT_TO_LOGBOOK_ROOT_MENU_ITEM.value: {
        // move document to root
        moveFileToDashboard();
        break;
      }

      case MOVE_DOCUMENT_TO_PARENT_FOLDER_MENU_ITEM.value: {
        // move document to parent folder
        moveFileToParentFolder();
        break;
      }

      case DELETE_DOCUMENT_MENU_ITEM.value: {
        // delete document
        deleteDocument();
        break;
      }

      case ARCHIVE_DOCUMENT_MENU_ITEM.value: {
        // archive document
        setWantsToArchive(true);
        break;
      }

      default:
        break;
    }
  };

  const onDragStart = (event) => {
    event.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "document",
        id: document.id,
      }),
    );
  };

  const documentIsLocked = !document.canDelete;
  const documentIsAFile = document.type === "File";
  const menuOptions = [VIEW_DOCUMENT_MENU_ITEM];
  if (isPublic) {
    if (documentIsAFile) {
      menuOptions.push(DOWNLOAD_DOCUMENT_MENU_ITEM);
    }
  } else {
    if (documentIsAFile) {
      if (!documentIsLocked) {
        menuOptions.push(EDIT_WARRANTY_FOR_DOCUMENT_MENU_ITEM);
      }
      menuOptions.push(DOWNLOAD_DOCUMENT_MENU_ITEM);
    } else {
      if (!documentIsLocked) {
        menuOptions.push(EDIT_DOCUMENT_LINK_MENU_ITEM);
      }
    }

    if (!isInsideASharedFolder && !documentIsLocked && document.folderId) {
      menuOptions.push(DIVIDER_MENU_ITEM);

      menuOptions.push(MOVE_DOCUMENT_TO_LOGBOOK_ROOT_MENU_ITEM);

      if (document.subfolderId) {
        menuOptions.push(MOVE_DOCUMENT_TO_PARENT_FOLDER_MENU_ITEM);
      }
    }

    if (!documentIsLocked) {
      menuOptions.push(DIVIDER_MENU_ITEM);
      menuOptions.push(DELETE_DOCUMENT_MENU_ITEM);

      if (!isInsideATemplate && !isCompanyUser) {
        menuOptions.push(ARCHIVE_DOCUMENT_MENU_ITEM);
      }
    }
  }

  if (!document.folderId && document.name === "unpacking.zip") {
    return <noscript />;
  }

  return (
    <Row
      draggable
      onDragStart={onDragStart}
      style={{
        cursor: document.folderId ? "unset" : "pointer",
      }}
    >
      <Col
        xs={10}
        sm={4}
        className={`col-body ${documentIsLocked ? "locked" : ""}`}
      >
        {getFileIcon(document, documentIsLocked)}

        <InlineEditor
          defaultValue={document.name}
          onConfirm={updateDocumentName}
          editable={!documentIsLocked}
          color={documentIsLocked ? "#ADADB0" : undefined}
        >
          {getDocumentWarrantyLabel(document)}
        </InlineEditor>
      </Col>

      <Hidden xs>
        <Col sm={2} className={`col-body ${documentIsLocked ? "locked" : ""}`}>
          <p className="truncate">{document.uploadedByDescription}</p>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className={`col-body ${documentIsLocked ? "locked" : ""}`}>
          <p className="truncate">
            {moment(document.dateCreated).format("DD/MM/YY")}
          </p>
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className={`col-body ${documentIsLocked ? "locked" : ""}`}>
          <p className="truncate">
            {document.warrantyExpiresOn
              ? document.warrantyExpiresOn.endsWith("Z")
                ? moment(document.warrantyExpiresOn).format("DD/MM/YY")
                : moment(document.warrantyExpiresOn + "Z").format("DD/MM/YY")
              : "-"}
          </p>
        </Col>
      </Hidden>

      <Col xs={2} className="col-body" style={{ justifyContent: "flex-end" }}>
        {isDocumentArchived ? (
          <Form
            onSubmit={async () => {
              try {
                await DocumentsApi.removeDocument(
                  document.propertyId,
                  document.id,
                );

                if (isInsideATemplate) {
                  dispatch(
                    updateTemplateDocument({
                      id: document.id,
                      dateArchived: undefined,
                    }),
                  );
                } else {
                  dispatch(
                    updateLogbookDocument({
                      id: document.id,
                      dateArchived: undefined,
                    }),
                  );
                }
              } catch (e) {
                handleError(e);
              }
            }}
            render={(props) => (
              <button
                className="button button-tertiary"
                style={{ padding: 0 }}
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#bdd23f" />
                ) : (
                  "Restore"
                )}
              </button>
            )}
          />
        ) : (
          <MoreMenu options={menuOptions} onSelect={actionSelectedMenuOption} />
        )}
      </Col>

      {/* Achive Document Modal */}
      <Modal
        isOpen={wantsToArchive}
        onClose={() => setWantsToArchive(false)}
        title="Archive document"
      >
        <p className="margin-top-1">
          This file will be moved within the <strong>Archived</strong> section
          which you can restore. Are you sure you want to proceed?
        </p>

        <Form
          onSubmit={async () => {
            try {
              await DocumentsApi.removeDocument(
                document.propertyId,
                document.id,
              );

              if (isInsideATemplate) {
                dispatch(
                  updateTemplateDocument({
                    id: document.id,
                    dateArchived: moment().toISOString(),
                  }),
                );
              } else {
                dispatch(
                  updateLogbookDocument({
                    id: document.id,
                    dateArchived: moment().toISOString(),
                  }),
                );
              }

              setWantsToArchive(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <button
              className="button button-archive margin-top-4"
              style={{ width: 160 }}
              onClick={props.submitting ? undefined : props.handleSubmit}
            >
              {props.submitting ? (
                <ClipLoader loading size={16} color="#fff" />
              ) : (
                "Archive"
              )}
            </button>
          )}
        />
      </Modal>

      {/* Add/Edit Warranty */}
      <AddOrEditWarrantyModal
        isOpen={wantsToAddOrEditWarranty}
        onClose={() => setWantsToAddOrEditWarranty(false)}
        document={document}
      />

      {/* Add or Edit Link */}
      <AddOrEditLinkModal
        isVisible={wantsEditLink}
        onClose={() => setWantsEditLink(false)}
        logbookId={document.propertyId}
        folderId={document.folderId}
        subFolderId={document.subfolderId}
        isPartOfTemplate={isInsideATemplate}
        isPartOfSharedFolder={isInsideASharedFolder}
        link={document}
      />
    </Row>
  );
}

function DocumentTableHeader() {
  return (
    <Row>
      <Col xs={10} sm={4} className="col-head">
        Name
      </Col>

      <Hidden xs>
        <Col sm={2} className="col-head">
          Submitter
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className="col-head">
          Date
        </Col>
      </Hidden>

      <Hidden xs>
        <Col sm={2} className="col-head">
          Warranty
        </Col>
      </Hidden>

      <Col xs={2} sm={2} className="col-head" />
    </Row>
  );
}

/**
 *
 * @typedef {Object} DocumentListProps
 * @property {inndox.LogbookDocument[]} documents
 * @property {boolean} documentsAreInsideAFolder
 * @property {boolean} showTitle
 * @property {boolean} isInsideATemplate
 * @property {boolean} isInsideASharedFolder
 * @property {boolean} isPublic
 * @property {HTMLDivElement["style"]} [style]
 */

/**
 *
 * @param {DocumentListProps} props
 */
export default function DocumentList({
  documents,
  showTitle,
  documentsAreInsideAFolder,
  isInsideATemplate,
  isInsideASharedFolder,
  isPublic,
  style,
}) {
  const titleElement = showTitle ? (
    <Row>
      <Col xs={12} id="files">
        <label>Files</label>
      </Col>
    </Row>
  ) : (
    <noscript />
  );

  if (!documents?.length) {
    if (documentsAreInsideAFolder) {
      return (
        <>
          {/* Title */}
          {titleElement}

          <div className="document-list" style={style}>
            <DocumentTableHeader />

            <Row>
              <Col xs={12} className="col-body">
                <SetupBlock description="There are currently no files within this folder." />
              </Col>
            </Row>
          </div>
        </>
      );
    }

    return <noscript />;
  }

  return (
    <>
      {/* Title */}
      {titleElement}

      <div className="document-list">
        {/* Header */}
        <DocumentTableHeader />

        {documents.map((document) => (
          <DocumentRowItem
            key={document.id}
            document={document}
            isInsideATemplate={isInsideATemplate}
            isInsideASharedFolder={isInsideASharedFolder}
            isPublic={isPublic}
          />
        ))}
      </div>
    </>
  );
}
