import DocumentTitle from "react-document-title";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/authentication";
import MoreMenu, { ADD_DEFECT_MENU_ITEM } from "../../components/more-menu";
import SetupBlock from "../../components/setup-block";
import { OwnerRoutes } from "../../routes";
import {
  selectCurrentLogbook,
  selectCurrentLogbookDefects,
} from "../../store/features/logbooks.slice";
import DefectList from "../common/defect-list";

export default function OwnerLogbookDefects() {
  const logbook = useSelector(selectCurrentLogbook);
  const defects = useSelector(selectCurrentLogbookDefects);
  const navigate = useNavigate();
  const { session } = useAuth();
  const isCurrentUserAReadOnlyMemberForLogbook =
    session.userId !== logbook.ownerId;

  const menuItems = [ADD_DEFECT_MENU_ITEM];

  const addNewDefect = () => {
    navigate(
      "/" +
        OwnerRoutes.basePath.replace("/*", "") +
        "/" +
        OwnerRoutes.logbook.replace(":logbookId", logbook.id) +
        "/" +
        OwnerRoutes.addLogbookDefect,
    );
  };

  const actionSelectedMenuOption = (value) => {
    switch (value) {
      case ADD_DEFECT_MENU_ITEM.value: {
        // add defect
        addNewDefect();
        break;
      }

      default:
        break;
    }
  };

  return (
    <DocumentTitle title="Logbook Issue Log | inndox">
      <Container className="folder">
        <Row className="margin-bottom-2">
          <Col xs={8} sm={6} className="flex">
            <Hidden xs>
              <h2 className="name">Logbook Issue Log</h2>
            </Hidden>

            <Visible xs>
              <h2 className="name">Logbook Issue Log</h2>
            </Visible>
          </Col>

          <Col xs={4} sm={6} className="flex end">
            {logbook.hasDefectManagement &&
            !isCurrentUserAReadOnlyMemberForLogbook ? (
              <>
                <Hidden xs>
                  <button
                    className="button button-primary margin-left-1"
                    onClick={addNewDefect}
                  >
                    New issue
                  </button>
                </Hidden>

                <Visible xs>
                  <MoreMenu
                    options={menuItems}
                    onSelect={actionSelectedMenuOption}
                  />
                </Visible>
              </>
            ) : (
              <noscript />
            )}
          </Col>
        </Row>

        {/* Defects List */}
        {defects.length ? (
          <DefectList defects={defects} source="logbook" />
        ) : (
          <SetupBlock description="This logbook has no filed issues on record." />
        )}
      </Container>
    </DocumentTitle>
  );
}
