import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import { UserSessionsApi } from "../../../api/user-sessions";
import { UsersApi } from "../../../api/users";
import TextField from "../../../components/form/text-field";
import {
  emailValidator,
  passwordValidator,
  requiredValidator,
} from "../../../components/form/validators";
import { handleError } from "../../../components/helpers";
import Modal from "../../../components/modal";
import Toast from "../../../components/toast";
import {
  selectAccount,
  setSession,
  updateUser,
} from "../../../store/features/user.slice";

const Header = styled.p`
  font-size: 20px;
  font-weight: bold;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    margin-top: 16px;
  }
`;

const InfoRow = styled(Row)`
  min-height: 56px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dde0e3;
`;

const InfoItem = styled(Col)`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  color: #bfbfbf;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const Value = styled.p`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function PersonalInfoSettings() {
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();
  const [wantsToUpdateName, setWantsToUpdateName] = useState(false);
  const [wantsToUpdateEmail, setWantsToUpdateEmail] = useState(false);
  const [wantsToUpdatePhone, setWantsToUpdatePhone] = useState(false);
  const [wantsToUpdatePassword, setWantsToUpdatePassword] = useState(false);
  const [wantsToDeleteAccount, setWantsToDeleteAccount] = useState(false);

  const { firstName, lastName, email, phone } = account;

  return (
    <>
      <Header>Personal Info</Header>

      {/* Name */}
      <InfoRow className="margin-top-3">
        <InfoItem xs={5} md={3}>
          <Label>Full name</Label>
        </InfoItem>
        <InfoItem xs={7} md={6}>
          <Value>
            {firstName} {lastName}
          </Value>
        </InfoItem>
        <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-secondary-link"
            style={{ padding: 0 }}
            onClick={() => setWantsToUpdateName(true)}
          >
            Update
          </button>
        </InfoItem>
      </InfoRow>

      <Modal
        title="Update Name"
        isOpen={wantsToUpdateName}
        onClose={() => setWantsToUpdateName(false)}
      >
        <Form
          initialValues={{ firstName, lastName, email, phone }}
          onSubmit={async (values) => {
            try {
              const updatedAccount = await UsersApi.updateCurrentUser(values);
              dispatch(updateUser(updatedAccount));
              toast.success(<Toast title="Your name has been updated" />);

              const updatedSession =
                await UserSessionsApi.fetchSessionForCurrentUser();
              dispatch(setSession(updatedSession));

              setWantsToUpdateName(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="firstName"
                label="First name"
                className="margin-top-2"
                required
                validate={requiredValidator}
              />

              <TextField
                name="lastName"
                label="Last name"
                className="margin-top-2"
                required
                validate={requiredValidator}
              />

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </>
          )}
        />
      </Modal>

      {/* Email */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Email</Label>
        </InfoItem>
        <InfoItem xs={7} md={6}>
          <Value>{email}</Value>
        </InfoItem>
        <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-secondary-link"
            style={{ padding: 0 }}
            onClick={() => setWantsToUpdateEmail(true)}
          >
            Update
          </button>
        </InfoItem>
      </InfoRow>

      <Modal
        title="Update Email"
        isOpen={wantsToUpdateEmail}
        onClose={() => setWantsToUpdateEmail(false)}
      >
        <Form
          initialValues={{ emailSpecified: true }}
          onSubmit={async (values) => {
            try {
              const updatedAccount = await UsersApi.updateLoginDetails(values);
              dispatch(updateUser(updatedAccount));
              toast.success(<Toast title="Your email has been updated" />);

              const updatedSession =
                await UserSessionsApi.fetchSessionForCurrentUser();
              dispatch(setSession(updatedSession));

              setWantsToUpdateEmail(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="email"
                label="New email address"
                className="margin-top-2"
                validate={emailValidator}
                required
              />

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </>
          )}
        />
      </Modal>

      {/* Password */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Password</Label>
        </InfoItem>
        <InfoItem xs={7} md={6}>
          <Value>*********</Value>
        </InfoItem>
        <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-secondary-link"
            style={{ padding: 0 }}
            onClick={() => setWantsToUpdatePassword(true)}
          >
            Update
          </button>
        </InfoItem>
      </InfoRow>

      <Modal
        title="Update Password"
        isOpen={wantsToUpdatePassword}
        onClose={() => setWantsToUpdatePassword(false)}
      >
        <Form
          initialValues={{ passwordSpecified: true }}
          onSubmit={async (values) => {
            try {
              // new password & confirm password match
              if (values["currentPassword"] === values["password"]) {
                throw new Error(
                  "New password can not be same as the current password",
                );
              }

              // new password & confirm password match
              if (values["password"] !== values["confirmPassword"]) {
                throw new Error("The new passwords do not match");
              }

              const updatedAccount = await UsersApi.updateLoginDetails(values);
              dispatch(updateUser(updatedAccount));
              toast.success(<Toast title="Your password has been updated" />);

              const updatedSession =
                await UserSessionsApi.fetchSessionForCurrentUser();
              dispatch(setSession(updatedSession));

              setWantsToUpdatePassword(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="currentPassword"
                label="Current password"
                type="password"
                className="margin-top-2"
                validate={passwordValidator}
                required
              />

              <TextField
                name="password"
                label="New password"
                type="password"
                className="margin-top-2"
                validate={passwordValidator}
                required
              />

              <TextField
                name="confirmPassword"
                label="Confirm new password"
                type="password"
                className="margin-top-2"
                validate={passwordValidator}
                required
              />

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </>
          )}
        />
      </Modal>

      {/* Phone */}
      <InfoRow>
        <InfoItem xs={5} md={3}>
          <Label>Phone Number</Label>
        </InfoItem>
        <InfoItem xs={7} md={6}>
          <Value>{phone}</Value>
        </InfoItem>
        <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-secondary-link"
            style={{ padding: 0 }}
            onClick={() => setWantsToUpdatePhone(true)}
          >
            Update
          </button>
        </InfoItem>
      </InfoRow>

      <Modal
        title="Update Phone Number"
        isOpen={wantsToUpdatePhone}
        onClose={() => setWantsToUpdatePhone(false)}
      >
        <Form
          initialValues={{ firstName, lastName, email, phone }}
          onSubmit={async (values) => {
            try {
              const updatedAccount = await UsersApi.updateCurrentUser(values);
              dispatch(updateUser(updatedAccount));
              toast.success(
                <Toast title="Your phone number has been updated" />,
              );

              const updatedSession =
                await UserSessionsApi.fetchSessionForCurrentUser();
              dispatch(setSession(updatedSession));

              setWantsToUpdatePhone(false);
            } catch (e) {
              handleError(e);
            }
          }}
          render={(props) => (
            <>
              <TextField
                name="phone"
                label="Phone Number"
                placeholder="0401 301 201"
                className="margin-top-2"
                required
                validate={requiredValidator}
              />

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Update"
                )}
              </button>
            </>
          )}
        />
      </Modal>

      {/* Status */}
      <InfoRow className="margin-bottom-2">
        <InfoItem xs={5} md={3}>
          <Label>Status</Label>
        </InfoItem>
        <InfoItem xs={7} md={6}>
          <Value>Active</Value>
        </InfoItem>
        <InfoItem xs={7} md={3} offset={{ xs: 5, md: 0 }}>
          <button
            className="button button-remove"
            style={{ padding: 0 }}
            onClick={() => setWantsToDeleteAccount(true)}
          >
            Delete
          </button>
        </InfoItem>
      </InfoRow>

      <Modal
        title="Delete Account"
        isOpen={wantsToDeleteAccount}
        onClose={() => setWantsToDeleteAccount(false)}
      >
        <Form
          onSubmit={async (values) => {
            await UsersApi.removeCurrentUser(values);
            toast.success("Your account has been deactivated.");

            dispatch({
              type: "CLEAR_OUT",
            });
          }}
          render={(props) => (
            <>
              <TextField
                name="confirm"
                label="Enter DELETE to confirm"
                className="margin-top-2"
                type="text"
                required
                validate={requiredValidator}
              />
              <p className="helper-text margin-top-1">
                You can reactivate your account by registering with the same
                email address
              </p>

              <button
                className="button button-primary margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Delete"
                )}
              </button>
            </>
          )}
        />
      </Modal>
    </>
  );
}
