import DocumentTitle from "react-document-title";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ContactsList, { sortContacts } from "../../components/contact-list";
import { CompanyRoutes } from "../../routes";
import { selectCurrentLogbook } from "../../store/features/logbooks.slice";

const StyledHeading = styled.h2`
  @media only screen and (max-width: 576px) {
    margin-bottom: 16px;
  }
`;

export default function CompanyLogbookContacts() {
  const logbook = useSelector(selectCurrentLogbook);
  const contacts = logbook.contacts;
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Logbook Contacts | inndox">
      <Container className="folder">
        <Row className="margin-bottom-3">
          <Col xs={12} md={6} className="flex">
            <StyledHeading className="name">Logbook Contacts</StyledHeading>
          </Col>

          <Col xs={12} md={6} className="flex end">
            <button
              className="button button-secondary margin-right-2"
              onClick={() =>
                navigate(
                  "/" +
                    CompanyRoutes.basePath.replace("/*", "") +
                    "/" +
                    CompanyRoutes.logbook.replace(":logbookId", logbook.id) +
                    "/" +
                    CompanyRoutes.addLogbookContact,
                )
              }
            >
              Add a new contact
            </button>
          </Col>
        </Row>

        <ContactsList contacts={sortContacts(contacts)} canEdit showCompany />
      </Container>
    </DocumentTitle>
  );
}
