import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LogbooksApi } from '../api/properties';
import { setCurrentTemplate } from '../store/features/templates.slice';

/**
 *
 * @param {number} templateLogbookId
 */
export function useTemplateData(templateLogbookId) {
	const dispatch = useDispatch();
	const [isFetchingData, setIsFetchingData] = useState(true);

	useEffect(() => {
		async function fetchLogbook() {
			try {
				// logbook
				const template = await LogbooksApi.fetchLogbook(templateLogbookId);
				dispatch(setCurrentTemplate(template));
			} catch (e) {
				console.error('template data > error fetching data');
			} finally {
				setIsFetchingData(false);
			}
		}
		fetchLogbook();
	}, [templateLogbookId, dispatch]);

	return isFetchingData;
}
