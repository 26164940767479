import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { FoldersApi } from "../api/folders";
import {
  addFolderToLogbook,
  updateLogbookFolder,
} from "../store/features/logbooks.slice";
import {
  addFolderToTemplate,
  updateTemplateFolder,
} from "../store/features/templates.slice";
import Checkbox from "./form/checkbox";
import TextField from "./form/text-field";
import { requiredValidator } from "./form/validators";
import { handleError } from "./helpers";
import Modal from "./modal";
import Toast from "./toast";

/**
 * @typedef {Object} AddOrEditFolderModalProps
 * @property {inndox.Folder} [folder]
 * @property {number} logbookId
 * @property {boolean} isVisible
 * @property {Function} onClose
 * @property {boolean} [isPartOfTemplate]
 */

/**
 *
 * @param {AddOrEditFolderModalProps} props
 * @returns
 */
export default function AddOrEditFolderModal({
  folder,
  isVisible,
  onClose,
  logbookId,
  isPartOfTemplate,
}) {
  const isEditingFolder = !!folder;
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={isVisible}
      title={isEditingFolder ? "Edit Folder" : "New Folder"}
      onClose={onClose}
    >
      <Form
        initialValues={{
          logbookId,
          ...folder,
        }}
        onSubmit={async ({ logbookId, ...remainingAttributes }) => {
          try {
            if (isEditingFolder) {
              const updatedFolder = await FoldersApi.updateFolder(
                logbookId,
                remainingAttributes.id,
                remainingAttributes,
              );

              if (isPartOfTemplate) {
                dispatch(updateTemplateFolder(updatedFolder));
              } else {
                dispatch(updateLogbookFolder(updatedFolder));
              }
            } else {
              const createdFolder = await FoldersApi.createNewFolder(
                logbookId,
                remainingAttributes,
              );

              if (isPartOfTemplate) {
                dispatch(addFolderToTemplate(createdFolder));
              } else {
                dispatch(addFolderToLogbook(createdFolder));
              }
            }

            toast.success(
              <Toast
                title="Success!"
                details="Your folder has been created."
              />,
            );

            onClose();
          } catch (e) {
            handleError(e);
          }
        }}
        render={(props) => (
          <>
            <p className="margin-top-1 margin-bottom-2">
              {isEditingFolder
                ? "Update folder details"
                : "Easily manage your documents by adding a new folder below."}
            </p>

            <TextField
              name="name"
              label="Name"
              required
              validate={requiredValidator}
              className="margin-top-2"
              autoFocus
            />

            <TextField
              name="description"
              label="Description"
              className="margin-top-2"
            />

            <Checkbox
              label="Make folder private"
              name="isPrivate"
              className="margin-top-2"
            />

            <div className="flex margin-top-5">
              <button
                className="button button-primary button-large button-big"
                style={{ minWidth: 123 }}
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : isEditingFolder ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>

              <button
                className="button button-secondary-link button-big margin-left-1"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      />
    </Modal>
  );
}
