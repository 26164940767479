import rexLogo from "../assets/images/rex.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  selectCompany,
  updateCurrentCompany,
} from "../store/features/company.slice";
import { CompanyApi } from "../api/company";
import { handleError } from "./helpers";
import { Col } from "react-grid-system";
import { ClipLoader } from "react-spinners";
import Modal from "./modal";
import { Form } from "react-final-form";
import TextField from "./form/text-field";
import { styled } from "styled-components";

const Content = styled.div`
  padding: 32px 32px 24px;
  border-radius: 8px;
  border: 1px solid #dde0e3;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.p`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
`;

export default function RexIntegration({ companyId }) {
  const [isAddingRexCredentials, setIsAddingRexCredentials] = useState(false);
  const [isRemovingRexIntegration, setIsRemovingRexIntegration] =
    useState(false);
  const company = useSelector(selectCompany);
  const dispatch = useDispatch();

  // methods
  const addRexIntegration = async (values) => {
    try {
      await CompanyApi.updateRexIntegration(values);

      dispatch(
        updateCurrentCompany({
          id: companyId,

          integrations: company.integrations
            ? [...company.integrations, "Rex"]
            : ["Rex"],
        })
      );

      setIsAddingRexCredentials(false);
    } catch (e) {
      handleError(e);
    }
  };

  const removeRexIntegration = async () => {
    setIsRemovingRexIntegration(true);

    try {
      await CompanyApi.removeRexIntegration();

      const integrations = company.integrations.filter(
        (integration) => !integration.toLowerCase().includes("rex")
      );
      dispatch(
        updateCurrentCompany({
          id: companyId,

          integrations,
        })
      );
    } catch (e) {
      handleError(e);
    } finally {
      setIsRemovingRexIntegration(false);
    }
  };

  // view
  const hasIntegratedWithRex = company?.integrations?.indexOf("Rex") > -1;
  return (
    <>
      <Col xs={12} sm={6} className="margin-top-2 margin-bottom-1">
        <Content style={{ textAlign: "center" }}>
          <IconContainer>
            <img src={rexLogo} alt="rex logo" />
          </IconContainer>

          <Title>Rex</Title>

          <p>
            {hasIntegratedWithRex
              ? "Your inndox is connected to Rex."
              : "Real estate software and automation platform."}
          </p>

          {hasIntegratedWithRex ? (
            <button
              className="button button-remove margin-top-2"
              onClick={removeRexIntegration}
            >
              {isRemovingRexIntegration && (
                <ClipLoader size={16} color="#FF3E3E" loading />
              )}
              {isRemovingRexIntegration ? "Removing..." : "Remove"}
            </button>
          ) : (
            <button
              className="button button-secondary margin-top-2"
              onClick={() => setIsAddingRexCredentials(true)}
            >
              Connect with Rex
            </button>
          )}
        </Content>
      </Col>

      {/* Rex Credentials Modal */}
      {hasIntegratedWithRex ? null : (
        <Modal
          icon={rexLogo}
          title=" "
          isOpen={isAddingRexCredentials}
          onClose={() => setIsAddingRexCredentials(false)}
        >
          <Form
            onSubmit={addRexIntegration}
            render={(props) => (
              <>
                <TextField
                  name="email"
                  label="Email"
                  placeholder="Rex account email"
                  className="margin-top-2"
                />

                <TextField
                  name="password"
                  label="Password"
                  placeholder="Rex account password"
                  className="margin-top-2"
                />

                <button
                  className="button button-primary margin-top-3"
                  onClick={props.submitting ? undefined : props.handleSubmit}
                >
                  {props.submitting ? (
                    <ClipLoader loading size={16} color="#fff" />
                  ) : (
                    "Connect"
                  )}
                </button>
              </>
            )}
          />
        </Modal>
      )}
    </>
  );
}
