import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes, TemplateRoutes } from '../routes';
import { selectTemplates } from '../store/features/templates.slice';
import { TOUR_LOCAL_STORAGE_KEYS } from './helpers';
import SetupBlock from './setup-block';
import TemplateList from './template-list';

export default function TemplatesWidget() {
	const templates = useSelector(selectTemplates);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const goToPageAndShowTour = (location, tourKey) => {
		// Set tour to show if tour key provided
		if (location) {
			navigate(location);
		}
	};

	if (!templates?.length) {
		return (
			<SetupBlock
				title="Setup maintenance reminders."
				description="Save time when creating your logbooks.">
				<div className="margin-top-2">
					<button
						id="property-templates-setup"
						onClick={() => {
							goToPageAndShowTour(
								'/' +
									CompanyRoutes.basePath.replace('/*', '') +
									'/' +
									TemplateRoutes.newTemplate,
								TOUR_LOCAL_STORAGE_KEYS.COMPANY_PROPERTY_TEMPLATE_CREATE,
								dispatch
							);
						}}
						className="button button-secondary">
						Setup logbook templates
					</button>
				</div>
			</SetupBlock>
		);
	}

	return <TemplateList templates={templates.slice(0, 5)} showActions />;
}
