import { Col, Hidden, Row } from 'react-grid-system';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes, TemplateRoutes } from '../routes';
import logbookTypeRealEstate from '../assets/images/logbook-type-real-estate.svg';
import logbookTypeVehicle from '../assets/images/logbook-type-vehicle.svg';
import logbookTypeMarine from '../assets/images/logbook-type-marine.svg';
import logbookTypeCaravan from '../assets/images/logbook-type-caravan.svg';
import logbookTypeOther from '../assets/images/logbook-type-other.svg';
import styled from 'styled-components';
import MoreMenu, {
	CLONE_TEMPLATE_MENU_ITEM,
	DELETE_TEMPLATE_MENU_ITEM,
	EDIT_TEMPLATE_MENU_ITEM,
} from './more-menu';
import Modal from './modal';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { ClipLoader } from 'react-spinners';
import { handleError } from './helpers';
import { TemplatesApi } from '../api/templates';
import { useDispatch } from 'react-redux';
import { removeTemplate } from '../store/features/templates.slice';
import { toast } from 'react-toastify';
import Toast from './toast';
import SetupBlock from './setup-block';
import CloneTemplateDialog from './clone-template';

const LOGBOOK_TYPE_TO_ICON_MAPPING = {
	Property: logbookTypeRealEstate,
	Vehicle: logbookTypeVehicle,
	Caravan: logbookTypeCaravan,
	Marine: logbookTypeMarine,
	Other: logbookTypeOther,
};

const LogbookTypeIconContainer = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LogbookTypeIcon = styled.img`
	width: 100%;
	height: 100%;
	max-width: 26px;
	max-height: 26px;
`;

/**
 * @typedef {Object} TemplateRowItemProps
 * @property {inndox.Template} template
 */

/**
 *
 * @param {TemplateRowItemProps} props
 */
function TemplateRowItem({ template }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [wantsToCloneTemplate, setWantsToCloneTemplate] = useState(false);
	const [wantsToDeleteTemplate, setWantsToDeleteTemplate] = useState(false);

	const actionSelectedMenuOption = (value) => {
		switch (value) {
			case CLONE_TEMPLATE_MENU_ITEM.value: {
				// clone template
				setWantsToCloneTemplate(true);
				break;
			}

			case DELETE_TEMPLATE_MENU_ITEM.value: {
				// delete template
				setWantsToDeleteTemplate(true);
				break;
			}

			case EDIT_TEMPLATE_MENU_ITEM.value: {
				navigate(
					'/' +
						CompanyRoutes.basePath.replace('/*', '') +
						'/' +
						CompanyRoutes.editTemplate.replace(':templateId', template.id)
				);
				break;
			}

			default:
				break;
		}
	};

	const menuOptions = [
		CLONE_TEMPLATE_MENU_ITEM,
		EDIT_TEMPLATE_MENU_ITEM,
		DELETE_TEMPLATE_MENU_ITEM,
	];

	return (
		<Row>
			<Col xs={3} md={1} className="col-body">
				<LogbookTypeIconContainer>
					<LogbookTypeIcon
						src={LOGBOOK_TYPE_TO_ICON_MAPPING[template.logbookType]}
						alt={template.logbookType}
					/>
				</LogbookTypeIconContainer>
			</Col>
			<Col xs={6} md={4} className="col-body">
				<p
					className="link-style-elem truncate"
					onClick={() =>
						navigate(
							'/' +
								CompanyRoutes.basePath.replace('/*', '') +
								'/' +
								TemplateRoutes.template.replace(':templateId', template.propertyId)
						)
					}>
					{template.name}
				</p>
			</Col>
			<Hidden xs sm>
				<Col md={6} className="col-body">
					{template.description}
				</Col>
			</Hidden>
			<Col xs={3} md={1} className="col-body">
				<MoreMenu options={menuOptions} onSelect={actionSelectedMenuOption} />
			</Col>

			{/* Clone template modal */}
			<CloneTemplateDialog
				isOpen={wantsToCloneTemplate}
				onClose={() => setWantsToCloneTemplate(false)}
				templateId={template.id}
			/>

			{/* Delete template modal */}
			<Modal
				title="Delete Template"
				isOpen={wantsToDeleteTemplate}
				onClose={() => setWantsToDeleteTemplate(false)}>
				<p className="margin-top-2">
					Are you sure you want to delete the template -{' '}
					<strong>{template.name}</strong> ?
				</p>

				<div className="flex margin-top-3">
					<Form
						onSubmit={async () => {
							try {
								await TemplatesApi.removeTemplate(template.id);
								dispatch(removeTemplate(template.id));

								toast.success(
									<Toast title={`${template.name} has been successfully deleted.`} />
								);
							} catch (e) {
								handleError(e);
							}
						}}
						render={(props) => (
							<button
								className="button button-archive"
								onClick={props.submitting ? undefined : props.handleSubmit}>
								{props.submitting ? (
									<ClipLoader loading size={16} color="#fff" />
								) : (
									'Delete template'
								)}
							</button>
						)}
					/>

					<button
						className="button button-alt margin-left-1"
						onClick={() => setWantsToDeleteTemplate(false)}>
						Cancel
					</button>
				</div>
			</Modal>
		</Row>
	);
}

/**
 * @typedef {Object} TemplateListProps
 * @property {inndox.Template[]} templates
 * @property {boolean} [showActions]
 */

/**
 *
 * @param {TemplateListProps} props
 */
export default function TemplateList({ templates, showActions = false }) {
	const navigate = useNavigate();

	if (!templates?.length) {
		return <SetupBlock description="There are no templates added yet" />;
	}

	return (
		<div className="document-list">
			<Row>
				<Col xs={3} md={1} className="col-head">
					Type
				</Col>
				<Col xs={6} md={4} className="col-head">
					Name
				</Col>
				<Hidden xs sm>
					<Col md={6} className="col-head">
						Description
					</Col>
				</Hidden>
				<Col xs={3} md={1} className="col-head"></Col>
			</Row>

			{templates.map((template) => (
				<TemplateRowItem key={template.id} template={template} />
			))}

			{showActions ? (
				<Row
					justify="end"
					style={{ borderTop: '1px solid #dde0e3', paddingTop: '16px' }}>
					<button
						className="button button-secondary"
						onClick={() =>
							navigate(
								'/' +
									CompanyRoutes.basePath.replace('/*', '') +
									'/' +
									TemplateRoutes.newTemplate
							)
						}>
						Add new
					</button>
					<button
						className="button button-alt"
						onClick={() =>
							navigate(
								'/' +
									CompanyRoutes.basePath.replace('/*', '') +
									'/' +
									TemplateRoutes.templates
							)
						}
						style={{
							marginLeft: 8,
						}}>
						View all
					</button>
				</Row>
			) : (
				<noscript />
			)}
		</div>
	);
}
