import moment from "moment";
import onClickOutside from "react-onclickoutside";
import { Component } from "react";
import styled from "styled-components";
import { SHARE_SITES } from "./add-to-calendar/enums";
import AddToCalendarHOC from "./add-to-calendar/index";

const DropdownContainer = styled.div`
  top: calc(100% + 8px);
  background-color: #fff;
  max-width: 100%;
  position: absolute;
  right: 0;
  border: 1px solid #dde0e3;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 128px;
  z-index: 1;

  > a {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding: 0 16px;

    &:hover {
      background-color: rgba(189, 210, 63, 0.27);
    }
  }
`;

const AddToCalendarContainer = styled.div`
  display: flex;

  > div {
    position: relative;
  }
`;

const TriggerContainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;

  svg path {
    fill: #adadb0;
  }

  &:hover {
    background-color: #bdd23f;

    svg path {
      fill: #fff;
    }
  }
`;

class Dropdown extends Component {
  handleClickOutside = (e) => {
    this.props.onRequestClose(e);
  };

  render() {
    return (
      <DropdownContainer onClick={this.props.onRequestClose}>
        {this.props.children}
      </DropdownContainer>
    );
  }
}

const Trigger = ({ children, onClick }) => {
  return (
    <TriggerContainer onClick={onClick}>
      <svg viewBox="0 0 448 512" height={20}>
        <path d="M336 292v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76h-76c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12zm112-180v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path>
      </svg>
    </TriggerContainer>
  );
};

/**
 * The solution is to have a button that is always there
 * and on click renders a dropdown in desktop and a full
 * screen modal on mobile devices. Users can add to Apple,
 * Google or Outlook.
 */

/**
 * AddToCalendarWidget
 * @param {*} props
 */
export default function AddToCalendar(props) {
  const date = moment(props.date).hour(9).minutes(0).seconds(0);

  const event = {
    title: props.title,
    duration: "2400",
    startDatetime: date.format("YYYYMMDDTHHmmss"),
    endDatetime: date.hour(10).format("YYYYMMDDTHHmmss"),
    location: props.location,
  };
  if (props.description) {
    event.description = props.description;
  }

  const Widget = AddToCalendarHOC(Trigger, onClickOutside(Dropdown));

  return (
    <AddToCalendarContainer>
      <Widget
        event={event}
        items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
      />
    </AddToCalendarContainer>
  );
}
