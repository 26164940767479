import { useDispatch } from 'react-redux';
import { DefectsApi } from '../api/defects';
import {
	removeCompanyDefect,
	updateCompanyDefect,
} from '../store/features/company.slice';
import { toast } from 'react-toastify';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import styled from 'styled-components';
import moment from 'moment';
import MoreMenu, {
	ASSIGN_DEFECT_MENU_ITEM,
	COMPLETE_DEFECT_MENU_ITEM,
	DELETE_DEFECT_MENU_ITEM,
	EDIT_DEFECT_MENU_ITEM,
	VIEW_DEFECT_MENU_ITEM,
} from './more-menu';
import { CompanyRoutes, OwnerRoutes } from '../routes';
import { useNavigate } from 'react-router-dom';
import Toast from './toast';
import { useAuth } from './authentication';
import { removeDefectFromCurrentLogbook } from '../store/features/logbooks.slice';

const DefectLabel = styled.span`
	padding: 2px 4px;
	font-size: 12px;
	line-height: 14px;
	font-weight: bold;
	border-radius: 4px;
	background-color: #ffaa00;
	color: #fff;

	&.urgent {
		background-color: #d44215;
		color: #fff;
	}

	&.resolved {
		background-color: #bfd62f;
		color: #fff;
	}

	&.completed {
		background-color: #dfdee3;
		color: #fff;
	}
`;

/**
 *
 * @typedef {Object} LogbookDefectRowItemProps
 * @property {inndox.LogbookDefect} defect
 * @property {boolean} isCurrentUserACompany
 * @property {'global' | 'logbook'} source
 * @property {number} propertyId
 * @property {boolean} showAddress
 * @returns
 */

/**
 *
 * @param {LogbookDefectRowItemProps} props
 * @returns
 */
export default function LogbookDefectRowItem({
	defect,
	source = 'logbook',
	isCurrentUserACompany,
	propertyId,
	showAddress = false,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { session } = useAuth();

	// Defect Status = Open | Resolved | Completed;
	const completeDefect = async () => {
		try {
			await DefectsApi.updateDefectStatus(propertyId, defect.id, {
				id: defect.id,
				status: 2,
			});

			if (source === 'global') {
				dispatch(
					updateCompanyDefect({
						...defect,
						status: 'Completed',
					})
				);
			} else {
				// TODO: add for property defect
			}

			toast.success(<Toast title="The defect has been completed." />);
		} catch (e) {
			toast.error(
				<Toast title="There was an error marking the defect as completed. Please try again later." />
			);
		}
	};

	const deleteDefect = async () => {
		try {
			await DefectsApi.removeDefect(propertyId, defect.id);

			if (source === 'global') {
				dispatch(removeCompanyDefect(defect.id));
			} else {
				dispatch(removeDefectFromCurrentLogbook(defect.id));
			}

			toast.success(<Toast title="The defect has been deleted." />);
		} catch (e) {
			toast.error(
				<Toast title="There was an error removing the defect. Please try again later." />
			);
		}
	};

	const actionSelectedMenuOption = (value) => {
		switch (value) {
			case VIEW_DEFECT_MENU_ITEM.value: {
				// view details of defect
				if (isCurrentUserACompany) {
					if (source === 'logbook') {
						navigate(
							'/' +
								CompanyRoutes.basePath.replace('/*', '') +
								'/' +
								CompanyRoutes.logbook.replace(':logbookId', defect.property.id) +
								'/' +
								CompanyRoutes.logbookDefect.replace(':defectId', defect.id)
						);
					} else {
						navigate(
							'/' +
								CompanyRoutes.basePath.replace('/*', '') +
								'/' +
								CompanyRoutes.companyDefect.replace(':defectId', defect.id)
						);
					}
				} else {
					navigate(
						'/' +
							OwnerRoutes.basePath.replace('/*', '') +
							'/' +
							OwnerRoutes.logbook.replace(':logbookId', defect.property.id) +
							'/' +
							OwnerRoutes.logbookDefect.replace(':defectId', defect.id)
					);
				}

				break;
			}

			case EDIT_DEFECT_MENU_ITEM.value: {
				// edit defect (only for user who creates the issue)
				if (isCurrentUserACompany) {
					navigate(
						'/' +
							CompanyRoutes.basePath.replace('/*', '') +
							'/' +
							CompanyRoutes.logbook.replace(':logbookId', defect.property.id) +
							'/' +
							OwnerRoutes.editLogbookDefect.replace(':defectId', defect.id)
					);
				} else {
					navigate(
						'/' +
							OwnerRoutes.basePath.replace('/*', '') +
							'/' +
							OwnerRoutes.logbook.replace(':logbookId', defect.property.id) +
							'/' +
							OwnerRoutes.editLogbookDefect.replace(':defectId', defect.id)
					);
				}
				break;
			}

			case ASSIGN_DEFECT_MENU_ITEM.value: {
				// assign defect
				if (isCurrentUserACompany) {
					if (source === 'global') {
						navigate(
							'/' +
								CompanyRoutes.basePath.replace('/*', '') +
								'/' +
								CompanyRoutes.assignCompanyDefect.replace(':defectId', defect.id)
						);
					} else {
						navigate(
							'/' +
								CompanyRoutes.basePath.replace('/*', '') +
								'/' +
								CompanyRoutes.logbook.replace(':logbookId', defect.property.id) +
								'/' +
								CompanyRoutes.assignCompanyDefect.replace(':defectId', defect.id)
						);
					}
				}

				break;
			}

			case COMPLETE_DEFECT_MENU_ITEM.value: {
				// complete defect
				completeDefect();
				break;
			}

			case DELETE_DEFECT_MENU_ITEM.value: {
				// delete defect
				deleteDefect();
				break;
			}

			default:
				break;
		}
	};

	const menuItems = [VIEW_DEFECT_MENU_ITEM];
	if (defect.status !== 'Completed') {
		if (isCurrentUserACompany) {
			menuItems.push(ASSIGN_DEFECT_MENU_ITEM);
			menuItems.push(COMPLETE_DEFECT_MENU_ITEM);
		}
	}
	// This ensures only the user who reported the defect
	// can edit it
	if (defect.reportedBy === session.fullName) {
		menuItems.push(EDIT_DEFECT_MENU_ITEM);
	}
	// if canDelete is true, then allow to do it
	if (defect.canDelete) {
		menuItems.push(DELETE_DEFECT_MENU_ITEM);
	}

	const defectLabel =
		defect.status === 'Completed' ? (
			<DefectLabel className="completed">Completed</DefectLabel>
		) : defect.status === 'Resolved' ? (
			<DefectLabel className="resolved">Resolved</DefectLabel>
		) : defect.isUrgent ? (
			<DefectLabel className="urgent">Urgent</DefectLabel>
		) : (
			<DefectLabel>In progress</DefectLabel>
		);

	return (
		<Row>
			{/* Status */}
			<Hidden xs>
				<Col xs={3} sm={2} className="col-body">
					{defectLabel}
				</Col>
			</Hidden>

			{/* Created On */}
			<Hidden xs>
				<Col sm={source === 'logbook' ? 3 : 2} className="col-body">
					{moment(defect.createdOn).format('DD/MM/YYYY')}
				</Col>
			</Hidden>

			{/* Address */}
			{source !== 'logbook' && showAddress ? (
				<Hidden xs>
					<Col sm={4} className="col-body">
						{defect.property.description}
					</Col>
				</Hidden>
			) : (
				<noscript />
			)}

			{/* Subject */}
			<Col xs={9} sm={source === 'logbook' ? 6 : 3} className="col-body">
				<Visible xs>
					{defectLabel}
					<span style={{ marginLeft: 8 }}>{defect.subject}</span>
				</Visible>
				<Hidden xs>{defect.subject}</Hidden>
			</Col>

			{/* Description */}
			{showAddress ? (
				<noscript />
			) : (
				<Hidden xs>
					<Col sm={4} className="col-body">
						{defect.description
							? defect.description.length > 64
								? defect.description.substr(0, 64) + '...'
								: defect.description
							: ''}
					</Col>
				</Hidden>
			)}

			{/* Actions */}
			<Col xs={3} sm={1} className="col-body flex end">
				<MoreMenu options={menuItems} onSelect={actionSelectedMenuOption} />
			</Col>
		</Row>
	);
}
