import styled from 'styled-components';
import googleIcon from '../assets/images/google.svg';

const P = styled.p`
	font-size: 16px;
	line-height: 28px;
	letter-spacing: 0.2px;
	color: #798995;
`;

const GoogleButton = styled.button`
	background-color: #efefef;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 5px;
	border: none;
	width: 100%;
	height: 56px;
	cursor: pointer;
	margin-top: 16px;
	margin-bottom: 16px;

	&:hover {
		background-color: #e8e8e8;
	}

	&:active {
		background-color: #d5d5d5;
	}
`;

const GoogleIcon = styled.div`
	width: 56px;
	height: 56px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 22px;
`;

/**
 *
 * @param { Object } props
 * @param { string } props.redirectUrl
 */
export function GoogleSignIn({ redirectUrl }) {
	const signInWithGoogle = async () => {
		window.location.href =
			process.env.REACT_APP_BASE_URL +
			`/api/v1/UserSessions/byoauth/Google?redirectUrl=${redirectUrl}`;
	};

	return (
		<GoogleButton onClick={signInWithGoogle} type="button">
			<GoogleIcon>
				<img src={googleIcon} alt="Google" />
			</GoogleIcon>

			<P
				style={{
					color: '#143A5A',
					marginRight: 22,
					marginLeft: 8,
				}}>
				Sign in with Google
			</P>
		</GoogleButton>
	);
}
