import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import { Col, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { DocumentsApi } from "../../../api/documents";
import logbookCoverCaravan from "../../../assets/images/logbook-cover-caravan.png";
import logbookCoverMarine from "../../../assets/images/logbook-cover-marine.png";
import logbookCoverOther from "../../../assets/images/logbook-cover-other.png";
import logbookCoverRealEstate from "../../../assets/images/logbook-cover-real-estate.png";
import logbookCoverVehicle from "../../../assets/images/logbook-cover-vehicle.png";
import { FiftyFifty } from "../../../components/fifty-fifty";
import Dropzone from "../../../components/form/dropzone";
import { CompanyRoutes, OwnerRoutes } from "../../../routes";
import { selectCurrentLogbook } from "../../../store/features/logbooks.slice";
import { isCurrentUserACompany } from "../../../store/features/user.slice";

const LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING = {
  Property: logbookCoverRealEstate,
  Vehicle: logbookCoverVehicle,
  Caravan: logbookCoverCaravan,
  Marine: logbookCoverMarine,
  Other: logbookCoverOther,
};

function LogbookFirstFileForm({ logbookId }) {
  const navigate = useNavigate();
  const isCompanyUser = useSelector(isCurrentUserACompany);

  return (
    <Form
      onSubmit={async (values) => {
        const formData = new FormData();
        formData.append("propertyId", logbookId);
        formData.append("documentFile", values["documentFile"]);

        await DocumentsApi.uploadDocumentsToProperty(logbookId, formData);

        navigate(
          isCompanyUser
            ? "/" +
                CompanyRoutes.basePath.replace("/*", "") +
                "/" +
                CompanyRoutes.logbook.replace(":logbookId", logbookId)
            : "/" +
                OwnerRoutes.basePath.replace("/*", "") +
                "/" +
                OwnerRoutes.logbook.replace(":logbookId", logbookId),
        );
      }}
      render={(props) => (
        <Row>
          <Col xs={12}>
            <Dropzone
              name="documentFile"
              placeholder="Drag a file here or click to browse"
              className="margin-top-4"
              accept=".doc,.docx,.png,.jpg,.jpeg,.pdf,.xls,.xlsx,.txt,.zip"
              removeCamera
            />
          </Col>

          <Col xs={12}>
            <button
              className="button button-primary button-large button-big margin-top-4"
              style={{ maxWidth: 380, width: "100%" }}
              onClick={props.submitting ? undefined : props.handleSubmit}
            >
              {props.submitting ? (
                <ClipLoader loading size={16} color="#fff" />
              ) : (
                "Upload"
              )}
            </button>

            <button
              className="button button-link button-large button-big margin-top-4"
              style={{ maxWidth: 380, width: "100%", color: "#68C9D6" }}
              onClick={() =>
                navigate(
                  isCompanyUser
                    ? "/" +
                        CompanyRoutes.basePath.replace("/*", "") +
                        "/" +
                        CompanyRoutes.logbook.replace(":logbookId", logbookId)
                    : "/" +
                        OwnerRoutes.basePath.replace("/*", "") +
                        "/" +
                        OwnerRoutes.logbook.replace(":logbookId", logbookId),
                )
              }
            >
              Skip and add later
            </button>
          </Col>
        </Row>
      )}
    />
  );
}

export default function NewLogbookFirstFile() {
  const logbook = useSelector(selectCurrentLogbook);

  return (
    <DocumentTitle title="New Logbook Details | inndox">
      <FiftyFifty
        backgroundImage={
          LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING[logbook.logbookType]
        }
      >
        <h1>Add your first file</h1>

        <p
          className="margin-top-1"
          style={{
            maxWidth: 326,
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 20,
          }}
        >
          e.g. a building report or any file to do with this logbook
        </p>

        <div className="margin-top-2">
          <LogbookFirstFileForm logbookId={logbook.id} />
        </div>
      </FiftyFifty>
    </DocumentTitle>
  );
}
