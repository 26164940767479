import { Col, Hidden, Row } from 'react-grid-system';
import uploadIcon from '../assets/images/upload_icon.svg';
import viewIcon from '../assets/images/view_icon.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { CompanyRoutes, SharedRoutes } from '../routes';
import logbookTypeRealEstate from '../assets/images/logbook-type-real-estate.svg';
import logbookTypeVehicle from '../assets/images/logbook-type-vehicle.svg';
import logbookTypeMarine from '../assets/images/logbook-type-marine.svg';
import logbookTypeCaravan from '../assets/images/logbook-type-caravan.svg';
import logbookTypeOther from '../assets/images/logbook-type-other.svg';
import SetupBlock from './setup-block';

const LOGBOOK_TYPE_TO_ICON_MAPPING = {
	Property: logbookTypeRealEstate,
	Vehicle: logbookTypeVehicle,
	Caravan: logbookTypeCaravan,
	Marine: logbookTypeMarine,
	Other: logbookTypeOther,
};

const Permission = styled.div`
	margin-right: 16px;
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1.38;
	color: #d7def0;
	width: 112px;
	flex-shrink: 0;
`;

const PermissionIcon = styled.div`
	width: 32px;
	height: 32px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
`;

const LogbookTypeIcon = styled.div`
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;

	img {
		max-width: 32px;
		max-height: 32px;
	}
`;

/**
 * @typedef {Object} SharedLogbooksWidgetProps
 * @property {inndox.SharedFolderLogbook[]} logbooks
 */

/**
 *
 * @param {SharedLogbooksWidgetProps} props
 */
export default function SharedLogbooksWidget({ logbooks }) {
	const folders = logbooks.reduce((reduction, logbook) => {
		return reduction.concat(
			logbook.folders.map((folder) => ({
				folder,
				logbook,
			}))
		);
	}, []);
	const navigate = useNavigate();

	if (!folders?.length) {
		return (
			<SetupBlock description="No folders have been shared with you yet"></SetupBlock>
		);
	}

	return (
		<div className="document-list bottom-only shared-folders-widget">
			<Row>
				<Col xs={2} sm={1} className="col-head">
					Type
				</Col>

				<Col xs={10} sm={7} className="col-head">
					For Logbook
				</Col>

				<Hidden xs>
					<Col sm={2} className="col-head">
						Access
					</Col>
				</Hidden>

				<Hidden xs>
					<Col sm={2} className="col-head">
						Shared By
					</Col>
				</Hidden>
			</Row>
			{folders.slice(0, 4).map((folder) => {
				return (
					<Row key={`shared-folder-${folder.folder.id}`}>
						<Col xs={2} sm={1} className="col-body thick">
							<LogbookTypeIcon>
								<img
									src={LOGBOOK_TYPE_TO_ICON_MAPPING[folder.logbook.logbookType]}
									alt={folder}
								/>
							</LogbookTypeIcon>
						</Col>
						<Col xs={10} sm={7} className="col-body thick">
							<p className="truncate">
								<span
									className="link-style-elem"
									onClick={() =>
										navigate(
											'/' +
												SharedRoutes.basePath.replace('/*', '') +
												'/' +
												SharedRoutes.folder
													.replace(':logbookId', folder.logbook.id)
													.replace(':folderId', folder.folder.id)
										)
									}>
									{folder.folder.name}
								</span>

								<span>&nbsp;&nbsp;in&nbsp;&nbsp;</span>
								<span>
									{folder.logbook.addressFormatted || folder.logbook.description}
								</span>
							</p>
						</Col>
						<Hidden xs>
							<Col xs={2} className="col-body thick">
								{folder.folder.sharePermissions.indexOf('Create') > -1 ? (
									<Permission>
										<PermissionIcon>
											<img src={uploadIcon} alt="Upload icon" />
										</PermissionIcon>
										Upload
									</Permission>
								) : (
									<Permission>
										<PermissionIcon>
											<img src={viewIcon} alt="View only icon" />
										</PermissionIcon>
										View only
									</Permission>
								)}
							</Col>
						</Hidden>

						<Hidden xs>
							<Col xs={2} className="col-body thick">
								{folder.folder.sharedBy}
							</Col>
						</Hidden>
					</Row>
				);
			})}
			<Row
				justify="end"
				style={{ borderTop: '1px solid #dde0e3', paddingTop: '16px' }}>
				<button
					className="button button-alt"
					onClick={() =>
						navigate(
							'/' + SharedRoutes.basePath.replace('/*', '') + '/' + CompanyRoutes.home
						)
					}>
					View all
				</button>
			</Row>
		</div>
	);
}
