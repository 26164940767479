import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { LinksApi } from "../api/links";
import {
  addDocumentToLogbook,
  addDocumentToSharedLogbook,
  incrementLogbookFolderStatistics,
  incrementSharedLogbookFolderStatistics,
  updateDocumentToSharedFolder,
  updateLogbookDocument,
} from "../store/features/logbooks.slice";
import {
  addDocumentToCurrentTemplate,
  incrementTemplateFolderStatistics,
  updateTemplateDocument,
} from "../store/features/templates.slice";
import TextField from "./form/text-field";
import { requiredValidator } from "./form/validators";
import { handleError } from "./helpers";
import Modal from "./modal";
import Toast from "./toast";

/**
 * @typedef {Object} AddOrEditLinkModalProps
 * @property {number} logbookId
 * @property {number} [folderId]
 * @property {number} [subFolderId]
 * @property {number} [isPartOfSharedFolder]
 * @property {number} [isPartOfTemplate]
 * @property {boolean} isVisible
 * @property {Function} onClose
 * @property {inndox.LogbookDocument} [link]
 */

/**
 *
 * @param {AddOrEditLinkModalProps} props
 */
export default function AddOrEditLinkModal({
  isVisible,
  onClose,
  logbookId,
  folderId,
  subFolderId,
  link,
  isPartOfSharedFolder,
  isPartOfTemplate,
}) {
  const dispatch = useDispatch();
  const isEditingLink = !!link;

  return (
    <Modal
      title={
        isEditingLink
          ? "Edit link"
          : subFolderId
            ? "Add Link to Subfolder"
            : folderId
              ? "Add Link to Folder"
              : "Add Link to Logbook"
      }
      isOpen={isVisible}
      onClose={onClose}
    >
      <p className="margin-top-1">
        {isEditingLink
          ? "Update link properties"
          : "Add a link to an external document or resource. Link must include http or https prefix."}
      </p>

      <Form
        initialValues={{
          ...link,
          propertyId: logbookId,
          folderId,
          subfolderId: subFolderId,
        }}
        onSubmit={async (values) => {
          try {
            if (isEditingLink) {
              const updatedLink = await LinksApi.updateLink(
                logbookId,
                link.id,
                values,
              );

              if (isPartOfSharedFolder) {
                dispatch(updateDocumentToSharedFolder(updatedLink));
              } else if (isPartOfTemplate) {
                dispatch(updateTemplateDocument(updatedLink));
              } else {
                dispatch(updateLogbookDocument(updatedLink));
              }

              toast.success(
                <Toast title="The selected link has been updated" />,
              );
            } else {
              const createdLink = await LinksApi.createNewLink(
                logbookId,
                values,
              );

              if (isPartOfSharedFolder) {
                dispatch(addDocumentToSharedLogbook(createdLink));
                if (folderId) {
                  dispatch(
                    incrementSharedLogbookFolderStatistics({
                      id: folderId,
                    }),
                  );
                }
              } else if (isPartOfTemplate) {
                dispatch(addDocumentToCurrentTemplate(createdLink));
                if (folderId) {
                  dispatch(
                    incrementTemplateFolderStatistics({
                      id: folderId,
                    }),
                  );
                }
              } else {
                dispatch(addDocumentToLogbook(createdLink));
                if (folderId) {
                  dispatch(
                    incrementLogbookFolderStatistics({
                      id: folderId,
                    }),
                  );
                }
              }

              toast.success(<Toast title="New link has been created" />);
            }

            onClose();
          } catch (e) {
            handleError(e);
          }
        }}
        render={(props) => (
          <>
            <TextField
              name="name"
              label="Name"
              required
              validate={requiredValidator}
              className="margin-top-2"
            />

            <TextField
              name="link"
              label="Link"
              required
              validate={requiredValidator}
              className="margin-top-2"
            />

            <div className="margin-top-3">
              <button
                className="button button-primary button-large button-big full-width margin-top-4"
                onClick={props.submitting ? undefined : props.handleSubmit}
              >
                {props.submitting ? (
                  <ClipLoader loading size={16} color="#fff" />
                ) : (
                  "Update link"
                )}
              </button>
              <button
                className="button button-link button-large button-big full-width"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      />
    </Modal>
  );
}
