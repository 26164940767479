import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './footer';
import Header from './header';

const OutletContainer = styled.div`
	flex-grow: 1;
	min-height: calc(100vh - 80px - 56px);
	padding-top: 40px;
	padding-bottom: 64px;

	@media only screen and (max-width: 576px) {
		padding-top: 32px;
		padding-bottom: 32px;
	}
`;

export default function PageLayout() {
	return (
		<>
			<Header />

			<OutletContainer>
				<Outlet />
			</OutletContainer>

			<Footer />
		</>
	);
}
