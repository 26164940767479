import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logoOnlyIcon from "../assets/images/logo-only.svg";
import { AccountRoutes } from "../routes";

const FiftyFiftyContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  height: 100vh;

  @media only screen and (max-width: 576px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const FortyChild = styled.div`
  flex: 0 0 50%;
  background-size: auto 100%;
  position: relative;
  flex-shrink: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  @media only screen and (max-width: 576px) {
    flex: 0 0 auto;
    height: 200px;
    width: 100%;
  }
`;

const LogoHeader = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 32px;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    margin-top: 16px;
    margin-bottom: 16px;
    position: relative;
    top: unset;
  }

  img {
    max-width: 32px;
    max-height: 32px;
  }
`;

const SixtyChild = styled.div`
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  overflow: auto;
  background-color: #fff;

  @media only screen and (max-width: 576px) {
    flex-grow: 1;
    width: 100%;
    overflow: unset;
    padding: 0;
  }

  @media only screen and (max-height: 568px) {
    ${LogoHeader} {
      top: 10px;
    }
  }
`;

const Content = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  padding-right: 0;
  width: 100%;

  @media only screen and (max-width: 576px) {
    justify-content: flex-start;
    padding: 32px 24px;
    max-width: 482px;
  }
`;

export function FiftyFifty({ backgroundImage, children, expanded, stretch }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isOnLoginOrRegisterPage =
    pathname.includes(AccountRoutes.login) ||
    pathname.includes(AccountRoutes.register);

  return (
    <FiftyFiftyContainer>
      <SixtyChild>
        <Content id="fifty-fifty-content">
          {/* {expanded ? ( */}
          <LogoHeader
            onClick={() => {
              if (isOnLoginOrRegisterPage) {
                window.location.href = "https://inndox.com";
                // window.open();
              } else {
                navigate("/route-decider");
              }
            }}
          >
            <img src={logoOnlyIcon} alt="logo only" />
          </LogoHeader>
          {/* ) : (
						<noscript />
					)} */}

          {children}
        </Content>
      </SixtyChild>

      <FortyChild style={{ backgroundImage: `url(${backgroundImage})` }} />

      <style
        dangerouslySetInnerHTML={{
          __html:
            "header, footer, #sponsored-banner, #subscription-banner { display: none } #root { padding-top: 0 } #root > div:first-of-type { padding-top: 0px; padding-bottom: 0px; }",
        }}
      />
    </FiftyFiftyContainer>
  );
}
