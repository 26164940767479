import { axiosInstance } from './base';

export const UserSessionsApi = {
	/**
	 *
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.UserSession>}
	 */
	login: (data) => axiosInstance.post('/UserSessions/byemail', data),
	/**
	 *
	 * @param {string} token
	 * @param {Record<string,any>} data
	 * @returns {Promise<inndox.UserSession>}
	 */
	loginAnonymously: (token, data) =>
		axiosInstance.post(`/UserSessions/bytoken/${token}`, data),
	/**
	 *
	 * @param {string} token
	 * @returns {Promise}
	 */
	loginAsAContact: (token) =>
		axiosInstance.post(`/UserSessions/bycontact/${token}`),
	/**
	 *
	 * @returns {Promise<inndox.UserSession>}
	 */
	fetchSessionForCurrentUser: () => axiosInstance.get('/UserSessions/current'),
	/**
	 *
	 * @returns {Promise<void>}
	 */
	logout: () => axiosInstance.delete('/UserSessions/current'),
	/**
	 *
	 * @returns {Promise<inndox.UserSession>}
	 */
	undoImpersonate: () => axiosInstance.post('/UserSessions/undoimpersonate'),
};
