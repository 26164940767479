import { capitalize } from "lodash";
import QueryString from "query-string";
import DocumentTitle from "react-document-title";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { FiftyFifty } from "../../../components/fifty-fifty";
import {
  LOGBOOK_TYPE_TO_CLASS_MAPPING,
  LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING,
} from "../../../components/logbook-setup-forms";

const VALID_LOGBOOK_TYPES = ["property", "vehicle", "caravan", "marine"];

const LOGBOOK_TYPE_TO_TITLE_MAPPING = {
  Property: "Setup your real estate logbook",
  Vehicle: "Setup your vehicle logbook",
  Caravan: "Setup your caravan logbook",
  Marine: "Setup your marine logbook",
  Other: "Setup your logbook",
};

const Content = styled.div`
  max-width: 408px;
  width: 100%;
  max-height: calc(100% - 64px);
`;

/**
 *
 * @param {string} typeQueryParam
 * @returns {string}
 */
const getLogbookType = (typeQueryParam) => {
  if (VALID_LOGBOOK_TYPES.includes(typeQueryParam.toLowerCase())) {
    return capitalize(typeQueryParam);
  }

  return "Other";
};

/**
 *
 * @param {string} logbookType
 * @returns {JSX.Element}
 */
const getLogbookForm = (typeQueryParam) => {
  return LOGBOOK_TYPE_TO_CLASS_MAPPING[typeQueryParam];
};

export default function NewLogbookDetails() {
  const { search } = useLocation();
  const { type = "other" } = QueryString.parse(search);
  const logbookType = getLogbookType(type);
  const LogbookForm = getLogbookForm(logbookType);

  return (
    <DocumentTitle title="New Logbook Details | inndox">
      <FiftyFifty
        backgroundImage={LOGBOOK_TYPE_TO_SIDE_IMAGE_MAPPING[logbookType]}
      >
        <Content>
          <h1>{LOGBOOK_TYPE_TO_TITLE_MAPPING[logbookType]}</h1>

          <p
            className="margin-top-1"
            style={{
              maxWidth: 326,
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 20,
            }}
          >
            <strong>Tip:</strong>&nbsp;&nbsp; You can setup additional logbooks
            later.
          </p>

          <div className="margin-top-2">
            <LogbookForm />
          </div>
        </Content>
      </FiftyFifty>
    </DocumentTitle>
  );
}
